import React from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"

function EditMessage(props) {
    const actionItem = props.actionItem
    const form = useForm({
        onSubmit: async model => await props.onEditAction(model),
        defaultModel: { ...actionItem, type: "message" }
    })

    const text = useField("text", {
        defaultValue: actionItem.text,
        form
    })

    return (
        <FormDialog
            {...props}
            title="Mensagem"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Text label="Texto" required {...text} rows={6} placeholder="Seja bem vindo {{name}}, seu protocolo é {{protocol}}" />
            <p style={{opacity: 0.5}}>
                Você pode usar códigos especiais para representar dados reais do
                cliente. Por exemplo:
                <br />
                <br />
                {"{{protocol}}"}
                <br />
                {"{{cpf}}"}
            </p>
        </FormDialog>
    )
}
export default EditMessage
