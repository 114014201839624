/* eslint-disable eqeqeq */
import React from "react"
import { useStore, telegram, channel } from "store"
import { useField, useForm } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import SettingsLoader from "components/settings/SettingsLoader"
import PageTitle from "components/PageTitle"
import { ChannelStatus } from "../Channels"
import { getIconClassName } from "@uifabric/styling"
import SettingsHelp from "components/SettingsHelp"
import { CommandPage, useCommand } from "nanui"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddTelegram(props) {
    const telegramStore = useStore(telegram)
    const channelStore = useStore(channel)

    const item = props.match.params.id
        ? telegramStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const channelItem = props.match.params.id
        ? channelStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adicionou uma linha de Telegram"
                    : "O agente editou uma linha de Telegram"
            })
            const response = await telegramStore.save({
                data: model,
                toast: "Linha de Telegram salva."
            })
            return () => {
                if (!item.id && !response.error && !response.errors) {
                    props.history.push("/settings/channels")
                }
            }
        },
        defaultModel: item
    })

    const token = useField("identifier", {
        defaultValue: item.identifier,
        form
    })

    const description = useField("description", {
        defaultValue: item.description,
        form
    })

    const { infoBarItem } = useCommand()

    const commandPageProps = {
        commandBarProps: {
            farItems: [infoBarItem],
            items: [
                {
                    key: "back",
                    name: "Voltar",
                    iconProps: {
                        iconName: "Back"
                    },
                    onClick: () => props.history.push('/settings/channels')
                }
            ]
        },
        commandPanels: {
            info: (
                <div>
                    <SettingsHelp title="Canal Telegram" icon={getIconClassName("OfficeChat")}>
                        O KimoChat Telegram é simples, rápido, seguro e sincronizado, trazendo mais praticidade! O Telegram é o terceiro mensageiro instantâneo mais utilizado no momento, só no KimoChat você tem mais essa incrível experiência de atender seus clientes em uma única plataforma!
                        <br />
                        <br />
                        <h2>Passo 1: Criar um Bot no Telegram</h2>

                        <ol>
                            <li>
                                <h3>Encontrar o BotFather:</h3>
                                <p>Abra o aplicativo do Telegram.</p>
                                <p>Na barra de pesquisa, digite "BotFather".</p>
                                <p>Selecione o BotFather nos resultados da pesquisa.</p>
                            </li>
                            <li>
                                <h3>Iniciar uma Conversa com o BotFather:</h3>
                                <p>Clique em "Iniciar" ou envie o comando <code>/start</code> para iniciar uma conversa com o BotFather.</p>
                            </li>
                            <li>
                                <h3>Criar um Novo Bot:</h3>
                                <p>Envie o comando <code>/newbot</code> para o BotFather.</p>
                                <p>Siga as instruções fornecidas pelo BotFather para criar seu bot:</p>
                                <ul>
                                    <li>Escolha um nome para o seu bot (exemplo: "MeuBot").</li>
                                    <li>Escolha um nome de usuário para o seu bot. Deve terminar com "bot" e ser único (exemplo: "@MeuBot_bot").</li>
                                </ul>
                            </li>
                        </ol>

                        <h2>Passo 2: Obter o Token do Bot</h2>

                        <ol>
                            <li>
                                <h3>Receber o Token do BotFather:</h3>
                                <p>Após seguir as etapas para criar o bot, o BotFather enviará uma mensagem com o token de acesso do seu bot.</p>
                                <p>O token é uma sequência alfanumérica única que parece com isso: <code>1234567890:ABCdefGhIjKlmnOpQRstuVwXyZ</code>.</p>
                            </li>
                            <li>
                                <h3>Guardar o Token:</h3>
                                <p>Copie o token fornecido pelo BotFather. Esse token é essencial para autenticar seu bot na API do Telegram.</p>
                                <p>Mantenha o token em um local seguro e não o compartilhe com ninguém, pois ele permite controlar o seu bot.</p>
                            </li>
                        </ol>

                        <h2>Passo 3: Criando um canal no KimoChat</h2>

                        <ol>
                            <li>
                                <h3>Preenchendo os campos:</h3>
                                <p>Nomeie o seu novo canal no campo "Nome do canal"</p>
                                <p>Com o token copiado, cole ele no campo "Token do seu Bot"</p>
                                <p>Clique em "Salvar"</p>
                            </li>
                        </ol>
                        <br />
                        Pronto! Agora você pode enviar e receber mensagem por meio do bot do Telegram!
                        <br />
                        <br />
                        <br />
                    </SettingsHelp>
                </div>
            )
        }
    }

    return (
        <CommandPage {...commandPageProps}>
            <div className="channel-page">
                <PageTitle
                    title={
                        <span>
                            {!props.match.params.id
                                ? "Adicionar canal de Telegram"
                                : "Editar canal de Telegram"}
                            {channelItem.status && (
                                <ChannelStatus
                                    status={channelItem.status}
                                    style={{ marginLeft: 20, top: 72, position: "absolute" }}
                                />
                            )}
                        </span>
                    }
                    description="Você poderá responder as perguntas dos seus clientes imediatamente e também
                continuar a conversa com eles a qualquer momento no futuro"
                />
                <SettingsLoader store={telegramStore} {...props}>
                    <FormDialog
                        {...props}
                        item={item}
                        endpoint="/integrations/telegram"
                        onSubmit={form.onSubmit}
                        onDelete={id =>
                            telegramStore
                                .remove({
                                    data: { id },
                                    toastMessage: "Deletado com sucesso"
                                })
                                .then(() => {
                                    props.history.push("/settings/channels")
                                })
                        }
                    >
                        <div style={{ maxWidth: 460 }}>
                            <Text label="Nome do canal" required {...description} />
                            <Text placeholder="1234567890:ABCdefGhIjKlmnOpQRstuVwXyZ" disabled={!!props.match.params.id} label="Token do seu Bot" required {...token} />
                        </div>
                    </FormDialog>
                </SettingsLoader>
            </div>
        </CommandPage>
    )
}
