import React, { useState, useMemo } from "react"
import { HashLink } from "react-router-hash-link"
import { Link, Redirect } from "react-router-dom"

export default function LandingFooter() {
    return (
        <>
            <div className="footer">
                <div className="midcol">
                    <div className="col">
                        <span className="col-title">Produto</span>
                        <Link to="/pricing" className="col-item">
                            Planos
                        </Link>
                        <Link to="/whatsapp" className="col-item">
                            Whatsapp
                        </Link>
                        <Link to="/features" className="col-item">
                            Chat Widget
                        </Link>
                        <Link to="/whatsapp" className="col-item">
                            Campanhas
                        </Link>
                        <Link to="/bot" className="col-item">
                            Bot
                        </Link>
                    </div>
                    <div className="col">
                        <span className="col-title">Sobre</span>
                        {/* <Link to="/" className="col-item">
                            Equipe
                        </Link> */}
                        {/* <HashLink to="/pricing#faq" className="col-item">
                            Central de Ajuda
                        </HashLink> */}
                        <HashLink to="/pricing#faq" className="col-item">
                            FAQ
                        </HashLink>
                        <Link to="/terms" className="col-item">
                            Termos de Uso
                        </Link>
                        <Link to="/privacy" className="col-item">
                            Politica de Privacidade
                        </Link>
                    </div>
                    {/* <div className="col">
                        <span className="col-title">Desenvolvedores</span>
                        <Link to="/" className="col-item">
                            API para desenvolvedores
                        </Link>
                        <Link to="/" className="col-item">
                            Integrações
                        </Link>
                    </div> */}
                    <div className="col">
                        <span className="col-title">Fale Conosco</span>
                        <span className="col-text">
                            WhatsApp:
                        </span>
                        <span className="col-text">
                            +55 85 9 9966-4490
                        </span>
                        <span className="col-text">
                            +55 85 9 8974-5229
                        </span>
                        <span style={{ marginTop: 10 }} className="col-text">
                            kimolivechat@gmail.com
                        </span>
                        <br />
                        {/* <div className="address">
                            <span>CNPJ 29.376.668/0001-10</span>
                            <span>Av. Eng. Humberto Monte, nº 2929.</span>
                            <span>Sala 402B Sul.</span>
                            <span>CEP 60440-593, Fortaleza/CE - Brasil</span>
                        </div> */}
                        <br />
                    </div>
                </div>
                <p className="rodape">
                    © {new Date().getFullYear()} KimoChat Software Ltda. Todos os direitos
                    reservados.
                </p>
            </div>
        </>
    )
}
