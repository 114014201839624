import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Text from './Text';

function SearchText({
    options = [],
    onOptionClick,
    observableValues = [],
    debounceDelay = 500,
    searchFunction = () => null,
    updateWhenFocused,
    ...props
}) {
    const [isFocused, setIsFocused] = useState(false)

    const debouncedSearchFunction = debounce(searchFunction, debounceDelay)

    useEffect(() => {
        if (!isFocused && !!options.length) return

        debouncedSearchFunction()

        return () => debouncedSearchFunction.cancel()
    }, [ ...observableValues, updateWhenFocused ? isFocused : null ])

    return (
        <div className='search-text-container'>
            <Text
                {...props}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {isFocused && !!options.length && (
                <ul className='search-text-list'>
                    {options.map((option, idx) => (
                        <li
                            className='search-text-option'
                            key={idx}
                            onMouseDown={() => onOptionClick(option.value)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default SearchText;