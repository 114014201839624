import React, { useMemo } from "react"
import Text from "components/forms/Text"
import SwitchField from "components/forms/SwitchField"
import FormDialog from "components/forms/FormDialog"
import ColorEmojiPicker from "components/forms/ColorEmojiPicker"
import { useField, useFormMultiSelect, useForm, useFetch, useColorEmoji, useCheckbox } from "hooks"
import shortid from "shortid"

// import { operator, useStore } from "store"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function EditPlugin(props) {
    const item = props.item

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Agente salvou um plugin"
            })
            console.log(model)
            await props.onSubmit(model, { toastMessage: "Plugin salvo" })
        },
        defaultModel: item
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    const endpoint = useField("endpoint", {
        defaultValue: item.endpoint,
        form
    })

    const dev = useCheckbox("dev", {
        defaultValue: item.dev,
        form
    })

    const newShortId = useMemo(() => shortid.generate(), [])

    const authSecret = useField("authSecret", {
        defaultValue: item.authSecret || newShortId,
        form
    })

    return (
        <FormDialog {...props} title="Plugin" onSubmit={form.onSubmit}>
            <Text label="Nome" required {...name} />
            <Text
                label="Endpoint"
                required
                placeholder="https://example.com/plugin"
                description="A localização HTTPS onde seu plugin está hospedado"
                {...endpoint}
            />
            <Text
                label="Auth Secret"
                required
                disabled
                description="Use para autenticar o seu usuário KimoChat no seu plugin"
                value={authSecret.value}
                copyable
            />
            <br />
            <SwitchField
                label="Modo Desenvolvedor"
                description="Apenas são visíveis para você"
                {...dev}
            />
        </FormDialog>
    )
}

export default EditPlugin
