import React from "react"
import { getIconClassName } from "@uifabric/styling"
import SettingsHelp from "components/SettingsHelp"
import PageTableBase from "components/PageTableBase"
import HelpBanner from "components/HelpBanner"
import EditBusinessHours from "./EditBusinessHours"
import { businessHours } from "store"

const columns = [
    {
        Header: "Horário",
        accessor: "name",
        Cell: props => (
            <span className="vertical-center">
                <i className={`${getIconClassName('Clock')} table-icon`} />
                {props.value}
            </span>
        )
    }
]

export default function BusinessHours(props) {
    return (
        <>
            <PageTableBase
                storeRef={businessHours}
                title="Horário Comercial"
                newLabel="Novo"
                columns={columns}
                EditComponent={EditBusinessHours}
                helperAction={
                    <SettingsHelp title="Seus Horários" icon={getIconClassName("Clock")}>
                        Cadastre os horários que sua equipe está disponível para atendimento para
                        usar nas configurações do bot.
                    </SettingsHelp>
                }
                nodata={{
                    title: "Parece que você ainda não adicionou nenhum horário...",
                    buttonText: "Adicionar Horário"
                }}
                {...props}
            />
        </>
    )
}
