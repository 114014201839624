import React, { useEffect, useState, useMemo, useRef } from "react"
import { css, select as $ } from "glamor"
import { NavLink, Switch, Route } from "react-router-dom"
import queryString from "query-string"
import SwipeableViews from "react-swipeable-views"
import { Resizable } from "re-resizable"
import { useLocalStorage } from "react-use"
import { rootItems, inboxItems, settingsRootItems, goBackItem } from "./SideConstants"
import { useFetch } from "hooks"
import WorkspaceDrop from "./WorkspaceDrop"
import EditSegment from "components/inbox/EditSegment"
import BrowseFilters from "components/sidebar/BrowseFilters"
import RootItems from "./RootItems"
import InboxItems from "./InboxItems"
import SideSeparator from "./SideSeparator"
import SettingsItems from "./SettingsItems"
import ReportsItems from "./ReportsItems"
import InboxIcon from "assets/icons/svg/InboxIcon3"
import PinnedIcon from "assets/icons/svg/PinnedIcon"
import menuSvg from "assets/icons/svg/menu.svg"
import SideItem from "./SideItem"
import { general, segment, inbox, useStore } from "store"
import SimpleBar from "simplebar-react"
import cx from "classnames"
import ChatActionIco from "components/inbox/ChatActionIco"
import { getIconClassName } from "@uifabric/styling"
import { DefaultButton, IconButton, CommandButton } from "nanui"
import ContextHeader from "components/ContextHeader"
import NewMessageModal from "components/inbox/NewMessageModal"

function SideAddButton({ onClick }) {
    return (
        <button className="side-add-button" onClick={onClick}>
            <span className="title">Filtros</span>
            <i className="side-add-icon" />
        </button>
    )
}

const SideFakes = (
    <div className="fakes-sidebar">
        <div className="fake-row">
            <div className="fake-line" />
        </div>
        <div className="fake-row">
            <div className="fake-line" />
        </div>
        <br />
        <div className="fake-row" style={{ marginBottom: 20 }}>
            <div className="fake-line small" />
            <div className="fake-circle" />
        </div>
        <div className="fake-row">
            <div className="fake-line small" />
        </div>
        <div className="fake-row">
            <div className="fake-line small" />
        </div>
        <div className="fake-row">
            <div className="fake-line small" style={{ maxWidth: 80 }} />
        </div>
    </div>
)

const Sidebar = props => {
    const { addModal, removeModal, sidebarMinimized, toggleSidebar, theme, loaders } = useStore(
        general
    )

    const segmentStore = useFetch(segment)
    const inboxStore = useStore(inbox)

    const segmentId = useMemo(() => (props.location.pathname.match(/\/inbox\/([^/]+)/) || [])[1], [
        props.location.pathname
    ])

    return (
        <div
            className={cx("sidebar", {
                minimized: sidebarMinimized && props.isInbox,
                white: !props.isInbox,
                isInbox: props.isInbox
            })}
            // {...sidebarStyles}
        >
            <Switch>
                <Route path="/inbox">
                    <section className="chat-header" style={{ paddingLeft: 0 }}>
                        <div className="chat-header-inner">
                            <ContextHeader
                                // backAction={{ to: "/settings/channels" }}
                                actions={[
                                    {
                                        icon: getIconClassName("GlobalNavButton"),
                                        onClick: toggleSidebar,
                                        iconStyle: { fontSize: 16, width: 25, color: "#9b9c9e" }
                                    },
                                    {
                                        name: "Nova mensagem",
                                        icon: getIconClassName("Add"),
                                        onClick: () => addModal(<NewMessageModal {...props} />),
                                        id: "new-message"
                                    },
                                    {
                                        name: "Atualizar",
                                        icon: getIconClassName("Refresh"),
                                        onClick: () => {
                                          segmentStore.get()
                                          inboxStore.clear()
                                          inboxStore.get({
                                              params: {
                                                  ...queryString.parse(props.location.search),
                                                  segmentId,
                                                  limit: 20
                                              }
                                          })
                                          inboxStore.setUnread(0)
                                        },
                                        id: "update-inbox"
                                    }
                                ]}
                            />
                            {/* <div className="top"> */}
                            {/* <div className="left">
                                    <ChatActionIco
                                        icon={getIconClassName("GlobalNavButton")}
                                        // icon="material-icons-round"
                                        // iconMaterial="menu"
                                        // iconSvg={menuSvg}
                                        description="Menu"
                                        medium
                                        onClick={toggleSidebar}
                                        iconStyle={{ fontSize: 16 }}
                                        // className="resolved"
                                    />
                                </div> */}
                            {/* <div className="right">
                                    <CommandButton
                                        iconProps={{ iconName: "AddTo" }}
                                        text="Add Mensagem"
                                        menuProps={{
                                            items: [
                                                {
                                                    key: "emailMessage",
                                                    text: "Email message",
                                                    iconProps: { iconName: "Mail" }
                                                },
                                                {
                                                    key: "calendarEvent",
                                                    text: "Calendar event",
                                                    iconProps: { iconName: "Calendar" }
                                                }
                                            ]
                                        }}
                                    />
                                    <DefaultButton
                                        // text="Novo"
                                        split
                                        onClick={() => {}}
                                        iconProps={{ iconName: 'EditSolid12' }}
                                        primary
                                        menuProps={{
                                            items: [
                                                {
                                                    key: "emailMessage",
                                                    text: "Email message",
                                                    iconProps: { iconName: "Mail" }
                                                },
                                                {
                                                    key: "calendarEvent",
                                                    text: "Calendar event",
                                                    iconProps: { iconName: "Calendar" }
                                                }
                                            ]
                                        }}
                                    />
                                    <IconButton
                                        split
                                        primary
                                        iconProps={{ iconName: "Add" }}
                                        // styles={customSplitButtonStyles}
                                        menuProps={{
                                            items: [
                                                {
                                                    key: "emailMessage",
                                                    text: "Email message",
                                                    iconProps: { iconName: "Mail" }
                                                },
                                                {
                                                    key: "calendarEvent",
                                                    text: "Calendar event",
                                                    iconProps: { iconName: "Calendar" }
                                                }
                                            ]
                                        }}
                                        text="Nova mensagem"
                                    />
                                </div> */}
                            {/* </div> */}
                        </div>
                    </section>
                </Route>
            </Switch>
            <SimpleBar
                style={{ height: "calc(100% - 48px)", width: "100%" }}
                className="sidebar-content"
            >
                {loaders.includes("get/inbox/segments") ? (
                    SideFakes
                ) : (
                    <Switch>
                        <Route path="/inbox">
                            <div className="sidebar-inner">
                                <SideItem
                                    icon={getIconClassName("Inbox")}
                                    to="/inbox/all"
                                    name="Inbox"
                                    count={(segmentStore.all.find(x => x.id === "all") || {}).count}
                                />
                                <SideItem
                                    icon={getIconClassName("FavoriteStar")}
                                    to="/inbox/starred"
                                    name="Favoritos"
                                    count={
                                        (segmentStore.all.find(x => x.id === "starred") || {}).count
                                    }
                                />
                                <SideItem
                                    icon={getIconClassName("Contact")}
                                    to="/inbox/me"
                                    name="Atribuído a mim"
                                    count={(segmentStore.all.find(x => x.id === "me") || {}).count}
                                />
                                <SideItem
                                    icon={getIconClassName("Archive")}
                                    to="/inbox/archived"
                                    name="Arquivado"
                                    count={
                                        (segmentStore.all.find(x => x.id === "archived") || {})
                                            .count
                                    }
                                />
                                <SideAddButton onClick={() => addModal(<BrowseFilters />)} />
                                {segmentStore.all
                                    .filter(
                                        x =>
                                            x.subscribed &&
                                            x.id !== "all" &&
                                            x.id !== "starred" &&
                                            x.id !== "me" &&
                                            x.id !== "archived"
                                    )
                                    .map(x => (
                                        <SideItem
                                            {...x}
                                            to={"/inbox/" + x.id}
                                            key={x.id}
                                            editable
                                            hideable
                                            onEdit={() =>
                                                addModal(
                                                    <EditSegment item={x} onBack={removeModal} />
                                                )
                                            }
                                            // onDelete={() => {
                                            //     segmentStore.removeFromAll(x)
                                            //     segmentStore.remove({
                                            //         data: x
                                            //     })
                                            // }}
                                            onUnsubscribe={() =>
                                                segmentStore.toggleSubscription(x.id, true)
                                            }
                                        />
                                    ))}
                            </div>
                        </Route>
                        <Route path="/settings">
                            <SettingsItems />
                        </Route>
                        <Route path="/reports">
                            <ReportsItems />
                        </Route>
                    </Switch>
                )}
            </SimpleBar>

            {/* {!segmentStore.all.length ? (
                                <div className="loading-sidebar-shadows">
                                    <div
                                        style={{
                                            marginTop: 15,
                                            height: 30
                                        }}
                                    />
                                    <br />
                                    <div style={{ width: 40 }} />
                                    <div />
                                    <div />
                                    <div />
                                    <br />
                                    <div style={{ width: 40 }} />
                                    <div />
                                    <br />
                                    <div />
                                    <div />
                                    <div />
                                </div>
                            ) : ( */}
            {/* <Route path="/inbox">
                                        <>
                                            <div className="sidebar-header">
                                                <WorkspaceDrop width={width} {...props} />
                                            </div>
                                            <InboxItems
                                                options={segmentStore.all.map(x => ({
                                                    name: x.name,
                                                    inbox: x.inbox,
                                                    to: "/inbox/" + x.id,
                                                    count: x.count,
                                                    icon: x.icon || "fal fa-archive",
                                                    iconStyle: {
                                                        fontSize: 14,
                                                        width: 20,
                                                        marginRight: 7,
                                                        marginLeft: -6,
                                                        textAlign: "center"
                                                    },
                                                    style: {
                                                        fontSize: 14,
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                        maxHeight: 30
                                                    },
                                                    editable: true,
                                                    onEdit: () =>
                                                        addModal(<EditSegment item={x} />),
                                                    onDelete: () => {
                                                        segmentStore.removeFromAll(x)
                                                        segmentStore.remove({
                                                            data: x
                                                        })
                                                    }
                                                }))}
                                                setIndex={setIndex}
                                            />
                                            <div className="minimize-side-item">
                                                <a
                                                    className="settings-tab"
                                                    onClick={() => toggleSidebar()}
                                                >
                                                    <i className="fa fa-arrow-left" />
                                                </a>
                                            </div>
                                        </>
                                    </Route> */}
        </div>
    )
}

export default Sidebar
