import React, { Component } from "react"
import cx from "classnames"
import masks from "lib/masks"
// import CheckboxSimple from 'react-simple-checkbox'
import RCCheckbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

function Checkbox({ label, disabled, children, setChecked, style, ...props }) {
    const className = cx("checkbox-space", {
        disabled
    })
    return (
        <div className={className} style={style}>
            {/* <label className="label-input">{label}</label> */}
            <div className="checkbox-box">
                <RCCheckbox {...props} />
                {/* <input {...props} type="checkbox"/> */}
            </div>
            <div className="checkbox-description">{children}</div>
        </div>
    )
}

export default Checkbox
