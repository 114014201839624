import React from "react"
import { NavLink } from "react-router-dom"
import UserMenuDrop from "./UserMenuDrop"
import { general, inbox, useStore } from "store"
import { getIconClassName } from "@uifabric/styling"
import { permissionsEnum } from "lib/constants"
import { session } from "store"

function HeaderItemButton({ to, icon, name, unread }) {
    return (
        <NavLink to={to} className="header-link">
            <i className={icon} />
            {name}
            {unread && <span className="unread" />}
        </NavLink>
    )
}

function Header(props) {
    const { workspace } = useStore(session)
    const { loaders, _isProduction, themeColors } = useStore(general)
    const { unread } = useStore(inbox)

    return (
        <header
            className="top-header"
            // style={
            //     _isProduction
            //         ? { backgroundColor: themeColors }
            //         : { backgroundColor: "#95a5a6" }
            // }
        >
            <section>
                {!loaders.includes("get/inbox/segments") ? (
                    <>
                        <HeaderItemButton
                            // icon="fa fa-comment-alt-dots"
                            icon={getIconClassName("SkypeMessage")}
                            to="/inbox"
                            name="Mensagens"
                            unread={!!unread}
                        />
                        {/* <HeaderItemButton icon="fa fa-signal-alt-3" to="/reports" name="Relatórios" /> */}
                        <HeaderItemButton
                            // icon="fa fa-user-chart"
                            icon={getIconClassName("BarChart4")}
                            to="/reports"
                            name="Relatórios"
                        />
                        {workspace.accessLevel >= permissionsEnum.MASTER && (
                            <HeaderItemButton
                                // icon="fa fa-user-chart"
                                icon={getIconClassName("MegaphoneSolid")}
                                to="/campaigns"
                                name="Campanhas"
                            />
                        )}
                        <HeaderItemButton
                            // icon="fa fa-cog"
                            icon={getIconClassName("Settings")}
                            to="/settings"
                            name="Configurações"
                        />
                    </>
                ) : (
                    <div className="header-fakes">
                        <div className="icon-fake" />
                        <div className="line-fake" />
                        <div className="icon-fake" />
                        <div className="line-fake" />
                        <div className="icon-fake" />
                        <div className="line-fake" />
                        <div className="icon-fake" />
                        <div className="line-fake" />
                    </div>
                )}
            </section>
            <section>
                <UserMenuDrop {...props} />
            </section>
        </header>
    )
}

export default Header
