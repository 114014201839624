import React from "react"
import workspaceImage from "assets/images/workspace.svg"
import { getImage } from "lib/helper"

function WorkspaceAvatar({ avatar }) {
    return (
        <img
            src={avatar ? getImage(avatar) : workspaceImage}
            alt=""
        />
    )
}

export default WorkspaceAvatar
