import React, { useState, useMemo } from "react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import SideItem from "./SideItem"
import GoBackItem from "./GoBackItem"
// import SideSection from "./SideSection"
import SideCollapse from "./SideCollapse"
import SideSeparator from "./SideSeparator"
import cx from "classnames"
import { useStore, segment, session } from "store"

const SortableItem = SortableElement(({ value }) => <li>{value}</li>)

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={value}
                />
            ))}
        </ul>
    )
})

function InboxItems({ options, setIndex, activeStyleBg, activeStyleColor }) {
    const [sorting, setSorting] = useState(false)
    const segmentStore = useStore(segment)
    const { user } = useStore(session)

    function onSortEnd({ oldIndex, newIndex }, offset = 0) {
        segmentStore.moveItem({
            oldIndex: oldIndex + offset,
            newIndex: newIndex + offset
        })
        setSorting(false)
    }

    const me = useMemo(
        () =>
            options
                .filter(x => x.inbox === "me")
                .map(x => (
                    <SideItem
                        {...x}
                        activeStyleBg={activeStyleBg}
                        activeStyleColor={activeStyleColor}
                        key={x.name}
                    />
                )),
        [options]
    )
    const workspace = useMemo(
        () =>
            options
                .filter(x => x.inbox === "workspace")
                .map(x => (
                    <SideItem
                        {...x}
                        activeStyleBg={activeStyleBg}
                        activeStyleColor={activeStyleColor}
                        key={x.name}
                    />
                )),
        [options]
    )

    return (
        <div className={cx("inbox-items", { sorting })}>
            {/* <GoBackItem setIndex={setIndex} /> */}
            <SideCollapse title="Meus" menuTitle="Meu Inbox" inbox="me">
                <div style={{ flexShrink: 0, overflow: "auto" }}>
                    <SortableList
                        items={me}
                        helperClass="dragging-segment"
                        axis="y"
                        lockAxis="y"
                        lockToContainerEdges
                        lockOffset={0}
                        // lockOffset={["50%", -15]}
                        distance={5}
                        onSortStart={() => setSorting(true)}
                        onSortEnd={indexes => onSortEnd(indexes, false)}
                    />
                </div>
            </SideCollapse>
            <div className="minimized-separator" />
            <SideCollapse
                title="Workspace"
                menuTitle="Workspace Inbox"
                inbox="workspace"
            >
                <SortableList
                    items={workspace}
                    helperClass="dragging-segment"
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges
                    lockOffset={0}
                    // lockOffset={["50%", -15]}
                    distance={5}
                    onSortStart={() => setSorting(true)}
                    onSortEnd={indexes => onSortEnd(indexes, me.length)}
                />
            </SideCollapse>

            <br />
            <div className="minimized-separator" />
            <SideItem
                agent
                name={user.name}
                to="/settings/account/profile"
                className="inbox-side-item"
                activeStyleBg={activeStyleBg}
                activeStyleColor={activeStyleColor}
            />
            <SideItem
                name="Configurações"
                icon="fa fa-cog"
                to="/settings/account/workspace"
                className="inbox-side-item"
                activeStyleBg={activeStyleBg}
                activeStyleColor={activeStyleColor}
            />
            {/* <SideItem
                name="Lixeira"
                icon="fa fa-trash"
                to="/trash"
                className="inbox-side-item"
            /> */}
            <SideItem
                name="Sair"
                icon="fa fa-sign-out"
                to="/user/logout"
                className="inbox-side-item"
                activeStyleBg={activeStyleBg}
                activeStyleColor={activeStyleColor}
            />
        </div>
    )
}

export default InboxItems
