import React, { useState } from "react"
import Text from "components/forms/Text"
import SettingsHeader from "components/settings/SettingsHeader"
import Button from "components/forms/Button"
import BillingTab from "components/BillingTab"
import Modal from "components/Modal"
import Checkbox from "components/forms/Checkbox"
import masks from "lib/masks"
import { useField, useCheckbox, useForm, useFetch, useBreadcrumb } from "hooks"
import { useStore, session, payment, operator, channel, general } from "store"
import { formatWithOptions } from "util"
import wppImg from "assets/images/wpp.png"
import { getIconClassName } from "@uifabric/styling"
import instagramImg from "assets/icons/svg/instagram.svg"
import AwaitingPaymentModal from "./AwaitingPaymentModal"
import cx from "classnames"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"

/**
 * Google Analytics
 */
import { analyticsCategory, channelsTypesEnum } from "lib/constants"
import ReactGA from "react-ga"

// function calculatePricePerUser(userUnits, zapUnits, { basePrice = 50, stepDiscount = 0.1 } = {}) {
//     let rawPrice = basePrice * zapUnits
//     let discount = 1 + Math.pow(1 / (1 + zapUnits * 0.05), userUnits)
//     return rawPrice * discount
// }

function BillingItem({ description, value }) {
    return (
        <span className="billing-item">
            <span className="billing-item-description">{description}</span>
            <span className="billing-item-value">{value}</span>
        </span>
    )
}

function Billing(props) {
    const { workspace, isExpired } = useStore(session)
    const { _isProduction } = useStore(general)
    const operatorStore = useStore(operator)
    const channelStore = useStore(channel)
    const sub = workspace.subscription
    const isPro = sub.license === "pro"
    const isUpgradingPlan = isPro && sub.status !== "canceled"
    const [monthly, setMonthly] = useState(true)
    const [awaitingPayment, setAwaitingPayment] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [coupon, setCoupon] = useState()
    const paymentStore = useStore(payment)
    // const [plan, setPlan] = useState(isPro ? plans.findIndex(x => x.months === sub.months) : 0)
    const minimumAgents = operatorStore.all.length
    const minimumWhatsapps = channelStore.all.filter(
        x => x.channelId == channelsTypesEnum.WHATSAPP
    ).length
    const minimumInstagrams = channelStore.all.filter(
        x => x.channelId == channelsTypesEnum.INSTAGRAM
    ).length

    const form = useForm({
        onSubmit: async model => {
            const response = await paymentStore.save({
                data: {
                    ...model
                    // [autorenewal.name]: autorenewal.checked,
                    // [whatsappIntegration.name]: whatsappIntegration.checked
                    // months: plans[plan]
                }
            })
            return props.history.push(
                "/settings/billing/payment/" + response.id
            )
        }
    })

    // const autorenewal = useCheckbox("autorenewal", {
    //     defaultValue: true
    // })

    // const whatsappIntegration = useCheckbox("whatsapp", {
    //     defaultValue: 1
    // })

    const agentsQuantity = useField("agents", {
        defaultValue: sub.agents.toString(),
        mask: [
            "number",
            v => (parseInt(v) >= minimumAgents ? v : minimumAgents.toString())
        ],
        form
    })

    const whatsappQuantity = useField("whatsapp", {
        defaultValue: sub.numbers.toString(),
        mask: [
            "number",
            v =>
                parseInt(v) >= minimumWhatsapps
                    ? v
                    : minimumWhatsapps.toString()
        ],
        form
    })

    const instagramQuantity = useField("instagram", {
        defaultValue: isPro ? sub.instagramUnits && sub.instagramUnits.toString() : "0",
        mask: [
            "number",
            v =>
                parseInt(v) >= minimumInstagrams
                    ? v
                    : minimumInstagrams.toString()
        ],
        form
    })

    const campaignQuantity = useField("campaign", {
      defaultValue: sub.campaignUnits.toString(),
      mask: [
          "number",
      ],
      form
  })

    const couponCode = useField("coupon", {
        form
    })

    const plans = {
      basic: {
        price: 149,
        operators: 1,
        whatsappUnits: 1,
        campaignUnits: 0
      },
      trial: {
        price: 449,
        operators: 5,
        whatsappUnits: 1,
        campaignUnits: 0
      },
      business: {
        price: 449,
        operators: 5,
        whatsappUnits: 1,
        campaignUnits: 0
      },
      enterprise: {
        price: 799,
        operators: 10,
        whatsappUnits: 1,
        campaignUnits: 1
      },
    }

    const agentsUnits = parseInt(agentsQuantity.value)
    const whatsappUnits = parseInt(whatsappQuantity.value)
    const campaignUnits = parseInt(campaignQuantity.value)
    const instagramUnits = parseInt(instagramQuantity.value)

    const extraAgents = agentsUnits - plans[sub.license].operators
    const extraWhatsapp = whatsappUnits - plans[sub.license].whatsappUnits
    const extraCampaigns = campaignUnits - plans[sub.license].campaignUnits

    const pricePerAgent = 49 //calculatePricePerUser(agentsUnits, whatsappUnits)
    const pricePerWhatsapp = 99 //calculatePricePerUser(agentsUnits, whatsappUnits)
    const pricePerCampaign = 149
    const pricePerInstagram = monthly ? 149 : 115 //calculatePricePerUser(agentsUnits, whatsappUnits)

    const totalMonthlyPerAgent = pricePerAgent * extraAgents
    const totalMonthlyPerWhatsapp = pricePerWhatsapp * extraWhatsapp
    const totalMonthlyPerCampaign = pricePerCampaign * extraCampaigns
    const totalMonthlyPerInstagram = pricePerInstagram * instagramUnits

    const totalPerAgent = monthly
        ? totalMonthlyPerAgent
        : totalMonthlyPerAgent * 12
    const totalPerWhatsapp = monthly
        ? totalMonthlyPerWhatsapp
        : totalMonthlyPerWhatsapp * 12
    const totalPerInstagram = monthly
        ? totalMonthlyPerInstagram
        : totalMonthlyPerInstagram * 12

    const currentPlanPrice = plans[sub.license] ? plans[sub.license].price : plans.business.price

    const totalFinal = currentPlanPrice + totalMonthlyPerAgent + totalMonthlyPerWhatsapp + totalMonthlyPerCampaign
    let totalFinalWithCoupon = 0

    if (coupon) {
        if (coupon.monthlyDiscount && monthly) {
            totalFinalWithCoupon =
                totalFinal * (1 - coupon.monthlyDiscount / 100)
        }
        if (coupon.annualDiscount && !monthly) {
            totalFinalWithCoupon =
                totalFinal * (1 - coupon.annualDiscount / 100)
        }
    }

    console.log("totalFinalWithCoupon", totalFinalWithCoupon)

    const saveAnnual =
        (79 * agentsUnits + 249 * whatsappUnits + 149 * instagramUnits) * 12 -
        (69 * agentsUnits + 209 * whatsappUnits + 115 * instagramUnits) * 12
    // const total = monthly ? totalMonthly : totalMonthly * 12 * 0.89

    function verifyCoupon(couponCodeValue) {
        console.log("couponCodeValue", couponCodeValue)
        paymentStore.verifyCoupon(
            couponCodeValue,
            cp => {
                setCoupon(cp)
            },
            () => {
                couponCode.setValue("")
            }
        )
    }

    function onClickBuy() {
        ReactGA.event({
            category: analyticsCategory.AGENT,
            action:
                "O agente abriu a modal do pagar.me para efetual a compra de um plano"
        })
        var checkout = new window.PagarMeCheckout.Checkout({
            // encryption_key: "ek_test_4ZJyx66EH2I2jEzHMnLuPLLbtpVOFY",
            encryption_key: _isProduction
                ? "ek_live_vocz2pXOQIeOEySw3Hxf3lAo8enXof"
                : "ek_test_4ZJyx66EH2I2jEzHMnLuPLLbtpVOFY",
            success: function(data) {
                setAwaitingPayment(data)
                ReactGA.event({
                    category: analyticsCategory.AGENT,
                    action: "O agente comprou um plano"
                })
            },
            error: function(err) {
                ReactGA.event({
                    category: analyticsCategory.AGENT,
                    action: "O agente encontrou um problema ao comprar um plano"
                })
            },
            close: function() {
                ReactGA.event({
                    category: analyticsCategory.AGENT,
                    action: "O agente desitiu de comprar um plano"
                })
            }
        })

        // Obs.: é necessário passar os valores boolean como string
        checkout.open({
            amount: Math.round(totalFinalWithCoupon || totalFinal) * 100,
            buttonText: "Pagar",
            buttonClass: "botao-pagamento",
            customerData: "true",
            createToken: "false",
            paymentMethods: "credit_card"
        })
    }

    const date = new Date(sub.expiresAt);
    const formattedDate = new Intl.DateTimeFormat('pt-BR').format(date);

    return (
        <div>
            <ContextHeader
                backAction={{
                    to: "/settings/billing/license"
                }}
            />
            {/* <br />
            <br /> */}
            <div style={{ maxWidth: 990, margin: "0 auto" }}>
                <PageTitle
                    // title={isUpgradingPlan ? "Alterar Plano" : "Checkout"}
                    title="Alterar Plano"
                />
            </div>

            <form
                className="billing-space"
                onSubmit={form.onSubmit}
                style={{ maxWidth: 960, margin: "0 auto" }}
            >
                <div className="left">
                    <div className="billing-box white">
                        <div className="top">
                            <div className="your-plan">
                                <span>Seu plano</span>
                                {sub.license === 'trial' && <span style={{color: "orangered"}}>Seu período de avaliação termina em {formattedDate}</span>}
                                <span>{sub.license[0].toUpperCase() + sub.license.slice(1)}</span>
                            </div>
                            <div className="price-per-agent">
                                <span className="billing-total-per-user">
                                    {/* {Math.round(totalMonthly / agentsUnits)} */}
                                    {pricePerAgent}
                                </span>
                                <span className="per-user">
                                    por usuário extra
                                    <br />
                                    por mês
                                </span>
                            </div>
                        </div>
                        <div className="billing-row">
                            <div className="left">
                                <div className="billing-agents">
                                    <Text
                                        required
                                        {...agentsQuantity}
                                        type="text"
                                        flat
                                        mask="number"
                                    />
                                    <Button
                                        icon="fal fa-minus"
                                        style={
                                            agentsUnits <= plans[sub.license].operators
                                                ? { visibility: "hidden" }
                                                : {}
                                        }
                                        onClick={() =>
                                            agentsQuantity.setValue(v =>
                                                (parseInt(v) - 1).toString()
                                            )
                                        }
                                    />
                                    <Button
                                        icon="fal fa-plus"
                                        onClick={() =>
                                            agentsQuantity.setValue(v =>
                                                (parseInt(v) + 1).toString()
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <span className="select-number-agents">
                                Selecione o número de licenças.
                            </span>
                        </div>
                        <br />
                        {agentsUnits <= minimumAgents && minimumAgents > 1 && (
                            <div className="addon-description warning">
                                {/* *Exclua alguns agentes para diminuir o número de agentes do seu plano. */}
                                *Atenção: Desative alguns agentes caso queira
                                diminuir o número de licenças do seu plano para
                                menos que {minimumAgents}.
                            </div>
                        )}
                    </div>
                    {/*
                     *
                     * WHATSAPP
                     *
                     */}
                    <div
                        className={cx("billing-box", {
                            white: whatsappUnits > 0
                        })}
                        style={{ marginTop: 30 }}
                    >
                        <div className="top">
                            <div className="plan-addon">
                                <div
                                    className="plan-addon-img"
                                    // style={{ backgroundColor: "#25D366" }}
                                >
                                    <img src={wppImg} alt="" />
                                    {/* <i className="fab fa-whatsapp" /> */}
                                </div>
                                <span className="plan-addon-name">
                                    Whatsapp
                                </span>
                            </div>
                            <div className="price-per-agent">
                                <span className="billing-total-per-user">
                                    {pricePerWhatsapp}
                                </span>
                                <span className="per-user">
                                    por linha extra
                                    <br />
                                    por mês
                                </span>
                            </div>
                        </div>
                        <div className="addon-description">
                            Atenda fácil e rápidamente seus clientes pelo
                            Whatsapp através da nossa plataforma.
                        </div>
                        <div className="billing-row">
                            {whatsappUnits > 0 ? (
                                <>
                                    <div className="left">
                                        <div className="billing-agents">
                                            <Text
                                                required
                                                {...whatsappQuantity}
                                                type="text"
                                                flat
                                                mask="number"
                                            />
                                            <Button
                                                icon="fal fa-minus"
                                                style={
                                                    whatsappUnits <= plans[sub.license].whatsappUnits
                                                        ? {
                                                              visibility:
                                                                  "hidden"
                                                          }
                                                        : {}
                                                }
                                                onClick={() =>
                                                    whatsappQuantity.setValue(
                                                        v =>
                                                            (
                                                                parseInt(v) - 1
                                                            ).toString()
                                                    )
                                                }
                                            />
                                            <Button
                                                icon="fal fa-plus"
                                                onClick={() =>
                                                    whatsappQuantity.setValue(
                                                        v =>
                                                            (
                                                                parseInt(v) + 1
                                                            ).toString()
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <span className="select-number-agents">
                                        Selecione o número de linhas.
                                    </span>
                                </>
                            ) : (
                                <Button
                                    primary
                                    onClick={() =>
                                        whatsappQuantity.setValue("1")
                                    }
                                    className="add-on-button"
                                >
                                    Adicionar
                                </Button>
                            )}
                        </div>
                        <br />
                        {
                        // whatsappUnits <= minimumWhatsapps &&
                        //     minimumWhatsapps > 0 && (
                        //         <div className="addon-description warning">
                        //             *Atenção: Exclua alguns canais de WhatsApp
                        //             caso queira diminuir o número de linhas do
                        //             seu plano para menos que {minimumWhatsapps}.
                        //             {/* Você tem atualmente {minimumWhatsapps} linha(s) de WhatsApp ativa(s) no seu Workspace, para contratar menos de {minimumWhatsapps} linha(s) você deve exclui-la(s). */}
                        //         </div>
                        //     )
                            }
                    </div>
                    {/*
                     *
                     * Instagram
                     *
                     */}
                    <div
                        className={cx("billing-box", {
                            white: campaignUnits > 0
                        })}
                        style={{ marginTop: 30 }}
                    >
                        <div className="top">
                            <div className="plan-addon">
                                <div
                                    className="plan-addon-img"
                                    // style={{ backgroundColor: "#25D366" }}
                                >
                                    <i className={getIconClassName("Megaphone")} />
                                    {/* <i className="fab fa-whatsapp" /> */}
                                </div>
                                <span className="plan-addon-name">
                                    Campanha
                                </span>
                            </div>
                            <div className="price-per-agent">
                                <span className="billing-total-per-user">
                                    {pricePerCampaign}
                                </span>
                                <span className="per-user">
                                    por campanha extra
                                    <br />
                                    por mês
                                </span>
                            </div>
                        </div>
                        <div className="addon-description">
                            Expanda o seu negócio através de Campanhas para vários usuários simultaneamente
                        </div>
                        <div className="billing-row">
                            {campaignUnits > 0 ? (
                                <>
                                    <div className="left">
                                        <div className="billing-agents">
                                            <Text
                                                required
                                                {...campaignQuantity}
                                                type="text"
                                                flat
                                                mask="number"
                                            />
                                            <Button
                                                icon="fal fa-minus"
                                                style={
                                                    campaignUnits <= plans[sub.license].campaignUnits
                                                        ? {
                                                              visibility:
                                                                  "hidden"
                                                          }
                                                        : {}
                                                }
                                                onClick={() =>
                                                    campaignQuantity.setValue(
                                                        v =>
                                                            (
                                                                parseInt(v) - 1
                                                            ).toString()
                                                    )
                                                }
                                            />
                                            <Button
                                                icon="fal fa-plus"
                                                onClick={() =>
                                                    campaignQuantity.setValue(
                                                        v =>
                                                            (
                                                                parseInt(v) + 1
                                                            ).toString()
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <span className="select-number-agents">
                                        Selecione o número de campanhas.
                                    </span>
                                </>
                            ) : (
                                <Button
                                    primary
                                    onClick={() =>
                                        campaignQuantity.setValue("1")
                                    }
                                    className="add-on-button"
                                >
                                    Adicionar
                                </Button>
                            )}
                        </div>
                        <br />
                        {
                            // instagramUnits <= minimumInstagrams &&
                            //     minimumInstagrams > 0 && (
                            //         <div className="addon-description warning">
                            //             *Atenção: Exclua alguns canais de Instagram Direct
                            //             caso queira diminuir o número de canais do
                            //             seu plano para menos que {minimumInstagrams}.
                            //             {/* Você tem atualmente {minimumWhatsapps} linha(s) de WhatsApp ativa(s) no seu Workspace, para contratar menos de {minimumWhatsapps} linha(s) você deve exclui-la(s). */}
                            //         </div>
                            //     )
                        }
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                {/*
                 *
                 * Resumo
                 *
                 */}
                <div className="right">
                    <div className="billing-box white">
                        {/* <BillingTab
                            onChange={v => {
                                ReactGA.event({
                                    category: analyticsCategory.AGENT,
                                    action: `O agente escolheu um plano ${
                                        v ? "Mensal" : "Anual"
                                    }`
                                })
                                setMonthly(v)
                            }}
                            value={monthly}
                        /> */}
                        <br />
                        <span className="billing-title-gray">
                            Resumo da fatura
                        </span>
                        <br />
                        <br />
                        <BillingItem
                            description="Preço do Plano"
                            value={masks.currency(currentPlanPrice)}
                        />
                        {!!extraAgents && (
                            <BillingItem
                                description={`${extraAgents} licenças extras de usuário`}
                                value={masks.currency(totalMonthlyPerAgent)}
                            />
                        )}
                        {!!extraWhatsapp && (
                            <BillingItem
                                description={`${extraWhatsapp} linhas extras de whatsapp`}
                                value={masks.currency(totalMonthlyPerWhatsapp)}
                            />
                        )}
                        {!!extraCampaigns && (
                            <BillingItem
                                description={`${extraCampaigns} campanhas extras`}
                                value={masks.currency(totalMonthlyPerCampaign)}
                            />
                        )}
                        <div className="billing-total">
                            <div className="billing-item-description">
                                <span className="billing-title-gray">
                                    Total por {monthly ? "mês" : "ano"}
                                </span>
                            </div>
                            <div
                                className={`billing-total-value ${!!totalFinalWithCoupon &&
                                    "line-through"}`}
                            >
                                {masks.currency(totalFinal)}
                            </div>
                            {!!totalFinalWithCoupon && (
                                <div className="billing-total-value coupon">
                                    {masks.currency(totalFinalWithCoupon)}
                                    {coupon.charges > 0 && (
                                        <div className="coupon-charges">{`por ${
                                            coupon.charges
                                        } ${
                                            monthly
                                                ? coupon.charges > 1
                                                    ? "meses"
                                                    : "mês"
                                                : coupon.charges > 1
                                                ? "anos"
                                                : "ano"
                                        }`}</div>
                                    )}
                                </div>
                            )}
                        </div>
                        {/* {monthly ? (
                            <div className="change-for-discount">
                                Troque pro plano anual e economize{" "}
                                {masks.currency(Math.abs(saveAnnual))} por ano!
                            </div>
                        ) : (
                            <div
                                className="change-for-discount"
                                style={{ color: "#7f7f7f" }}
                            >
                                Economia com plano anual:
                                <br />
                                <span
                                    className="billing-saving"
                                    style={{ color: "#2ecc71" }}
                                >
                                    {masks.currency(Math.abs(saveAnnual))}
                                </span>
                            </div>
                        )} */}
                        {/* <Button big full onClick={onClickBuy} style={{ marginBottom: 5 }}>
                            Aplicar cupom
                        </Button> */}
                        {/* <div className="billing-agents coupon">
                            <Text
                                required
                                {...couponCode}
                                type="text"
                                label="Aplicar Cupom"
                            />
                            <Button
                                icon="fal fa-check"
                                loader="coupon"
                                // style={agentsUnits <= minimumAgents ? { visibility: "hidden" } : {}}
                                onClick={() => verifyCoupon(couponCode.value)}
                            />
                            <Button
                                icon="fal fa-plus"
                                onClick={() =>
                                    agentsQuantity.setValue(v => (parseInt(v) + 1).toString())
                                }
                            /> 
                        </div> */}
                        <Button
                            primary
                            big
                            to="/settings/billing/subscription"
                            full
                            onClick={() => setModalIsOpen(true)}
                        >
                            {/* {isUpgradingPlan ? "Alterar Plano" : "Comprar"} */}
                            Alterar Plano
                        </Button>
                    </div>
                </div>
            </form>
            {modalIsOpen && (
                <Modal onBack={() => setModalIsOpen(false)}>
                    <div className="modal-billing-contact">
                        <p className="title">Alterar plano</p>
                        <p>Entre em contato conosco para alterar o seu plano</p>
                        <strong style={{ marginTop: '8px' }}>
                            <i
                                className="fab fa-whatsapp"
                                style={{ color: "#2ecc71", opacity: 1, fontSize: 16 }}
                            />{" "}
                            +55 85 9 9966-4490
                        </strong>
                        <strong>
                            <i
                                className="fab fa-whatsapp"
                                style={{ color: "#2ecc71", opacity: 1, fontSize: 16 }}
                            />{" "}
                            +55 85 9 8974-5229
                        </strong>
                        <strong>
                            kimolivechat@gmail.com
                        </strong>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Billing
