import React from "react"
import Tippy from "@tippyjs/react"

export default function Tooltip({ title, children, maxWidth, placement }) {
    return (
        <Tippy
            content={title}
            animation="none"
            animateFill={false}
            inertia={false}
            delay={[500, 0]}
            className="tooltip-custom"
            maxWidth={maxWidth || 300}
            // placement={placement}
        >
            {children}
        </Tippy>
    )
}
