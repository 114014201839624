import React from "react"
import { Redirect } from "react-router-dom"
import {session, inbox, useStore} from 'store'

function Logout(props) {
    const { logout } = useStore(session)
    const { clearSocket } = useStore(inbox)
    clearSocket()
    logout()
    return <Redirect to='/user/login' />
}

export default Logout