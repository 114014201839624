import React, { useState, useEffect } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import EditWhatsapp from "pages/settings/channels/whatsapp/EditWhatsapp"
import { useStore, general, whatsapp, channel } from "store"
import { useField, useFormMultiSelect, useForm, useCheckbox } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import QRCodeModal from "./QRCodeModal"
import RestartZapModal from "./RestartZapModal"
import { getStatus } from "pages/settings/channels/Channels"
import SettingsLoader from "components/settings/SettingsLoader"
import masks from "lib/masks"
import ContextHeader from "components/ContextHeader"
import { getIconClassName } from "@uifabric/styling"
import PageTitle from "components/PageTitle"
import { ChannelStatus } from "../Channels"
import SwitchField from "components/forms/SwitchField"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddChannel(props) {
    const whatsappStore = useStore(whatsapp)
    const channelStore = useStore(channel)

    const channelItem = props.match.params.id
        ? channelStore.all.find((x) => x.id == props.match.params.id) || {}
        : {}

    const { addModal, removeModal } = useStore(general)

    const item = props.match.params.id
        ? whatsappStore.all.find((x) => x.id == props.match.params.id) || {}
        : {}

    const form = useForm({
        onSubmit: async (model) => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adicionou uma linha de WhatsApp"
                    : "O agente editou uma linha de WhatsApp",
            })
            const response = await whatsappStore.save({
                data: { ...model, phone: masks.number(model.phone) },
                toastMessage: "Linha de WhatsApp salva",
            })
            return () => {
                if (!item.id && !response.error && !response.errors) {
                    props.history.push("/settings/channels")
                }
            }
        },
        defaultModel: item,
    })

    const phone = useField("phone", {
        defaultValue: item.phone,
        mask: ["cell"],
        validations: [value => value && value.length < 19 && "Número inválido. O número precisa incluir: DDI, DDD e o 9 extra."],
        form,
    })

    const description = useField("description", {
        defaultValue: item.description,
        form,
    })

    const active = useCheckbox("allowGroups", {
        defaultValue: item.allowGroups,
        form
    })

    return (
        <div className="channel-page">
            <ContextHeader
                backAction={{ to: "/settings/channels" }}
                actions={
                    item.id
                        ? [
                              {
                                  name: "Ler QRCode",
                                  icon: getIconClassName("QRCode"),
                                  disabled: channelItem.status === "connected",
                                  onClick: () =>
                                      addModal(
                                          <QRCodeModal
                                              item={item}
                                              onBackModal={() => removeModal()}
                                          />
                                      ),
                              },
                            //   {
                            //       name: "Reiniciar Servidor",
                            //       icon: getIconClassName("ReturnToSession"),
                            //       onClick: () =>
                            //           addModal(
                            //               <RestartZapModal
                            //                   item={item}
                            //                   onBackModal={() => removeModal()}
                            //               />
                            //           ),
                            //   },
                          ]
                        : []
                }
            />

            <PageTitle
                title={
                    <span>
                        {!props.match.params.id
                            ? "Adicionar linha de WhatsApp"
                            : "Editar linha de WhatsApp"}
                        {channelItem.status && (
                            <ChannelStatus
                                status={channelItem.status}
                                style={{ marginLeft: 20, top: 72, position: "absolute" }}
                            />
                        )}
                    </span>
                }
                description="Você poderá responder as perguntas dos seus clientes imediatamente e também
                continuar a conversa com eles a qualquer momento no futuro"
            />

            <SettingsLoader store={whatsappStore} {...props}>
                <FormDialog
                    {...props}
                    item={item}
                    endpoint="/integrations/whatsapp"
                    onSubmit={form.onSubmit}
                    onDelete={(id) =>
                        whatsappStore
                            .remove({
                                data: { id },
                                toastMessage: "Deletado com sucesso",
                            })
                            .then(() => {
                                props.history.push("/settings/channels")
                            })
                    }
                >
                    <div style={{ maxWidth: 460 }}>
                        {/* {item.status === "disconnected" && (
                            <>
                                <span
                                    className="contract-status channels-status"
                                    style={{ backgroundColor: "rgba(243,156,18, 0.3)" }}
                                >
                                    Desconectado
                                </span>
                                <br />
                                <br />
                            </>
                        )} */}
                        <Text label="Nome da linha" required {...description} />
                        <Text
                            label="Número"
                            required
                            disabled={!!item.id}
                            placeholder="55 (85) 9 9999-9999"
                            maxlength={19}
                            minlength={19}
                            {...phone}
                        />
                        <SwitchField
                            label="Receber mensagens de grupos"
                            description="Permitir que a linha receba mensagens de grupos do seu WhatsApp"
                            {...active}
                        />
                    </div>
                </FormDialog>
            </SettingsLoader>
        </div>
    )
}
