import React, { useState, useEffect } from "react"
import Text from "components/forms/Text"
import SwitchField from "components/forms/SwitchField"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useFetch, useFormMultiSelect, useForm, useCheckbox } from "hooks"
import { useStore, businessHours, bot, channel } from "store"
import { channelsTypes } from "lib/constants"
import Checkbox from "components/forms/Checkbox"
import CheckboxGroup from "components/forms/CheckboxGroup"
import Radiobox from "components/forms/Radiobox"
import Switch from "components/forms/Switch"

// const channelOptions = [
//     { value: "whatsapp", label: "Whatsapp" },
//     { value: "facebook", label: "Facebook" },
//     { value: "web", label: "Web" }
// ]

// const workingHoursOptions = [
//     { value: "151", label: "Horário padrão" },
//     { value: "152", label: "Horário de férias" }
// ]

function EditBotConfig(props) {
    const item = props.item
    const botStore = useStore(bot)
    const hoursStore = useFetch(businessHours)
    const channelStore = useStore(channel)
    const [userType, setUserType] = useState(item.userType || "all")

    const form = useForm({
        onSubmit: async (model) => {
            const savedBot = await botStore.save({
                data: {
                  ...botStore.item,
                  ...model,
                  userType,
                  expire: autoExpireSwitch.checked
                      ? {
                            hours: autoExpireHours.value,
                            minutes: autoExpireMinutes.value,
                            message: autoExpireMessage.value,
                        }
                      : null,
                },
                toastMessage: item.id ? "Bot atualizado" : "Bot criado"
            })

            botStore.setItem(savedBot)

            props.onBackModal()

            return () => props.changeRoutePath(savedBot.id)
        },
        defaultModel: { id: item.id },
    })

    const name = useField("name", {
        defaultValue: item.name,
        form,
    })

    const channels = useFormMultiSelect("channels", {
        defaultValue: item.channels,
        loader: "GET/channels",
        form,
        options: channelStore.all.map((x) => ({
            value: x.id,
            label: `${channelsTypes[x.channelId].name} - ${x.description}`,
        })),
        // options: Object.keys(channelsTypes).map(key => ({
        //     value: key,
        //     label: channelsTypes[key].name
        // }))
    })

    const workingHours = useFormMultiSelect("businessHours", {
        defaultValue: item.businessHours,
        loader: "GET/business-hours",
        form,
        options: hoursStore.all.map((e) => ({
            value: e.id,
            label: e.name,
        })),
    })

    const priority = useField("priority", {
        defaultValue: item.priority || 0,
        form,
    })

    const autoExpireSwitch = useCheckbox("autoExpire", {
        defaultValue: !!item.expire,
    })

    const autoExpireHours = useField("autoExpireHours", {
        defaultValue: (item.expire || {}).hours || 0,
    })

    const autoExpireMessage = useField("autoExpireMessage", {
        defaultValue: (item.expire || {}).message || "",
    })

    const minValueMinutes = autoExpireHours.value > 0 ? 0 : 1
    const autoExpireMinutes = useField("autoExpireMinutes", {
        defaultValue: (item.expire || {}).minutes || minValueMinutes,
        // mask: (v) => (v % 5 > 0 ? v - (v % 5) : v),
    })

    // const isForNewUsers = useCheckbox("isForNewUsers", {
    //     defaultValue: !!item.isForNewUsers
    // })

    return (
        <FormDialog
            {...props}
            superTitle="Configurações do Bot"
            onSubmit={form.onSubmit}
            item={item}
            noExcludable
            // onDelete={() => botStore.remove({ data: item.id })}
            endpoint={botStore.endpoint}
            onClickCancelButton={props.onBackModal}
        >
            <Text label="Nome" required {...name} />
            <Select label="Canais" placeholder="Todos os canais" {...channels} />
            <Select label="Horários" placeholder="Todos os horários" {...workingHours} />
            <Text
                label="Prioridade"
                required
                description="O cenário que tiver maior prioridade será o executado"
                type="number"
                {...priority}
            />
            <SwitchField
                label="Expirar Sessão"
                description="A sessão é resolvida se contato não responder no tempo marcado"
                {...autoExpireSwitch}
            />
            {autoExpireSwitch.checked && (
                <>
                    <div className="same-line-row">
                        <Text type="number" {...autoExpireHours} suffix="horas" min={0} />
                        <Text
                            type="number"
                            {...autoExpireMinutes}
                            suffix="minutos"
                            min={minValueMinutes}
                        />
                    </div>
                    <Text
                        label="Mensagem de expiração"
                        {...autoExpireMessage}
                        placeholder="Sua sessão expirou"
                    />
                </>
            )}
            <br />
            {/* <Checkbox {...isForNewUsers}>Apenas usuários novos</Checkbox> */}
            <CheckboxGroup title="Atende que tipo de contato?" style={{ marginBottom: 0 }}>
                <div style={{ marginTop: 6 }}></div>
                <Radiobox
                    name="bot-user-type"
                    onChange={() => setUserType("all")}
                    checked={userType === "all"}
                >
                    Todos
                </Radiobox>
                <Radiobox
                    name="bot-user-type"
                    onChange={() => setUserType("unregistered")}
                    checked={userType === "unregistered"}
                >
                    Somente contato novos (Primeiro contato)
                </Radiobox>
                <Radiobox
                    name="bot-user-type"
                    onChange={() => setUserType("registered")}
                    checked={userType === "registered"}
                >
                    Somente contatos existentes (Já atendido antes)
                </Radiobox>
            </CheckboxGroup>
        </FormDialog>
    )
}

export default EditBotConfig
