import React, { Component } from "react"
import { observer } from "mobx-react"
import ReactSelect from "react-select"
import cx from "classnames"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import Select from "components/forms/Select"
import Checkbox from "components/forms/Checkbox"

import store from "store"

const getHours = () => {
    const hours = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23"
    ]
    const hoursAndMinutes = []
    hours.map(h => {
        hoursAndMinutes.push(h + ":00")
        hoursAndMinutes.push(h + ":15")
        hoursAndMinutes.push(h + ":30")
        hoursAndMinutes.push(h + ":45")
    })
    return hoursAndMinutes.map((h, index) => ({ value: h, label: h, index }))
}

@observer
class HourBlock extends Component {
    constructor(props) {
        super(props)
        this.hours = [...getHours(), { value: "23:59", label: "00:00", index: getHours().length }]
    }

    onChangeStartHour = item => {
        const hour = {
            startHourIndex: item.index,
            endHourIndex:
                item.index >= this.props.hour.endHourIndex
                    ? this.hours.length - 1
                    : this.props.hour.endHourIndex
        }
        this.props.onChangeHour(this.props.index, hour)
    }

    onChangeLastHour = item => {
        const hour = {
            startHourIndex: this.props.hour.startHourIndex,
            endHourIndex: item.index
        }
        this.props.onChangeHour(this.props.index, hour)
    }

    render() {
        const { onAddHour, onDeleteHour, hour, index, isLast } = this.props
        const startHours = this.hours.filter((h, i) => i !== this.hours.length - 1)
        const endHours = this.hours.filter((h, i) => i > hour.startHourIndex)
        const calcEndHourIndex = (hour.endHourIndex || 1) - hour.startHourIndex - 1
        const timeDiff = String(((hour.endHourIndex - hour.startHourIndex) * 15) / 60).split(".")
        // console.log(parseInt(timeDiff[1]))
        // const endHourIndexTemp = startHourIndex < endHourIndex ? calcEndHourIndex : endHours.length - 1
        return (
            <div className="hour-block">
                <Select
                    options={startHours}
                    name="hour"
                    value={startHours[hour.startHourIndex]}
                    onChange={this.onChangeStartHour}
                    flat
                />
                <span className="until label-input">até</span>
                <Select
                    options={endHours}
                    name="hour"
                    value={endHours[calcEndHourIndex]}
                    onChange={this.onChangeLastHour}
                    flat
                />
                {/* <span className="time-diff label-input">
                    {timeDiff[0]}hrs {timeDiff[1] && `${parseInt(((timeDiff[1] === '5') ? '50' : (timeDiff[1] || '0'))) * 6 / 10} mins`}
                </span> */}
                <div className="actions">
                    <Button
                        icon="fa fa-times-circle"
                        onClick={() => onDeleteHour(index)}
                        className={index > 0 ? "" : "invisible"}
                        cancel
                        danger
                    />
                    <Button
                        icon="fa fa-plus-circle"
                        onClick={onAddHour}
                        className={isLast ? "" : "invisible"}
                    />
                </div>
            </div>
        )
    }
}

export default HourBlock
