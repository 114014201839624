// const isProduction = process.env.NODE_ENV === 'production'
// let domain = 'http://d564a62f.ngrok.io'
// let domain = 'https://zapiko-back.herokuapp.com/api'
let domain = "http://localhost:3001"

const endpoints = {
    // Auth
    LOGIN: "/operators/login",
    CHANGE_PASSWORD: "/operators/change-password",
    UPDATE_PROFILE: "/operators/profile",
    WORKSPACE: "/workspace",
    COMPLETE_REGISTER: "/operators/complete-register",
    DOWNLOAD_CONTRACT: "/loans/pdf",
    DOWNLOAD_ASSET: "/assets/download",
    UPLOAD_FILE: "/assets/upload",
    FORGOT: "/operators/forgot-password",
    RESET: "/operators/reset-password",
    RENEW_TOKEN: "/operators/renew-token",
    JOIN_WORKSPACE: "/operators/join-workspace",
    REGISTER: "/signup",
    RESEND_AGENT_EMAIL: "/operators/resend-email",
    CONFIRM_AGENT_EMAIL: "/operators/confirm-email",

    // Inbox
    INBOX: "/inbox",
    MESSAGE: "/inbox/messages",
    QUICK_PHRASES: "/inbox/quick-phrases",
    SAVE_TAG: "/inbox/tag",
    CUSTOMER_DETAILS: "/inbox/customer",
    CUSTOMER_MEDIAS: "/inbox/media-messages",
    // RESOLVE: '/inbox/resolve',
    // OPEN: '/inbox/open',
    TRANSFER_CALL_AGENT: "/calls/transfer-operator",
    TRANSFER_CALL_GROUP: "/calls/transfer-group",
    INIT_BOT: "/calls/init-bot",
    INBOX_COUNTERS: "/inbox/counter",
    SEGMENTS: "/inbox/segments",
    FAVORITES: "/inbox/favorites",
    ASSIGNMENT_RULES: "/inbox/assignment",
    SEND_ACTIVE_MESSAGE: "/inbox/active-chat",
    SEND_COMPOSING: "/integrations/composing",

    // Pages
    CONTRACT: "/loans",
    CUSTOMER: "/customer",
    DASHBOARD: "/dashboard",
    DASHBOARD_AGENTS: "/dashboard/agents",

    // Settings
    COMPANY: "/companies",
    RESEND_EMAIL: "/companies/resend-email",
    OPERATOR: "/operators",

    GROUP: "/group",
    LABEL: "/label",
    WEBHOOK: "/webhook",
    EXTENSION: "/extension",
    BOT: "/bot",
    BUSINESS_HOURS: "/business-hours",
    PLUGIN: "/plugin",
    API_TOKENS: "/workspace/secrets",

    // Settings Setup
    CHANNEL: "/integrations/channel",
    FACEBOOK: "/integrations/facebook",
    FACEBOOK_GRAPH_API:
        "https://graph.facebook.com/v3.3/me/accounts?fields=picture%7Burl%7D%2Caccess_token%2Cname&access_token=",
    WHATSAPP: "/integrations/whatsapp",
    WHATSAPP_QRCODE: "/integrations/whatsapp/qrcode",
    WHATSAPP_RESTART_CONTAINER: "/integrations/restart",
    TELEGRAM: "/integrations/telegram",
    INSTAGRAM: "/integrations/instagram",
    EMAIL: "/integrations/mail",
    CUSTOM: "/integrations/custom",
    WIDGET: "/integrations/widget",

    // Power Ups
    POWER_UPS: "/power-ups",
    PAYMENT: "/payment/subscribe",
    VERIFY_COUPON: "/payment/cupom/verify",

    // Reports
    REPORT: "/report",
    REPORT_CUSTOMER: "/report/customer",
    REPORT_TAGS: "/report/tags",
    REPORT_TAGS_EXPORT_EMAIL: "/report/tags/export-email",
    DOWNLOAD_REPORT_CAMPAIGN: "/report/campaign",
    
    // Campanhas
    CAMPAIGN: "/campaign",

    //Merge
    MERGE: "/customer/merge-customer",

    // Channels
    SEND_EMAIL_CODE_INSTAGRAM: "/integrations/instagram/send-verification-email",
    SEND_CODE_INSTAGRAM: "/integrations/instagram/send-verification-code",

    // Widget
    SEND_MESSAGE_BY_CLIENT: "/v1/widget/messages",

    //Customer
    COSTUMERS: "/customer/all-customers",
    COSTUMERS_PROPERTIES: "/customer/properties"
}

// const mapEndpoint = () => {
//     const mappedEndpoints = {}
//     Object.keys(endpoints).forEach(e => {
//         mappedEndpoints[e] = domain + endpoints[e]
//     })
//     return mappedEndpoints
// }

export default {
    domain,
    ...endpoints
}
