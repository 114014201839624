import React from "react"
import ReactWebcam from "react-webcam"
// import Button from "components/forms/ButtonMaterial"
import Button from "components/forms/Button"
import avatarDefaultImage from "assets/images/agent.svg"
import store from "store"

export default class AvatarUploader extends React.PureComponent {
    state = {
        imageWebcamSrc: null,
        imageSrc: null,
        menuOpen: false
        // files: null
    }

    onOpen = () => {
        this.setState({ menuOpen: true })
        document.addEventListener("click", this.eventClick)
    }

    eventClick = () => {
        document.removeEventListener("click", this.eventClick)
        this.setState({ menuOpen: false })
    }

    onHandleFile = async files => {
        this.props.setAvatar(files[0])
        this.setState({ imageSrc: URL.createObjectURL(files[0]) })
    }

    render() {
        const { webcam, height, width, label, avatar, defaultAvatar } = this.props

        const parcedAvatar = avatar && avatar.replace(" ", "%20");

        const image = this.state.imageSrc
            ? `url(${this.state.imageSrc})`
            : parcedAvatar
            ? `url(${parcedAvatar})`
            : `url(${defaultAvatar || avatarDefaultImage})`

        return (
            <div style={{ flexDirection: "column", display: "flex" }}>
                <label className="label-input">
                    {label} <span>, Max 1MB</span>{" "}
                </label>
                <a
                    className="user-avatar"
                    style={{ height, width, backgroundImage: image }}
                    type="button"
                >
                    <div className="overlay">
                        <div className="overlay-inner">
                            <i className="fa fa-cloud-upload" />
                            Upload
                        </div>
                    </div>
                    {/* <i className="material-icons" style={{ fontSize: 30 }}>
                            backup
                        </i> */}
                    {/* <div className="avatar-upload-indicator" /> */}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={e => this.onHandleFile(e.target.files)}
                    />
                </a>
                {/* <Button onClick={() => store.helper.openWebcam()} light>
                        Mudar foto
                    </Button> */}
            </div>
        )
    }
}
