import React from "react"
import _ from "lodash"
import HelpBanner from "components/HelpBanner"
import masks from "lib/masks"
import PageTable from "../PageTable"
import EditContract from "./EditContract"
import ViewContract from "./ViewContract"
import { typeFilters } from "lib/constants"
import { operationTypes } from "lib/constants"
import { contract } from "store"

function getTitle(status) {
    switch (status) {
        case "created":
            return "Processando contrato..."
        case "formalized":
            return "Contrato Formalizado"
        case "rejected":
            return "Contrato rejeitado pelo cliente"
        case "processed":
            return "Aguardando formalização"
        case "failed":
            return "Falha no processamento do PDF"
        case "overwritten":
            return "Substituido por outro com mesmo número de contrato"
        default:
            return ""
    }
}

function getStatus(status) {
    switch (status) {
        case "created":
            return {
                title: "Processando",
                bg: "rgba(52, 152, 219, 0.3)"
            }
        case "formalized":
            return {
                title: "Formalizado",
                bg: "rgba(46,204,113, 0.3)"
            }
        case "rejected":
            return { title: "Rejeitado", bg: "rgba(243,156,18, 0.3)" }
        case "processed":
            return { title: "Aguardando Formalização", bg: "" }
        case "failed":
            return { title: "Falhou", bg: "rgba(231, 76, 60, 0.3)" }
        case "overwritten":
            return { title: "Substituido", bg: "rgba(155,89,182, 0.3)" }
        default:
            return ""
    }
}

function getIcon(original) {
    let iconProps = {}
    switch (original.status) {
        case "created":
            iconProps = {
                className: "fas fa-cog cell-avatar",
                style: {
                    backgroundColor: "#3498db",
                    color: "white",
                    animation: "fa-spin 2s linear infinite"
                }
            }
            break
        case "formalized":
            iconProps = {
                className: "fas fa-check cell-avatar",
                style: { backgroundColor: "#2ecc71", color: "white" }
            }
            break
        case "rejected":
            iconProps = {
                className: "fas fa-thumbs-down cell-avatar",
                style: { backgroundColor: "#f39c12", color: "white" }
            }
            break
        case "failed":
            iconProps = {
                className: "fas fa-times cell-avatar",
                style: { backgroundColor: "#e74c3c", color: "white" }
            }
            break
        case "overwritten":
            iconProps = {
                className: "fas fa-exchange cell-avatar",
                style: { backgroundColor: "#e74c3c", color: "white" }
            }
            break
        default:
            break
    }
    return (
        <i
            className="fas fa-clock cell-avatar"
            style={{ color: "rgba(0,0,0,0.2)" }}
            title={getTitle(original.status)}
            {...iconProps}
        />
    )
}

const columns = [
    {
        Header: "Pessoa",
        accessor: "name",
        minWidth: 200,
        Cell: props => (
            <div className="vertical-center">
                {getIcon(props.row._original)}
                {props.value ? (
                    <span>{_.startCase(_.toLower(props.value))}</span>
                ) : (
                    <span style={{ color: "rgba(0,0,0,0.5)" }}>
                        {getTitle(props.row._original.status)}
                    </span>
                )}
            </div>
        )
    },
    // {
    //     Header: "Pessoa",
    //     accessor: "name",
    // },
    {
        Header: "Status",
        accessor: "status",
        width: 220,
        Cell: props => (
            <span
                className="contract-status"
                style={{ backgroundColor: getStatus(props.value).bg }}
            >
                {getStatus(props.value).title}
            </span>
        )
    },
    {
        Header: "CPF",
        accessor: "cpf",
        width: 150,
        Cell: props => masks.cpf(props.value)
    },
    {
        Header: "Tipo de operação",
        accessor: "code",
        width: 160,
        Cell: props => {
            try {
                return operationTypes.find(e => e.value == props.value).label
            } catch (e) {
                return ""
            }
        }
    },
    {
        Header: "Criado em",
        accessor: "createdAt",
        type: "date",
        width: 150
    },
    {
        Header: "Formalizado em",
        accessor: "formalized",
        type: "date",
        width: 150
    }
]

const helpBanner = (
    <HelpBanner
        id="people"
        title="Como funciona"
        // tutorial={{ name: "Tutorial: Create an article", to: "/" }}
    >
        Clique em <strong>"Importar Contrato"</strong>, logo abaixo, para subir um ou mais PDFs de
        solicitação de empréstimo de clientes. <br />O arquivo será processado e virará uma linha na
        tabela abaixo onde você poderá acompanhar quando o mesmo for formalizado pelo cliente no
        app.
    </HelpBanner>
)

const filters = [
    { type: typeFilters.SEARCH },
    {
        type: typeFilters.TABS,
        tabs: [
            {
                name: "Todos",
                query: {
                    status: undefined
                }
            },
            {
                name: "Pendentes",
                query: {
                    status: "processed"
                }
            },
            {
                name: "Concluídos",
                query: {
                    status: "formalized"
                }
            }
        ]
    },
    {
        type: typeFilters.MORE,
        name: "Período",
        position: "right",
        subfilters: [
            {
                type: typeFilters.PERIOD
            }
        ]
    }
]

export default function Contracts(props) {
    return (
        <PageTable
            storeRef={contract}
            title="Contratos"
            newLabel="Importar Contrato"
            helper={helpBanner}
            columns={columns}
            filters={[filters]}
            EditComponent={EditContract}
            ViewComponent={ViewContract}
            nodata={{
                title: "Parece que você ainda não adicionou nenhum contrato...",
                buttonText: "Importar contrato"
            }}
            {...props}
        />
    )
}
