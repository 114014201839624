import React, { useState } from "react"
import _ from "lodash"
import Text from "components/forms/Text"
import SwitchField from "components/forms/SwitchField"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import ColorEmojiPicker from "components/forms/ColorEmojiPicker"
import Checkbox from "components/forms/Checkbox"
import SearchText from "components/forms/SearchText"
import {
    useField,
    useCheckbox,
    useForm,
    useFormMultiSelect,
    useColorEmoji
} from "hooks"
import { useStore, segment, operator, channel, group, tag } from "store"
import { channelsTypes } from "lib/constants"
import config from "config"
import req from "lib/req"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function EditSegment(props) {
    const { item = { filters: {}, isPublic: true } } = props
    const [propKeys, setPropKeys] = useState([])
    const [propValues, setPropValues] = useState([])
    const segmentStore = useStore(segment)
    const operatorStore = useStore(operator)
    const groupStore = useStore(group)
    const tagStore = useStore(tag)
    const channelStore = useStore(channel)

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Salvou um novo segmento"
            })
            const newSegment = await segmentStore.save(
                {
                    data: {
                        ...model,
                        filters: _.pickBy(
                            {
                                assigned: notAssigned.checked
                                    ? false
                                    : assigned.checked || null,
                                open: notOpen.checked
                                    ? false
                                    : open.checked || null,
                                channels: channels.rawValue,
                                groups: groups.rawValue,
                                agents: agents.rawValue,
                                tags: tags.rawValue,
                                customerProps: property.value && propertyValue.value
                                    ? { property: property.value, value: propertyValue.value }
                                    : null
                            },
                            v => v != null && v !== ""
                        ),
                        icon: icon.value
                    }
                },
                { callback: () => {} }
            )
            console.log("newSegment", newSegment)
            segmentStore.saveItem(
                { ...newSegment, subscribed: true },
                { saveOnEnd: true }
            )
        },
        defaultModel: item
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    const channels = useFormMultiSelect("channels", {
        defaultValue: item.filters.channels,
        loader: "GET/channels",
        // options: Object.keys(channelsTypes).map(key => ({
        //     value: key,
        //     label: channelsTypes[key].name
        // }))
        options: channelStore.all.map(x => ({
            value: x.id,
            label: `${channelsTypes[x.channelId].name} - ${x.description}`
        }))
    })

    const agents = useFormMultiSelect("agents", {
        defaultValue: item.filters.agents,
        loader: "GET/operators",
        options: operatorStore.all.map(e => ({
            value: e.id,
            label: e.name
        }))
    })

    const groups = useFormMultiSelect("groups", {
        defaultValue: item.filters.groups,
        loader: "GET/groups",
        options: groupStore.all.map(e => ({
            value: e.id,
            label: e.name
        }))
    })

    const tags = useFormMultiSelect("tags", {
        defaultValue: item.filters.tags,
        loader: "GET/tags",
        options: tagStore.all.map(e => ({
            value: e.id,
            label: e.name
        }))
    })

    const notAssigned = useCheckbox("notAssigned", {
        defaultValue: item.filters.hasOwnProperty("assigned")
            ? !item.filters.assigned
            : false
    })

    const assigned = useCheckbox("assigned", {
        defaultValue: item.filters.assigned
    })

    const open = useCheckbox("open", {
        defaultValue: item.filters.open
    })

    const notOpen = useCheckbox("notOpen", {
        defaultValue: item.filters.hasOwnProperty("open")
            ? !item.filters.open
            : false
    })

    const icon = useColorEmoji("icon", {
        defaultValue: item.icon || "#b3bac5"
    })

    const privacy = useCheckbox("isPublic", {
        defaultValue: item.isPublic,
        form
    })

    const property = useField("property", {
        defaultValue: item.filters.customerProps && item.filters.customerProps.property,
        form
    })

    const propertyValue = useField("propertyValue", {
        defaultValue: item.filters.customerProps && item.filters.customerProps.value,
        mask: props.valueMask,
        form
    })

    async function searchProperties(query) {
        const state = Object.hasOwn(query, 'value') ? 'value' : 'property'
        const setState = state === 'value' ? setPropValues : setPropKeys

        try {
            const response = await req(config.api.COSTUMERS_PROPERTIES, {
                data: {
                    limit: 20,
                    ...query
                }
            })
            const formattedState = response
                .map((item) => ({
                    value: item[state],
                    label: item[state]
                }))
            setState(formattedState)
        } catch (error) {
            setState([])
        }
    }

    return (
        <FormDialog
            {...props}
            title="Filtros"
            item={item}
            onSubmit={form.onSubmit}
            endpoint={config.api.SEGMENTS}
            onDelete={() => {
                segmentStore.removeFromAll(item)
                segmentStore.remove({
                    data: item
                })
                props.onBack()
            }}
            // noExcludable
        >
            <Text label="Nome" required {...name} />
            <Select
                label="Agentes"
                placeholder="Filtrar por Agente"
                {...agents}
            />
            <Select
                label="Grupos"
                placeholder="Filtrar por Grupo"
                {...groups}
            />
            <label className="label-input">Filtrar por status</label>
            <div className="checkbox-row">
                <Checkbox {...notAssigned} disabled={assigned.checked}>
                    Não atribuído
                </Checkbox>
                <Checkbox {...assigned} disabled={notAssigned.checked}>
                    Atribuído
                </Checkbox>
                <Checkbox {...open} disabled={notOpen.checked}>
                    Aberto
                </Checkbox>
                <Checkbox {...notOpen} disabled={open.checked}>
                    Resolvido
                </Checkbox>
            </div>
            <br />
            <label className="label-input">Filtrar por atributo do cliente</label>
            <div style={{ display: "flex", gap: "4px", marginTop: "4px" }}>
                <SearchText
                    {...property}
                    placeholder="Nome do atributo"
                    options={propKeys}
                    onOptionClick={(item) => property.setValue(item)}
                    searchFunction={() => searchProperties({ property: property.value })}
                    observableValues={[ property.value ]}
                />
                <span style={{marginTop: "6px"}}>=</span>
                <SearchText
                    required={!!property.value}
                    {...propertyValue}
                    placeholder="Valor do atributo"
                    options={propValues}
                    onOptionClick={(item) => propertyValue.setValue(item)}
                    searchFunction={() => searchProperties({ property: property.value, value: propertyValue.value })}
                    observableValues={[ propertyValue.value, property.value ]}
                    updateWhenFocused
                />
            </div>
            <Select
                label="Canais"
                placeholder="Filtrar por canal"
                {...channels}
            />
            <Select
                label="Etiquetas"
                placeholder="Filtrar por etiqueta"
                {...tags}
            />
            <ColorEmojiPicker {...icon} />
            <br />
            <SwitchField
                label="Público"
                description="Todos poderão ver e usar seu filtro"
                {...privacy}
            />
        </FormDialog>
    )
}

export default EditSegment
