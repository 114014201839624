import React from "react"

export default function ModalSide({ title, description, img, imgStyle }) {
    return (
        <div className="modal-side">
            <h2 className="title">{title}</h2>
            <p>{description}</p>
            <img src={img} style={imgStyle} alt="" />
        </div>
    )
}
