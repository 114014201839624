import React, { useMemo, useEffect } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import { useStore, general, custom } from "store"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import { getStatus } from "pages/settings/channels/Channels"
import SettingsLoader from "components/settings/SettingsLoader"
import shortid from "shortid"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import ContextBg from "components/ContextBg"
/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddCustom(props) {
    const customStore = useStore(custom)
    const { addModal, removeModal } = useStore(general)

    const item = props.match.params.id
        ? customStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adiciou um canal customizado"
                    : "O agente editou um canal customizado"
            })
            const response = await customStore.save({
                data: model,
                toastMessage: "Canal customizado salvo."
            })
            return () => {
                if (!item.id && !response.error && !response.errors) {
                    props.history.push("/settings/channels")
                }
            }
        },
        defaultModel: item
    })

    const name = useField("description", {
        defaultValue: item.description,
        form
    })

    const newIdentifier = useMemo(() => shortid.generate(), [])

    const identifier = useField("identifier", {
        defaultValue: item.identifier || newIdentifier,
        form
    })

    const hashIdentifier = useField("hashIdentifier", {
        defaultValue: item.hashIdentifier,
        form
    })

    return (
        <div className="channel-page">
            <ContextHeader backAction={{ to: "/settings/channels" }} />
            <ContextBg success>
                <h3>Funcionalidade em desenvolvimento!</h3>
                <p> Agradecemos o interesse, em breve teremos novidades!</p>
            </ContextBg>
            <PageTitle
                title="Canal Customizado"
                description="Um canal customizado pode ser controlado usando nossa API para
                integrar qualquer plataforma de mensagem com o KimoChat."
            />
            <SettingsLoader store={customStore} {...props}>
                <FormDialog
                    {...props}
                    disabledSubmit
                    item={item}
                    endpoint="/integrations/custom"
                    onSubmit={form.onSubmit}
                    onDelete={id =>
                        customStore
                            .remove({
                                data: { id },
                                toastMessage: "Deletado com sucesso"
                            })
                            .then(() => {
                                props.history.push("/settings/channels")
                            })
                    }
                >
                    <div style={{ maxWidth: 460 }}>
                        <Text label="Nome" required {...name} />
                        <Text
                            label="Endpoint para o KimoChat receber mensagens"
                            required
                            disabled
                            description="Chame essa URL para receber mensagens no KimoChat"
                            value={`https://api.kimochat.com.br/api/v1/channels/${identifier.value}/messages`}
                        />
                        <Text
                            label="Endpoint para o KimoChat enviar mensagem e atualização de status"
                            required
                            description="KimoChat vai enviar um POST para essa URL sempre que um agente interagir com uma conversa desse canal"
                            placeholder="https://api.myserver.com/integration/kimochat"
                            {...hashIdentifier}
                        />
                    </div>
                </FormDialog>
            </SettingsLoader>
        </div>
    )
}
