import React from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import ColorEmojiPicker from "components/forms/ColorEmojiPicker"
import { useField, useFormMultiSelect, useForm, useFetch, useColorEmoji } from "hooks"
import { operator, useStore } from "store"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function EditGroup(props) {
    const item = props.item
    const operatorStore = useFetch(operator)

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Agente salvou um grupo"
            })
            await props.onSubmit(model, { toastMessage: "Grupo salvo" })
        },
        defaultModel: item
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    const description = useField("description", {
        defaultValue: item.description,
        form
    })

    const icon = useColorEmoji("icon", {
        defaultValue: item.icon || "#b3bac5",
        form
    })

    const members = useFormMultiSelect("members", {
        defaultValue: item.members,
        loader: "GET/user",
        form,
        options: operatorStore.all.map(e => ({
            value: e.id,
            label: e.name
        }))
    })

    return (
        <FormDialog {...props} title="Grupo" onSubmit={form.onSubmit}>
            <Text label="Nome" required {...name} />
            <Text label="Descrição" {...description} />
            <Select label="Agentes" {...members} />
            <ColorEmojiPicker {...icon} />
        </FormDialog>
    )
}

export default EditGroup
