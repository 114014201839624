/* Layouts
================================================= */
import UserLayout from "../layouts/UserLayout"
import BasicLayout from "../layouts/BasicLayout"

/* Pages
================================================= */
import Landing from "pages/landing/Landing"
import Pricing from "pages/landing/Pricing"
import WppLanding from "pages/landing/whatsapp/WppLanding"
import FacebookLanding from "pages/landing/facebook/FacebookLanding"
import TelegramLanding from "pages/landing/telegram/TelegramLanding"
import InstagramLanding from "pages/landing/instagram/InstagramLanding"
import EmailLanding from "pages/landing/email/EmailLanding"
import WidgetLanding from "pages/landing/widget/WidgetLanding"
import BotLanding from "pages/landing/bot/BotLanding"
import TermsOfUse from "pages/landing/TermsOfUse"
import Privacy from "pages/landing/Privacy"
import Ganhomais from "pages/ganhomais/Ganhomais"

// user
import Login from "pages/user/Login"
import Forgot from "pages/user/Forgot"
import ForgotSent from "pages/user/ForgotSent"
import JoinRegister from "pages/user/join/JoinRegister"
import JoinRegisterSuccess from "pages/user/join/JoinRegisterSuccess"
import Register from "pages/user/Register"
import Registering from "pages/user/Registering"
import Logout from "pages/user/Logout"
import Reset from "pages/user/Reset"
import ResetSuccess from "pages/user/ResetSuccess"
import EmailConfirm from "pages/user/EmailConfirm"

import JoinWorkspace from "pages/user/join/JoinWorkspace"

// dashboard
import DashboardGeneral from "../pages/dashboard/DashboardGeneral"
import DashboardMembers from "../pages/dashboard/DashboardMembers"

// reports
// import Reports from "../pages/reports/Reports"
import ReportGeneral from "../pages/reports/ReportGeneral"
import ReportConversations from "../pages/reports/ReportConversations"
import ReportCustomers from "../pages/reports/ReportCustomers"
import ReportTags from "pages/reports/ReportTags"
// import ReportMembers from "../pages/reports/members/ReportMembers"
// import ReportLabels from "../pages/reports/labels/ReportLabels"

// inbox
import Inbox from "pages/inbox/Inbox"
import Campaigns from "pages/campaigns/Campaigns"
import Contracts from "pages/contracts/Contracts"

// people
// import People from "pages/people/People"

// power ups
import PowerUps from "pages/power-ups/PowerUps"
import Facebook from "pages/power-ups/channels/facebook/Facebook"
import Whatsapp from "pages/power-ups/channels/whatsapp/Whatsapp"
import Bot from "pages/settings/bot/Bot"
import EditBot from "pages/power-ups/bot/EditBot"

// settings setup
import Settings from "pages/settings/Settings"
import InvoicePrint from "pages/settings/billing/InvoicePrint"
// import Workspace from "pages/settings/workspace/Workspace"
import EditWorkspace from "pages/settings/workspace/EditWorkspace"
import EditProfile from "pages/settings/profile/EditProfile"
import EditPreferences from "pages/settings/preferences/EditPreferences"
import ChangePassword from "pages/settings/profile/ChangePassword"

import GettingStarted from "pages/settings/widget/GettingStarted"
import CustomizeWidget from "pages/settings/widget/CustomizeWidget"

// import Widget from "pages/settings/widget/Widget"
import License from "pages/settings/billing/License"
import Invoices from "pages/settings/billing/Invoices"
import Billing from "pages/settings/billing/Billing"
import Payment from "pages/settings/billing/Payment"

import Members from "pages/settings/members/Members"
import Groups from "pages/settings/groups/Groups"
import AssignmentRules from "pages/settings/assignmentrules/AssignmentRules"
import Tags from "pages/settings/tags/Tags"
import QuickPhrases from "pages/settings/quickphrases/QuickPhrases"
import Channels from "pages/settings/channels/Channels"
import AddChannel from "pages/settings/channels/AddChannel"
import AddWhatsapp from "pages/settings/channels/whatsapp/AddWhatsapp"
import AddInstagram from "pages/settings/channels/instagram/AddInstagram"
import AddFacebook from "pages/settings/channels/facebook/AddFacebook"
import AddTelegram from "pages/settings/channels/telegram/AddTelegram"
import AddEmail from "pages/settings/channels/email/AddEmail"
import AddCustom from "pages/settings/channels/custom/AddCustom"
import AddWidget from "pages/settings/channels/widget/AddWidget"

import Webhooks from "pages/settings/webhooks/Webhooks"
import Plugins from "pages/settings/plugins/Plugins"
import Api from "pages/settings/api/Api"

import BusinessHours from "pages/settings/businesshours/BusinessHours"

import Widget from "pages/widget/Widget"

// import Profile from "pages/settings/setup/profile/Profile"
// import Widget from "pages/settings/setup/widget/Widget"
// import CustomizeWidget from "pages/settings/setup/widget/CustomizeWidget";
// import Billing from "pages/settings/setup/billing/Billing"

//     //Integrations
// import Integrations from "pages/settings/setup/integrations/Integrations"
// import Whatsapp from "pages/settings/setup/integrations/whatsapp/Whatsapp"
// import TelegramIntegration from "pages/settings/setup/integrations/TelegramIntegration"

// // settings manage
// import Companies from "pages/settings/companies/Companies"
// import TeamMembers from "pages/settings/members/TeamMembers"
// import Groups from "pages/settings/groups/Groups"
// import Labels from "pages/settings/labels/Labels"
// import Webhooks from "pages/settings/webhooks/Webhooks"
// import Extensions from "pages/settings/extensions/Extensions"
// import Bot from "pages/settings/bot/Bot"
// import BusinessHours from "pages/settings/businesshours/BusinessHours"

/* Goole Analytics
================================================= */


/* Routes
================================================= */

const routes = [
    {
        path: "/",
        exact: true,
        component: Landing
    },
    {
        path: "/whatsapp",
        exact: true,
        component: WppLanding
    },
    {
        path: "/facebook",
        exact: true,
        component: FacebookLanding
    },
    {
        path: "/telegram",
        exact: true,
        component: TelegramLanding
    },
    {
        path: "/instagram",
        exact: true,
        component: InstagramLanding
    },
    {
        path: "/email",
        exact: true,
        component: EmailLanding
    },
    {
        path: "/features",
        exact: true,
        component: WidgetLanding
    },
    {
        path: "/bot",
        exact: true,
        component: BotLanding
    },
    {
        path: "/widget",
        exact: true,
        component: Widget
    },
    {
        path: "/user/email-confirm",
        exact: true,
        component: EmailConfirm
    },
    {
        path: "/pricing",
        exact: true,
        component: Pricing
    },
    {
        path: "/terms",
        exact: true,
        component: TermsOfUse
    },
    {
        path: "/privacy",
        exact: true,
        component: Privacy
    },
    {
        path: "/invoices/:id/:transactionId",
        exact: true,
        component: InvoicePrint
    },
    {
        path: "/ganhomais",
        exact: true,
        component: Ganhomais
    },
    {
        path: "/user/join-workspace",
        exact: true,
        component: JoinWorkspace
        // routes: [{ path: "/user/join-workspace", component: JoinWorkspace }]
    },
    // user
    {
        path: "/user",
        component: UserLayout,
        routes: [
            // { path: "/user", redirect: "/user/login" },
            { path: "/user/login", component: Login },
            { path: "/user/forgot/sent", component: ForgotSent },
            { path: "/user/forgot", component: Forgot },
            { path: "/user/reset/success", component: ResetSuccess },
            { path: "/user/reset", component: Reset },
            { path: "/user/logout", component: Logout },

            // Register
            { path: "/user/register/creating", exact: true, component: Registering },
            { path: "/user/register", exact: true, component: Register },

            // Join
            { path: "/user/join-workspace", component: JoinWorkspace },
            { path: "/user/join/register/success", component: JoinRegisterSuccess },
            { path: "/user/join/register", component: JoinRegister }
        ]
    },
    // app
    {
        path: "/",
        component: BasicLayout,
        // Routes: ["src/pages/Authorized"],
        authority: ["admin", "user"],
        routes: [
            // {
            //     path: "/",
            //     name: "",
            //     icon: "",
            //     component: Landing,
            //     exact: true
            //     // redirect: "/settings"
            // },
            {
                path: "/inbox",
                name: "Caixa de Entrada",
                icon: "inbox",
                routes: [
                    {
                        path: "/inbox",
                        exact: true,
                        redirect: "/inbox/all"
                    },
                    {
                        path: "/inbox/:segment/:id?",
                        name: "Caixa de Entrada",
                        component: Inbox
                    }
                ]
            },
            {
                path: "/campaigns",
                name: "Campanhas",
                icon: "campaign",
                routes: [
                    {
                        path: "/campaigns/:id?",
                        exact: true,
                        component: Campaigns
                    },
                ]
            },
            {
                path: "/power-ups",
                name: "Power Ups",
                routes: [
                    {
                        path: "/power-ups",
                        component: PowerUps,
                        exact: true
                    },
                    {
                        path: "/power-ups/facebook/:id?",
                        component: Facebook
                    },
                    {
                        path: "/power-ups/whatsapp/:id?",
                        component: Whatsapp
                    },
                    {
                        path: "/power-ups/bot",
                        component: Bot,
                        exact: true
                    },
                    {
                        path: "/power-ups/bot/:id",
                        component: EditBot
                    }
                ]
            },
            // {
            //     path: "/dashboard",
            //     routes: [
            //         {
            //             path: "/dashboard",
            //             component: Dashboard
            //         },
            //         {
            //             path: "/dashboard/agents",
            //             component: Dashboard
            //         }
            //     ]
            // },
            // {
            //     path: "/people",
            //     name: "Caixa de Entrada",
            //     icon: "users",
            //     routes: [
            //         {
            //             path: "/people/:id?",
            //             name: "Pessoas",
            //             component: People
            //         }
            //     ]
            // },
            // reports
            {
                path: "/reports",
                // component: ReportGeneral
                routes: [
                    {
                        path: "/reports",
                        exact: true,
                        redirect: "/reports/overview"
                    },
                    {
                        path: "/reports/overview",
                        component: DashboardGeneral
                    },
                    {
                        path: "/reports/team",
                        component: DashboardMembers
                    },
                    {
                        path: "/reports/conversations2",
                        component: ReportGeneral
                    },
                    {
                        path: "/reports/conversations",
                        component: ReportConversations
                    },
                    {
                        path: "/reports/customers",
                        component: ReportCustomers
                    },
                    {
                        path: "/reports/tags",
                        component: ReportTags
                    }
                ]
            },
            {
                path: "/contracts",
                name: "Contratos",
                icon: "inbox",
                routes: [
                    {
                        path: "/contract/:id",
                        name: "Visualizar Contrato",
                        component: Contracts
                    },
                    {
                        path: "/contracts/:id?",
                        name: "Contratos",
                        component: Contracts
                    }
                ]
            },
            //settings
            {
                path: "/settings/:setting?",
                // component: Settings,
                routes: [
                    {
                        path: "/settings",
                        exact: true,
                        redirect: "/settings/account/profile"
                    },
                    {
                        name: "Workspace",
                        path: "/settings/account/workspace",
                        component: EditWorkspace
                    },
                    {
                        name: "Change Password",
                        path: "/settings/account/profile/password",
                        component: ChangePassword
                    },
                    {
                        name: "Meu perfil",
                        path: "/settings/account/profile",
                        component: EditProfile
                    },
                    {
                        name: "Preferencias",
                        path: "/settings/preferences",
                        component: EditPreferences
                    },
                    {
                        name: "Canais",
                        path: "/settings/channels",
                        exact: true,
                        component: Channels
                    },
                    {
                        name: "Add Canal Whatsapp",
                        path: "/settings/channels/add/whatsapp",
                        exact: true,
                        component: AddWhatsapp
                    },
                    {
                        name: "Add Canal Whatsapp",
                        path: "/settings/channels/whatsapp/:id",
                        exact: true,
                        component: AddWhatsapp
                    },
                    {
                        name: "Add Canal Chat Widget",
                        path: "/settings/channels/add/chat-widget",
                        exact: true,
                        component: AddWidget
                    },
                    {
                        name: "Edit Canal Chat Widget",
                        path: "/settings/channels/chat-widget/:id",
                        exact: true,
                        component: AddWidget
                    },
                    {
                        name: "Add Canal Instagram",
                        path: "/settings/channels/add/instagram",
                        exact: true,
                        component: AddInstagram
                    },
                    {
                        name: "Add Canal Instagram",
                        path: "/settings/channels/instagram/:id",
                        exact: true,
                        component: AddInstagram
                    },
                    {
                        name: "Edit Canal Facebook",
                        path: "/settings/channels/facebook/:id",
                        component: AddFacebook
                    },
                    {
                        name: "Add Canal Facebook",
                        path: "/settings/channels/add/facebook",
                        component: AddFacebook
                    },
                    {
                        name: "Edit Canal Telegram",
                        path: "/settings/channels/telegram/:id",
                        component: AddTelegram
                    },
                    {
                        name: "Add Canal Telegram",
                        path: "/settings/channels/add/telegram",
                        component: AddTelegram
                    },
                    {
                        name: "Edit Canal Custom",
                        path: "/settings/channels/custom/:id",
                        component: AddCustom
                    },
                    {
                        name: "Add Canal Custom",
                        path: "/settings/channels/add/custom",
                        component: AddCustom
                    },
                    {
                        name: "Edit Canal Email",
                        path: "/settings/channels/email/:id",
                        component: AddEmail
                    },
                    {
                        name: "Add Canal Email",
                        path: "/settings/channels/add/email",
                        component: AddEmail
                    },
                    {
                        name: "Add Canais",
                        path: "/settings/channels/new",
                        component: AddChannel
                    },
                    {
                        name: "Assinatura",
                        path: "/settings/billing/license",
                        component: License
                    },
                    {
                        name: "Faturas",
                        path: "/settings/billing/invoices",
                        component: Invoices
                    },
                    {
                        name: "Assinatura",
                        path: "/settings/billing/subscription",
                        component: Billing,
                        hideTab: true
                    },
                    {
                        name: "Pagamento",
                        path: "/settings/billing/payment/:id",
                        component: Payment
                    },
                    {
                        name: "Agentes",
                        path: "/settings/members/:id?",
                        component: Members
                    },
                    {
                        name: "Grupos",
                        path: "/settings/groups/:id?",
                        component: Groups
                    },
                    {
                        name: "Plugins",
                        path: "/settings/plugins/:id?",
                        component: Plugins
                    },
                    {
                        name: "API",
                        path: "/settings/api",
                        component: Api
                    },
                    {
                        name: "Tags",
                        path: "/settings/tags/:id?",
                        component: Tags
                    },
                    {
                        name: "QuickPhrases",
                        path: "/settings/quick-phrases/:id?",
                        component: QuickPhrases
                    },
                    {
                        name: "Horário comercial",
                        path: "/settings/business-hours/:id?",
                        component: BusinessHours
                    },
                    {
                        name: "Webhook",
                        path: "/settings/webhook/:id?",
                        component: Webhooks
                    },
                    {
                        name: "Assignment Rules",
                        path: "/settings/assignment-rules/:id?",
                        component: AssignmentRules
                    },
                    {
                        path: "/settings/bot",
                        component: Bot,
                        exact: true
                    },
                    {
                        path: "/settings/bot/:id",
                        component: EditBot
                    }

                    //     // Conta
                    //     {
                    //         path: "/settings",
                    //         component: Settings,
                    //         exact: true
                    //     },
                    //     {
                    //         path: "/settings/workspace",
                    //         component: Workspace
                    //     },
                    //     {
                    //         path: "/settings/profile",
                    //         component: Profile
                    //     },
                    //     {
                    //         path: "/settings/widget",
                    //         component: Widget
                    //     },
                    //     {
                    //         path: "/settings/widget/customize",
                    //         component: CustomizeWidget
                    //     },
                    //     {
                    //         path: "/settings/billing",
                    //         component: Billing
                    //     },
                    //     // Integrations
                    //     {
                    //         path: "/settings/integrations",
                    //         component: Integrations,
                    //         exact: true
                    //     },
                    //     {
                    //         path: "/settings/integrations/facebook/:id?",
                    //         component: Facebook
                    //     },
                    //     {
                    //         path: "/settings/integrations/whatsapp/:id?",
                    //         component: Whatsapp
                    //     },
                    //     {
                    //         path: "/settings/integrations/telegram",
                    //         component: TelegramIntegration
                    //     },

                    //     // Configurar
                    //     {
                    //         path: "/settings/companies/:id?",
                    //         component: Companies
                    //     },
                    //     {
                    //         path: "/settings/members/:id?",
                    //         component: TeamMembers
                    //     },
                    //     {
                    //         path: "/settings/groups/:id?",
                    //         component: Groups
                    //     },
                    //     {
                    //         path: "/settings/labels/:id?",
                    //         component: Labels
                    //     },
                    //     {
                    //         path: "/settings/webhooks/:id?",
                    //         component: Webhooks
                    //     },
                    //     {
                    //         path: "/settings/extensions/:id?",
                    //         component: Extensions
                    //     },
                    //     {
                    //         path: "/settings/bot/:id?",
                    //         component: Bot
                    //     },
                    //     {
                    //         path: "/settings/business-hours/:id?",
                    //         component: BusinessHours
                    //     },
                ]
            }
        ]
    }
]

export default routes
