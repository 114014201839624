import React, { Component } from "react"
// import { Link } from "react-router-dom"
// import Text from "components/forms/Text"
// import Button from "components/forms/Button"
import Box from "components/Box"
// import Logo from "components/sidenav/Logo.js"
// import LogoImg from "assets/images/logo.svg"
// import store from "store"
// import Lottie from "react-lottie"
// import livemapLottie from "assets/animations/livemap.json"

// const lottieOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: livemapLottie,
//     rendererSettings: {
//         preserveAspectRatio: "xMidYMid slice"
//     }
// }

class AuthBase extends Component {
    render() {
        const { children, after } = this.props
        return (
            <div className="page login">
                <a href="/">
                    <div className="landing-logo" />
                </a>
                {/* <div className="auth-header">
                    <Link to="/">
                        <img src={LogoImg} alt="" />
                        <span>KimoChat</span>
                    </Link>
                    <Lottie
                        options={lottieOptions}
                        // height={70}
                        // width={140}
                        // isStopped={!showArrow}
                        speed={0.1}
                        // isPaused={!showArrow}
                    />
                </div> */}
                <div className="login-page-inner">
                    <Box className="login-box">{children}</Box>
                    {after}
                </div>
            </div>
        )
    }
}

export default AuthBase
