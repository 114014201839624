import React from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm } from "hooks"
import Grid from "@material-ui/core/Grid"
import masks from "lib/masks"

export const inputGroupedOptions = [
    {
        label: "Padrões",
        options: [
            { value: "name", label: "Nome", type: "string", code: "name" },
            { value: "email", label: "Email", type: "email", code: "email" },
            {
                value: "cellphone",
                label: "Celular",
                type: "phone",
                code: "phone"
            }
        ]
    },
    {
        label: "Comuns",
        options: [
            { value: "cpf", label: "CPF", type: "cpf", code: "cpf" },
            {
                value: "address",
                label: "Endereço",
                type: "string",
                code: "endereco"
            },
            {
                value: "neighborhood",
                label: "Bairro",
                type: "string",
                code: "bairro"
            },
            { value: "city", label: "Cidade", type: "string", code: "cidade" },
            { value: "state", label: "UF", type: "string", code: "uf" },
            { value: "zipcode", label: "CEP", type: "string", code: "cep" },
            { value: "cnpj", label: "CNPJ", type: "string", code: "cnpj" },
            {
                value: "company",
                label: "Empresa",
                type: "string",
                code: "empresa"
            }
        ]
    },
    {
        label: "Outros",
        options: [{ value: "custom", label: "Personalizado", type: "string" }]
    }
]

function EditInput(props) {
    const actionItem = props.actionItem
    const form = useForm({
        onSubmit: async model => await props.onEditAction(model),
        defaultModel: { ...actionItem, type: "input", inputInvalid: "Campo inválido, escreva novamente." }
    })

    const text = useField("text", {
        defaultValue: actionItem.text,
        form
    })

    const inputProp = useSelectField("inputProp", {
        defaultValue: actionItem.inputProp,
        form,
        options: inputGroupedOptions,
        onChange: item => {
            if (item.value === "custom") {
                inputPropName.setValue("")
                inputPropCode.setValue("")
            } else {
                inputPropName.setValue(item.label)
                inputPropCode.setValue(masks.propertyCode(item.code))
            }
        }
    })

    const inputPropName = useField("inputPropName", {
        defaultValue: actionItem.inputPropName,
        form
    })

    const inputPropCode = useField("inputPropCode", {
        defaultValue: actionItem.inputPropCode,
        mask: ["propertyCode"],
        form
    })

    const inputInvalid = useField("inputInvalid", {
        defaultValue: actionItem.inputInvalid,
        form
    })

    return (
        <FormDialog
            {...props}
            title="Entrada de Dados"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Text
                label="Texto"
                placeholder="Qual seu email?"
                required
                {...text}
            />
            <Select label="Tipo da resposta" required {...inputProp} />

            {inputProp.value && (
                <>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <Text
                                {...inputPropName}
                                label="Nome da propriedade"
                                required
                                disabled={inputProp.value.value !== "custom"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Text
                                {...inputPropCode}
                                label="Código da propriedade"
                                placeholder="{{name}}"
                                required
                                disabled={inputProp.value.value !== "custom"}
                                tooltipText={
                                    inputProp.value.value === "custom"
                                        ? 'O código deve conter apenas letras minúsculas e não pode conter espaços ou caracteres especiais.'
                                        : null
                                }
                            />
                        </Grid>
                    </Grid>
                    <Text
                        {...inputInvalid}
                        label="Caso resposta seja inválida"
                        placeholder="Email inválido, digite seu email corretamente"
                        rows={3}
                    />
                </>
            )}
        </FormDialog>
    )
}
export default EditInput
