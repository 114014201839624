import React, { createRef } from "react"
import { observer } from "mobx-react"
import { NavLink } from "react-router-dom"
import Dropdown from "components/Dropdown"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import Text from "components/forms/Text"
import DateRangePicker from "components/forms/DateRangePicker"

const segments = [
    {
        name: "Novo",
        to: "/inbox/new"
    },
    {
        name: "Meus",
        to: "/inbox/my"
    },
    {
        name: "Resolvidos",
        to: "/inbox/solved"
    },
    {
        name: "Todos",
        to: "/inbox/all"
    }
]

// const customSegments = [
//     {
//         name: "Clientes VIP",
//         to: "/inbox/vip"
//     }
// ]

@observer
class ReportFilterDropdown extends React.Component {
    state = {
        search: ""
    }

    constructor(props) {
        super(props)
        this.dropdownRef = React.createRef()
    }

    onCloseDropdown = () => {
        this.dropdownRef.current.onCloseMenu()
    }

    render() {
        const { search } = this.state
        const trigger = (
            <div className="conve-filter-trigger">
                <span className="name">Filtrar por </span>
                <i className="fal fa-angle-down" />
            </div>
        )
        // console.log(
        //     segments.filter(e => {
        //         console.log(e.name)
        //         console.log(search)
        //         console.log(e.name.toLowerCase().includes(search.toLowerCase()))
        //         return e.name.toLowerCase().includes(search.toLowerCase())
        //     })
        // )
        // console.log(search)
        // const customSegmentsFiltered = customSegments.filter(e =>
        //     e.name.toLowerCase().includes(search.toLowerCase())
        // )
        return (
            <div className="conve-filter-dropdown small">
                <Dropdown trigger={trigger} ref={this.dropdownRef} notClose>
                    <div className="filter-drop-inner">
                        <label className="label-input">Período de tempo</label>
                        <DateRangePicker />
                        <Select
                            label="Frequência"
                            options={[
                                { value: "2", label: "Diário" },
                                { value: "1", label: "Semanal" },
                                { value: "3", label: "Mensal" }
                            ]}
                            name="group"
                        />
                        <Select
                            label="Escolha um grupo"
                            options={[{ value: "2", label: "Vendas" }, { value: "1", label: "Financeiro" }]}
                            name="group"
                        />
                        <div className="footer-actions" style={{ marginTop: 20 }}>
                            <Button
                                onClick={() => {
                                    this.dropdownRef.current.onCloseMenu()
                                }}
                                primary
                                small
                            >
                                Aplicar
                            </Button>
                            <Button onClick={() => { this.dropdownRef.current.onCloseMenu() }} small>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Dropdown>
            </div>
        )
    }
}

export default ReportFilterDropdown
