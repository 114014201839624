import React, { useMemo } from "react"
import cx from "classnames"
import Tooltip from "components/Tooltip"
import { messagesTypes } from "lib/constants"
import DefaultAvatar from "components/DefaultAvatar"
import { inbox, general, useStore, session } from "store"
import { getImage } from "lib/helper"
import { getTime } from "lib/dates"
import botImg from "assets/images/bot.svg"
import decode from "decode-html"
import { getIconClassName } from "@uifabric/styling"
import { channelsTypesEnum, messageStatusEnumText, messageStatusEnum } from "lib/constants"
import PluginConfirmDialog from "components/plugin/PluginConfirmDialog"

function Message({ message, id, setReplyMessage, inboxItem }) {
    const inboxStore = useStore(inbox)
    const { workspace } = useStore(session)
    const { addModal, removeModal } = useStore(general)

    const isCustomerMsg = !message.operatorId
    const isBotMsg = message.type === messagesTypes.BOT

    const classname = cx("message", {
        customer: isCustomerMsg && !isBotMsg,
        agentMessage: !isCustomerMsg || isBotMsg,
        agentMessageWithoutDelete: (!isCustomerMsg || isBotMsg) && inboxItem.platformId === channelsTypesEnum.INSTAGRAM,
        private: message.type === messagesTypes.PRIVATE,
        system: message.type === messagesTypes.SYSTEM,
        bot: message.type === messagesTypes.BOT,
        isSameUser: message.isSameUser,
        deleted: message.isDeleted,
    })

    const customer = inboxStore.all.find((x) => x.id == id) || {}

    const messageTime = useMemo(() => {
        return getTime(new Date(message.createdAt))
    })
    const messageDate = useMemo(() => {
        return new Date(message.createdAt).toLocaleString()
    })

    const username = isBotMsg
        ? "Bot"
        : isCustomerMsg
        ? message.sender || customer.name || "Visitante"
        : message.Operator.name

    const getAvatar = useMemo(() => {
        if (isBotMsg) {
            return <img src={botImg} alt="" />
        }
        if (isCustomerMsg) {
            // Quebra no grupo
            // if (message.sender !== customer.name) {
            //     return workspace.avatar 
            //         ? <img src={workspace.avatar} alt="Avatar" /> 
            //         : <DefaultAvatar index={customer.id} name={message.sender} size={30} />
            // }

            return (message.avatar || customer.avatar) ? (
                <img src={getImage(message.avatar || customer.avatar)} alt="Customer Avatar" />
            ) : (
                <DefaultAvatar index={customer.id} name={customer.name} size={30} />
            )
        }
        if (message.Operator) {
            return message.Operator.avatar ? (
                <img src={getImage(message.Operator.avatar)} alt="" />
            ) : (
                <DefaultAvatar index={message.Operator.id} name={message.Operator.name} size={30} />
            )
        }
    }, [])

    const getEmailHeader = ({ from, to, subject } = {}) => (
        <div className="email-balloon-header">
            <div className="row">
                <span>Para:</span>
                <span>{to}</span>
            </div>
            <div className="row">
                <span>De:</span>
                <span>{from}</span>
            </div>
            <div className="row">
                <span>Assunto:</span>
                <span>{subject}</span>
            </div>
        </div>
    )

    const messageDiv = (
        <div
            className="chat-text"
            dangerouslySetInnerHTML={{
                __html: message.type == 4 ? decode(message.html) : message.html,
            }}
        />
    )

    function getMessageStatusIcon(status) {
        switch (status) {
            case messageStatusEnum.SENT:
                return "fal fa-clock"
            case messageStatusEnum.RECEIVED_ON_SERVER:
                return "fal fa-check"
            case messageStatusEnum.DELIVERED_TO_DEVICE:
                return "fal fa-check-double"
            case messageStatusEnum.READED:
                return "fal fa-check-double"
            default:
                return "fal fa-check"
        }
    }

    return (
        <div className={classname}>
            <div className="message-box">
                <div className="message-author-avatar">{getAvatar}</div>
                <div className="message-content">
                    {message.type != messagesTypes.SYSTEM && (
                        <span className="message-date">
                            <span className="user-name truncate" title={username}>
                                { username }
                            </span>
                            {/* <span className="time">{messageTime}</span> */}
                            {/* {message.id && (
                                <i
                                    className="fal fa-check"
                                    // style={{ marginRight: 13 }}
                                />
                            )} */}
                        </span>
                    )}
                    <div className="chat-balloon" style={{ paddingRight: (message.Operator && message.status !== 0) && '25px' }} title={messageDate}>
                        {message.isDeleted ? (
                            <div className="removed-message">Mensagem removida</div>
                        ) : (
                            <>
                                {message.replyTo && (
                                    <div className="reply-to">
                                        {/* <span>{message.replyTo.}</span> */}
                                        <div
                                            className="chat-text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    message.type == 4
                                                        ? decode(message.replyToHtml)
                                                        : message.replyToHtml,
                                            }}
                                        />
                                    </div>
                                )}

                                {message.type === messagesTypes.EMAIL &&
                                    getEmailHeader(message.Call.extraData)}
                                {messageDiv}
                                {message.type != messagesTypes.SYSTEM && <span className="time">{messageTime}</span>}
                                {message.Operator && message.status !== 0 && (
                                        <Tooltip title={messageStatusEnumText[message.status]}>
                                            <i 
                                            style={{ color: message.status === messageStatusEnum.READED ? 'chartreuse': 'white' }} 
                                            className={getMessageStatusIcon(message.status)}  />
                                        </Tooltip>
                                    )
                                    }
                                {(
                                    inboxItem.platformId === channelsTypesEnum.WHATSAPP || 
                                    inboxItem.platformId === channelsTypesEnum.INSTAGRAM || 
                                    inboxItem.platformId === channelsTypesEnum.TELEGRAM
                                ) &&
                                    message.externalId && (
                                        <div className={`message-actions ${!(
                                            inboxItem.platformId === channelsTypesEnum.WHATSAPP || 
                                            inboxItem.platformId === channelsTypesEnum.TELEGRAM
                                        ) &&'no-delete'}`}>
                                            <div>
                                                <Tooltip title="Responder">
                                                    <button
                                                        className={"reply-button"}
                                                        type="button"
                                                        onClick={() => {
                                                            setReplyMessage({
                                                                node: messageDiv,
                                                                message,
                                                            })
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                "icon-trigger " +
                                                                getIconClassName("Reply")
                                                            }
                                                        />
                                                    </button>
                                                </Tooltip>
                                                {(
                                                    inboxItem.platformId === channelsTypesEnum.WHATSAPP || 
                                                    inboxItem.platformId === channelsTypesEnum.TELEGRAM
                                                ) && message.externalId && !inboxItem.isDeleted && (
                                                        <Tooltip title="Excluir">
                                                            <button
                                                                className="reply-button"
                                                                type="button"
                                                                onClick={() =>
                                                                    addModal(
                                                                        <PluginConfirmDialog
                                                                            title="Deletar Mensagem"
                                                                            message="Tem certeza que deseja deletar essa mensagem?"
                                                                            okTitle="Deletar"
                                                                            okBtnProps={{
                                                                                danger: true,
                                                                            }}
                                                                            onClick={(confirm) => {
                                                                                if (confirm) {
                                                                                    inboxStore.removeMessage(
                                                                                        message,
                                                                                        id,
                                                                                        inboxItem.platformId
                                                                                    )
                                                                                }
                                                                                removeModal()
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        "icon-trigger " +
                                                                        getIconClassName("Delete")
                                                                    }
                                                                />
                                                            </button>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                        {/* <div className="arrow-left" /> */}
                        {/* <Tooltip
                            title={messageDate}
                            position="top"
                            animation="shift"
                            inertia
                            animateFill={false}
                            disabled={message.isSameUser}
                        > */}
                        {/* </Tooltip> */}
                        {/* Message Actions */}
                    </div>
                </div>
            </div>
            {/* {!message.hideStatus && (
                <span className="sent-status">
                    {message.hideStatus ? "" : message.id ? "Enviado" : "Enviando..."}
                </span>
            )} */}
        </div>
    )
}

export default Message
