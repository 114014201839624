import React, { useState } from "react"
import MenuDrop from "components/menu/MenuDrop"
// import EmojiPickerReact from "emoji-picker-react"
// import EmojiConvertor from "emoji-js"
import "emoji-mart/css/emoji-mart.css"
import { Picker, Emoji } from "emoji-mart"
import cx from "classnames"

function EmojiPicker(props) {
    const [closeDrop, setCloseDrop] = useState(false)
    return (
        <div className="emoji-picker-box">
            <MenuDrop
                title="Emoji"
                width={380}
                trigger={
                    <div
                        className={cx("icon-trigger emoji-trigger", {
                            selected: props.value,
                            bordered: props.bordered,
                            "fal fa-smile-beam": !props.value,
                        })}
                    >
                        {props.value && <Emoji emoji={props.value} size={20} native />}
                    </div>
                }
                toTop
                toLeft={props.left}
                close={closeDrop}
            >
                <Picker
                    native
                    onSelect={emoji => {
                        props.onPickEmoji(emoji)
                        setCloseDrop(true)
                        setTimeout(() => setCloseDrop(false))
                    }}
                    emojiSize={23}
                    perLine={10}
                    title=""
                    emoji="satisfied"
                />
            </MenuDrop>
        </div>
    )
}

export default EmojiPicker
