import React from "react"

export default function ModalFooter({ children, style }) {
    if (!children) return null
    return (
        <div className="modal-footer" style={style}>
            {children}
        </div>
    )
}
