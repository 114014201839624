import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import queryString from "query-string"
import Button from "components/forms/Button"
import Loader from "components/Loader"
import AuthBase from "../AuthBase"
import Lottie from "react-lottie"
import okLottie from "assets/animations/success.json"
import DefaultAvatar from "components/DefaultAvatar"
import { getImage } from "lib/helper"
import { session, useStore } from "store"
import workspaceImg from "assets/images/workspace.svg"

const optionsLottie = {
    loop: false,
    autoplay: true,
    animationData: okLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

function JoinWorkspace(props) {
    const [joinInfo, setJoinInfo] = useState({})
    const [joinSuccess, setJoinSuccess] = useState(false)
    const { operatorId, workspaceId, token } = queryString.parse(props.location.search)
    const { getJoinInfo, joinWorkspace } = useStore(session)

    useEffect(() => {
        getJoinInfo({
            data: {
                operatorId,
                workspaceId
            }
        }).then(info => {
            setJoinInfo(info)
        })
    }, [])

    function onSubmit(e) {
        e.preventDefault()
        joinWorkspace({
            data: {
                operatorId,
                workspaceId,
                token
            },
            loader: "workspace"
        }).then(data => {
            setJoinSuccess(true)
        })
    }

    return (
        <AuthBase>
            <Loader
                loading={!joinInfo.workspace}
                // style={{ height: "100%", marginTop: 0, width: "100%" }}
            >
                {joinSuccess ? (
                    <div className="register-success-space">
                        <h1 className="headline">Você se juntou a um novo workspace!</h1>
                        <Lottie options={optionsLottie} height={220} width={220} speed={1} />
                        <h2 className="headline" style={{ textAlign: "center", marginTop: 20 }}>
                            <strong>{joinInfo.operatorName}</strong>, você agora faz parte do
                            workspace <br /> <strong>{joinInfo.workspace}</strong>.
                        </h2>

                        <div className="login-actions">
                            <Button
                                className="login-btn"
                                primary
                                loader="login"
                                to="/"
                                style={{ textDecoration: "none" }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                ) : (
                    <form className="join-workspace-space" onSubmit={onSubmit}>
                        <div className="top">
                            <div className="join-avatar">
                                {joinInfo.operatorAvatar ? (
                                    <img src={getImage(joinInfo.operatorAvatar)} alt="" />
                                ) : (
                                    <DefaultAvatar
                                        index={joinInfo.operatorId}
                                        name={joinInfo.operatorName}
                                        size={100}
                                        fontSize={30}
                                    />
                                )}
                            </div>
                            <div className="join-dashed" />
                            <i className="fa fa-arrow-alt-right" />
                            <div className="join-dashed" />
                            <div className="join-avatar">
                                <img
                                    src={
                                        joinInfo.workspaceAvatar
                                            ? getImage(joinInfo.workspaceAvatar)
                                            : workspaceImg
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                        <h3>
                            <span className="workspace-span">{joinInfo.operatorName}</span>, você
                            foi convidado para se juntar ao workspace{" "}
                            <span className="workspace-span">{joinInfo.workspace}</span>. Aceita o
                            convite?
                        </h3>
                        <div className="login-actions">
                            <Button
                                className="login-btn"
                                primary
                                loader="workspace"
                                style={{ textDecoration: "none" }}
                                type="submit"
                            >
                                Aceitar convite
                            </Button>
                        </div>
                    </form>
                )}
                {/* <div className="register-success-space">
                    <h1 className="headline">Cadastro concluído!</h1>
                    <Lottie
                        options={optionsLottie}
                        height={220}
                        width={220}
                        speed={1}
                    />
                    <h2 className="headline" style={{ textAlign: "center" }}>
                        <strong>{operatorId}</strong>, você agora faz parte do workspace <br />{" "}
                        <strong>{workspaceId}</strong>.
                    </h2>

                    <div className="login-actions">
                        <Button
                            className="login-btn"
                            primary
                            loader="login"
                            to="/"
                            style={{ textDecoration: "none" }}
                        >
                            Ok
                        </Button>
                    </div>
                </div> */}
            </Loader>
        </AuthBase>
    )
}

export default JoinWorkspace
