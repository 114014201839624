import React, { useState } from "react"
// import Caret from "components/Caret"
import { getIconClassName } from "@uifabric/styling"
import cx from "classnames"

function Collapse({ title, defaultState = false, children, disabled }) {
    const [isOpened, setIsOpened] = useState(defaultState)

    return (
        <div className={cx("collapse", { isOpened, disabled })}>
            <div className="top">
                <button
                    onClick={() => setIsOpened(oldOpen => !oldOpen)}
                    className="title"
                    type="button"
                >
                    {title}
                    <i className={getIconClassName("ChevronDown")}></i>
                </button>
            </div>
            <section style={{ display: isOpened ? "block" : "none" }}>
                {children}
            </section>
        </div>
    )
}

export default Collapse
