import React, { useRef, useMemo, useEffect } from "react"
import MenuDrop from "components/menu/MenuDrop"
import { useStore, session, general } from "store"
import AgentAvatar from "components/AgentAvatar"
import MenuItem from "components/menu/MenuItem"
import MenuSeparator from "components/menu/MenuSeparator"
import CreateWorkspace from "components/CreateWorkspace"
import WorkspaceAvatar from "components/WorkspaceAvatar"
import { Link } from "react-router-dom"

const MASTER_USER = 6

function UserMenuDrop(props) {
    const { addModal, removeModal } = useStore(general)
    const { user, workspace, changeWorkspace } = useStore(session)

    function openCreateModal() {
        addModal(<CreateWorkspace onBackModal={() => removeModal()} history={props.history} />)
    }

    const workspaceOptions = useMemo(
        () =>
            user.workspaces.map(x => ({
                searchable: x.workspace,
                selected: x.workspaceId === workspace.workspaceId,
                onClick: () => changeWorkspace(x, props.history),
                name: (
                    <span className="menu-item-with-avatar">
                        <WorkspaceAvatar avatar={x.avatar} />
                        {x.workspace}
                    </span>
                )
            })),
        []
    )

    return (
        <div className="user-menu-drop">
            {workspace.workspaceId && (
                <MenuDrop
                    dropClassName="user-menu-drop-inner"
                    title={
                        <Link className="user-menu-drop-title" to="/settings/account/profile">
                            <AgentAvatar agent={user} size={40} style={{ width: 40, height: 40 }} />
                            <span className="user-name truncate">{user.name}</span>
                        </Link>
                    }
                    trigger={
                        <div className="user-menu-drop-trigger">
                            <span className="workspace-name">{workspace.workspace}</span>
                            <AgentAvatar agent={user} size={28} style={{ width: 28, height: 28 }} />
                        </div>
                    }
                    options={[
                        // { section: { title: "Criar" } },
                        {
                            onClick: () => {
                                props.history.push("/settings/account/profile")
                            },
                            name: "Configurações"
                        },
                        workspace.accessLevel >= MASTER_USER 
                            ? {
                                onClick: () => {
                                    props.history.push("/settings/billing/license")
                                },
                                name: "Assinatura"
                            }
                            : {},
                        // {
                        //     onClick: () => {
                        //         window.open('https://help.kimochat.com.br/docs/into/geting-started-into', '_blank')
                        //     },
                        //     name: "Ajuda e Documentação"
                        // },
                        { separator: true },
                        { section: { title: "Workspace" } },
                        ...workspaceOptions,
                        { separator: true },
                        {
                            icon: "fal fa-plus drop-icon-add",
                            onClick: () => openCreateModal(),
                            name: "Novo workspace",
                            searchable: "@"
                        }
                        // ...all.map((x, i) => ({
                        //     searchable: x.tag,
                        //     key: x.id,
                        //     onClick: () => {
                        //         props.setText(x.phrase)
                        //     },
                        //     name: (
                        //         <span
                        //             className="quick-phrase-phrase-space"
                        //             style={{ maxWidth: width - 30 }}
                        //         >
                        //             <span className="quick-phrase-code">{x.tag}</span>
                        //             <span className="quick-phrase-phrase">{x.phrase}</span>
                        //         </span>
                        //     )
                        // }))
                    ]}
                    // width={props.width - 15}
                    toLeft
                >
                    <MenuSeparator />
                    <MenuItem name="Sair" onClick={() => props.history.push("/user/logout")} />
                </MenuDrop>
            )}
        </div>
    )
}

export default UserMenuDrop
