import React, { useState } from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useSelectField, useForm, useFetch } from "hooks"
import Radiobox from "components/forms/Radiobox"
import Select from "components/forms/Select"
import { operator, tag, useStore } from "store"

function EditOthers(props) {
    const actionItem = props.actionItem
    const tagStore = useFetch(tag)

    const form = useForm({
        onSubmit: async model => await props.onEditAction(model),
        defaultModel: { ...actionItem, type: "tag" }
    })

    const tagField = useSelectField("tag", {
        defaultValue: actionItem.tag,
        form,
        options: tagStore.all.map(x => ({ label: x.name, value: x.id }))
    })

    return (
        <FormDialog
            {...props}
            superTitle="Categorizar"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Select label={"Tags"} required {...tagField} />
        </FormDialog>
    )
}
export default EditOthers
