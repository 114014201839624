import React from "react"
import { ResponsiveBar } from "@nivo/bar"
import { getHoursAndMinutes } from "lib/helper"

const DashBarHorizontal = ({ data }) => (
    <ResponsiveBar
        margin={{
            top: 10,
            right: 80,
            bottom: 60,
            left: 80
        }}
        height={300}
        data={data}
        colors="red_yellow_green"
        indexBy="member"
        keys={["Tempo de atendimento", "Tempo de primeira resposta", "Tempo de resposta", ]}
        padding={0.2}
        labelTextColor="inherit:darker(1.4)"
        labelSkipWidth={16}
        labelSkipHeight={16}
        labelFormat={value => getHoursAndMinutes(value)}
        tooltip={obj => {
            return getHoursAndMinutes(obj.value)
        }}
        // groupMode="grouped"
        layout="horizontal"
        enableGridY={false}
        enableGridX
        animate
    />
)

export default DashBarHorizontal
