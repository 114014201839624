import React from "react"
// import queryString from "query-string"
import Table from "components/Table"
import FilterHeader from "components/FilterHeader"
import Button from "components/forms/Button"
import { useBreadcrumb } from "hooks"
import ModalBase from "./ModalBase"
import { buildUrlQuery } from "lib/helper"
import { useStore } from "store"

export default function PageTable({
    storeRef,
    title,
    breadcrumb,
    newLabel,
    columns,
    nodata,
    EditComponent,
    ViewComponent,
    noModal,
    helper,
    filters,
    backTo,
    subheader,
    isSettings,
    hideSubheader,
    tableStyle,
    ...props
}) {
    const store = useStore(storeRef)
    useBreadcrumb({ title: breadcrumb || title, backTo })

    const onBackModal = () =>
        props.history.replace(
            props.match.url.substring(0, props.match.url.lastIndexOf("/")) + props.location.search
        )

    async function onSubmit(data, configs) {
        store.save({ ...configs, data }).then(onBackModal)
    }

    function onDelete(id) {
        store.remove({ data: { id }, toastMessage: "Deletado com sucesso" }).then(onBackModal)
    }

    const hideTable = noModal && props.location.pathname.split("/").length >= 4
    const foundCount =
        store.totalCount === store.matchCount
            ? store.totalCount
            : `${store.matchCount} de ${store.totalCount}`
    return (
        <div>
            {!hideSubheader && subheader}
            {filters && (
                <FilterHeader
                    filters={filters}
                    pathname={props.match.url}
                    history={props.history}
                    location={props.location}
                />
            )}
            {!isSettings && (
                <div className="table-title">
                    <h1>{breadcrumb || title}</h1>
                    {/* {store.totalCount > 0 && (
                        <span className="table-title-badge">{foundCount}</span>
                    )} */}
                    {newLabel && (
                        <Button
                            gray
                            ios
                            icon="fa fa-plus-circle"
                            to={buildUrlQuery("new", props)}
                            className="button-page-table-add"
                        >
                            {newLabel}
                        </Button>
                    )}
                </div>
            )}
            <div className="settings-midcols">
                {helper}
                {!hideTable && (
                    <div
                        style={
                            isSettings
                                ? {}
                                : { padding: "30px 0", position: "relative", ...tableStyle }
                        }
                        className={isSettings ? "midcol" : ""}
                    >
                        <Table
                            store={store}
                            columns={columns}
                            query={props.location.search}
                            // loader={"/" + endpoint}
                            history={props.history}
                            getTrProps={(state, rowInfo) => ({
                                onClick: () => {
                                    props.history.push(buildUrlQuery(rowInfo.original.id, props))
                                }
                            })}
                            nodata={{
                                ...nodata,
                                to: nodata.buttonText ? buildUrlQuery("new", props) : undefined
                            }}
                        />
                    </div>
                )}
            </div>

            {props.match.params.id && (
                <ModalBase
                    {...props}
                    store={store}
                    ViewComponent={ViewComponent}
                    EditComponent={EditComponent}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    noModal={noModal}
                    onBackModal={onBackModal}
                />
            )}
            {/* <Route
                path={props.match.url + "/:id"}
                render={routeProps => {
                    return (
                        <ModalBase
                            {...routeProps}
                            store={store}
                            ViewComponent={ViewComponent}
                            EditComponent={EditComponent}
                            onSubmit={onSubmit}
                            onDelete={onDelete}
                            noModal={noModal}
                            onBackModal={onBackModal}
                        />
                    )
                }}
            /> */}
        </div>
    )
}
