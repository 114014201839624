import React, { useEffect, useState } from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm } from "hooks"
import { useStore, tag } from "store"
import config from "config"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

const colors = [
    {
        bg: "#3480EA",
        text: "#fff"
    },
    {
        bg: "#EBB62D",
        text: "#8A2C0F"
    },
    {
        bg: "#94FFA9",
        text: "#25652F"
    },
    {
        bg: "#F2870D",
        text: "#5F2C0C"
    },
    {
        bg: "#F25226",
        text: "#FFF5F8"
    },
    {
        bg: "#6EC8F2",
        text: "#1C4973"
    },
    {
        bg: "#FFF280",
        text: "#AF4F18"
    },
    {
        bg: "#E34F85",
        text: "#FFE0EC"
    },
    {
        bg: "#D3E7F3",
        text: "#1B4964"
    },
    {
        bg: "#77F3E9",
        text: "#1C606E"
    },
    {
        bg: "#9CE53E",
        text: "#245313"
    },
    {
        bg: "#9263E9",
        text: "#EADCFE"
    }
]

function EditTag(props) {
    const item = props.item
    const tagStore = useStore(tag)
    const [color, setColor] = useState(colors.find(e => e.bg === props.item.color) || colors[0])

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Agente salvou tags"
            })
            await tagStore.save({
                data: {
                    ...model,
                    color: color.bg || props.item.color || colors[0].bg,
                    textColor: color.text || props.item.textColor || colors[0].text,
                },
                toastMessage: "Etiqueta " + (item.id ? "atualizada." : "criada.")
            })
            return props.onBackModal
        },
        defaultModel: { id: item.id }
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    return (
        <FormDialog
            {...props}
            title="Tag"
            onSubmit={form.onSubmit}
            item={item}
            endpoint={config.api.LABEL}
            onDelete={async () => {
                await tagStore.remove({
                    data: { id: item.id },
                    toastMessage: "Etiqueta excluída."
                })
                props.onBackModal()
            }}
        >
            <Text label="Nome" required {...name} />
            <label className="label-input" style={{ display: "block" }}>
                Selecione uma cor
            </label>
            <div className="tag-colors">
                {colors.map(x => (
                    <a
                        className="tag-color"
                        style={{ backgroundColor: x.bg }}
                        onClick={() => setColor(x)}
                        key={x.bg}
                    >
                        {x.bg === color.bg && (
                            <i className="far fa-check" style={{ color: color.text }} />
                        )}
                    </a>
                ))}
            </div>
        </FormDialog>
    )
}

export default EditTag
