import React from "react"
import BasePlatform from '../BasePlatform'

export default function EmailLanding(props) {
    return (
        <BasePlatform
            title="KimoChat Email"
            description="Com o KimoChat Email você pode trocar emails com seus clientes como se estivesse em um chat! Receba e envie emails de forma simples e prática, aumentando sua produtividade e também seus lucros!"
            icon="https://i.imgur.com/zEV3V3H.png"
            banner="https://i.imgur.com/BZW95IN.png"
            path="/user/register"
            {...props}
        />
    )
}
