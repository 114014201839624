import React, { useState, useMemo } from "react"
import { Link, Redirect } from "react-router-dom"
import LandingHeader from "./LandingHeader"
import wppImg from "assets/images/wpp.png"
import LandingFooter from "./LandingFooter"
import LandingRegisterSection from "./LandingRegisterSection"
import cx from "classnames"
import { useField, useScrollTop } from "hooks"

function TermsSection({ children, title }) {
    return (
        <div className="terms-section">
            <h2>{title}</h2>
            {children}
        </div>
    )
}

export default function TermsOfUse(props) {
    useScrollTop(props.location)

    return (
        <div className="landing-space">
            <LandingHeader white />
            <div className="landing-section">
                <div className="midcol">
                    <div className="center">
                        <h1 style={{ fontSize: 50, marginBottom: 10 }}>
                            Termos de Uso
                        </h1>
                    </div>
                </div>
            </div>
            <div className="landing-section">
                <div className="midcol" style={{ maxWidth: 800 }}>
                    <TermsSection title="1 - O Serviço">
                        <p>
                            O “Serviço” inclui <br />
                            <br />
                            a) Acesso ao portal com todos os recurso que a
                            plataforma disponibiliza, de acordo com a licença
                            adquirida <br />
                            b) O portal de suporte específico da conta <br />
                            c) A API KimoChat para integração com aplicativos de
                            terceiros <br />
                            d) Conteúdo relacionado ao plano adquirido,
                            incluindo todos dados de software, sons, imagens,
                            PDFs, vídeos e qualquer outro conteúdo
                            disponibilizado até o final pelo KimoChat. Quaisquer
                            novos recursos adicionados ou aumentando o Serviço
                            também estão sujeitos aos Termos e condições
                            (doravante também referidos como: "os Termos"){" "}
                            <br />
                        </p>
                    </TermsSection>

                    <TermsSection title="2 - Aceitação de Termos">
                        <p>
                            A KimoChat fornece seu serviço para você através de
                            seu website, www.kimochat.com.br e contas individuais
                            da KimoChat criadas para você, o cliente, conforme
                            descrito acima.
                        </p>
                        <p>
                            Ao aceitar estes Termos ou acessar os URLs
                            fornecidos pelo KimoChat, você reconhece que leu,
                            entendeu e concordou em aceitar aos seguintes
                            termos e que as representações feitas por você no
                            www.kimochat.com.br são precisas, completas e dentro
                            de seus direitos para fazer. Se você está entrando
                            nestes Termos em nome de um empregador ou de
                            terceiros, você declara que tem autoridade para
                            vincular essa entidade a estes Termos. Se você não
                            tem essa autoridade ou não concorda com os Termos,
                            você não tem permissão para usar o serviço KimoChat.
                        </p>
                        <p>
                            O serviço KimoChat está disponível apenas para
                            clientes corporativos. Se você não é uma entidade
                            comercial, mas um indivíduo particular, você não tem
                            permissão para usar o serviço KimoChat.
                        </p>
                        <p>
                            Você reconhece que estes Termos constituem um
                            contrato entre o KimoChat e você e que sua inscrição
                            eletrônica atua como um contrato legalmente
                            vinculativo.
                        </p>
                    </TermsSection>
                    <TermsSection title="3 - Termos de Uso">
                        <p>
                            A KimoChat se reserva o direito de rescindir ou
                            modificar o serviço a qualquer momento, sem
                            notificação prévia.
                        </p>
                        <p>
                            O Serviço é fornecido “como está” e “conforme
                            disponível” sem qualquer garantia ou condição, seja
                            expressa, implícita ou estatutária.
                        </p>
                        <p>
                            A KimoChat não declara que o serviço será ininterrupto
                            ou livre de erros e os clientes usam o aplicativo
                            por sua conta e risco. No entanto, se o aplicativo
                            não estiver disponível devido à manutenção
                            planejada, faremos o possível para notificar todos
                            os usuários com antecedência.
                        </p>
                        <p>
                            Você concorda, como cliente, em não licenciar,
                            revender, alugar, transferir ou distribuir o serviço
                            a terceiros.
                        </p>
                        <p>
                            Você concorda, como cliente, em não usar o KimoChat
                            para fins ilegais, como violação de privacidade,
                            propriedade de dados ou direitos de propriedade
                            intelectual.
                        </p>
                        <p>
                            Você concorda em agir de acordo com as diretrizes da
                            política anti-spam do seu país e não deve usar o
                            KimoChat para transmitir mensagens não solicitadas,
                            sujeito a banimento por parte da plataforma que não
                            autoriza tal comportamento.
                        </p>
                        <p>
                            Como cliente da KimoChat, você reconhece que o KimoChat
                            é o proprietário do software e não tem o direito de
                            adaptar, hackear ou fazer engenharia reversa do
                            código-fonte do software.
                        </p>
                        <p>
                            Você é responsável pelos dados armazenados em sua
                            conta do KimoChat e por todos os dados, sejam eles
                            mensagens de e-mail, bate-papo ou mídias sociais,
                            enviadas de sua conta do KimoChat. A KimoChat não se
                            responsabiliza por qualquer mensagem racista,
                            difamatória ou de difamação (chat, e-mail ou mídia
                            social) enviada pelo aplicativo KimoChat, e você
                            indenizará a KimoChat por quaisquer danos relacionados
                            a tal ocorrência.
                        </p>
                        <p>
                            Você concorda em manter suas informações de login
                            confidenciais e restringir cada login a uma pessoa.
                            Uma única conta de usuário não pode ser
                            compartilhada por várias pessoas.
                        </p>
                        <p>
                            A KimoChat se reserva o direito de cancelar a conta de
                            um cliente imediatamente e, sem aviso prévio, se o
                            cliente infringir os Termos acima. Se, a critério
                            exclusivo da KimoChat, determinarmos que o cliente
                            abusou de seus direitos de acesso à rescisão do
                            aplicativo KimoChat, sem aviso, entrará em vigor
                            imediatamente.
                        </p>
                    </TermsSection>
                    <TermsSection title="4 - Privacidade e segurança de dados">
                        <p>
                            Ao fornecer-lhe o serviço, devemos manter todas as
                            garantias administrativas e técnicas razoáveis
                            ​​para proteger a segurança, a confidencialidade e a
                            integridade de seus dados.
                        </p>
                        <p>
                            O KimoChat só acessará sua conta mediante sua
                            solicitação de assistência técnica. Você será
                            solicitado a fornecer consentimento explícito toda
                            vez que um funcionário da KimoChat precisar acessar
                            sua conta. Nenhum funcionário da KimoChat acessará sua
                            conta sem permissão prévia, a menos que seja
                            obrigado por lei.
                        </p>
                    </TermsSection>
                    <TermsSection title="5 - Direitos de propriedade intelectual">
                        <p>
                            A KimoChat deve manter todos os direitos, títulos e
                            interesses em nossas respectivas patentes,
                            invenções, direitos autorais, marcas registradas,
                            nomes de domínio e quaisquer outros direitos
                            intelectuais ou de propriedade. Os direitos
                            concedidos a você para usar o Serviço não
                            transmitem, por extensão, qualquer título a
                            patentes, invenções, direitos autorais, marcas
                            registradas, nomes de domínio ou qualquer outra
                            propriedade intelectual de propriedade da KimoChat.
                        </p>
                        <p>
                            Qualquer feedback, sugestões de aprimoramento ou
                            recomendações recebidas de você podem ser
                            incorporados ao serviço KimoChat: em todo o mundo;
                            livre de royalties; na perpetuidade; e para qualquer
                            outro produto KimoChat por transferência. Você não
                            pode reivindicar quaisquer direitos sobre essas
                            incorporações, agora ou no futuro.
                        </p>
                    </TermsSection>
                    <TermsSection title="6 - Serviços de terceiros">
                        <p>
                            Para entregar nossos serviços a você, a KimoChat pode
                            utilizar serviços de terceiros. Sua aceitação desses
                            serviços confirma sua conformidade com os Termos e
                            condições desses serviços de terceiros. A KimoChat não
                            é responsável nem endossa a governança de seus
                            direitos por parte de empresas terceirizadas. A
                            KimoChat não se responsabiliza por danos ou perdas
                            atribuídos ou conectados ao seu acesso aos serviços
                            de terceiros.
                        </p>
                        <p>
                            Na entrega do serviço Whatsapp, o KimoChat depende das
                            possibilidades que o WhatsApp Inc. oferece para
                            estabelecer uma conexão bem sucedida com a
                            plataforma WhatsApp Messenger. O WhatsApp Inc. pode
                            executar ações técnicas ou legais que podem resultar
                            em um funcionamento inoperante ou defeituoso do
                            serviço Whatsapp do KimoChat. O KimoChat não pode,
                            portanto, garantir a disponibilidade (ininterrupta
                            ou sem erros) do serviço do Whatsapp e os clientes
                            usam este serviço por sua conta e risco. A KimoChat
                            não aceita nenhuma responsabilidade por uma possível
                            indisponibilidade ou disponibilidade interrompida do
                            serviço do conector Whatsapp da KimoChat.
                        </p>
                    </TermsSection>
                    <TermsSection title="7 - Julgamento, Faturamento, Renovação e Pagamentos">
                        <p>
                            Cada indivíduo representando uma empresa, conforme
                            identificado por um endereço de e-mail exclusivo,
                            tem permissão para uma avaliação gratuita do KimoChat
                            Trial por um período de 7 dias, a menos que
                            acordado de outra forma.
                        </p>
                        <p>
                            Ao final do período de avaliação de 7 dias, a conta
                            Trial será bloqueada, a menos que o cliente escolha
                            expressamente um dos outros planos de serviço, o
                            que, no caso de planos pagos, exige que eles insiram
                            suas informações de faturamento. O KimoChat não se
                            responsabiliza por qualquer perda de acesso a
                            funcionalidades e dados quando o aplicativo KimoChat é
                            rebaixado de "Pro" para "Free".
                        </p>
                        <p>
                            Os planos de serviços pagos da KimoChat são faturados
                            mensalmente (isso inclui o mês de faturamento e pode
                            ser de 28 dias a 31 dias, dependendo do mês do
                            calendário) ou anual e antecipadamente.
                        </p>
                        <p>
                            Ao escolher uma assinatura mensal, o cliente recebe
                            o acesso de um mês (28 a 31 dias, conforme
                            determinado pelo mês do calendário) à assinatura
                            determinada após a realização do pagamento. O
                            cliente será automaticamente cobrado no mesmo dia de
                            cada mês para acesso contínuo à assinatura paga. Por
                            exemplo, um cliente adquire uma assinatura "Pro"
                            mensal no dia 4 de janeiro, o que dá acesso ao dia 3
                            de fevereiro. No dia 4 de fevereiro, a conta paga do
                            cliente será renovada automaticamente pelo período
                            de um mês, e o cliente será cobrado pelas
                            informações de faturamento fornecidas para o acesso
                            do próximo mês.{" "}
                        </p>
                        <p>
                            Ao escolher a assinatura anual, o cliente recebe um
                            ano de acesso à assinatura determinada após o
                            pagamento ter sido feito. O cliente será
                            automaticamente cobrado na mesma data a cada ano
                            para acesso contínuo à assinatura paga. Por exemplo,
                            um cliente adquire uma assinatura anual "Enterprise"
                            no dia 6 de abril, o que dá acesso a 5 de abril do
                            ano seguinte. No dia 6 de abril do próximo ano, a
                            conta paga do cliente será renovada automaticamente
                            pelo período de um ano, e o cliente será cobrado
                            pelas informações de faturamento fornecidas para o
                            acesso do ano seguinte.
                        </p>
                        <p>
                            Se o pagamento não for recebido pelo KimoChat no
                            processo de ativação ou renovação da assinatura, o
                            cliente terá sua conta bloqueada. A KimoChat se
                            reserva o direito de excluir contas bloqueadas,
                            incluindo seu conteúdo.
                        </p>
                        <p>
                            Os clientes podem atualizar sua assinatura para
                            outro plano a qualquer momento. Não há reembolso
                            para cancelamentos de contas antes do vencimento do
                            mês pré-pago ou do ano pré-pago. Se o cliente
                            atualizar durante seu mês pré-pago ou ano pré-pago,
                            a diferença para os dias restantes de seu mês de
                            faturamento ou ano de faturamento será cobrada pro
                            rata no momento da atualização. Posteriormente, o
                            cliente será cobrado o novo preço para cada mês de
                            faturamento subseqüente ou ano de faturamento.
                        </p>
                    </TermsSection>
                    <TermsSection title="8 - Rescisão / Reembolso / Cancelamento de serviço">
                        <p>
                            O cancelamento pode ser feito dentro do aplicativo
                            KimoChat ou enviando um pedido por escrito para
                            contato@kimochat.com.
                        </p>
                        <p>
                            O cancelamento torna-se efetivo a partir do final do
                            período de subscrição aplicável. Ao escolher uma
                            assinatura mensal, a assinatura termina no final do
                            período do mês de faturamento aplicável. Ao escolher
                            uma assinatura anual, a assinatura é encerrada no
                            final do período do ano de faturamento aplicável.
                            Dessa forma, ao cancelar uma assinatura, não há
                            estorno do valor, mas o cliente poderá acessar e
                            usufruir dos recursos da plataforma até o final do
                            período de subscrição.
                        </p>
                        <p>
                            Assim que o cancelamento entrar em vigor, o cliente
                            não terá mais acesso a funcionalidades básicas do
                            aplicativo ou seu conteúdo. A KimoChat reserva-se o
                            direito de excluir contas canceladas, incluindo
                            todas o seu conteúdo.
                        </p>
                    </TermsSection>
                    <TermsSection title="9 - Modificações ao serviço ou preços">
                        <p>
                            A KimoChat se reserva o direito de, a qualquer
                            momento, modificar ou descontinuar o Serviço (ou
                            qualquer parte dele) com ou sem aviso prévio.
                        </p>
                        <p>
                            A KimoChat não se responsabilizará perante você ou
                            terceiros por qualquer modificação, alteração de
                            preço, suspensão ou descontinuidade do Serviço.
                        </p>
                        <p>
                            A KimoChat se reserva o direito de implementar
                            alterações de preço com um aviso prévio de 7 dias
                            ao cliente. Os clientes em assinaturas mensais
                            receberão o novo preço no próximo mês de faturamento
                            que cair após o aviso de 7 dias. Clientes em contas
                            anuais será cobrado o valor acordado para os 12
                            meses completos do seu contrato; a partir de então,
                            o novo preço entrará em vigor.
                        </p>
                    </TermsSection>
                    <TermsSection title="10 - Renúncia de garantias">
                        <p>
                            O serviço, incluindo o site e o conteúdo, e todos os
                            componentes do servidor e da rede são fornecidos
                            “como estão” e “conforme disponíveis”, sem quaisquer
                            garantias de qualquer tipo na extensão máxima
                            permitida por lei, e a KimoChat se isenta
                            expressamente de qualquer e todas as garantias,
                            expressas ou implícitas, incluindo, mas não
                            limitadas a, garantias implícitas de
                            comerciabilidade, título, adequação a uma finalidade
                            específica e não violação. Você reconhece que a
                            KimoChat não garante que o serviço será ininterrupto,
                            oportuno, seguro, livre de erros ou livre de vírus,
                            e nenhuma informação ou aconselhamento obtido por
                            você da KimoChat ou através do serviço criará qualquer
                            garantia não expressamente declarada nestes Termos.
                        </p>
                    </TermsSection>
                    <TermsSection title="11 - Limitação de responsabilidade">
                        <p>
                            Nenhum dano consequente. Sob nenhuma circunstância e
                            sob nenhuma teoria legal (seja em contrato, ato
                            ilícito, negligência ou de outra forma) qualquer das
                            partes destes Termos, ou afiliadas de tais partes ou
                            seus respectivos diretores, diretores, funcionários,
                            agentes, fornecedores ou licenciadores serão
                            responsáveis ​​perante a outra parte. ou qualquer
                            terceiro por quaisquer danos indiretos, incidentais,
                            especiais, exemplares, consequenciais, punitivos ou
                            similares, incluindo perda de lucros, vendas ou
                            negócios perdidos, perda de dados, interrupção de
                            negócios ou qualquer outra perda incorrida por tal
                            parte em conexão com estes Termos ou o serviço,
                            independentemente de tal parte ter sido avisada da
                            possibilidade de ou poderia ter previsto tais danos.
                        </p>
                        <p>
                            Limites de danos monetários. Não obstante qualquer
                            disposição em contrário nestes Termos, a
                            responsabilidade agregada da KimoChat, por danos
                            (monetários ou outros) sob estes Termos
                            reivindicados por você ou por terceiros decorrentes
                            de nossos serviços, será limitada à menor ( i) danos
                            reais incorridos, ou (ii) pagamentos feitos por você
                            pelo serviço durante os 12 (doze) meses que
                            antecedem a reivindicação. as partes reconhecem e
                            concordam que o objetivo essencial desta seção 11.2
                            é alocar os riscos sob estes Termos entre as partes
                            e limitar sua responsabilidade em potencial dadas as
                            taxas cobradas sob este contrato, que teriam sido
                            substancialmente maiores se a KimoChat assumisse
                            qualquer outra responsabilidade diferente da
                            estabelecida neste documento. As partes confiaram
                            nessas limitações para determinar se devem entrar
                            nestes Termos.
                        </p>
                    </TermsSection>
                    <TermsSection title="12 - Tarefa; total acordo; revisões">
                        <p>
                            Qualquer uma das partes pode ceder ou transferir
                            estes Termos, no todo ou em parte, sem restrições,
                            desde que o cessionário concorde em estar totalmente
                            vinculado a estes Termos e Condições. Estes Termos
                            substituem versões anteriores destes Termos, ou
                            quaisquer outras discussões, acordos ou
                            entendimentos entre as partes (exceto acordos
                            escritos expressamente aceitos e executados por
                            ambas as partes). Podemos alterar estes Termos de
                            tempos em tempos, caso em que os novos Termos
                            substituirão as versões anteriores. Vamos
                            notificá-lo de tais alterações e direcioná-lo para a
                            versão mais recente.
                        </p>
                    </TermsSection>
                    <TermsSection title="13 - Divisibilidade">
                        <p>
                            Se qualquer cláusula nestes Termos for considerada
                            inexecutável por um tribunal de jurisdição
                            competente, tal cláusula deverá ser modificada pelo
                            tribunal e interpretada de forma a melhor cumprir a
                            disposição original na máxima extensão permitida por
                            lei, e as disposições restantes destes Os termos
                            devem permanecer em vigor.
                        </p>
                    </TermsSection>
                    <TermsSection title="14 - Relacionamento das partes">
                        <p>
                            As partes são contratantes independentes. Estes
                            Termos não criam uma parceria, franquia, joint
                            venture, agência, relacionamento fiduciário ou de
                            emprego entre as partes.
                        </p>
                    </TermsSection>
                    <TermsSection title="15 - Sobrevivência">
                        <p>
                            Seções 5 (Direitos de Propriedade Intelectual), 7
                            (Julgamento, Faturamento e Pagamentos), 8 (Rescisão
                            / cancelamento de serviço), 10 (Isenção de
                            garantia), 11 (Limitação de Responsabilidade), 12
                            (Atribuição; Contrato; Revisões), 13
                            (Divisibilidade), 14 (Relacionamento das Partes) e
                            16 (Lei Aplicável) sobreviverão a qualquer rescisão
                            destes Termos.
                        </p>
                    </TermsSection>
                    <TermsSection title="16 - Lei aplicável e resolução de disputas">
                        <p>
                            Este Contrato será regido e interpretado de acordo
                            com as leis do Brasil. Qualquer disputa ou
                            reclamação decorrente ou relacionada a este Contrato
                            ou a sua execução, violação ou rescisão será
                            definitivamente resolvida pelo tribunal competente
                            em Fortaleza, Brasil.
                        </p>
                    </TermsSection>
                    <TermsSection title="17 - Nenhum outro direito">
                        <p>
                            Nenhum outro direito ou licença é concedido sob este
                            Contrato, por implicação, preclusão, estatuto ou
                            outro, exceto conforme expressamente previsto neste
                            documento.
                        </p>
                    </TermsSection>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="landing-separator" />
                <LandingFooter />
            </div>
        </div>
    )
}
