import React, { useState, useMemo, useEffect } from "react"
import MenuDrop from "components/menu/MenuDrop"
import MenuSection from "components/menu/MenuSection"
import MenuItem from "components/menu//MenuItem"
import { general, quickPhrase, useStore } from "store"
import { getIconClassName } from "@uifabric/styling"

// import EmojiPickerReact from "emoji-picker-react"
// import EmojiConvertor from "emoji-js"
import "emoji-mart/css/emoji-mart.css"
import { Picker, Emoji } from "emoji-mart"
import EditQuickPhrase from "pages/settings/quickphrases/EditQuickPhrase"
import { useModalClick, useKeyPress } from "hooks"

function QuickPhrasesDrop(props) {
    // const [closeDrop, setCloseDrop] = useState(false)
    // const [focused, setFocused] = useState(0)
    const { addModal } = useStore(general)
    const { all } = useStore(quickPhrase)
    // useKeyPress(onKey, [focused, props.slashPhrase])
    const width = 600

    // const filteredQuickPhrases = useMemo(() => {
    //     setFocused(0)
    //     return quickPhrases.filter(x =>
    //         props.slashPhrase ? ("/" + x.tag).includes(props.slashPhrase) : true
    //     )
    // }, [props.slashPhrase])

    // const finalQuickPhrases = props.slashPhrase ? filteredQuickPhrases : quickPhrases

    return (
        <div className="quick-phrase-picker-box">
            <MenuDrop
                title="Respostas Rápidas"
                width={width}
                trigger={<div className={"icon-trigger " + getIconClassName('LightningBolt')} />}
                toTop
                toLeft
                isOpen={!!props.slashPhrase}
                searchable
                initialFocusIndex={0}
                onEsc={() => props.quillRef.current.focus()}
                options={[
                    { section: { title: "Criar" } },
                    {
                        icon: "fal fa-plus drop-icon-add",
                        onClick: () => addModal(<EditQuickPhrase />),
                        name: "Nova resposta rápida",
                        searchable: "@"
                    },
                    { section: { title: "Respostas" } },
                    ...all.map((x, i) => ({
                        searchable: x.tag,
                        key: x.id,
                        onClick: () => {
                            props.setText(x.phrase)
                        },
                        name: (
                            <span
                                className="quick-phrase-phrase-space"
                                style={{ maxWidth: width - 30 }}
                            >
                                <span className="quick-phrase-code">{x.tag}</span>
                                <span className="quick-phrase-phrase">{x.phrase}</span>
                            </span>
                        )
                    }))
                ]}
            >
                {/* <MenuSection title="Criar">
                    <MenuItem
                        icon="fal fa-plus drop-icon-add"
                        onClick={() => addModal(<EditQuickPhrase />)}
                        name="Nova resposta rápida"
                    />
                </MenuSection>
                <MenuSection title="Respostas">
                    {finalQuickPhrases.map((x, i) => (
                        <MenuItem
                            focused={props.slashPhrase && i === focused}
                            key={x.id}
                            onClick={() => {
                                setCloseDrop(true)
                                props.setText(x.phrase)
                            }}
                            name={
                                <span
                                    className="quick-phrase-phrase-space"
                                    style={{ maxWidth: width - 30 }}
                                >
                                    <span className="quick-phrase-code">/{x.tag}</span>
                                    <span className="quick-phrase-phrase">{x.phrase}</span>
                                </span>
                            }
                        />
                    ))}
                </MenuSection> */}
            </MenuDrop>
        </div>
    )
}

export default QuickPhrasesDrop
