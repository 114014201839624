import React from "react"
import cx from "classnames"
import Fade from "react-reveal/Fade"

function NoDataChat({ inboxStore, unresolved }) {
    return (
        <>
            {/* <div className="empty-inbox-panel-header" /> */}
            <div className="empty-inbox-panel">
                <Fade>
                    <div className="empty-inbox-inner">
                        {/* <img
                                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MCIgaGVpZ2h0PSI4MCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDgwIDgwIj48cGF0aCBmaWxsPSIjRThFQUVEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4yMDEgMzcuNzVIMjcuNzFsLjU3IDEuNzQ0YzEuNzQxIDUuMzE5IDYuNjU1IDguOTgxIDEyLjI2IDguOTgxIDUuNjA0IDAgMTAuNTE3LTMuNjYyIDEyLjI1Ny04Ljk4MWwuNTctMS43NDRoMTQuNTA5bC01LjI2Mi0xNC42MzRjLTEuMDg0LTMuMDE1LTMuOTA1LTUuMDItNy4wNjMtNS4wMkgyNS41MjZjLTMuMTU4IDAtNS45OCAyLjAwNS03LjA2MyA1LjAyTDEzLjIgMzcuNzV6bTU5Ljg3NSAwdjE2LjI4MmMwIDcuMDM1LTUuNjExIDEyLjczOS0xMi41MzMgMTIuNzM5aC00MC4wMUMxMy42MTEgNjYuNzcgOCA2MS4wNjcgOCA1NC4wM1YzNy4zNzNsNS43NTQtMTYuMDA2QzE1LjU2MSAxNi4zNDIgMjAuMjYzIDEzIDI1LjUyNiAxM0g1NS41NWM1LjI2MyAwIDkuOTY2IDMuMzQyIDExLjc3MiA4LjM2Nmw1Ljc1NCAxNi4wMDZ2LjM3OHoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="
                                            alt=""
                                        /> */}
                        <div
                            className={cx("empty-image", {
                                "not-checked": unresolved > 0
                            })}
                        />
                        <div className="empty-inbox-details">
                            <div className="empty-top">Nenhuma conversa selecionada.</div>
                            <div
                                className="empty-bottom"
                                style={{ opacity: inboxStore.totalCount > -1 ? 1 : 0 }}
                            >
                                Você tem {/* <strong> */}
                                {unresolved}{" "}
                                {`conversa${unresolved !== 1 ? "s" : ""} pendente${
                                    unresolved !== 1 ? "s" : ""
                                }.`}
                                {/* </strong> */}
                                <br />
                                {/* {unresolved > 1 ? "" : " Pode descansar!"} */}
                                {/* <br/>
                                            <br/>
                                            <Button primary>Adiciona um canal</Button> */}
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </>
    )
}

export default NoDataChat
