import { useState } from "react"
import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"

function apiTokensStore(store) {
    const [secrets, setSecrets] = useState({})
    async function getSecrets() {
        const response = await req(config.api.API_TOKENS)
        const errors = response.error || response.errors
        if (!errors) {
            setSecrets(response)
        }
    }

    return {
        ...store,
        secrets,
        getSecrets
    }
}

export default fromBase(config.api.API_TOKENS, apiTokensStore)
