import React, { useEffect, useMemo, useState } from "react"
// import SimpleBar from "simplebar-react"
import { Resizable } from "re-resizable"
import { useLocalStorage } from "react-use"
// import ChatSideDrop from "./ChatSideDrop"
import ContactDetails from "./ContactDetails"
import SidePlugin from "./SidePlugin"
// import ChatActionIco from "components/inbox/ChatActionIco"
import { useStore, general, plugin, inbox } from "store"
// import Scrollbar from "react-scrollbars-custom"

function ChatSide(props) {
    const { activeIntegration } = useStore(general)
    const pluginStore = useStore(plugin)
    const inboxStore = useStore(inbox)

    const [width, setWidth] = useLocalStorage("chatSideWidth", 280)

    /* BEGIN -GET ITEM DETAILS */
    const item = useMemo(() => inboxStore.all.find(x => x.id == props.id) || {}, [
        inboxStore.all,
        props.id
    ])
    const customerDetails = useMemo(() => inboxStore.customers[props.id] || {}, [
        props.id,
        inboxStore.customers,
        inboxStore.customers[props.id]
    ])

    
    const itemWithDetails = useMemo(() => ({ ...customerDetails, ...item }), [
        customerDetails,
        customerDetails.properties,
        item
    ])

    useEffect(() => {
        if (props.id && !customerDetails.id) {
            inboxStore.getCustomerDetails(props.id)
        }
    }, [props.id])
    /* END - GET ITEM DETAILS */


    // console.log(111, customerDetails, item, itemWithDetails)

    const { sideComponent, sideName } = useMemo(() => {
        switch (activeIntegration.type) {
            case "plugin":
                return {
                    sideName: activeIntegration.title,
                    sideComponent: (
                        <SidePlugin
                            id={props.id}
                            plugin={pluginStore.all.find(x => x.id === activeIntegration.id)}
                            customer={itemWithDetails}
                            location={props.location}
                        />
                    )
                }

            case "contact":
            default:
                return {
                    sideName: "Contato",
                    sideComponent: (
                        <ContactDetails
                            id={props.id}
                            itemWithDetails={itemWithDetails}
                            customerDetails={customerDetails}
                            item={item}
                        />
                    )
                }
        }
    }, [activeIntegration, pluginStore.all, inboxStore.customers, props.id, itemWithDetails])

    return (
        <div>
            {activeIntegration.type && (
                <Resizable
                    size={{ width, height: "100%" }}
                    onResizeStop={(e, direction, ref, d) => {
                        setWidth(w => w + d.width)
                    }}
                    maxWidth={700}
                    minWidth={300}
                    enable={{ left: true }}
                    handleWrapperClass="resize-line"
                    className="resizer"
                >
                    <div className="chat-side">
                        <div className="chat-side-header">
                            <h3>{sideName}</h3>
                        </div>

                        <div className="chat-side-inner">
                            {/* <Scrollbar style={{ width: "100%", height: "100%" }} noScrollX={true}> */}
                                {sideComponent}
                            {/* </Scrollbar> */}
                        </div>
                    </div>
                </Resizable>
            )}
            {/* <ChatActionIco
                icon={opened ? "fal fa-times" : "fal fa-chevron-square-left"}
                // description="Nota pro time"
                onClick={() => {
                    setOpened(opened => !opened)
                }}
                medium
                style={{ position: "absolute", top: 15, right: 15, zIndex: 1 }}
                // iconStyle={{ fontSize: 22 }}
            /> */}
        </div>
    )
}

export default ChatSide
