import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"
import toaster from "lib/toaster"
import { stores } from "store"

function reportStore(store) {
    async function exportToEmail(endpoint, query) {
        toaster.ok("Relatório será enviado em breve para " + stores.session.user.email)
        const response = await req(endpoint, {
            method: "post",
            data: query
        })
    }

    return {
        ...store,
        exportToEmail
    }
}

export default fromBase(config.api.REPORT_CUSTOMER, reportStore)
