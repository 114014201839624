import React, { useRef, useMemo, useEffect } from "react"
import MenuDrop from "components/menu/MenuDrop"
import Button from "components/forms/Button"

function ExportDrop({ options }) {
    const trigger = useMemo(() => {
        return (
            <Button
                gray
                ios
                icon="far fa-file-export"
                onClick={() => {}}
                className="button-page-table-add"
            >
                Exportar
            </Button>
        )
    })

    return (
        <div className="select-dropdown-space">
            <MenuDrop
                // title="Exportar para"
                trigger={trigger}
                options={options}
                width={200}
                toLeft
            />
        </div>
    )
}

export default ExportDrop
