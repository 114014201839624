import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import ReadOnlyProperty from "./ReadOnlyProperty"
import userImg from "assets/images/user.svg"
import Loader from "components/Loader"
import { general, operator, inbox, useStore } from "store"
import Chat from "pages/inbox/Chat"

function ContactConversations({ contact, ...props }) {
    const operatorStore = useStore(operator)
    const inboxStore = useStore(inbox)
    const { addModal } = useStore(general)

    return (
        <div className="contact-medias">
            {contact.calls &&
                contact.calls.map(x => {
                    const operator = operatorStore.all.find(o => o.id == x.operatorId) || {}

                    return (
                        // <Link className="contact-call-card" to={{ search: "?callId=" + x.id }} >
                        <a
                            key={x.id}
                            className="contact-call-card"
                            style={!x.finishedAt ? { fontWeight: 700 } : {}}
                            onClick={() =>
                                addModal(
                                    <Chat
                                        {...props}
                                        id={contact.id}
                                        inboxStore={inboxStore}
                                        callId={x.id}
                                        protocol={x.protocol}
                                        viewOnly
                                    />,
                                    {
                                        style: { width: 590 }
                                    }
                                )
                            }
                        >
                            <div className="left">
                                <div className="top" title="Agente">
                                    {operator.name || "Não atríbuido"}
                                </div>
                                <div className="bottom" title="Protocolo">
                                    {x.protocol}
                                </div>
                            </div>
                            <div className="right">
                                {new Date(x.createdAt).toLocaleDateString()}
                            </div>
                        </a>
                        // </Link>
                    )
                })}
        </div>
    )
}

export default ContactConversations
