import React, { useMemo, useState } from "react"
import { NavLink, Link } from "react-router-dom"
import Logo from "./Logo"
// import logo from "assets/images/logo.svg"
// import person from "../../assets/images/person-eg.png"
// import people from "assets/icons/icon-people.svg"
// import logo from "assets/images/logo-nav.png"
// import Logo from "./Logo.js"
// import Button from "../forms/Button"
// import store from "store"
import UserMenu from "components/sidenav/UserMenu"
import { permissionsEnum } from "lib/constants"
import { session, general, useStore } from "store"
import { getImage } from "lib/helper"
import Fade from "react-reveal/Fade"

function Sidenav(props) {
    const { user, workspace } = useStore(session)
    const { localFilters } = useStore(general)
    const inboxQuery = useMemo(() => localFilters["inbox"], [localFilters])
    const itemsTop = useMemo(
        () => [
            {
                name: "Caixa de entrada",
                // icon: props.location.pathname.includes("/inbox")
                //     ? "fal fa-comment-dots"
                //     : "fal fa-comment-dots",
                icon: "fal fa-inbox",
                to: "/inbox" + (inboxQuery ? "?" + inboxQuery : ""),
                permission: level => level >= permissionsEnum.OPERATOR_RESTRICT,
            },
            // {
            //     name: "Dashboard",
            //     // icon: "ico ico-dashboard",
            //     icon: "fal fa-chart-pie-alt",
            //     to: "/dashboard"
            // },
            // {
            //     name: "Relatórios",
            //     icon: "fal fa-chart-bar",
            //     to: "/reports"
            // },
            // {
            //     name: "Power-Ups",
            //     icon: "fal fa-rocket",

            //     // icon: props.location.pathname.includes("/power-ups")
            //     //     ? "fal fa-rocket"
            //     //     : "fal fa-rocket",
            //     to: "/power-ups"
            // },
            // {
            //     name: "Configurações",
            //     icon: "fal fa-cog",
            //     // icon: props.location.pathname.includes("/settings") ? "fal fa-cog" : "fal fa-cog",
            //     to: "/settings"
            // }
        ],
        [localFilters, props.location]
    )
    const itemsBottom = useMemo(
        () => [
            // {
            //     name: "Power-Ups",
            //     icon: "fal fa-rocket",
            //     to: "/power-ups"
            // },
            {
                name: "Configurações",
                icon: "fa fa-cog",
                to: "/settings"
            }
        ],
        [localFilters, props.location]
    )

    const options = itemsTop.filter(e => !e.permission || e.permission(workspace.accessLevel))
    const optionsBottom = itemsBottom.filter(e => !e.permission || e.permission(workspace.accessLevel))
    // const workspaceAvatar = workspace.avatar

    const renderNavItem = e => (
            <li className="sidenav-item" key={e.name}>
                <NavLink
                    to={e.to}
                    isActive={(match, location) =>
                        location.pathname.split("/")[1] === e.to.split("/")[1].split("?")[0]
                    }
                >
                    <i className={e.icon} />
                </NavLink>
            </li>
    )

    return (
        <div className="sidenav-space">
            <Fade>
                <section className="sidenav">
                    <div className="top">
                        <div className="nav-logo-space">
                            {/* <Link to="/inbox">
                            {workspaceAvatar ? (
                                <img
                                    src={getImage(workspaceAvatar)}
                                    alt="logo"
                                    style={{ borderRadius: "50%" }}
                                />
                                ) : (
                                    <Logo />
                                    )}
                                </Link> */}
                            <Link to="/inbox">
                                <img
                                    src={getImage(
                                        "https://www.k15t.com/files/158505766/158512134/1/1544452301068/jsw_white.png"
                                    )}
                                    alt="logo"
                                    // style={{ borderRadius: "50%" }}
                                />
                                {/* <Logo /> */}
                            </Link>
                        </div>
                        <div className="sidenav-items">
                            <ul>{options.map(e => renderNavItem(e))}</ul>
                        </div>
                    </div>
                    <div className="bottom">
                        <ul>{optionsBottom.map(e => renderNavItem(e))}</ul>
                        <UserMenu {...props} />
                    </div>
                </section>
            </Fade>
        </div>
    )
}

export default Sidenav
