import React, { useState, useMemo } from "react"
import { Link, Redirect } from "react-router-dom"
import LandingHeader from "./LandingHeader"
import wppImg from "assets/images/wpp.png"
import instagramImg from "assets/icons/svg/instagram.svg"
import LandingFooter from "./LandingFooter"
import Modal from "components/Modal"
import LandingRegisterSection from "./LandingRegisterSection"
import cx from "classnames"
import { getIconClassName } from "@uifabric/styling"
import BillingTab from "components/BillingTab"
import { useField, useScrollTop } from "hooks"

function PricingPerUnit({ price, name }) {
    return (
        <div className="princing-plan-price">
            <span className="amount">
                <span className="dollar-sign">R$</span>
                {price}
            </span>
            <span className="peruser">
                {/* por {name}
                <br /> */}
                por mês
            </span>
        </div>
    )
}

function PlanFeature({ children }) {
    return (
        <div className="plan-feature-row">
            <i className="far fa-check" />
            <span>{children}</span>
        </div>
    )
}

function PricingPlanCard({ name, features, pro, buttonText, buttonOnClick, price, description }) {
    return (
        <div className={cx("princing-plan-card", { pro })}>
            <div className="pricing-plan-name">{name}</div>
            <div className="pricing-plan-description">{description}</div>
            {!!price && <PricingPerUnit price={price} name="agente" />}
            {
                buttonOnClick
                    ? (
                        <button onClick={buttonOnClick} className="landing-button-connect">
                            {buttonText}
                        </button>
                    )
                    : (
                      <Link to="/user/register" className="landing-button-connect">
                          {buttonText || "Experimente de graça"}
                      </Link>
                    )
            }
            <br />
            <br />
            <br />
            {!!features && (
                <div className="plan-features">
                    {features.map(x => (
                        <PlanFeature key={x.description}>{x.description}</PlanFeature>
                    ))}
                </div>
            )}
        </div>
    )
}

function FaqItem({ question, children }) {
    return (
        <div className="faq-item">
            <div className="question">{question}</div>
            <div className="answer">{children}</div>
        </div>
    )
}

function AddOnCard({img, icon, title, price, description, features}) {
  return (
      <div className="pricing-add-on">
          <div className="top">
              <div className="left">
              {!!img && <img src={img} alt="" />}
              {!!icon && <i className={icon} />}
              <span className="title">{title}</span>
              </div>
              <div className="right">
                  <PricingPerUnit price={price} />
              </div>
          </div>
          <div className="bottom">
              <div className="add-on-description">
                  {description}
              </div>
              <br />
              <div className="add-on-features plan-features">
                  {
                      features.map((text) => (
                          <PlanFeature>{text}</PlanFeature>
                      ))
                  }
              </div>
          </div>
      </div>
  )
}

export default function Princing(props) {
    useScrollTop(props.location)
    const [monthly, setMonthly] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    return (
        <div className="landing-space">
            <LandingHeader white />
            <br />
            <br />
            <div className="landing-section pricing">
                <div className="pricing-midcol">
                    <div className="center">
                        <h1 style={{ fontSize: 35, marginBottom: 10 }}>
                            Escolha o plano ideal para seu time
                        </h1>
                        <h3>Comece seu trial gratuitamente, sem compromisso.</h3>
                    </div>
                    {/* <div style={{ width: 200, margin: "0 auto" }}>
                        <BillingTab
                            onChange={v => {
                                setMonthly(v)
                            }}
                            value={monthly}
                        />
                    </div> */}
                    <div className="pricing-plans">
                        <PricingPlanCard
                            name="Plano Básico"
                            buttonText="Cadastre-se grátis"
                            price="149"
                            features={[
                                { description: "Integração com Whatsapp, Telegram e Instagram" },
                                { description: "Widget exclusivo para sua marca" },
                                { description: "Relatórios" },
                                { description: "Conversas ilimitadas" },
                                { description: "App Android e iOS" },
                                { description: "1 agente ativo" },
                            ]}
                            description="Inicie sua presença omnichannel com as principais funcionalidades"
                        />
                        <PricingPlanCard
                            name="Plano Business"
                            buttonText="Cadastre-se grátis"
                            price="449"
                            features={[
                                { description: "Tudo do plano Básico" },
                                { description: "Bot personalizado" },
                                { description: "5 agentes ativos" },
                            ]}
                            description="Maximize o esforço da sua equipe com atendimentos automatizados"
                        />
                        <PricingPlanCard
                            pro
                            name="Plano Enterprise"
                            buttonText="Cadastre-se grátis"
                            price="799"
                            features={[
                                { description: "Tudo do plano Business" },
                                { description: "Campanhas" },
                                { description: "10 agentes ativos" },
                            ]}
                            description="Aproveite todas as funcionalidades e expanda o seu alcance com as Campanhas"
                        />
                        <PricingPlanCard
                            name="Plano Custom"
                            description="Crie sua própria experiência: Entre em contato para um plano personalizado"
                            buttonText="Entrar em contato"
                            buttonOnClick={() => setModalIsOpen(true)}
                        />
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <div className="landing-section pricing">
                <div className="midcol">
                    <div className="center">
                        <h1 style={{ fontSize: 35, marginBottom: 10 }}>
                            Personalize seu plano com add-ons
                        </h1>
                        <h3>Procurando por algo mais? Adicione o que você precisar.</h3>
                    </div>
                    <br />
                    <br />
                    <AddOnCard
                        img={wppImg}
                        title="Whatsapp"
                        description="Adicione uma integração de Whatsapp extra e atenda seus clientes na rede social
                        preferida do Brasil."
                        price="99"
                        features={[
                            "Ler e enviar mensagens",
                            "Envio e recebimento de mídias"
                        ]}
                    />
                    <br />
                    <br />
                    <AddOnCard
                        icon={getIconClassName("Telemarketer")}
                        title="Agente"
                        description="Adicione mais um agente à sua equipe."
                        price="49"
                        features={[
                            "Um agente extra com as mesmas funções dos demais",
                        ]}
                    />
                    <br />
                    <br />
                    <AddOnCard
                        icon={getIconClassName("Megaphone")}
                        title="Campanhas"
                        description="Adicione mais uma campanha em paralelo."
                        price="149"
                        features={[
                            "Agora você pode criar mais uma campanha simultaneamente",
                        ]}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                    <span style={{ fontWeight: 300, fontSize: 17 }}>
                        Tem alguma dúvida? Talvez você possa encontrar a resposta abaixo:
                    </span>
                    <i className="fal fa-arrow-down" id="faq" />
                    <br />
                    <br />
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="landing-section" style={{ background: "#f2f9ff" }}>
                <div className="midcol">
                    <div className="center">
                        <h1 style={{ fontSize: 35, marginBottom: 10 }}>Perguntas Frequentes</h1>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="princing-faq">
                        <div className="left">
                            <FaqItem question="O que vai acontecer quando a versão trial acabar?">
                                Você vai precisar assinar algum dos planos oferecidos se quiser continuar usando as
                                ferramentas exclusivas.
                            </FaqItem>
                            <FaqItem question="Quais são os métodos de pagamento disponíveis?">
                                Pix ou Boleto. Qualquer dúvida entre em contato com nosso
                                suporte.
                            </FaqItem>
                            <FaqItem question="Tenho uma empresa grande e preciso de um plano que vai além do máximo oferecido, o que devo fazer?">
                                Entre em contato conosco pelos canais de atendimento:
                                Via WhatsApp pelos números (85) 99966-4490 | (85) 98974-5229 ou 
                                Email em kimolivechat@gmail.com
                            </FaqItem>
                        </div>
                        <div className="right">
                            <FaqItem question="Caso eu queira mudar meu plano atual para um novo plano eu perco o dinheiro que investi?">
                                Não, caso o novo plano seja mais barato do que o antigo, é calculado
                                o tempo não utilizado do seu plano e utilizado como crédito durante
                                o uso do novo plano. Caso o novo plano seja mais caro do que o
                                antigo, é calculado o tempo não utilizado do seu plano atual e
                                utilizado como crédito para o pagamento do novo plano, restando a você pagar a diferença.
                            </FaqItem>
                            <FaqItem question="Eu posso ter vários Workspaces com planos diferentes?">
                                Sim, cada Workspace é individual, com agentes individuais e configurações únicas, nele você pode
                                aderir a um plano diferente caso já tenha ou não um outro Workspace em um plano Pro.
                            </FaqItem>
                            {/* <FaqItem question="Por que vocês cobram extra pelo Whatsapp?">
                                O Whatsapp requer um servidor dedicado para ler e receber mensagens.
                                A taxa serve para cobrir esses gastos.
                            </FaqItem> */}
                        </div>
                    </div>
                    <div className="princing-faq">
                        <div className="left">
                            <FaqItem question="Qual o limite de envio e recebimento de arquivos (imagem, audio, vídeo, documentos e stickers)?">
                                O Limite máximo de envio e recebimento de arquivos para todas as plataformas é 16mb (Megabytes),
                                podendo ainda alguma plataforma ter um limite inferior ou superior ao estipulado pelo KimoChat.
                            </FaqItem>
                            <FaqItem question="Como eu entro em contato com alguém para tirar dúvidas sobre o sistema?">
                                Nós fazemos atendimento via WhatsApp pelos números (85) 99966-4490 | (85) 98974-5229 ou 
                                por email em kimolivechat@gmail.com (essas informações estão no rodapé do site).
                            </FaqItem>
                        </div>
                        <div className="right">
                            <FaqItem question="O que é um Workspace?">
                                Entenda Workspace como a representação da sua empresa no KimoChat, esse é o espaço de trabalho
                                onde você atende, configura e automatiza os seus atendimentos.
                            </FaqItem>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
            {modalIsOpen && (
                <Modal onBack={() => setModalIsOpen(false)}>
                    <div className="modal-billing-contact">
                        <p className="title">Contatos</p>
                        <p>Entre em contato conosco para montar um plano que melhor se encaixa com o seu negócio:</p>
                        <strong style={{ marginTop: '8px' }}>
                            <i
                                className="fab fa-whatsapp"
                                style={{ color: "#2ecc71", opacity: 1, fontSize: 16 }}
                            />{" "}
                            +55 85 9 9966-4490
                        </strong>
                        <strong>
                            <i
                                className="fab fa-whatsapp"
                                style={{ color: "#2ecc71", opacity: 1, fontSize: 16 }}
                            />{" "}
                            +55 85 9 8974-5229
                        </strong>
                        <strong>
                            kimolivechat@gmail.com
                        </strong>
                    </div>
                </Modal>
            )}
            <LandingRegisterSection />
            <LandingFooter />
        </div>
    )
}
