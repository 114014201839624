import React from "react"
import BasePlatform from '../BasePlatform'

export default function WppLanding(props) {
    return (
        <BasePlatform
            title="WhatsApp KimoChat"
            description="O KimoChat WhatsApp é uma plataforma que foi pensada para atender as demandas de comunicação da sua empresa em mente. Com nossa plataforma, as empresas podem interagir facilmente com clientes, usando ferramentas para automatizar, organizar e responder rapidamente a mensagens."
            icon="https://i.imgur.com/bHWfKu5.png"
            banner="https://i.imgur.com/atPGq7R.png"
            separator="green-box"
            path="/user/register"
            {...props}
        />
    )
}
