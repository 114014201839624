import React from "react"
import SettingsHelp from "components/SettingsHelp"
import EditAssignmentRule from "./EditAssignmentRule"
// import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
// import Switch from "components/forms/Switch"
// import { limit } from "lib/helper"
import { assignmentRules, useStore } from "store"
// import { channelsTypes } from "lib/constants"
import SwitchField from "components/forms/SwitchField"
import PageTableBase from "components/PageTableBase"
import { getIconClassName } from "@uifabric/styling"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

const columns = onChangeActive => [
    {
        Header: "Regra",
        accessor: "name",
        maxWidth: 500,
        Cell: props => (
            <span className="vertical-center">
                <i
                    className={getIconClassName("MailForward")}
                    style={{ marginRight: 20, color: "rgb(54, 127, 238)", fontSize: 16 }}
                />
                {props.value}
            </span>
        )
    },
    {
        Header: "Status",
        accessor: "isActive",
        width: 100,
        Cell: props => (
            <SwitchField
                small
                checked={props.value}
                onClick={e => {
                    e.stopPropagation()
                }}
                onChange={value => onChangeActive(props.row._original, value)}
            />
        )
    }
]

export default function AssignmentRules(props) {
    const assignmentRulesStore = useStore(assignmentRules)

    function onChangeActive(item, isActive) {
        ReactGA.event({
            category: analyticsCategory.AGENT,
            action: `Agente alterou o estado da regra de atribuição para: ${isActive}`
        })
        assignmentRulesStore.saveItem({ ...item, isActive })
        assignmentRulesStore.save({
            method: "put",
            data: { ...item, isActive }
        })
    }

    return (
        <PageTableBase
            storeRef={assignmentRules}
            title="Atribuição Automática"
            newLabel="Novo"
            columns={columns(onChangeActive)}
            EditComponent={EditAssignmentRule}
            helperAction={
                <SettingsHelp title="Atribuição Automática" icon={getIconClassName("MailForward")}>
                    Atribua conversas automaticamente entre seus agentes baseado em regras
                    predefinidas, eventos, filtros e palavras chaves.
                </SettingsHelp>
            }
            nodata={{
                title: "Parece que você ainda não adicionou nenhuma regra...",
                buttonText: "Adicionar Regra"
            }}
            {...props}
        />
    )
}
