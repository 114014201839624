import React from "react"
import { Widget } from "react-chat-widget"
import 'react-chat-widget/lib/styles.css'
import './styles.css'

function WidgetChat({
    sendMessage,
    title,
    subtitle
}) {
    return (
        <Widget 
            handleNewUserMessage={(message) => sendMessage({ content: message })}
            title={title}
            subtitle={subtitle}
            senderPlaceHolder="Digite aqui..."
            // launcherOpenImg={gymLogo}
            // launcherCloseImg={gymLogo}
            showCloseButton
            emojis
        />
    )
}

export default WidgetChat
