import React from "react"
import { Link } from "react-router-dom"
import Select from "components/forms/Select"
import Box from "components/Box"
import DashBarHorizontal from "components/graphs/DashBarHorizontal"

// const data = [
//     {
//         member: "Andrew",
//         "Tempo de primeira resposta": 86,
//         "Tempo de resposta": 20,
//         "Tempo de atendimento": 200,
//     },
//     {
//         member: "Isaac",
//         "Tempo de primeira resposta": 78,
//         "Tempo de resposta": 40,
//         "Tempo de atendimento": 340,
//     }
// ]

const GraphMembersCard = ({ height, data }) => (
    <div className="page dash-card graph">
        {console.log(data)}
        <Box title="Comparação de tempos">
            <div style={{ height }} className="graph-members-dash">
                <DashBarHorizontal data={data.map(x => ({...x, member: x.member.split(' ')[0]}))} />
            </div>
        </Box>
    </div>
)

export default GraphMembersCard
