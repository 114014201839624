import React, { useEffect, useMemo, useState } from "react"
import ChatSideItem from "./ChatSideItem"
import DetailsOverview from "./DetailsOverview"
import PeopleDataProperties from "./PeopleDataProperties"
import ContactConversations from "./ContactConversations"
import ContactMedias from "./ContactMedias"
import ContactChannels from "./ContactChannels"
import { inbox, useStore } from "store"
import Loader from "components/Loader"
import { plugin } from "store"

function ContactDetails({ item, itemWithDetails, customerDetails, ...props }) {
    const inboxStore = useStore(inbox)

    return (
        <div className="contact-details">
            {props.id ? (
                <>
                    <ChatSideItem
                        id="overview"
                        title="Geral"
                        style={{ borderTop: "none" }}
                    >
                        <DetailsOverview
                            id={props.id}
                            item={item}
                            customerDetails={customerDetails}
                            itemWithDetails={itemWithDetails}
                        />
                        <PeopleDataProperties
                            properties={customerDetails.properties || []}
                            saveProperty={inboxStore.saveProperty}
                            removeProperty={inboxStore.removeProperty}
                            item={itemWithDetails}
                            customerId={customerDetails.id}
                        />
                    </ChatSideItem>
                    <Loader loader="get-customer" noMarginTop>
                        {/* <ChatSideItem id="custom-props" title="Propriedades">
                            <PeopleDataProperties
                                properties={customerDetails.properties || []}
                                saveProperty={inboxStore.saveProperty}
                                removeProperty={inboxStore.removeProperty}
                                item={itemWithDetails}
                                customerId={customerDetails.id}
                            />
                        </ChatSideItem> */}
                        {itemWithDetails.calls && (
                            <>
                                <ChatSideItem
                                    id="channels-contact-props"
                                    title="Canais"
                                >
                                    <ContactChannels
                                        contact={itemWithDetails}
                                    />
                                </ChatSideItem>
                                <ChatSideItem
                                    id="media-props"
                                    title="Mídias"
                                >
                                    <ContactMedias
                                        contact={itemWithDetails}
                                    />
                                </ChatSideItem>
                                <ChatSideItem
                                    id="conversations-props"
                                    title={`Conversas (${itemWithDetails.calls.length})`}
                                >
                                    <ContactConversations
                                        contact={itemWithDetails}
                                    />
                                </ChatSideItem>
                            </>
                        )}
                    </Loader>
                    <br />
                    <br />
                    <br />
                </>
            ) : (
                <span className="not-selected-plugin">
                    Selecione uma conversa para obter informações do contato
                </span>
            )}
        </div>
    )
}

export default ContactDetails
