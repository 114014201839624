import config from "config"
import fromBase from "stores/baseStore"
import { request, toast } from "nanui"
import { stores } from "store"

function campaignStore(store) {
    async function getReport({ id }) {
        stores.general.addLoader("report-campaign")
        return request
            .get(config.api.DOWNLOAD_REPORT_CAMPAIGN + "/" + id)
            .then(response => {
                toast("Campanha baixada", "success")
                window.open('data:text/xls,' + encodeURIComponent(response.data));
                return response.data
            })
            .finally(() => {
                stores.general.removeLoader("report-campaign")
            })
    }

    return {
        ...store,
        getReport
    }
}

export default fromBase(config.api.CAMPAIGN, campaignStore)
