import React, { Component } from "react"
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

class ScriptJsonBox extends Component {
    render() {
        const { label } = this.props
        return (
            <div className="text-space text-margin-bottom">
                <label className="label-input">{label}</label>

                <div className="script-box">
                <JSONInput
                    id="a_unique_id"
                    placeholder={this.props.placeholder}
                    // theme="light_mitsuketa_tribute"
                    // colors={"light_mitsuketa_tribute"}
                    locale={locale}
                    height="300px"
                    onChange={({json}) => {
                        this.props.onChange(json)
                    }}
                    width="100%"
                />
                    {/* <CodeMirror
                        value={this.props.value}
                        options={{
                            mode: 'jsx',
                            theme: 'material',
                            lineNumbers: true
                        }}
                        onBeforeChange={(editor, data, value) => {
                            this.props.onChange(value)
                          }}
                        // onChange={(editor, data, value) => {
                        //     console.log(editor, data, value)
                        //     this.props.onChange(value)
                        // }}
                    /> */}
                </div>
            </div>
        )
    }
}

export default ScriptJsonBox