import React from "react"
import Text from "components/forms/Text"
import cx from "classnames"
import Button from "components/forms/Button"

export default class ReadOnlyProperty extends React.PureComponent {
    state = {
        copied: false
    }

    copyToClipboard = str => {
        const el = document.createElement("textarea") // Create a <textarea> element
        el.value = str // Set its value to the string that you want copied
        el.setAttribute("readonly", "") // Make it readonly to be tamper-proof
        el.style.position = "absolute"
        el.style.left = "-9999px" // Move outside the screen to make it invisible
        document.body.appendChild(el) // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0 // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0) // Store selection if found
                : false // Mark as false to know no selection existed before
        el.select() // Select the <textarea> content
        document.execCommand("copy") // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el) // Remove the <textarea> element
        if (selected) {
            // If a selection existed before copying
            document.getSelection().removeAllRanges() // Unselect everything on the HTML document
            document.getSelection().addRange(selected) // Restore the original selection
        }
        this.setState({ copied: true })
        setTimeout(() => {
            this.setState({ copied: false })
        }, 5000)
    }

    render() {
        const { copied } = this.state
        const { property, value } = this.props

        const copyButtonClass = cx("action-hover", {
            copied
        })

        return (
            <div className="editable-property">
                <div className="people-data-row readable-row">
                    <div className="left">{property}</div>
                    <div className="right">
                        {value}
                        <button
                            className={copyButtonClass}
                            onClick={() => this.copyToClipboard(value)}
                            style={{ right: 28 }}
                        >
                            <i className="fa fa-copy" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
