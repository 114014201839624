import React, { useMemo, useState } from "react"
import Button from "components/forms/Button"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import {
    useField,
    useFormMultiSelect,
    useForm,
    useFetch,
    useColorEmoji
} from "hooks"
import { channel, campaign, general, session, useStore } from "store"
import { TextField, request, Stack, toast } from "nanui"
import DatePicker from "components/forms/DatePicker"
import ModuleChatTextEditor from "components/chat/ModuleChatTextEditor"
import masks from "lib/masks"
import Label from "components/forms/Label"
import Collapse from "components/Collapse"
import { getIconClassName } from "@uifabric/styling"
import config from "config"

function EditCampaignModal(props) {
    const [loadingPlay, setLoadingPlay] = useState(false)
    const channelStore = useStore(channel)
    const campaignStore = useStore(campaign)
    const { _domain } = useStore(general)
    const { workspace } = useStore(session)
    const item = props.item

    const form = useForm({
        onSubmit: async model => {
            await props.onSubmit(
                {
                    id: model.id,
                    name: model.name
                },
                { toastMessage: "Campanha alterada." }
            )
        },
        defaultModel: item
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    const columnProps = {
        tokens: { childrenGap: 20 }
        // styles: { root: { width: 460, marginLeft: 32 } }
    }

    const date = useMemo(
        () => (item.executionDate ? new Date(item.executionDate) : null),
        [item]
    )
    const channelObj = useMemo(
        () =>
            channelStore.all.find(x => x.id == item.workspacePlatformId) || {},
        [item]
    )

    async function onTogglePlay(status) {
        setLoadingPlay(true)
        try {
            const response = await request.save(config.api.CAMPAIGN, {
                id: item.id,
                status
            })
            campaignStore.saveItem(response.data)
            toast("Campanha " + (status === "paused" ? "pausada" : "retomada"))
        } catch (e) {
            toast("Erro ao alterar status da campanha")
        }
        setLoadingPlay(false)
    }

    return (
        <FormDialog {...props} title="Campanha" onSubmit={form.onSubmit}>
            <Stack {...columnProps}>
                <TextField label="Nome" required {...name} />
                <TextField
                    label="Canal"
                    required
                    disabled
                    value={`${channelObj.description ||
                        ""}    ${masks.cell(channelObj.phone || "")}`}
                />
                <DatePicker label="Data de início" value={date} disabled />
                <ModuleChatTextEditor
                    label="Mensagem"
                    text={
                        item.message ? { ops: JSON.parse(item.message) } : null
                    }
                    disabled
                />
                <div>
                    <Label disabled>Avançado</Label>
                    <Collapse title="Configurações de Tempo" disabled>
                        <Stack {...columnProps}>
                            <TextField
                                label="Tamanho do Lote"
                                type="number"
                                value={item.chunks}
                                disabled
                            />
                            <TextField
                                label="Tempo de espera entre lotes (seg)"
                                type="number"
                                value={item.timeBetweenChunks}
                                disabled
                            />
                            <TextField
                                label="Tempo de espera mínimo entre mensagens (seg)"
                                type="number"
                                value={item.initialTimeBetweenMessages}
                                disabled
                            />
                            <TextField
                                // defaultValue="5"
                                label="Tempo de espera máximo entre mensagens (seg)"
                                type="number"
                                value={item.finalTimeBetweenMessages}
                                disabled
                            />
                        </Stack>
                    </Collapse>
                </div>
                {(item.status === "paused" || item.status === "executing") && (
                    <div>
                        <Label disabled>Ações</Label>
                        {item.status === "paused" ? (
                            <Button
                                icon={getIconClassName("Play")}
                                isLoading={loadingPlay}
                                onClick={() => onTogglePlay("waiting_processing")}
                            >
                                Executar
                            </Button>
                        ) : (
                            <Button
                                icon={getIconClassName("Pause")}
                                isLoading={loadingPlay}
                                onClick={() => onTogglePlay("paused")}
                            >
                                Pausar
                            </Button>
                        )}
                    </div>
                )}

                <div>
                    <Label disabled>Relatórios</Label>
                    <Button
                        loader="report-campaign"
                        // onClick={() => campaignStore.getReport(item)}
                        onClick={() =>
                            window.open(
                                _domain.url +
                                    "/report/campaign/" +
                                    item.id +
                                    "?authorization=" +
                                    workspace.token,
                                "_blank"
                            )
                        }
                    >
                        Gerar Relatório
                    </Button>
                </div>
            </Stack>
            {/* <Text label="Nome" required {...name} />
            <Text label="Data de início" required {...name} />
            <Select label="Agentes" {...members} />
            <ColorEmojiPicker {...icon} /> */}
        </FormDialog>
    )
}

export default EditCampaignModal
