import React from "react"
import DefaultAvatar from "./DefaultAvatar"
import { getImage } from "lib/helper"

function AgentAvatar({ agent, size, style } = {}) {
    if (!agent.avatar) {
        return (
            <span className="agent-avatar">
                <DefaultAvatar size={size} index={agent.id} name={agent.name} />
            </span>
        )
    }
    return (
        <span className="agent-avatar">
            <img src={getImage(agent.avatar)} alt="user" style={style} />
        </span>
    )
}

export default AgentAvatar
