import React, { useEffect, useMemo } from "react"
import queryString from "query-string"
import { useSelectField, useDatePeriod, useForm, useFetch } from "hooks"
import Select from "components/forms/Select"
import DateRangePicker from "components/forms/DateRangePicker"
import { Panel, PanelType, Stack, Button, useStore } from "nanui"
import { group, operator, tag } from "store"
import { addQueryParams, spreadQueryParams } from "lib/helper"

const frequencyOptions = [
    { label: "Diário", value: "daily" },
    { label: "Semanal", value: "weekly" },
    { label: "Mensal", value: "monthly" }
]

export default function ReportFiltersPanel({ isFilterOpen, setFilterOpen, ...props }) {
    const groupStore = useStore(group)
    const operatorStore = useStore(operator)
    const tagStore = useFetch(tag)
    const query = queryString.parse(props.location.search)

    const form = useForm({
        onSubmit: async newParams => {
            addQueryParams({ ...props, newParams })
            setFilterOpen(false)
        }
    })

    const period = useDatePeriod("period", {
        defaultValue: { from: query.from, to: query.to },
        form
    })

    const frequency = useSelectField("frequency", {
        defaultValue: query.frequency,
        form,
        options: frequencyOptions
    })

    const groups = useSelectField("group", {
        defaultValue: query.group,
        form,
        options: groupStore.all.map(e => ({
            value: e.id,
            label: e.name
        }))
    })

    const agents = useSelectField("agent", {
        defaultValue: query.agent,
        loader: "GET/operators",
        form,
        options: operatorStore.all.map(e => ({
            value: e.id,
            label: e.name
        }))
    })

    const tags = useSelectField("tag", {
        defaultValue: query.tag,
        form,
        options: tagStore.all.map(e => ({
            label: e.name,
            value: e.id
        }))
    })

    const queryGroup = (groups.options.find(x => x.value == query.group) || {}).label
    const queryFrequency = (frequencyOptions.find(x => x.value === query.frequency) || {}).label
    const queryAgent = (agents.options.find(x => x.value == query.agent) || {}).label
    const queryTag = (tags.options.find(x => x.value == query.tag) || {}).label

    // {
    /* const queryLabel = (labels.flatOptions.find(x => x.value === query.label) || {}).label */
    // }
    // const queryTag = (members.options.find(x => x.value === query.agents) || {}).label

    useEffect(() => {
        if (!queryGroup) groups.onChange("")
        if (!queryFrequency) frequency.onChange("")
        if (!queryAgent) agents.onChange("")
        if (!queryTag) tags.onChange("")
    }, [isFilterOpen])

    const columnProps = {
        tokens: { childrenGap: 0 },
        styles: { root: { width: 300 } }
    }

    // console.log(111, frequency)

    return (
        <Panel
            isOpen={isFilterOpen}
            onDismiss={() => setFilterOpen(false)}
            type={PanelType.smallFixedRight}
            headerText="Filtros"
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
            onRenderFooterContent={() => (
                <form onSubmit={form.onSubmit}>
                    <Button primary type="submit" style={{ marginRight: "8px" }}>
                        Salvar
                    </Button>
                    <Button onClick={() => setFilterOpen(false)} type="button">
                        Cancelar
                    </Button>
                </form>
            )}
        >
            <Stack {...columnProps}>
                <Select label="Frequência" isClearable {...frequency} menuPortalTarget={null} />
                <Select label="Grupo" isClearable {...groups} menuPortalTarget={null} />
                <Select label="Agente" isClearable {...agents} menuPortalTarget={null} />
                <Select label="Etiqueta" isClearable {...tags} menuPortalTarget={null} />
                <div>
                    <label className="label-input">Período de tempo</label>
                    <DateRangePicker {...period} />
                </div>
            </Stack>
        </Panel>
    )
}
