import React from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"

function EditResolve(props) {
    const actionItem = props.actionItem
    const form = useForm({
        onSubmit: async model => await props.onEditAction(model),
        defaultModel: { ...actionItem, type: "resolve" }
    })

    const text = useField("text", {
        defaultValue: actionItem.text,
        form
    })

    return (
        <FormDialog
            {...props}
            title="Finalizar conversa"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Text label="Texto" {...text} rows={3} />
        </FormDialog>
    )
}
export default EditResolve
