import React, { useEffect, useMemo, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { general, operator, inbox, useStore, session } from "store"
import Chat from "pages/inbox/Chat"
import { getIconClassName } from "@uifabric/styling"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import Button from "components/forms/Button"
import PluginConfirmDialog from "components/plugin/PluginConfirmDialog"
import { permissionsEnum } from "lib/constants"

function ContactChannels({ contact, ...props }) {
    const operatorStore = useStore(operator)
    const { mergeUsers } = useStore(inbox)
    const { workspace } = useStore(session)
    const { addModal, removeModal } = useStore(general)
    return (
        <div className="contact-conversations">
            {contact.channels.map(x => {
                const operator = operatorStore.all.find(o => o.id == x.Call.operatorId) || {}

                return (
                    <div className="contact-call-card-wrapper" key={x.customerPlatformId}>
                        <NavLink
                            className="contact-call-card channels"
                            to={`/inbox/all/${x.customerPlatformId}`}
                        >
                            <div className="more-left">
                                <img src={channelsTypes[x.platformId].image} alt="" />
                            </div>
                            <div className="left">
                                <div className="top" title="Canal">
                                    {x.description}
                                </div>
                                <div className="bottom" title="Agente em atendimento">
                                    Atend: {operator.name || ""}
                                </div>
                            </div>
                            {
                                <div
                                    className={x.Call.isFinished ? "right finished" : "right"}
                                    title={
                                        x.Call.isFinished ? "Conversa resolvida" : "Conversa aberta"
                                    }
                                >
                                    <i
                                        className={getIconClassName(
                                            x.Call.isFinished ? "CheckMark" : "Chat"
                                        )}
                                    />
                                </div>
                            }
                        </NavLink>
                        {x.pendingMerge && (
                            <div
                                className="bottom merge-btn"
                                title="Mesclar esse usuário com usuário atual"
                            >
                                <Button
                                    primary
                                    full
                                    onClick={() => {
                                        addModal(
                                            <PluginConfirmDialog
                                                title="Mesclar usuário"
                                                message={
                                                    <p>
                                                        Esse usuário selecionado será unificado com
                                                        o usuário selecionado. Essa operação não
                                                        poderá ser desfeita. Você confirma?
                                                        {workspace.accessLevel <
                                                            permissionsEnum.MASTER && (
                                                            <p>
                                                                <br />
                                                                ESSA AÇÃO SOMENTE PODERÁ SER FEITA
                                                                COMO MASTER
                                                            </p>
                                                        )}
                                                    </p>
                                                }
                                                okTitle="Confirmar"
                                                onClick={async confirmed => {
                                                    if (confirmed) {
                                                        console.log("Usuario mesclado", contact, x)
                                                        await mergeUsers(
                                                            contact.id,
                                                            x.customerPlatformId
                                                        )
                                                        removeModal()
                                                    }
                                                    removeModal()
                                                }}
                                                okBtnProps={{
                                                    loader: "merging",
                                                    disabled:
                                                        workspace.accessLevel <
                                                        permissionsEnum.MASTER
                                                }}
                                            />,
                                            {
                                                style: { width: 490 }
                                            }
                                        )
                                    }}
                                >
                                    Mesclar
                                </Button>
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default ContactChannels
