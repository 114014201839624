const MAX_STORAGE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

let db;

const dbName = 'assetDB';
const dbVersion = 1;
const request = indexedDB.open(dbName, dbVersion);

request.onerror = function (event) {
    console.error('Error opening IndexedDB:', event.target.errorCode);
};

request.onupgradeneeded = function (event) {
    const db = event.target.result;

    // Create the object store if it doesn't exist
    if (!db.objectStoreNames.contains('assets')) {
        db.createObjectStore('assets', { keyPath: 'id' }); // Replace 'id' with your keyPath
    }
};

request.onsuccess = function (event) {
    db = event.target.result;
    console.log('IndexedDB opened successfully');
};

export async function cacheMessageAssets(messages) {
    const cachedMessages = []

    for (const message of messages) {
        const cachedContent = await parseContent(message);
        message.content = JSON.stringify(cachedContent)

        let cachedReply = null
        if (message.replyTo) {
            cachedReply = await parseReply(message);
            message.replyTo = JSON.stringify(cachedReply)
        }
        cachedMessages.push(message)
    }
    return cachedMessages
}

async function parseReply(message) {
    let parsedReply = JSON.parse(message.replyTo);
    const cachedReply = [];
    for (const { insert } of parsedReply) {
        let mediaType = null
        if (typeof insert === 'object' && insert !== null) {
            mediaType = Object.keys(insert)[0]
        }

        if (insert && insert[mediaType]) {
            let blob
            const cachedAsset = await retrieveAssets(`${message.id}-${message.Call ? message.Call.id : message.callId}`)
            if (cachedAsset) {
                blob = cachedAsset
            } else {
                blob = await fetchAssetsAsBlob(insert[mediaType]);
                if (blob) saveAssets(`${message.id}-${message.Call ? message.Call.id : message.callId}`, blob);
            }
            const imageUrl = URL.createObjectURL(blob);
            insert[mediaType] = imageUrl;
            cachedReply.push({ insert });
        } else {
            cachedReply.push({ insert });
        }
    }
    return cachedReply;
}

async function parseContent(message) {
    let parsedContent = JSON.parse(message.content);
    const cachedContent = [];
    for (const { insert } of parsedContent) {
        
        let mediaType = null
        if (typeof insert === 'object' && insert !== null) {
            mediaType = Object.keys(insert)[0]
        }

        if (insert && insert[mediaType]) {
            let blob
            const cachedAsset = await retrieveAssets(`${message.id}-${message.Call ? message.Call.id : message.callId}`)
            if (cachedAsset) {
                blob = cachedAsset
            } else {
                blob = await fetchAssetsAsBlob(insert[mediaType]);
                if (blob) saveAssets(`${message.id}-${message.Call ? message.Call.id : message.callId}`, blob);
            }
            const imageUrl = URL.createObjectURL(blob);
            insert[mediaType] = imageUrl;
            cachedContent.push({ insert });
        } else {
            cachedContent.push({ insert });
        }
    }
    return cachedContent;
}

export async function saveAssets(assetId, assetBlob) {
    const transaction = db.transaction(['assets'], 'readwrite');
    const store = transaction.objectStore('assets');
    store.add({ id: assetId, asset: assetBlob });
}

export async function retrieveAssets(assetId) {
    const transaction = db.transaction(['assets'], 'readonly');
    const store = transaction.objectStore('assets');

    try {
        const assetData = await new Promise((resolve, reject) => {
            const request = store.get(assetId);

            request.onsuccess = function (event) {
                const result = event.target.result;
                resolve(result ? result.asset : null);
            };

            request.onerror = function (event) {
                reject(event.target.error);
            };
        });

        if (assetData) {
            return assetData;
        } else {
            return null;
        }
    } catch (error) {
        console.log('Error retrieving asset:', error);
        return null;
    }
}

export async function fetchAssetsAsBlob(url) {
    try {
        const response = await fetch(url);
        return await response.blob();
    } catch (error) {
        console.error('Error fetching asset:', error);
    }
}