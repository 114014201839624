import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { getRoutes } from "components/Router"
import { session, useStore } from "store"

export default function UserLayout(props) {
    const { user } = useStore(session)

    // useEffect(() => {
    //     try {
    //         console.log('MOSTRANDO customerchar')
    //         window.FB.CustomerChat.show()
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }, [])

    const isLogout =
        props.location.pathname === "/user/logout" || props.location.pathname === "/user/logout/"
    if (!isLogout && user.email) {
        return <Redirect to="/" />
    }
    return <>{getRoutes(props.routes, props.location)}</>
}
