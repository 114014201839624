import React from "react"
import SideItem from "./SideItem"
import { getIconClassName } from "@uifabric/styling"

function ReportsItems() {
    const items = [
        {
            name: "Resumo de hoje",
            to: "/reports/overview",
            // icon: "fa fa-heart-rate"
            icon: getIconClassName("LineChart")
        }
        // {
        //     name: "Operadores",
        //     to: "/reports/team",
        //     icon: "fa fa-stopwatch"
        // },
    ]

    const reports = [
        {
            name: "Conversas",
            to: "/reports/conversations",
            icon: getIconClassName("ReportDocument")
            // icon: "fa fa-file-chart-line"
        },
        {
            name: "Tags",
            to: "/reports/tags",
            icon: getIconClassName("Tag")
            // icon: "fa fa-file-chart-line"
        },
        {
            name: "Contatos",
            to: "/reports/customers",
            icon: getIconClassName("UserOptional")
            // icon: "fa fa-file-chart-line"
        }
    ]

    return (
        <div className="settings-side-items">
            <div className="sidebar-section-title" style={{ marginTop: 7 }}>
                {/* Conta */}
            </div>
            {items.map(x => (
                <SideItem {...x} key={x.name} />
            ))}
            <div className="sidebar-section-title" style={{ marginTop: 7 }}>
                Por período
            </div>
            {reports.map(x => (
                <SideItem {...x} key={x.name} />
            ))}
        </div>
    )
}

export default ReportsItems
