import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import Button from "components/forms/Button"
import Label from "components/forms/Label"
import { getIconClassName } from "@uifabric/styling"

function Dropzone({
    files,
    setFiles,
    label,
    accept,
    icon,
    iconStyle,
    description,
    disabledIsDragReject,
    multiple
}) {
    const onDrop = useCallback(
        acceptedFiles => {
            setFiles([...files, ...acceptedFiles])
        },
        [files]
    )
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open,
        isDragReject,
        isDragAccept
    } = useDropzone({
        onDrop,
        accept,
        multiple
    })

    const style1 = files.length ? { alignItems: "flex-start", padding: 0, border: 0 } : {}
    const style2 = isDragAccept
        ? { borderColor: "#2ecc71", backgroundColor: "#ecf8f1" }
        : {}
    const style3 = isDragReject
        ? { borderColor: "#367fee" }
        // ? { borderColor: "#367fee", backgroundColor: "#fff4f3" }
        : {}

    // console.log(isDragAccept, isDragReject)

    return (
        <div>
            {label && <Label style={{ paddingBottom: 7 }}>{label}</Label>}
            <div
                {...getRootProps({
                    onClick: e => e.stopPropagation()
                })}
                className="dropzone"
                style={{ ...style1, ...style2, ...style3 }}
            >
                <input {...getInputProps()} />

                {!files.length && (
                    <div className="drag-drop">
                        {isDragReject && !disabledIsDragReject ? (
                            <i
                                className="fa fa-ban"
                                style={{ color: "#e74c3c" }}
                            />
                        ) : (
                            <i
                                className={getIconClassName(icon)}
                                style={
                                    isDragAccept
                                        ? { ...iconStyle, color: "#2ecc71" }
                                        : { ...iconStyle }
                                }
                            />
                        )}
                        {/* {isDragReject ? (
                            <h1>Formato inválido</h1>
                        ) : (
                            <h1>Arraste & Solte</h1>
                        )} */}
                        <span style={{ textAlign: "center" }}>
                            {description}, <br />
                            ou{" "}
                            <a
                                onClick={open}
                                style={{
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                            >
                                browse.
                            </a>
                        </span>
                    </div>
                )}
                {/* {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
            )} */}
                {!!files.length && (
                    <div className="drop-rows">
                        {files.map((file, i) => (
                            <div className="drop-row" key={i}>
                                <i className={getIconClassName(icon)} />
                                <div className="details">
                                    <span>{file.name}</span>
                                    <span>
                                        {(file.size / 1024).toFixed(1)} KB
                                    </span>
                                </div>
                                <Button
                                    icon="fal fa-trash-alt"
                                    iconStyle={{ color: "rgba(0,0,0,0.3)" }}
                                    round
                                    onClick={() =>
                                        setFiles(
                                            files.filter(
                                                (c, index) => i !== index
                                            )
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dropzone
