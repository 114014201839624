import config from "config"
import { useStore, initStore, stores } from "nanui"

import fromBase from "stores/baseStore"
import sessionStore from "stores/sessionStore"
import generalStore from "stores/generalStore"
import botStore from "stores/botStore"
import messageStore from "stores/messageStore"
import inboxStore from "stores/inboxStore"
import paymentStore from "stores/paymentStore"
import dashboardStore from "stores/dashboardStore"
import channelStore from "stores/channelStore"
import segmentStore from "stores/segmentStore"
import apiTokenStore from "stores/apiTokenStore"
import reportStore from "stores/reportStore"
import reportCustomerStore from "stores/reportCustomerStore"
import reportTagsStore from "stores/reportTagsStore"
import campaignStore from "stores/campaignStore"

export const general = generalStore
export const session = sessionStore
export const bot = botStore
export const inbox = inboxStore
export const payment = paymentStore
export const message = messageStore
export const dashboard = dashboardStore
export const channel = channelStore
export const segment = segmentStore
export const apiToken = apiTokenStore
export const report = reportStore
export const reportCustomer = reportCustomerStore
export const reportTags = reportTagsStore
export const campaign = campaignStore
// export const segment = fromBase(config.api.SEGMENTS)
// export const favorite = fromBase(config.api.FAVORITES)
export const customer = fromBase(config.api.CUSTOMER)
export const contract = fromBase(config.api.CONTRACT)
export const company = fromBase(config.api.COMPANY)
export const operator = fromBase(config.api.OPERATOR)
export const group = fromBase(config.api.GROUP)
export const label = fromBase(config.api.LABEL)
export const tag = fromBase(config.api.LABEL)
export const quickPhrase = fromBase(config.api.QUICK_PHRASES)
export const webhook = fromBase(config.api.WEBHOOK)
export const extension = fromBase(config.api.EXTENSION)
export const businessHours = fromBase(config.api.BUSINESS_HOURS)
export const whatsapp = fromBase(config.api.WHATSAPP)
export const facebook = fromBase(config.api.FACEBOOK)
export const telegram = fromBase(config.api.TELEGRAM)
export const instagram = fromBase(config.api.INSTAGRAM)
export const custom = fromBase(config.api.CUSTOM)
export const widget = fromBase(config.api.WIDGET)
export const email = fromBase(config.api.EMAIL)
export const dashboardAgents = fromBase(config.api.DASHBOARD_AGENTS)
export const reportGeneralVolume = fromBase(config.api.REPORT)
export const powerUps = fromBase(config.api.POWER_UPS)
export const plugin = fromBase(config.api.PLUGIN)
export const assignmentRules = fromBase(config.api.ASSIGNMENT_RULES)
export const workspaceStore = fromBase(config.api.WORKSPACE)
export const medias = fromBase(config.api.CUSTOMER_MEDIAS)

export const STORES = {
    general,
    segment,
    // favorite,
    session,
    contract,
    assignmentRules,
    customer,
    message,
    inbox,
    company,
    operator,
    group,
    tag,
    webhook,
    extension,
    bot,
    businessHours,
    dashboard,
    dashboardAgents,
    whatsapp,
    instagram,
    facebook,
    telegram,
    email,
    custom,
    widget,
    report,
    reportCustomer,
    reportTags,
    payment,
    powerUps,
    quickPhrase,
    channel,
    plugin,
    apiToken,
    workspaceStore,
    campaign,
    medias
}

const Provider = initStore(STORES)
export { useStore, Provider, stores }
