import config from "config"
import fromBase from "stores/baseStore"

function channelStore(store) {
    return {
        ...store,
    }
}

export default fromBase(config.api.CHANNEL, channelStore)
