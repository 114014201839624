import React, { useState, useMemo } from "react"
import { Link, Redirect } from "react-router-dom"
import LandingHeader from "./LandingHeader"
import wppImg from "assets/images/wpp.png"
import LandingFooter from "./LandingFooter"
import LandingRegisterSection from "./LandingRegisterSection"
import cx from "classnames"
import { useField, useScrollTop } from "hooks"

function TermsSection({ children, title }) {
    return (
        <div className="terms-section">
            <h2>{title}</h2>
            {children}
        </div>
    )
}

export default function Privacy(props) {
    useScrollTop(props.location)

    return (
        <div className="landing-space">
            <LandingHeader white />
            <div className="landing-section">
                <div className="midcol">
                    <div className="center">
                        <h1 style={{ fontSize: 50, marginBottom: 10 }}>Política de Privacidade</h1>
                    </div>
                </div>
            </div>
            <div className="landing-section">
                <div className="midcol" style={{ maxWidth: 800 }}>
                    <TermsSection>
                        <p>
                            A KimoChat respeita a privacidade dos usuários de seu site e garante que
                            as informações pessoais que você fornecer sejam tratadas
                            confidencialmente. Usamos seus dados para processar pedidos o mais
                            rápido e fácil possível. Além disso, usaremos seus dados somente com sua
                            permissão.
                        </p>
                        <p>
                            A KimoChat não venderá suas informações pessoais a terceiros e só
                            compartilhará as informações com terceiros envolvidos no processamento
                            de seu pedido.
                        </p>
                    </TermsSection>
                    <TermsSection title="Dados coletados">
                        <p>
                            A KimoChat usa os dados coletados para fornecer a seus clientes os
                            seguintes serviços:
                        </p>
                        <p>
                            Para navegar no site da KimoChat da forma mais agradável possível,
                            salvamos suas informações e dados pessoais (com sua permissão) com
                            relação aos seus hábitos de navegação e ao uso de nossos serviços. Isso
                            nos permite personalizar o site e fazer ofertas direcionadas além de
                            recomendações que você pode achar interessante.
                        </p>
                        <p>
                            Com sua permissão, usamos seus dados para informá-lo sobre o
                            desenvolvimento do site, ofertas especiais e promoções. Se você não
                            deseja mais receber essas informações, entre em contato conosco enviando
                            um e-mail para contato@kimochat.com.
                        </p>
                        <p>
                            Se você criar uma conta KimoChat, salvaremos seus dados em um servidor
                            seguro. Em sua conta KimoChat, salvamos informações como nome, número de
                            telefone, endereço de e-mail, informações de entrega e pagamento, para
                            que você não precise preencher suas informações a cada visita.
                        </p>
                        <p>
                            Os dados sobre o uso de nosso site e o feedback que recebemos de nossos
                            visitantes nos ajudam a melhorar nosso site para desenvolver e melhorar
                            ainda mais o serviço. As promoções da KimoChat podem exigir seus dados
                            pessoais, como seu nome, endereço e endereço de e-mail. Usamos essas
                            informações para realizar nossos esforços de marketing e promocionais.
                        </p>
                    </TermsSection>
                    <TermsSection title="KimoChat não venderá suas informações">
                        <p>
                            A KimoChat não venderá suas informações pessoais a terceiros e só se
                            comunicará com terceiros envolvidos na execução de sua inscrição. Nossos
                            funcionários e nossos terceiros são obrigados a proteger a
                            confidencialidade de suas informações pessoais.
                        </p>
                    </TermsSection>
                    <TermsSection title="Suas informações pessoais estão seguras com KimoChat">
                        <p>
                            Agradecemos a confiança que você tem em nós e seremos extremamente
                            cuidadosos com seus dados. Quando você faz um pedido ou acessa sua conta
                            KimoChat, a tecnologia Secure Socket Layer protege seus dados. Seus dados
                            serão sempre protegidos e mantidos em sigilo.
                        </p>
                    </TermsSection>
                    <TermsSection title="Cookies">
                        <p>
                            Cookies são pequenas informações que seu navegador salva em seu
                            computador. KimoChat usa cookies para reconhecê-lo em sua próxima visita.
                            Os cookies nos permitem coletar informações sobre o uso de nossos
                            serviços e melhorar e adaptar-se às necessidades de nossos visitantes.
                            Nossos cookies fornecem informações relacionadas à identificação
                            pessoal. Você pode configurar seu navegador para visitar KimoChat sem
                            receber cookies.
                        </p>
                    </TermsSection>
                    <TermsSection>
                        <p>
                            Se você tiver alguma dúvida sobre a Política de Privacidade da KimoChat,
                            envie um e-mail para contato@kimochat.com. Nosso serviço ao cliente
                            ajudará se você precisar de informações sobre seus dados ou se quiser
                            alterá-los. Se uma mudança de nossa política de privacidade for
                            necessária, você encontrará as informações mais recentes nesta página.
                        </p>
                    </TermsSection>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="landing-separator" />
                <LandingFooter />
            </div>
        </div>
    )
}
