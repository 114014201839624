import React, { useState } from "react"
import { SettingsEnum } from "pages/settings/Settings"
import Modal from "components/Modal"
import PageTable from "pages/PageTable"
import EditFacebook from "./EditFacebook"
import PagesModal from "./PagesModal"
import { facebook } from "store"
import { FacebookProvider, Login } from "react-facebook"
import Button from "components/forms/Button"
import toaster from "lib/toaster"
import PageHeader from "components/PageHeader"
import TopHeader from "components/TopHeader"

// function getStatus(status) {
//     switch (status) {
//         case "connected":
//             return {
//                 title: "Conectado",
//                 bg: "rgba(46,204,113, 0.3)"
//             }
//         case "disconnected":
//             return { title: "Desconectado", bg: "rgba(243,156,18, 0.3)" }
//         // case "formalized":
//         //     return {
//         //         title: "Formalizado",
//         //         bg: "rgba(46,204,113, 0.3)"
//         //     }
//         // case "rejected":
//         //     return { title: "Rejeitado", bg: "rgba(243,156,18, 0.3)" }
//         // case "processed":
//         //     return { title: "Aguardando Formalização", bg: "" }
//         // case "overwritten":
//         //     return { title: "Substituido", bg: "rgba(155,89,182, 0.3)" }
//         default:
//             return ""
//     }
// }

const columns = () => [
    {
        Header: "",
        accessor: "name",
        Cell: props => (
            <span className="vertical-center">
                <img className="cell-avatar" src={props.row._original.avatar} alt="" />
                {/* <i
                    className="fab fa-facebook-f"
                    style={{ marginRight: 20, fontSize: 16 }}
                /> */}
                {props.value}
            </span>
        )
    }
    // {
    //     Header: "Número",
    //     accessor: "phone",
    //     width: 160,
    //     Cell: props => masks.cell(props.value)
    // },
    // {
    //     Header: "Status",
    //     accessor: "status",
    //     width: 160,
    //     Cell: props => (
    //         <span
    //             className="contract-status"
    //             style={{ backgroundColor: getStatus(props.value).bg }}
    //         >
    //             {getStatus(props.value).title}
    //         </span>
    //     )
    // },
    // {
    //     Header: "Conectar",
    //     accessor: "id",
    //     width: 160,
    //     Cell: props => (
    //         <Button
    //             gray
    //             ios
    //             icon="far fa-barcode-read"
    //             style={{ width: 130, height: 41 }}
    //             onClick={e => {
    //                 e.stopPropagation()
    //                 showQRCode(props.value)
    //             }}
    //         >
    //             Conectar
    //         </Button>
    //     )
    // }
]

export default function Facebook(props) {
    const [modalPages, setModalPages] = useState()

    function handleResponse(data) {
        setModalPages({ accessToken: data.tokenDetail.accessToken })
    }

    function handleError(error) {
        toaster.warn(error.message)
        console.log(error)
    }

    return (
        <FacebookProvider appId="406703890178760">
            <TopHeader backTo="/power-ups" breadcrumb="Power-Ups" />
            <PageHeader
                title="Facebook"
                image="https://simply-communicate.com/wp-content/uploads/2019/03/facebook-2661207_960_720.jpg"
                description="Veja e responda mensagens do Facebook na sua caixa de entrada."
                afterDescription={
                    <span className="integration-status">
                        <i className="fa fa-check-circle" />
                        Integrado
                    </span>
                }
                action={
                    <Login
                        scope="manage_pages,pages_messaging"
                        onCompleted={handleResponse}
                        onError={handleError}
                    >
                        {({ loading, handleClick, error, data }) => (
                            <Button
                                gray
                                icon="fab fa-facebook-f"
                                onClick={handleClick}
                                isLoading={loading}
                            >
                                Conectar ao Facebook
                            </Button>
                        )}
                    </Login>
                }
            />
            <div className="page-inner">
                <PageTable
                    storeRef={facebook}
                    title="Páginas conectadas"
                    columns={columns()}
                    EditComponent={EditFacebook}
                    nodata={{
                        title: "Parece que você ainda não conectou nenhuma página..."
                    }}
                    {...props}
                />
            </div>
            {/* <ManagerBase
                storeRef={facebook}
                title="Facebook"
                headerDescription="Responda suas páginas do Facebook direto da sua caixa de entrada"
                headerImage={SettingsEnum.FACEBOOK}
                customButton={
                    
                }
                columns={columns()}
                EditComponent={EditFacebook}
                nodata={{
                    title:
                        "Parece que você ainda não adicionou nenhuma página...",
                    buttonText: "Conectar nova página"
                }}
                {...props}
            /> */}
            {modalPages && (
                <Modal onBack={() => setModalPages(null)}>
                    <PagesModal onBackModal={() => setModalPages(null)} item={modalPages} />
                </Modal>
            )}
        </FacebookProvider>
    )
}
