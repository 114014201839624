import React, { useMemo } from "react"
import Grid from "@material-ui/core/Grid"
import { NavLink, Switch, Route } from "react-router-dom"
import Subheader from "components/Subheader"
import FilterTag from "components/FilterTag"
import ReportFilterDropdown from "./ReportFilterDropdown"
import ReportProductivity from "./ReportProductivity"
import ReportSessionsGraph from "./ReportSessionsGraph"
import ReportGeneralTable from "./ReportGeneralTable"
import TopHeader from "components/TopHeader"
import { useBreadcrumb } from "hooks"
import FilterHeader from "components/FilterHeader"
import { typeFilters } from "lib/constants"
import { report, useStore } from "store"
import Table from "components/Table"
import Button from "components/forms/Button"
import { getHoursAndMinutes } from "lib/helper"

const filters = [[
    {
        type: typeFilters.MORE,
        name: "Filtrar por",
        position: "right",
        subfilters: [
            { type: typeFilters.PERIOD },
            { type: typeFilters.SELECT_FREQUENCY },
            { type: typeFilters.SELECT_GROUP }
        ]
    }
]]

function ReportGeneral(props) {
    useBreadcrumb({ title: "Relatórios" })
    const reportGeneralStore = useStore(report)
    const all = reportGeneralStore.all

    const productivity = useMemo(
        () => [
            {
                id: "Tempo de resolução",
                color: "hsl(86, 70%, 50%)",
                data: reportGeneralStore.all.map(x => ({
                    x: x.label,
                    y: x.resolutionTime
                }))
            },
            {
                id: "Tempo de resposta",
                color: "hsl(86, 70%, 50%)",
                data: reportGeneralStore.all.map(x => ({
                    x: x.label,
                    y: x.timeResponse
                }))
            },
            {
                id: "Tempo de primeira resposta",
                color: "hsl(86, 70%, 50%)",
                data: reportGeneralStore.all.map(x => ({
                    x: x.label,
                    y: x.firstTimeResponse
                }))
            }
        ],
        [all]
    )

    const volume = useMemo(
        () => [
            {
                id: "Conversas resolvidas",
                color: "hsl(321, 70%, 50%)",
                data: reportGeneralStore.all.map(x => ({
                    x: x.label,
                    y: x.resolvedConversations
                }))
            },
            {
                id: "Novas Conversas",
                color: "hsl(321, 70%, 50%)",
                data: reportGeneralStore.all.map(x => ({
                    x: x.label,
                    y: x.newConversations
                }))
            }
        ],
        [all]
    )

    const columns = useMemo(
        () => [
            {
                Header: "Período",
                accessor: "labelLong"
            },
            {
                Header: "Tempo de primeira resposta",
                accessor: "firstTimeResponse",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Tempo de resposta",
                accessor: "timeResponse",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Tempo de atendimento",
                accessor: "resolutionTime",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Novas conversas",
                accessor: "newConversations"
            },
            {
                Header: "Conversas resolvidas",
                accessor: "resolvedConversations"
            }
        ],
        [reportGeneralStore.all]
    )

    return (
        <div className="page-layout has-subheader reports">
            <TopHeader breadcrumb="Relatórios" bordered />
            <FilterHeader
                filters={filters}
                pathname={props.match.url}
                history={props.history}
                location={props.location}
            />

            {/* <Subheader>
                <ReportFilterDropdown />
                <FilterTag>01/06/2018 - 08/08/2018</FilterTag>
                <FilterTag onRemove={() => {}}>faturamento</FilterTag>
            </Subheader> */}
            <div className="page-inner">
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <ReportProductivity height={300} data={productivity} />
                    </Grid>
                    <Grid item xs={6}>
                        <ReportSessionsGraph height={300} data={volume} />
                    </Grid>
                </Grid>
                <Table
                    store={reportGeneralStore}
                    columns={columns}
                    query={props.location.search}
                    limit={20}
                    defaultPageSize={10}
                    // loader={"/" + endpoint}
                    history={props.history}
                    // getTrProps={(state, rowInfo) => ({
                    //     onClick: () => {
                    //         props.history.push("/settings/members/" + rowInfo.original.id)
                    //     }
                    // })}
                    nodata={{
                        title: "Parece que você ainda não possui nenhuma estatistica..."
                    }}
                />
            </div>
        </div>
    )
}

export default ReportGeneral
