import React, { useEffect, useCallback, useState, useRef, useMemo } from "react"
import { NavLink } from "react-router-dom"
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html"
import Button from "components/forms/Button"
import ChatTextEditor from "./ChatTextEditor"
import Message from "./Message"
import AttachFileModal from "./AttachFileModal"
import DaySeparator from "./DaySeparator"
import Select from "components/forms/Select"
import AssignAgentDrop from "components/inbox/AssignAgentDrop"
import AssignGroupDrop from "components/inbox/AssignGroupDrop"
import AssignBotDrop from "components/inbox/AssignBotDrop"
import ChatActionsDrop from "components/chat/ChatActionsDrop"
import ResolveModal from "components/chat/ResolveModal"
import TagDrop from "components/chat/TagDrop"
import ChatActionIco from "components/inbox/ChatActionIco"
import Loader from "components/Loader"
import Modal from "components/Modal"
// import SimpleBar from "simplebar-react"
import Scrollbar from "react-scrollbars-custom"
import { isSameDay } from "lib/dates"
import { useDropzone } from "react-dropzone"
import { stores, general, session, inbox, useStore } from "store"
import { messagesTypes } from "lib/constants"
import TagBox from "components/chat/TagBox"
import { useInfiniteScroll } from "hooks"
import Loading from "components/Loading"
import cx from "classnames"
import { channelsTypesEnum } from "lib/constants"
// import archiveSvg from "assets/icons/svg/archive.png"
// import unarchiveSvg from "assets/icons/svg/unarchive.png"
// import starSvg from "assets/icons/svg/star.png"
// import starActiveSvg from "assets/icons/svg/star_active.png"
// import moveSvg from "assets/icons/svg/move.png"
import { getIconClassName } from "@uifabric/styling"
import ContextHeader from "components/ContextHeader"
import ChatSide from "components/chat/side/ChatSide"
import ChatSideNav from "components/chat/side/ChatSideNav"
import Viewer from 'react-viewer';

function getAuthor(msg) {
    if (msg.type === messagesTypes.BOT) return "bot"
    if ((!msg.operatorId || msg.sender) && (msg.type === messagesTypes.COMMON || msg.type === messagesTypes.EMAIL))
        return msg.sender || 'customer'
    if (msg.operatorId && (msg.type === messagesTypes.COMMON || msg.type === messagesTypes.PRIVATE))
        return "agent"
    return "system"
}

function isValidURL(string) {
    const colonRegex = /:(?!\/\/)/;
    if (colonRegex.test(string)) {
        return false;
    }

    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;  
    }
}

function splitStringByUrls(string) {
    const parts = [];
    const words = string.split(' ');

    words.forEach((word, index) => {
        if (isValidURL(word)) {
            if (!word.startsWith('http://') && !word.startsWith('https://')) {
                word = 'https://' + word;
            }
            parts.push({ insert: word, attributes: { link: word, target: '_blank' } });
        } else {
            parts.push({ insert: word });
        }

        // Add a space after each word except the last one
        if (index < words.length - 1) {
            parts.push({ insert: ' ' });
        }
    });

    return parts;
}

function Chat({ viewOnly, ...props }) {
    const inboxStore = useStore(inbox)
    const { isExpired } = useStore(session)
    const dummyScrollTo = useRef()
    // const scrollRef = useRef()
    const [files, setFiles] = useState([])
    const [replyMessage, setReplyMessage] = useState()
    const {
        isFetching,
        setIsFetching,
        handleScroll,
        scrollHeight,
        containerRef,
    } = useInfiniteScroll(fetchMore)
    const { chatPanelOpen, addModal, removeModal } = useStore(general)

    const messageKey = props.callId ? "call-" + props.callId : props.id

    function fetchMore() {
        if (inboxStore.messages[messageKey]) {
            const count = inboxStore.messages[messageKey].data.length
            const matchCount = inboxStore.messages[messageKey].matchCount
            if (count < matchCount) {
                inboxStore
                    .getMessages({
                        data: {
                            id: props.id,
                            limit: 30,
                            offset: count,
                            callId: props.callId,
                        },
                        isNextPage: true,
                    })
                    .then(() => {
                        setIsFetching(false)
                    })
            } else {
                setIsFetching(false)
            }
        } else {
            setIsFetching(false)
        }
    }

    useEffect(() => {
        if (!isFetching && containerRef.current && containerRef.current.scrollTo) {
            if (containerRef.current && scrollHeight)
                // console.log('hhhh', containerRef.current.scrollHeight, scrollHeight)
                containerRef.current.scrollTo(0, containerRef.current.scrollHeight - scrollHeight)
            // setTimeout(() => {
            // }, 1000)
        }
    }, [isFetching])

    const inboxItem = useMemo(
        () => ({
            ...(inboxStore.customers[props.id] || {}),
            ...(inboxStore.all.find((x) => x.id == props.id) || {}),
        }),
        [props.id, inboxStore.all, inboxStore.customers, props.callId]
    )

    const messagesItem = useMemo(() => {
        return inboxStore.messages[messageKey] || { data: [] }
    }, [inboxStore.messages, inboxStore.messages[messageKey], props.id, props.callId])

    useEffect(() => {
        if (!messagesItem.data.length) {
            // const queries = props.query.callId ? { callId: props.query.callId } : {}
            inboxStore.getMessages({
                data: { id: props.id, limit: 30, callId: props.callId },
            })
        }
        if (inboxItem && inboxItem.id) {
            inboxStore.saveItem({ ...inboxItem, hasNewMessages: false })
        }
        setReplyMessage(null)
    }, [props.id, props.callId])

    const renderCustomWithCallback = function(customOp, contextOp) {
        let val = customOp.insert.value
        if (customOp.insert.type === "audio") {
            return `<audio id="${val}" src="${val}" controls> </audio>`
        }
        if (customOp.insert.type === "media_video") {
            return `<video id="${val}" width="300" height="168" controls><source src="${val}" type="video/mp4"> </video>`
        } else {
            const splitted = val.split("/")
            return `<div className="attach-file-item">
    <a href="${val}" style="text-decoration: underline">${splitted[splitted.length - 1]}</a>
</div>`
        }
    }

    const processedMsgs = useMemo(() => {
        return (
            ([...messagesItem.data].reverse() || [])
                .map((x) => {
                    if (x.replyTo && typeof x.replyTo === "string") {
                        return { ...x, replyTo: { content: x.replyTo } }
                    }
                    return x
                })
                // Converter pra delta
                .map((x) => {
                    let content = JSON.parse(x.content);
                
                    content = content.flatMap(item => {
                        if (typeof item.insert === 'string') {
                            let url = item.insert.trim();
                            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                                url = 'https://' + url;
                            }
                            if (isValidURL(url)) {
                                return splitStringByUrls(item.insert);
                            }
                        }
                        return item;
                    });
                
                    var converter = new QuillDeltaToHtmlConverter(content, {});
                    converter.renderCustomWith(renderCustomWithCallback);
                    var html = converter.convert();
                
                    if (x.replyTo) {
                        var replyContent = JSON.parse(x.replyTo.content);
                
                        replyContent = replyContent.flatMap(item => {
                            // Verificar se o conteúdo da resposta é um link
                            if (typeof item.insert === 'string') {
                                let url = item.insert.trim();
                                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                                    url = 'https://' + url;
                                }
                                if (isValidURL(url)) {
                                    return splitStringByUrls(item.insert);
                                }
                            }
                            return item;
                        });
                
                        var converterReply = new QuillDeltaToHtmlConverter(replyContent, {});
                        converterReply.renderCustomWith(renderCustomWithCallback);
                        var htmlReplyTo = converterReply.convert();
                        return { ...x, html, replyTo: { ...x.replyTo, content: JSON.stringify(replyContent) }, replyToHtml: htmlReplyTo };
                    }
                
                    return { ...x, html, content: JSON.stringify(content) };
                })
                // Processar se mostra status ou se é continuação da mensagem do usuário
                .reduce((acc, msg) => {
                    const prev = acc[acc.length - 1]
                    let message = msg
                    const prevAuthor = prev && getAuthor(prev)
                    const msgAuthor = getAuthor(msg)
                    if (prevAuthor === msgAuthor) {
                        const isAgent = msgAuthor === 'agent'
                        const isSameAgent = prev.operatorId === msg.operatorId

                        acc[acc.length - 1] = { ...prev, hideStatus: true }
                        return [...acc, { ...message, isSameUser: isAgent ? isSameAgent : true }]
                    }
                    return [...acc, message]
                }, [])
                // Colocar os divisores de dias
                .reduce((acc, msg) => {
                    const prev = acc[acc.length - 1]
                    const prevCreatedAt = prev && new Date(prev.createdAt)
                    const createdAt = new Date(msg.createdAt)
                    if (!prevCreatedAt || !isSameDay(createdAt, prevCreatedAt)) {
                        return [...acc, { type: "NEW_DAY", date: createdAt }, msg]
                    }
                    return [...acc, msg]
                }, [])
        )
        // })
    }, [
        messagesItem.data.length,
        JSON.stringify(messagesItem.data),
        inboxStore.messages[messageKey],
    ])


    useEffect(() => {
        if (dummyScrollTo.current) dummyScrollTo.current.scrollIntoView({ behavior: "auto" })
        var agentMessages = document.getElementsByClassName("agentMessage")
        if (agentMessages.length)
            agentMessages[agentMessages.length - 1].classList.add("last-agent")

        // Lógica de aumentar imagem
        var classname = document.getElementsByClassName("ql-image")
        var addImageModal = function() {
            var attributeSrc = this.getAttribute("src")
            addModal(
                <Viewer
                    visible
                    onClose={() => { removeModal() } }
                    changeable={false}
                    zoomSpeed={0.5}
                    images={[{src: attributeSrc, alt: ''}]}
                />,
                {}
            )
        }
        for (var i = 0; i < classname.length; i++) {
            classname[i].addEventListener("click", addImageModal, false)
        }
    }, [messagesItem.data.length, processedMsgs])

    const onDrop = useCallback(
        (acceptedFiles) => {
            console.log(acceptedFiles)
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
        [files]
    )

    function onResolve() {
        inboxStore.toggleResolved(props.id, inboxItem.isFinished ? "open" : "resolve", inboxItem)
    }

    function onPin() {
        inboxStore.togglePin(inboxItem)
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open,
        isDragReject,
        isDragAccept,
    } = useDropzone({
        onDrop,
    })

    const actions = useMemo(() => {
        return [
            {
                name: inboxItem.isFinished ? "Reabrir" : "Resolver",
                icon: inboxItem.isFinished
                    ? getIconClassName("SkypeCircleCheck")
                    : getIconClassName("CheckMark"),
                iconStyle: inboxItem.isFinished ? { color: "#2ecc71" } : {},
                onClick: onResolve,
            },
            {
                name: inboxItem.starred ? "Desfavoritar" : "Favoritar",
                icon: inboxItem.starred
                    ? getIconClassName("FavoriteStarFill")
                    : getIconClassName("FavoriteStar"),
                iconStyle: inboxItem.starred ? { color: "#f1c40f" } : {},
                onClick: onPin,
            },
            {
                custom: <TagDrop inboxItem={inboxItem} />,
                key: "tags" + inboxItem.id,
            },
            {
                custom: <AssignAgentDrop inboxItem={inboxItem} />,
                key: "agents" + inboxItem.id,
            },
            {
                custom: <AssignGroupDrop inboxItem={inboxItem} />,
                key: "groups" + inboxItem.id,
            },
            {
                custom: <AssignBotDrop inboxItem={inboxItem} />,
                key: "bots" + inboxItem.id,
            },
        ]
    })

    return (
        <div className={cx("chat", { viewOnly })}>
            <ContextHeader
                // backAction={{ to: "/settings/channels" }}
                actions={actions}
                actionsEnd={
                    [
                        // {
                        //     icon: getIconClassName("Info"),
                        //     style: chatPanelOpen
                        //         ? { backgroundColor: "#dadada", justifyContent: "center", width: 48 }
                        //         : { justifyContent: "center", width: 48 },
                        //     iconStyle: chatPanelOpen ? { color: "#000000" } : {},
                        //     onClick: () => {
                        //         setChatPanelOpen(opened => !opened)
                        //     }
                        // }
                        // {
                        //     custom: <AssignAgentDrop inboxItem={inboxItem} />
                        // },
                        // {
                        //     custom: <AssignGroupDrop inboxItem={inboxItem} />
                        // },
                    ]
                }
            />
            {/* <section className="chat-header" style={{ backgroundColor: "white" }}>
                <div className="chat-header-inner">
                    <div className="top">
                        <div className="left">
                            <div className="actions">
                                <ChatActionIco
                                    icon={getIconClassName("SkypeCircleCheck")}
                                    description={inboxItem.isFinished ? "Reabrir" : "Resolver"}
                                    medium
                                    onClick={onResolve}
                                    className={inboxItem.isFinished ? "resolved" : ""}
                                />
                                <ChatActionIco
                                    icon={
                                        inboxItem.starred
                                            ? getIconClassName("FavoriteStarFill")
                                            : getIconClassName("FavoriteStarFill")
                                    }
                                    description="Favoritar"
                                    medium
                                    onClick={onPin}
                                    className={inboxItem.starred ? "favorited" : ""}
                                />
                                <TagDrop inboxItem={inboxItem} />
                                <div className="chat-header-separator" />
                                <div className="title-name">{inboxItem.name}</div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="actions">
                                <AssignAgentDrop inboxItem={inboxItem} />
                                <AssignGroupDrop inboxItem={inboxItem} />
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        {inboxItem.tags && !!inboxItem.tags.length && (
                            <TagBox inboxItem={inboxItem} />
                        )}
                    </div>
                </div>
            </section> */}
            <div className="chat-inner-layout">
                <div className="left">
                    {inboxItem.tags && !!inboxItem.tags.length && (
                        <div
                            style={{
                                padding: "10px 25px",
                                borderBottom: "1px solid #edebe9",
                                minHeight: 48,
                                boxSizing: "border-box",
                            }}
                        >
                            <TagBox inboxItem={inboxItem} />
                        </div>
                    )}
                    {inboxItem.isFinished && !props.protocol && (
                        <div className="closed-conversation-banner">
                            <i className="fal fa-check-circle" />
                            Essa conversa está fechada
                        </div>
                    )}
                    {props.protocol && (
                        <div className="closed-conversation-banner protocol">
                            <i className="fal fa-file-check" />
                            Protocolo: {props.protocol}
                        </div>
                    )}
                    <section className="chat-inner" onScroll={handleScroll} ref={containerRef}>
                        <div
                            className={cx("chat-box", {
                                chatWhatsapp: inboxItem.platformId == channelsTypesEnum.WHATSAPP,
                                chatEmail: inboxItem.platformId == channelsTypesEnum.EMAIL,
                            })}
                        >
                            <input {...getInputProps()} />
                            {isDragActive && (
                                <div className="chat-drag-active">
                                    <div className="chat-drag-active-inner">
                                        <h1>Arraste e solte os arquivos aqui</h1>
                                    </div>
                                </div>
                            )}
                            <Loader
                                loading={
                                    stores.general.loaders.includes(
                                        props.callId ? "get-message-call" : "get-message"
                                    ) && !isFetching
                                }
                                style={{ height: "100%", marginTop: 0 }}
                                size={28}
                            >
                                <div className={cx("messages", { isFetching })}>
                                    {/* <Scrollbar
                                        style={{ width: "100%", height: "100%" }}
                                        onScroll={handleScroll}
                                        ref={scrollRef}
                                        noScrollX={true}
                                    > */}
                                    <div className="messages-inner chat-midcol">
                                        {isFetching && (
                                            <Loading center style={{ marginTop: 30 }} size={28} />
                                        )}
                                        <br />
                                        <br />
                                        {processedMsgs.map((x, i) => {
                                            return x.type === "NEW_DAY" ? (
                                                <DaySeparator date={x.date} key={x.date + i} />
                                            ) : (
                                                <Message
                                                    message={x}
                                                    key={x.createdAt + i}
                                                    id={props.id}
                                                    setReplyMessage={setReplyMessage}
                                                    inboxItem={inboxItem}
                                                />
                                            )
                                        })}
                                        <div className="dummy" ref={dummyScrollTo} />
                                    </div>
                                    {/* </Scrollbar> */}
                                </div>
                            </Loader>
                            {!!files.length && (
                                <Modal onBack={() => setFiles([])}>
                                    <AttachFileModal
                                        files={files}
                                        inboxStore={inboxStore}
                                        id={props.id}
                                        onBack={() => setFiles([])}
                                    />
                                </Modal>
                            )}
                        </div>
                    </section>

                    {inboxItem.isFinished ? (
                        <div className="finished-chat-footer">
                            <Button primary type="button" onClick={onResolve}>
                                Reabrir conversa
                            </Button>
                        </div>
                    ) : (
                        !isExpired && (
                            <ChatTextEditor
                                id={props.id}
                                openFile={open}
                                inboxItem={inboxItem}
                                replyMessage={replyMessage}
                                setReplyMessage={setReplyMessage}
                            />
                        )
                    )}
                </div>
                {chatPanelOpen && (
                    <div className="right">
                        <ChatSide id={props.id} location={props.location} />
                        <ChatSideNav />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Chat
