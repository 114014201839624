import React, { Component } from "react"
import { observer } from "mobx-react"
import Grid from "@material-ui/core/Grid"
import Table from "components/Table"
import Button from "components/forms/Button"
import { NavLink, Link } from "react-router-dom"
import Caret from "components/Caret"

import store from "store"

const isActive = (search, match, location) => search === location.search.split("=")[0]

@observer
class ReportGeneralTable extends Component {
    // async componentDidMount() {
    //     if (!store.report.all.length) {
    //         await store.report.getAll()
    //     }
    // }

    getHeaders = () => {
        const columns = [
            {
                Header: (
                    <NavLink
                        to={{ search: "?sort_name=asc" }}
                        className="table-header"
                        isActive={isActive.bind(this, "?sort_name")}
                    >
                        <span>Data</span>
                        <Caret />
                    </NavLink>
                ),
                accessor: "date",
                resizable: false,
            },
            {
                Header: "Tempo de primeira resposta",
                accessor: "firstTimeResponse",
                resizable: false,
                sortable: false
            },
            {
                Header: "Tempo de resposta (Média)",
                accessor: "timeResponse",
                resizable: false,
                sortable: false
            },
            {
                Header: "Tempo de atendimento (Média)",
                accessor: "resolutionTime",
                resizable: false,
                sortable: false
            },
            {
                Header: "Novas conversas",
                accessor: "newSessions",
                resizable: false,
                sortable: false
            },
            {
                Header: "Conversas resolvidas",
                accessor: "resolvedSessions",
                resizable: false,
                sortable: false
            },
        ]
        return columns
    }

    render() {
        // const showPagination = store.report.matchCount > 20
        return (
            <>
            <div className="report-table-header">
                <h3>Desempenho Geral</h3>
                <Button primary>Exportar tudo</Button>
            </div>
            {/* <Table
                store={store.report}
                data={store.report.all}
                count={store.report.all.matchCount}
                columns={this.getHeaders()}
                defaultPageSize={20}
                history={this.props.history}
                location={this.props.location}
                showPagination={showPagination}
                loader="dashmembers"
                nodata={{
                    title: "Parece que você ainda não criou nenhum cliente...",
                    to: "/customers/new",
                    buttonText: "Criar Novo Cliente"
                }}
            /> */}
            </>
        )
    }
}

export default ReportGeneralTable
