import React from "react"
import cx from "classnames"

function MenuItem({ icon, iconStyle, name, onClick, selected, focused }) {
    return (
        <a
            className={cx("menu-drop-item", { selected, focused })}
            onClick={e => {
                e.stopPropagation()
                onClick()
            }}
        >
            <div className="menu-drop-item-inner">
                {icon && (
                    <span className="left">
                        <i className={icon} style={iconStyle} />
                    </span>
                )}
                <span className="name">{name}</span>
                {selected && <i className="fa fa-check selected-icon" />}
            </div>
        </a>
    )
}

export default MenuItem
