import React, { useState, useEffect } from "react"
import Text from "components/forms/Text"
import cx from "classnames"
// import Button from "components/forms/Button"
// import { useField, useForm } from "hooks"
// import DeleteDropdown from "components/DeleteDropdown"
import { getIconClassName } from "@uifabric/styling"
import { general, inbox, useStore } from "store"
import AddPropertyModal from "./AddPropertyModal"
import { checkPropTypes } from "prop-types"

function ContactProperty({ property, onSubmit, onDelete, fixed, addLabel, valueMask, valuePlaceholder }) {
    const { addModal, removeModal } = useStore(general)
    const { updateCustomer } = useStore(inbox)

    const [copied, setCopied] = useState(false)

    function copyToClipboard(str) {
        const el = document.createElement("textarea") // Create a <textarea> element
        el.value = str // Set its value to the string that you want copied
        el.setAttribute("readonly", "") // Make it readonly to be tamper-proof
        el.style.position = "absolute"
        el.style.left = "-9999px" // Move outside the screen to make it invisible
        document.body.appendChild(el) // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0 // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0) // Store selection if found
                : false // Mark as false to know no selection existed before
        el.select() // Select the <textarea> content
        document.execCommand("copy") // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el) // Remove the <textarea> element
        if (selected) {
            // If a selection existed before copying
            document.getSelection().removeAllRanges() // Unselect everything on the HTML document
            document.getSelection().addRange(selected) // Restore the original selection
        }
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 5000)
    }

    const copyButtonClass = cx("action-hover", {
        copied
    })

    function openEditModal() {
        addModal(
            <AddPropertyModal
                item={property}
                onSubmit={onSubmit}
                onDelete={onDelete}
                onBackModal={() => removeModal()}
                fixed={fixed}
                valueMask={valueMask}
                valuePlaceholder={valuePlaceholder}
            />
        )
    }

    return (
        <div className="contact-property">
            <div className="contact-property-inner">
                <div className="contact-property-label">{property.label}</div>
                <div className="contact-property-value">
                    {property.value || (
                        <a
                            className="add-prop"
                            style={{ color: "$primary-dark", marginTop: fixed ? 0 : 5 }}
                            onClick={openEditModal}
                        >
                            {fixed ? (
                                `Adicionar`
                            ) : (
                                <>
                                    <i className={getIconClassName("Add")} /> Novo
                                </>
                            )}
                        </a>
                    )}
                </div>
            </div>

            {property.value && (
                <div className="right">
                    <button
                        className={copyButtonClass}
                        onClick={() => copyToClipboard(property.value)}
                        style={{ right: 28 }}
                    >
                        <i className={getIconClassName("Copy")} />
                    </button>
                    <button className="action-hover" onClick={openEditModal}>
                        <i className={getIconClassName("Edit")} />
                    </button>
                </div>
            )}
        </div>
    )
}

export default ContactProperty
