import React from "react"
import { Link } from "react-router-dom"
import LandingHeader from "./LandingHeader"
import LandingFooter from "./LandingFooter"
import { useScrollTop } from "hooks"

export default function BasePlatform(props) {
    useScrollTop(props.location)
    return (
        <div className="landing-space base-platform-wrapper">
            <LandingHeader white />
            <div className="landing-section">
                <div className="midcol">
                    <div className="center">
                        <h1>{props.title}</h1>
                    </div>
                </div>
            </div>
            <div className="landing-section">
                <div className="midcol" style={{ maxWidth: 800 }}>
                    <div className="wrapper">
                        <img src={props.icon} alt="logo" />
                        <p>{props.description}</p>
                    </div>
                    <img className="wpp-inbox" src={props.banner} alt="plataforma e-mail kimochat" />
                </div>
                <div className="midcol">
                    <Link to={props.path} className="landing-button-connect">
                        Conhecer a Plataforma
                    </Link>
                </div>
                <div className="landing-section">
                    <div className={props.separator ? `midcol ${props.separator} flex column center` : 'midcol blue-box flex column center'} />
                </div>
                <div className="landing-separator" />
                <LandingFooter />
            </div>
        </div>
    )
}
