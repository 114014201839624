import React, { useState } from "react"
import FormDialog from "components/forms/FormDialog"
import Select from "components/forms/Select"
import { useSelectField, useForm } from "hooks"

function EditGoTo(props) {
    const actionItem = props.actionItem
    const goToOptions = props.item.paths.map(x => ({
        value: x.guid,
        label: x.name
    }))

    const form = useForm({
        onSubmit: async model => await props.onEditAction(model),
        defaultModel: { ...actionItem, type: "goto" }
    })

    const goto = useSelectField("goToPath", {
        defaultValue: actionItem.goToPath,
        form,
        options: goToOptions
    })

    return (
        <FormDialog
            {...props}
            title="Ir Para"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Select {...goto} placeholder="Ir para..." />
        </FormDialog>
    )
}
export default EditGoTo
