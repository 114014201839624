import React from "react"
import Tooltip from "components/Tooltip"

const HelpIcon = ({ description, marginLeft, maxWidth, style }) => (
    <Tooltip title={description} position="top" animation="shift" inertia animateFill={false} maxWidth={maxWidth}>
        <span className="help-icon" style={{ marginLeft: marginLeft || 5, ...style }}>
            <i className="far fa-question-circle" />
        </span>
    </Tooltip>
)

export default HelpIcon
