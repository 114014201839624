import React, { useState, useMemo } from "react"
import Tooltip from "components/Tooltip"
import cx from "classnames"
import { css, select as $ } from "glamor"
import { useStore, general, plugin } from "store"
import { getIconClassName } from "@uifabric/styling"

// import { useStore, general } from "store"



function ChatSideNav(props) {
    const { activeIntegration, setActiveIntegration } = useStore(general)
    const pluginStore = useStore(plugin)

    const navItems = [
        {
            id: "contact",
            type: "contact",
            title: "Contato",
            // icon: "fa fa-address-card",
            // icon: "material-icons-round",
            // iconMaterial: "account_box",
            icon: getIconClassName("Contact"),
            color: "#15acc0",
            iconStyle: { fontSize: 16 }
        }
    ]

    const items = useMemo(
        () =>
            [
                ...navItems,
                ...pluginStore.all.map(x => ({
                    type: "plugin",
                    id: x.id,
                    title: x.name,
                    iconLetter: x.name[0],
                    color: "rgb(242, 131, 11)",
                    iconStyle: { fontSize: 17 }
                }))
            ].map((x, i) => {
                const styles = css(
                    $("&:hover i", {
                        color: x.color
                    }),
                    // $("&.active:hover", {
                    //     transform: "scale(1.1)"
                    // }),
                    $("&.active", {
                        backgroundColor: x.color + " !important"
                    })
                    // $("&.active i", {
                    //     color: "#fff",
                    // })
                )
                return (
                    <Tooltip title={x.title} key={x.title + i} placement="left">
                        <a
                            className={cx("chat-side-nav-item", {
                                active: activeIntegration.id === x.id
                            })}
                            onClick={() =>
                                setActiveIntegration(integration =>
                                    integration.id === x.id ? false : x
                                )
                            }
                            {...styles}
                        >
                            {x.icon && (
                                <i className={x.icon} style={x.iconStyle}>
                                    {x.iconMaterial}
                                </i>
                            )}
                            {x.iconLetter && (
                                <i className="plugin-icon" style={x.iconStyle}>
                                    {x.iconLetter}
                                </i>
                            )}
                        </a>
                    </Tooltip>
                )
            }),
        [navItems, activeIntegration, pluginStore.all]
    )

    return <nav className={cx("chat-side-nav", { active: activeIntegration.id })}>{items}</nav>
}

export default ChatSideNav
