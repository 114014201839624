import React from "react"
import { SortableElement } from "react-sortable-hoc"
import Tooltip from "components/Tooltip"
import reactStringReplace from "react-string-replace"
import Button from "components/forms/Button"
import ClampLines from "react-clamp-lines"
import Grid from "@material-ui/core/Grid"
import botImg from "assets/images/bot.svg"
import userImg from "assets/images/user.svg"
import { inputGroupedOptions } from "./EditInput"
import { group, operator, tag, useStore } from "store"
import { useFetch } from "hooks"

const actionsInfo = {
    message: {
        icon: "far fa-comment",
        title: "Mensagem"
    },
    input: {
        icon: "fal fa-comment-edit",
        title: "Entrada de dados"
    },
    agent: {
        icon: "fal fa-headset",
        title: "Iniciar Atendimento"
    },
    menu: {
        icon: "fal fa-code-branch",
        title: "Menu"
    },
    goto: {
        icon: "fal fa-directions",
        title: "Ir para"
    },
    integration: {
        icon: "fal fa-plug",
        title: "Integração"
    },
    resolve: {
        icon: "fal fa-comment-check",
        title: "Finalizar conversa"
    },
    tag: {
        icon: "fal fa-tag",
        title: "Categorizar"
    }
}

const EventHeader = ({ action, url }) => {
    return (
        <div className="event-box-header">
            <div className="left">
                <i className={actionsInfo[action.type].icon} />
                {actionsInfo[action.type].title}
            </div>
            <Button
                icon="far fa-pen"
                round
                // onClick={() => onEdit(action)}
                to={url + "/" + action.type + "/" + action.guid}
                iconStyle={{ color: "rgba(0,0,0,0.5)", fontSize: 14 }}
            />
            {/* <span className="event-number">Edit</span> */}
        </div>
    )
}

const BotMessage = ({ children, noClamp }) => (
    <Grid container spacing={16}>
        <Grid item xs={1}>
            <img src={botImg} alt="" className="bot-image" />
        </Grid>
        <Grid item xs={11}>
            <div className="event-box-message">
                {noClamp ? (
                    <div style={{ whiteSpace: "pre-wrap" }}>{children}</div>
                ) : (
                    <ClampLines lines={8} moreText={null} text={children} />
                )}
            </div>
        </Grid>
    </Grid>
)

const UserMessage = ({ children }) => (
    <Grid container spacing={16} justify="flex-end">
        <Grid item xs={11}>
            <div className="event-box-message user">
                Usuário digita o seu {children.toLowerCase()}
            </div>
        </Grid>
        <Grid item xs={1}>
            <img src={userImg} alt="" className="bot-image user" />
        </Grid>
    </Grid>
)

const GoToPaths = ({ options, item }) => (
    <div className="event-options">
        {options.map(e => (
            <div style={{ marginBottom: 10 }} key={e.name} className="bot-menu-item">
                {/* <Tooltip
                    title={(item.paths.find(x => x.guid === e.goTo) || {}).name}
                    key={e.name + e.goTo}
                > */}
                <Button to={e.goTo}>{e.name}</Button>
                <span>
                    <i className="fa fa-long-arrow-alt-right" />{" "}
                    {(item.paths.find(x => x.guid === e.goTo) || {}).name}
                </span>
                {/* </Tooltip> */}
            </div>
        ))}
    </div>
)

const IntegrationText = ({ text }) => {
    const replacedText = reactStringReplace(text, /({{.+}})/g, (match, i) => (
        <span key={i} style={{ color: "#9b59b6", fontWeight: 800 }}>
            "{match.substring(2, match.length - 2)}"
        </span>
    ))
    return <span>{replacedText}</span>
}

const EventBox = SortableElement(props => {
    const groupStore = useStore(group)
    const operatorStore = useStore(operator)
    const tagStore = useFetch(tag)
    return (
        <div className="event-box">
            <EventHeader {...props} />
            <div className="box event-box-space">
                {/* <h4 className="event-title">Introduction to HTML and CSS</h4> */}

                {/* Text */}
                {props.action.type === "message" && (
                    <BotMessage noClamp>{props.action.text}</BotMessage>
                )}

                {/* Input */}
                {props.action.type === "input" && (
                    <>
                        <BotMessage noClamp>{props.action.text}</BotMessage>
                        <UserMessage>{props.action.inputPropName}</UserMessage>
                    </>
                )}

                {/* To Agent */}
                {props.action.type === "agent" && (
                    <div className="event-box-message system">
                        {props.action.assigneeType === "group"
                            ? `Conversa enviada ao grupo ${
                                  (groupStore.all.find(x => x.id == props.action.assignee) || {})
                                      .name
                              }`
                            : `Conversa enviada ao agente ${
                                  (operatorStore.all.find(x => x.id == props.action.assignee) || {})
                                      .name
                              }`}
                    </div>
                )}

                {/* Menu */}
                {props.action.type === "menu" && (
                    <>
                        <BotMessage noClamp>{props.action.text}</BotMessage>
                        <GoToPaths options={props.action.options} item={props.item} />
                    </>
                )}

                {/* Go To */}
                {props.action.type === "goto" && (
                    <div className="event-box-message system">
                        {`Encaminhar para ${
                            (props.item.paths.find(x => x.guid === props.action.goToPath) || {})
                                .name
                        }`}
                    </div>
                )}

                {/* Integration */}
                {props.action.type === "integration" && (
                    <>
                        <div className="event-box-message system" style={{ marginBottom: 10 }}>
                            <i className="far fa-plug" style={{ marginRight: 15 }} />
                            {props.action.name}
                        </div>
                        {props.action.text && (
                            <BotMessage noClamp>
                                <IntegrationText text={props.action.text} />
                            </BotMessage>
                        )}
                    </>
                )}

                {/* Resolve */}
                {props.action.type === "resolve" && (
                    <>
                        <div className="event-box-message system" style={{ marginBottom: 10 }}>
                            <i className="far fa-comment-check" style={{ marginRight: 15 }} />
                            Conversa finalizada
                        </div>
                        {props.action.text && <BotMessage>{props.action.text}</BotMessage>}
                    </>
                )}

                {/* Tag */}
                {props.action.type === "tag" && (
                    <>
                        <div className="event-box-message system" style={{ marginBottom: 10 }}>
                            <i className="far fa-tag" style={{ marginRight: 15 }} />
                            Categorizar como{" "}
                            <strong>{(tagStore.findById(props.action.tag) || {}).name}</strong>
                        </div>
                        {props.action.text && <BotMessage>{props.action.text}</BotMessage>}
                    </>
                )}
            </div>
        </div>
    )
})

export default EventBox
