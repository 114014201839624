import React, { useEffect } from "react"
import { useFetch } from "hooks"
import { powerUps } from "store"
import PageHeader from "components/PageHeader"
import PowerUpCard from "./PowerUpCard"

function PowerUps(props) {
    // const powerUpsStore = useFetch(powerUps)

    return (
        <div className="page-layout has-subheader">
            <PageHeader
                title="Power Ups"
                titleStyle={{ marginBottom: 25 }}
                description={
                    <span>
                        Adicione recursos extras ou integre com seus aplicativos
                        <br />
                        preferidos.
                    </span>
                }
            />
            <div className="pup-section">
                <h2 className="pup-title">Canais Recomendados</h2>
                <div className="pup-cards">
                    <PowerUpCard
                        name="Facebook"
                        code="facebook"
                        description="Veja e responda mensagens do Facebook na sua caixa de entrada."
                        image="https://simply-communicate.com/wp-content/uploads/2019/03/facebook-2661207_960_720.jpg"
                        to="/power-ups/facebook"
                        />
                    <PowerUpCard
                        name="Whatsapp"
                        code="whatsapp"
                        description="Veja e responda mensagens do Whatsapp na sua caixa de entrada."
                        image="https://image.flaticon.com/icons/png/512/124/124034.png"
                        to="/power-ups/whatsapp"
                        />
                    <PowerUpCard
                        name="Telegram"
                        code="telegram"
                        description="Veja e responda mensagens do Telegram na sua caixa de entrada."
                        image="https://image.flaticon.com/icons/png/512/124/124019.png"
                        disabled
                        />
                </div>
            </div>
            <div className="pup-section">
                <h2 className="pup-title">Recursos Adicionais</h2>
                <div className="pup-cards">
                    <PowerUpCard
                        name="Bot"
                        code="bot"
                        description="Crie cenários de respostas de chat automáticas com o nosso Bot."
                        image="https://cdn.dribbble.com/users/69237/screenshots/1894857/dribbble_shot.jpg"
                        to="/power-ups/bot"
                        />
                </div>
            </div>
        </div>
    )
}

export default PowerUps
