import React from "react"
import { Route, Redirect } from "react-router-dom"

const getRoute = (route, i, parentRoute) => {
    return (
        <Route
            path={route.path}
            render={routeProps => {
                const props = {
                    ...routeProps,
                    ...route.props,
                    routes: route.routes,
                    parentRoute: parentRoute,
                    routeName: route.name
                }
                return <route.component {...props} />
            }}
            key={i + route.path}
            exact={!!route.exact}
        />
    )
}

export const getRootRoutes = (routes) => {
    return routes.map((r, i) => getRoute(r, i))
}

export const getRoutes = (routes, parentRoute) => {
    return routes.map((r, i) => {
        if (r.component) {
            return getRoute(r, i, parentRoute)
        } else if (r.routes) {
            return getRoutes(r.routes, r)
        } else if (r.redirect) {
            return <Redirect exact from={r.path} to={r.redirect} key={r.path + r.redirect} push />
        }
    })
}