import React from "react"
import Button from "components/forms/Button"

function SettingsHeader({ title, add, toBack, right }) {
    return (
        <div className="settings-title">
            {toBack && (
                <Button
                    round
                    small
                    icon="fa fa-chevron-left"
                    style={{ marginRight: 10 }}
                    to={toBack}
                />
            )}
            <h1>{title}</h1>
            {add && (
                <Button
                    gray
                    ios
                    icon="fa fa-plus-circle"
                    to={add.to}
                    className="button-page-table-add"
                >
                    {add.name}
                </Button>
            )}
            {!add && (
                <div className="right" style={{ marginLeft: "auto" }}>
                    {right}
                </div>
            )}
        </div>
    )
}

export default SettingsHeader
