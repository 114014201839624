import React, { Component } from "react"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import queryString from "query-string"
import AuthBase from "./AuthBase"
import { useField, useForm } from "hooks"
import { session, useStore } from "store"

function Reset(props) {
    const { resetPassword } = useStore(session)
    const query = queryString.parse(props.location.search)
    const token = query.token
    const email = query.email
    const form = useForm({
        onSubmit: async model => {
            await resetPassword({ data: { ...model, token, email } })
            return () => props.history.push("/user/reset/success")
        }
    })

    const newPassword = useField("newPassword", {
        form,
        validations: [
            value =>
                value.length < 5 && "Sua senha deve ter no mínimo 5 caracteres"
        ]
    })

    const confirmNewPassword = useField("confirmNewPassword", {
        form,
        validations: [
            (value, formData) =>
                value !== formData.newPassword &&
                "Suas senhas estão diferentes",
            value =>
                value.length < 5 && "Sua senha deve ter no mínimo 5 caracteres"
        ]
    })

    return (
        <AuthBase>
            <form className="auth-box" onSubmit={form.onSubmit}>
                <h1 className="headline">Resete sua senha</h1>
                <Text
                    label="Nova senha"
                    required
                    type="password"
                    {...newPassword}
                />
                <Text
                    label="Confirme a nova senha"
                    type="password"
                    required
                    {...confirmNewPassword}
                />
                <div className="login-actions">
                    <Button
                        type="submit"
                        className="login-btn"
                        primary
                        loader="put/session"
                    >
                        Salvar nova senha
                    </Button>
                </div>
            </form>
        </AuthBase>
    )
}

export default Reset
