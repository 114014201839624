import React, { useState, useEffect } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import { useStore, general, instagram, channel } from "store"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import { getStatus } from "pages/settings/channels/Channels"
import SettingsLoader from "components/settings/SettingsLoader"
import masks from "lib/masks"
import ContextHeader from "components/ContextHeader"
import { getIconClassName } from "@uifabric/styling"
import PageTitle from "components/PageTitle"
import ContextBg from "components/ContextBg"
import RestartInstaModal from "./RestartInstaModal"
import { request, toast, Checkbox } from "nanui"
import config from "config"
import { ChannelStatus } from "../Channels"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddChannel(props) {
    const instagramStore = useStore(instagram)
    const channelStore = useStore(channel)
    const { addModal, removeModal } = useStore(general)

    // const { addModal, removeModal } = useStore(general)

    const item = props.match.params.id
        ? instagramStore.all.find((x) => x.id == props.match.params.id) || {}
        : {}

    const channelItem = props.match.params.id
        ? channelStore.all.find((x) => x.id == props.match.params.id) || {}
        : {}

    const form = useForm({
        onSubmit: async (model) => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adicionou uma canal de Instagram"
                    : "O agente editou uma canal de Instagram",
            })
            const response = await instagramStore.save({
                data: model,
                toastMessage: `Canal de Instagram ${item.id ? 'atualizado' : 'criado'}`,
            })
            return () => {
                if (!response.error && !response.errors) {
                    props.history.push("/settings/channels")
                }
            }
        },
        defaultModel: item,
    })

    const username = useField("username", {
        defaultValue: item.username,
        form,
    })

    const password = useField("password", {
        defaultValue: item.password,
        form,
    })

    const description = useField("description", {
        defaultValue: item.description,
        form,
    })

    const verificationCode = useField("verificationCode", {})

    return (
        <div className="channel-page">
            <ContextHeader
                backAction={{ to: "/settings/channels" }}
                // actions={
                //     item.id
                //         ? [
                //               {
                //                   name: "Reiniciar Servidor",
                //                   icon: getIconClassName("ReturnToSession"),
                //                   onClick: () =>
                //                       addModal(
                //                           <RestartInstaModal
                //                               item={item}
                //                               onBackModal={() => removeModal()}
                //                           />
                //                       ),
                //               },
                //           ]
                //         : []
                // }
            />
            <ContextBg success>
                <h3>Funcionalidade em desenvolvimento!</h3>
                <p> Agradecemos o interesse, em breve teremos novidades!</p>
            </ContextBg>
            <PageTitle
                title={
                    <span>
                        {!props.match.params.id
                            ? "Adicionar canal de Instagram Direct"
                            : "Editar canal de Instagram Direct"}
                        {channelItem.status && (
                            <ChannelStatus
                                status={channelItem.status}
                                style={{ marginLeft: 20, top: 72, position: "absolute" }}
                            />
                        )}
                    </span>
                }
                description="Coloque abaixo as suas credenciais da conta de Instagram que você deseja conectar. É um processo automático e não temos acesso
                manual as suas credenciais."
            />

            <SettingsLoader store={instagramStore} {...props}>
                <FormDialog
                    {...props}
                    disabledSubmit
                    item={item}
                    endpoint="/integrations/instagram"
                    onSubmit={form.onSubmit}
                    onDelete={(id) =>
                        instagramStore
                            .remove({
                                data: { id },
                                toastMessage: "Deletado com sucesso",
                            })
                            .then(() => {
                                props.history.push("/settings/channels")
                            })
                    }
                >
                    <div style={{ maxWidth: 460 }}>
                        {/* {["disconnected", "challenge_required"].includes(item.status) && (
                            <>
                                <span
                                    className="contract-status channels-status"
                                    style={{
                                        backgroundColor: "rgba(243,156,18, 0.3)"
                                    }}
                                >
                                    Desconectado
                                </span>
                                <br />
                                <br />
                            </>
                        )} */}
                        {/* <ContextBg>
                            <h2><b>Você tem autenticação de dois fatores? (2FA)</b></h2>
                            <br />
                            Nesse momento não suportamos autenticação de dois fatores (2FA), <b>se você habilitou</b>, por favor desabilite nas configurações do seu aplicativo, faça login  aqui, e depois habilite novamente.
                            <br />
                            <br />
                            <a style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/help/instagram/1124604297705184">Clique aqui para mais detalhes</a>
                        </ContextBg> */}
                        {item.status === "disconnected" && (
                            <ContextBg>
                                Seu canal de instagram desconectou.
                                <br />
                                Coloque novamente a senha e clique em salvar.
                            </ContextBg>
                        )}
                        {item.status === "challenge_required" && (
                            <ContextBg>
                                Seu canal de instagram desconectou.
                                <br />
                                Foi enviado um SMS com o código de verificação para o celular
                                cadastrado da conta de instagram. Insira o código no campo abaixo e
                                clique em Salvar código.
                                <br />
                                <br />
                                {/* <Button
                                    type="button"
                                    loader={"post" + config.api.SEND_EMAIL_CODE_INSTAGRAM}
                                    onClick={async () => {
                                        request
                                            .save(config.api.SEND_EMAIL_CODE_INSTAGRAM, item)
                                            .then(() => {
                                                toast(
                                                    "Código SMS enviado para o celular da conta do instagram.",
                                                    "success"
                                                )
                                            })
                                    }}
                                    // danger
                                    // style={{
                                    //     marginRight: noSalvable ? 0 : 10,
                                    //     height: "auto"
                                    // }}
                                >
                                    Enviar código SMS
                                </Button> */}
                                {/* <br />
                                <br /> */}
                                <Text label="Código" required {...verificationCode} />
                                <Button
                                    type="button"
                                    loader={"post" + config.api.SEND_CODE_INSTAGRAM}
                                    danger
                                    onClick={async () => {
                                        request
                                            .save(config.api.SEND_CODE_INSTAGRAM, {
                                                ...item,
                                                verificationCode: verificationCode.value,
                                                password: password.value,
                                            })
                                            .then((response) => {
                                                instagramStore.saveItem(response.data)
                                                toast("Instagram conectado", "success")
                                            })
                                    }}
                                >
                                    Salvar código
                                </Button>
                            </ContextBg>
                        )}
                        {/* {item.status === "challenge_required" && (
                            <ContextBg>
                                Seu canal de instagram desconectou.
                                <br />
                                Abra o seu instagram no celular ou navegador web e clique no botão
                                "Fui eu" para autorizar a conexão.
                            </ContextBg>
                        )} */}
                        {item.status === "incorrect_password" && (
                            <ContextBg>
                                Sua senha está incorreta, insira novamente e clique em Salvar.
                            </ContextBg>
                        )}
                        {item.status === "invalid_username" && (
                            <ContextBg>Esse nome de usuário não existe.</ContextBg>
                        )}

                        <Text disabled label="Nome do canal" required {...description} />
                        <Text disabled label="Usuário do instagram" required {...username} />
                        <Text disabled label="Senha" required type="password" {...password} />
                    </div>
                </FormDialog>
            </SettingsLoader>
        </div>
    )
}
