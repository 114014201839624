import React, { PureComponent } from "react"
import { getIconClassName } from "@uifabric/styling"

const FilterTag = ({ children, onRemove, style }) => (
    <div className="filter-tag" style={style}>
        {children}
        {onRemove && (
            <button onClick={onRemove}>
                <i className={getIconClassName("Cancel")} />
            </button>
        )}
    </div>
)

export default FilterTag
