import React from "react"

function MenuSection(props) {
    return (
        <div className="menu-drop-section">
            <div className="menu-drop-section-title">{props.title}</div>
        </div>
    )
}

export default MenuSection
