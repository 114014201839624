import React from "react"
import Button from "components/forms/Button"

const SettingsHeader = ({ title, image, description, afterDescription, action, ...props }) => (
    <div className="page-header">
        {image && <img src={image} alt="" className="page-header-image" />}
        <div className="page-header-info">
            <h2 className="title" style={props.titleStyle}>
                {title}
            </h2>
            <span className="description">{description}</span>
            {afterDescription && <span className="after-description">{afterDescription}</span>}
        </div>
        {action && <div className="action">{action}</div>}
    </div>
)

export default SettingsHeader
