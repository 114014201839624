import React, { useState } from "react"
import { Link } from "react-router-dom"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import AuthBase from "./AuthBase"
import { useField, useForm, useBreadcrumb } from "hooks"
import { session, useStore } from "store"

/**
 * Google Analytics
 */
import { analyticsCategory } from 'lib/constants'
import ReactGA from 'react-ga'

function Login(props) {
    useBreadcrumb({ title: "Entrar" })
    const { login } = useStore(session)
    // console.log('1222', login, session)
    const form = useForm({
        onSubmit: async formData => {
            ReactGA.event({
                category: analyticsCategory.VISITOR,
                action: 'Entrar no sistema'
            })
            await login({
                ...formData
                // workspace: "Workspace_2"
            })
        }
    })

    const email = useField("email", { form })
    const password = useField("password", { form })

    return (
        <AuthBase
            after={
                <>
                    <br />
                    <div className="login-signup">
                        Não tem uma conta?{" "}
                        <Link to="/user/register" className="color-link">
                            Registre-se
                        </Link>
                    </div>
                </>
            }
        >
            <form className="auth-box" onSubmit={form.onSubmit}>
                <h1 className="headline">Entrar</h1>
                <Text
                    type="email"
                    required
                    placeholder="Email"
                    {...email}
                />
                <Text
                    type="password"
                    required
                    placeholder="Senha"
                    {...password}
                />
                <div className="login-actions">
                    <Button
                        type="submit"
                        className="login-btn"
                        primary
                        loader="login"
                    >
                        Entrar no workspace
                    </Button>
                    <Link to="/user/forgot">Esqueceu a senha?</Link>
                </div>
                {/* <div className="auth-image">
                    <img
                        src="https://a.slack-edge.com/73cd3/img/get_started/create_team.png"
                        alt=""
                    />
                </div> */}
            </form>
        </AuthBase>
    )
}

export default Login
