import React, { useEffect, useState } from "react"
import req from "lib/req"
import config from "config"
import toaster from "lib/toaster"
import { whatsapp, useStore } from "store"
import Loader from "components/Loader"

let verifyInterval

function QRCodeModal(props) {
    const [item, setItem] = useState({})
    const whatsappStore = useStore(whatsapp)

    async function getQrcode() {
        const response = await req(
            config.api.WHATSAPP_QRCODE + "/" + props.item.id
        )
        const errors = response.error || response.errors
        if (!errors) {
            setItem(response)
        }
    }

    useEffect(() => {
        getQrcode()
        verifyInterval = setInterval(async () => {
            getQrcode()
        }, 3000)
        return () => clearInterval(verifyInterval)
    }, [])

    useEffect(() => {
        if (item.status === "connected") {
            toaster.ok("Whatsapp conectado!")
            whatsappStore.get({})
            props.onBackModal()
        }
    }, [item])

    return (
        <div className="dialog-inner">
            <h2 className="modal-title">Leia o qrcode no seu whatsapp</h2>
            <Loader loading={!item.qrcode} style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                    src={item.qrcode}
                    alt="qrcode"
                    style={{ width: 250, height: 250, margin: "auto" }}
                />
            </Loader>
        </div>
    )
}

export default QRCodeModal
