import React from "react"
import ModalHeader from "components/ModalHeader"
import ModalBody from "components/ModalBody"
import ModalFooter from "components/ModalFooter"
import Button from "components/forms/Button"

function PluginDialog({ title, message, okTitle, cancelTitle, onClick, okBtnProps, ...props } = {}) {
    return (
        <div className="plugin-dialog">
            {title && <ModalHeader title={title} />}
            <ModalBody>{message}</ModalBody>
            <ModalFooter>
                <Button gray type="button" style={{ marginRight: 10, height: "auto" }} onClick={() => onClick(false)}>
                    {cancelTitle || "Cancelar"}
                </Button>
                <Button primary type="button" onClick={() => onClick(true)} {...okBtnProps}>
                    {okTitle || "Ok"}
                </Button>
            </ModalFooter>
        </div>
    )
}

export default PluginDialog
