import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import ReadOnlyProperty from "./ReadOnlyProperty"
import userImg from "assets/images/user.svg"
import fileMediaImg from "assets/images/fileMedia.png"
import Loader from "components/Loader"
import { general, operator, inbox, useStore, medias } from "store"
import Chat from "pages/inbox/Chat"
import Viewer from 'react-viewer';

function ContactMedias({ contact, ...props }) {
    // const operatorStore = useStore(operator)
    // const inboxStore = useStore(inbox)
    // const inboxStore = useStore(medias)
    const mediasStore = useStore(medias)
    const { addModal, removeModal } = useStore(general)

    // Carrega mais conversas
    useEffect(() => {
        mediasStore.clear()
        mediasStore.get({
            params: {
                id: contact.id,
                limit: 30
            }
        })
    }, [contact.id])

    function onClickMedia(src, isImage) {
        if (!isImage) {
            window.open(src, "_blank")
            return
        }
        addModal(
            <Viewer
                visible
                onClose={() => { removeModal() } }
                changeable={false}
                zoomSpeed={0.5}
                images={[{src: src, alt: ''}]}
            />,
            {}
        )
    }

    return (
        <div className="contact-medias">
            {mediasStore.all.map((x, i) => {
                let downloadSrc = ""
                let src = x.content.match(/"image":"(.+)"/)
                let isImage = !!src

                if (src) {
                    const parsed = JSON.parse(x.content)
                    let index = 0
                    let hasUrl = parsed && Array.isArray(parsed) && parsed.length && parsed[index].insert
                    if (!hasUrl) {
                        index ++
                        hasUrl = parsed && Array.isArray(parsed) && parsed.length && parsed[index].insert
                    }
                    src = hasUrl && parsed[index].insert.image
                    downloadSrc = src
                } else {
                    src = fileMediaImg
                    try {
                        downloadSrc = x.content.match(/"(.+)":"(https:.+)"/)[2]
                    } catch (e) {
                        console.log(e)
                    }
                }

                return (
                    <a
                        className="contact-media-item"
                        onClick={() => onClickMedia(downloadSrc, isImage)}
                        key={x.content + i}
                        style={!isImage ? { padding: 8 } : {}}
                    >
                        <img
                            src={src}
                            alt=""
                            style={isImage ? {} : { objectFit: "contain" }}
                        />
                    </a>
                )
            })}
        </div>
    )
}

export default ContactMedias
