import React from "react"
import EditWebhook from "./EditWebhook"
import SettingsHelp from "components/SettingsHelp"
import { webhook } from "store"
import { CommandPage, useCommand } from "nanui"
import { getIconClassName } from "@uifabric/styling"

export default function Webhooks(props) {
    const { infoBarItem } = useCommand()

    const commandPageProps = {
        commandBarProps: {
            farItems: [infoBarItem]
        },
        commandPanels: {
            info: (
                <SettingsHelp title="Webhook" icon={getIconClassName("AzureAPIManagement")}>
                    Receba notificação de eventos como novas mensagens, atribuições, atualizações de
                    perfil, etc.
                </SettingsHelp>
            )
        }
    }

    return (
        <CommandPage {...commandPageProps}>
            <EditWebhook webhookRef={webhook} />
        </CommandPage>
    )
}
