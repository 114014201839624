import React, { Component } from "react"
import { Controlled as CodeMirror } from 'react-codemirror2'
// require('codemirror/mode/xml/xml');
require('codemirror/lib/codemirror.css')
require('codemirror/mode/jsx/jsx');
require('codemirror/theme/material.css')
require('codemirror/theme/pastel-on-dark.css')
require('codemirror/theme/neo.css')

// require('codemirror/mode/javascript/javascript');
// import cx from "classnames"

class ScriptBox extends Component {
    render() {
        const { label } = this.props
        return (
            <div className="text-space text-margin-bottom">
                <label className="label-input">{label}</label>

                <div className="script-box">
                    <CodeMirror
                        value={this.props.value}
                        options={{
                            mode: 'jsx',
                            theme: 'material',
                            lineNumbers: true
                        }}
                        onBeforeChange={(editor, data, value) => {
                            this.props.onChange(value)
                          }}
                        // onChange={(editor, data, value) => {
                        //     console.log(editor, data, value)
                        //     this.props.onChange(value)
                        // }}
                    />
                </div>
            </div>
        )
    }
}

export default ScriptBox