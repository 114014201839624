import React, { useState } from "react"
import ModalMultiPage from "components/ModalMultiPage"
import campaignImage from "assets/images/campaign.svg"
import campaignImageWhatsApp from "assets/images/campaignWhatsapp.svg"
import { useForm, useField, useSelectField } from "hooks"
import { campaign, general, channel, useStore } from "store"
import AddCampaignWhatsapp from "./AddCampaignWhatsapp"
import AddCampaignWhatsappPart2 from "./AddCampaignWhatsappPart2"
import AddCampaignWhatsappPart3 from "./AddCampaignWhatsappPart3"
import AddCampaignInstagram from "./AddCampaignInstagram"
import AddCampaignInstagramPart2 from "./AddCampaignInstagramPart2"
import AddCampaignInstagramPart3 from "./AddCampaignInstagramPart3"
import ChooseCampaign from "./ChooseCampaign"
import { checkType, assetsToDelta } from "pages/inbox/AttachFileModal"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import wppImg from "assets/images/whatsapp.png"
import masks from "lib/masks"

const instagramImg =
    "http://lilliputadventure.com/wp-content/uploads/2018/06/Instagram-Icon.png"

const defaultSideProps = {
    title: "Nova Campanha",
    description:
        "Dispare mensagens em massa usando a plataforma de comunicação preferida dos seus clientes.",
    img: campaignImage
}

const whatsappSideProps = {
    title: "Campanha de WhatsApp",
    description: (
        <span>
            Dispare mensagens em massa para os números de WhatsApp que você
            importar via <strong>.xlsx</strong>, <strong>.csv</strong>,{" "}
            <strong>.txt</strong> ou <strong>.xml</strong>
        </span>
    ),
    img: campaignImageWhatsApp
}

const instagramSideProps = {
    title: "Campanha de Instagram",
    description: (
        <span>
            Dispare mensagens em massa para os usuários de Instagram que você
            importar via <strong>.xlsx</strong>, <strong>.csv</strong>,{" "}
            <strong>.txt</strong> ou <strong>.xml</strong>
        </span>
    ),
    img: instagramImg
}

export default function AddCampaingModal(props) {
    const campaignStore = useStore(campaign)
    const channelStore = useStore(channel)
    const { uploadAssets, _domain } = useStore(general)
    const [assetsLoaded, setAssetsLoaded] = useState(false)
    const [files, setFiles] = useState([])
    const [text, setText] = useState("")
    const [messageFiles, setMessageFiles] = useState([])
    const [index, setIndex] = useState(0)
    const [executionDate, setExecutionDate] = useState()
    const [campaignType, setCampaignType] = useState(channelsTypesEnum.WHATSAPP)

    const form = useForm({
        onSubmit: async formData => {
            let assets = []
            let assetsDelta = []
            if (messageFiles.length) {
                assets = await uploadAssets(messageFiles)
                assetsDelta = assetsToDelta(messageFiles, assets, _domain)
            }
            let numbersAssets = await uploadAssets(files)
            setAssetsLoaded(true)

            const response = await campaignStore.save({
                method: "post",
                data: {
                    ...formData,
                    message: JSON.stringify([...assetsDelta, ...text.ops]),
                    file: numbersAssets[0].fileName,
                    executionDate
                },
                toastMessage: "Campanha criada."
            })
            console.log("response", response)
            props.history.replace("/campaigns")
        },
        onCatch: errors => setAssetsLoaded(false)
    })

    const name = useField("name", {
        form
    })

    const chunks = useField("chunks", {
        form,
        defaultValue: "100"
    })

    const timeBetweenChunks = useField("timeBetweenChunks", {
        form,
        defaultValue: "1800"
    })

    const initialTimeBetweenMessages = useField("initialTimeBetweenMessages", {
        form,
        defaultValue: "1"
    })

    const finalTimeBetweenMessages = useField("finalTimeBetweenMessages", {
        form,
        defaultValue: "5"
    })

    const workspacePlatformId = useSelectField("workspacePlatformId", {
        form,
        options: channelStore.all
            .filter(x => x.channelId == campaignType)
            .map(x => ({
                value: x.id,
                label: (
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={
                                campaignType == channelsTypesEnum.WHATSAPP
                                    ? wppImg
                                    : instagramImg
                            }
                            alt=""
                            style={{ width: 15, marginRight: 10 }}
                        />
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flex: 1
                            }}
                        >
                            <span>{x.description}&nbsp;&nbsp;&nbsp;</span>
                            <span>{masks.cell(x.phone)}</span>
                        </span>
                    </span>
                )
            }))
    })

    return (
        <form onSubmit={form.onSubmit} className="form-mid">
            <ModalMultiPage
                pages={[
                    {
                        sideProps: {
                            ...defaultSideProps,
                            imgStyle: {
                                marginTop: 90,
                                marginBottom: 80,
                                opacity: 0.2,
                                height: 80
                            }
                        },
                        component: (
                            <ChooseCampaign
                                setIndex={setIndex}
                                setCampaignType={setCampaignType}
                            />
                        )
                    },

                    // Whatsapp Modals
                    {
                        sideProps: whatsappSideProps,
                        component: (
                            <AddCampaignWhatsapp
                                form={form}
                                name={name}
                                files={files}
                                setFiles={setFiles}
                                setIndex={setIndex}
                            />
                        )
                    },
                    {
                        sideProps: whatsappSideProps,
                        component: (
                            <AddCampaignWhatsappPart2
                                form={form}
                                files={messageFiles}
                                setFiles={setMessageFiles}
                                setIndex={setIndex}
                                text={text}
                                setText={setText}
                                workspacePlatformId={workspacePlatformId}
                            />
                        )
                    },
                    {
                        sideProps: whatsappSideProps,
                        component: (
                            <AddCampaignWhatsappPart3
                                chunks={chunks}
                                timeBetweenChunks={timeBetweenChunks}
                                initialTimeBetweenMessages={
                                    initialTimeBetweenMessages
                                }
                                finalTimeBetweenMessages={
                                    finalTimeBetweenMessages
                                }
                                executionDate={executionDate}
                                setExecutionDate={setExecutionDate}
                                setIndex={setIndex}
                                loader={
                                    assetsLoaded
                                        ? "post/campaign"
                                        : "post/assets"
                                }
                            />
                        )
                    },

                    // Instagram Modals
                    {
                        sideProps: instagramSideProps,
                        component: (
                            <AddCampaignInstagram
                                form={form}
                                name={name}
                                files={files}
                                setFiles={setFiles}
                                setIndex={setIndex}
                            />
                        )
                    },
                    {
                        sideProps: instagramSideProps,
                        component: (
                            <AddCampaignInstagramPart2
                                form={form}
                                files={messageFiles}
                                setFiles={setMessageFiles}
                                setIndex={setIndex}
                                text={text}
                                setText={setText}
                                workspacePlatformId={workspacePlatformId}
                            />
                        )
                    },
                    {
                        sideProps: instagramSideProps,
                        component: (
                            <AddCampaignInstagramPart3
                                chunks={chunks}
                                timeBetweenChunks={timeBetweenChunks}
                                initialTimeBetweenMessages={
                                    initialTimeBetweenMessages
                                }
                                finalTimeBetweenMessages={
                                    finalTimeBetweenMessages
                                }
                                executionDate={executionDate}
                                setExecutionDate={setExecutionDate}
                                setIndex={setIndex}
                                loader={
                                    assetsLoaded
                                        ? "post/campaign"
                                        : "post/assets"
                                }
                            />
                        )
                    }
                ]}
                index={index}
            />
        </form>
    )
}
