import React, { useState, useMemo } from "react"
import { useBreadcrumb } from "hooks"
import { useStore, reportCustomer } from "store"
import Table from "components/Table"
import config from "config"
import queryString from "query-string"
import PageTitle from "components/PageTitle"
import { getIconClassName } from "@uifabric/styling"
import SettingsHelp from "components/SettingsHelp"
import FilterDropdown from "components/FilterDropdown"
import { CommandPage, useCommand } from "nanui"
import ReportCustomerFiltersPanel from "./ReportCustomerFiltersPanel"
import masks from "lib/masks"
import { channelsTypesEnum } from "lib/constants"

function getPlatformById(id) {
    switch (id) {
        case channelsTypesEnum.WHATSAPP:
            return 'WhatsApp'
        case channelsTypesEnum.INSTAGRAM:
            return 'Instagram'
        case channelsTypesEnum.FACEBOOK:
            return 'Messenger'
        case channelsTypesEnum.TELEGRAM:
            return 'Telegram'
        case channelsTypesEnum.EMAIL:
            return 'Email'
        case channelsTypesEnum.CUSTOM:
            return 'Customizado'
        case channelsTypesEnum.WIDGET:
            return 'Widget'

        default:
            return null
    }
}

function ReportCustomers(props) {
    useBreadcrumb({ title: "Relatório de Contatos" })
    const reportStore = useStore(reportCustomer)
    const { infoBarItem } = useCommand()
    const [isFilterOpen, setFilterOpen] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name"
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Celular",
                accessor: "phone",
                Cell: props => masks.cell(props.value)
            },
            {
                Header: "Canal de atendimento",
                accessor: "CustomerPlatforms[0].platformId",
                Cell: props => getPlatformById(props.value)
            },
        ],
        [reportStore.all]
    )

    const commandPageProps = {
        commandBarProps: {
            farItems: [infoBarItem],
            // items: [
            //     {
            //         key: "export",
            //         name: "Exportar",
            //         cacheKey: "exportKey", // changing this key will invalidate this items cache
            //         iconProps: {
            //             iconName: "ShareiOS"
            //         },
            //         ariaLabel: "export",
            //         subMenuProps: {
            //             items: [
            //                 {
            //                     key: "emailMessage",
            //                     name: "Exportar para Email",
            //                     iconProps: {
            //                         iconName: "Mail"
            //                     },
            //                     onClick: () => {
            //                         reportStore.exportToEmail(
            //                             config.api.REPORT_CUSTOMER + "/export/email",
            //                             queryString.parse(props.location.search)
            //                         )
            //                     }
            //                 }
            //             ]
            //         }
            //     },
            //     {
            //         key: "filter",
            //         name: "Filtros",
            //         iconProps: {
            //             iconName: "Filter"
            //         },
            //         onClick: () => setFilterOpen(true)
            //     }
            // ]
        },
        commandPanels: {
            info: (
                <SettingsHelp
                    title="Relatório de Contatos"
                    icon={getIconClassName("AzureAPIManagement")}
                >
                    Aqui você tem acesso a todos os seus contatos dos seus canais de antendimento.
                </SettingsHelp>
            )
        }
    }

    return (
        <CommandPage {...commandPageProps}>

            <div className="settings-layout">
                <PageTitle
                    title={
                        <span style={{ display: "flex", flexWrap: "wrap" }}>
                            <span style={{ marginRight: 15 }}>Relatório de Contatos</span>
                            <FilterDropdown {...props} notLimit />
                        </span>
                    }
                    style={{ paddingLeft: 0 }}
                />

                <Table
                    store={reportStore}
                    columns={columns}
                    query={props.location.search}
                    limit={9999}
                    defaultPageSize={20}
                    // loader={"/" + endpoint}
                    history={props.history}
                    // getTrProps={(state, rowInfo) => ({
                    //     onClick: () => {
                    //         props.history.push("/settings/members/" + rowInfo.original.id)
                    //     }
                    // })}
                    nodata={{
                        title: "Parece que você ainda não possui nenhum contato..."
                    }}
                />
            </div>
            <ReportCustomerFiltersPanel
                isFilterOpen={isFilterOpen}
                setFilterOpen={setFilterOpen}
                {...props}
            />
        </CommandPage>
    )
}

export default ReportCustomers
