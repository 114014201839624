import React, { useMemo } from "react"

const colors = [
    "#f7d794",
    "#cf6a87",
    "#f3a683",
    "#f8a5c2",
    "#63cdda",
    "#b8e994",
    "#f5cd79",
    "#e15f41",
    "#badc58",
    "#18dcff",
    "#f8c291",
    "#e056fd"
]

function DefaultAvatar({ name = "", index, size, fontSize } = {}) {
    const parsedIndex = useMemo(() => parseInt((String(index) || "")[0]), [index])
    const style = size
        ? { width: size, height: size, minWidth: size, fontSize: fontSize || size - 7 }
        : {}
    const colorIndex = useMemo(
        () => (isNaN(parsedIndex) ? Math.round(Math.random() * colors.length) : parsedIndex),
        [index]
    )
    if (!name) return <div />
    return (
        <div
            className="cell-avatar default-letter"
            style={{
                backgroundColor: colors[colorIndex % colors.length],
                ...style
            }}
        >
            {name[0]}
        </div>
    )
}

export default DefaultAvatar
