import React from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import { whatsapp } from "store"

function EditWhatsapp(props) {
    const item = props.item
    // const whatsappStore = useFetch(whatsapp)

    const form = useForm({
        onSubmit: async model => {
            await props.onSubmit(model, { toastMessage: "Número salvo" })
        },
        defaultModel: item
    })

    const phone = useField("phone", {
        defaultValue: item.phone,
        form
    })

    const description = useField("description", {
        defaultValue: item.description,
        form
    })

    return (
        <FormDialog {...props} title="número" onSubmit={form.onSubmit}>
            <Text label="Número" required {...phone} />
            <Text label="Descrição" required {...description} />
        </FormDialog>
    )
}

export default EditWhatsapp
