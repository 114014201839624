import React, { useState, useEffect } from "react"
import AuthBase from "./AuthBase"
import Loader from "components/Loader"
import Lottie from "react-lottie"
// import okLottie from "assets/animations/success.json"
import successLottie from "assets/animations/successBlue.json"
import { session, useStore } from "store"

const successLottieOptions = {
    loop: false,
    autoplay: true,
    animationData: successLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

function Registering(props) {
    const [success, setSuccess] = useState(false)
    const { onLogin } = useStore(session)
    const response = props.location.state

    useEffect(() => {
        setTimeout(() => {
            setSuccess(true)
            setTimeout(() => {
                onLogin(response)
            }, 1500)
        }, 2000)
    }, [])

    return (
        <AuthBase>
            <div className="registering">
                <Loader loading={!success}>
                    <Lottie
                        options={successLottieOptions}
                        height={120}
                        width={140}
                        speed={1}
                        // isPaused={!showArrow}
                    />
                </Loader>
                {!success && (
                    <>
                        <br />
                        <br />
                        <h3>Criando seu workspace...</h3>
                    </>
                )}
                {/* <h1 className="headline">Cadastro concluído!</h1>
                <h2 className="headline" style={{ textAlign: "center" }}>
                    <strong>{name}</strong>, você agora faz parte do workspace <br />{" "}
                    <strong>{workspaceName}</strong>.
                </h2>

                <div className="login-actions">
                    <Button
                        className="login-btn"
                        primary
                        loader="login"
                        to="/"
                        style={{ textDecoration: "none" }}
                    >
                        Ok
                    </Button>
                </div> */}
            </div>
        </AuthBase>
    )
}

export default Registering
