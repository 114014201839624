import React from "react"
import { Link } from "react-router-dom"
import Select from "components/forms/Select"
import Box from "components/Box"
import DashLine from "components/graphs/DashLine"

// const data = [
//     {
//         id: "Novas Conversas",
//         color: "hsl(321, 70%, 50%)",
//         data: [
//             {
//                 x: "4 Fev",
//                 y: 239
//             },
//             {
//                 x: "5 Fev",
//                 y: 72
//             },
//             {
//                 x: "6 Fev",
//                 y: 41
//             },
//             {
//                 x: "7 Fev",
//                 y: 59
//             },
//             {
//                 x: "8 Fev",
//                 y: 70
//             },
//             {
//                 x: "9 Fev",
//                 y: 26
//             },
//             {
//                 x: "10 Fev",
//                 y: 38
//             }
//         ]
//     },
//     {
//         id: "Conversas resolvidas",
//         color: "hsl(321, 70%, 50%)",
//         data: [
//             {
//                 x: "4 Fev",
//                 y: 220
//             },
//             {
//                 x: "5 Fev",
//                 y: 50
//             },
//             {
//                 x: "6 Fev",
//                 y: 38
//             },
//             {
//                 x: "7 Fev",
//                 y: 70
//             },
//             {
//                 x: "8 Fev",
//                 y: 65
//             },
//             {
//                 x: "9 Fev",
//                 y: 2
//             },
//             {
//                 x: "10 Fev",
//                 y: 20
//             }
//         ]
//     },
// ]

const ReportSessionsGraph = ({ height, data }) => (
    <div className="page dash-card graph">
        <Box title="Volume do chat">
            <div style={{ height }}>
                <DashLine data={data} />
            </div>
        </Box>
    </div>
)

export default ReportSessionsGraph
