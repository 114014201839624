import React from "react"
import cx from "classnames"

function BannerNotification({ message, type, actions }) {
    return (
        <div className="banner-notification-space">
            <div className={cx("banner-notification", type)}>
                {message}
                {actions}
            </div>
        </div>
    )
}

export default BannerNotification
