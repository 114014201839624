export default [
    { url: "https://api.kimochat.com.br/api", key: "staging" },
    { url: "https://api.kimochat.com.br/api", key: "production" },
    { url: "http://localhost:3000/api", key: "localhost" },
    { url: "mock", key: "mock" },
]

export const envKeys = {
    production: {
        facebook_app_id: "406703890178760",
    },
    staging: {
        facebook_app_id: "135752657099328",
    },
}
