import React from "react"
import PageTableBase from "components/PageTableBase"
import _ from "lodash"
import SettingsHelp from "components/SettingsHelp"
import AddCampaign from "./AddCampaignModal"
import EditCampaign from "./EditCampaignModal"
import { campaign } from "store"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import { getIconClassName } from "@uifabric/styling"
import wppImg from "assets/images/whatsapp.png"
import masks from "lib/masks"
import cx from "classnames"

function getProps(item) {
    if (item.channelId == channelsTypesEnum.WHATSAPP) {
        return {
            name: item.description,
            description: `${masks.cell(item.phone)}`,
            img: wppImg,
            iconStyle: {
                backgroundColor: "#f1fbf6"
            },
            to: "/settings/channels/whatsapp/" + item.id
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
    return {}
}

// function getChannelImage({
//     name,
//     description,
//     right,
//     to,
//     iconStyle,
//     icon,
//     img,
//     onClick,
//     imgStyle
// }) {
//     if (img) {
//         return <img src={img} alt="" className="cell-avatar" style={imgStyle} />
//     } else {
//         return <i className={cx("channel-icon", icon)} style={iconStyle} />
//     }
// }

// function getTitle(status) {
//     switch (status) {
//         case "waiting_file_processing":
//             return "Processando números..."
//         case "waiting_processing":
//             return "Aguardando início..."
//         case "executing":
//             return "Executando"
//         case "finished_fail":
//             return "Finalizado com erro"
//         case "canceled":
//             return "Cancelado"
//         case "finished_success":
//             return "Finalizado"
//         case "paused":
//             return "Pausado"
//         default:
//             return ""
//     }
// }

function getStatus(status) {
    switch (status) {
        case "waiting_file_processing":
            return {
                title: "Aguardando processamento...",
                bg: "rgba(0, 0, 0, 0.1)"
            }
        case "waiting_processing":
            return {
                title: "Aguardando início...",
                bg: "rgba(0, 0, 0, 0.1)"
            }
        case "executing":
            return { title: "Em execução", bg: "rgba(54, 127, 238, 0.3)" }
        case "finished_fail":
            return {
                title: "Finalizado com erro",
                bg: "rgba(231, 76, 60, 0.2)"
            }
        case "canceled":
            return { title: "Cancelado", bg: "rgba(231, 76, 60, 0.2)" }
        case "finished_success":
            return { title: "Finalizado", bg: "rgb(46, 204, 113, 0.7)" }
        case "paused":
            return { title: "Pausado", bg: "#f1c40f" }
        default:
            return ""
    }
}

function getIcon(original) {
    let iconProps = {}
    switch (original.status) {
        case "waiting_file_processing":
            iconProps = {
                className: getIconClassName("ExploreData") + " campaign-icon",
                style: {
                    color: "#c"
                }
            }
            break
        case "waiting_processing":
            iconProps = {
                className: getIconClassName("DateTime2") + " campaign-icon",
                style: {
                    color: "#34495e"
                }
            }
            break
        case "executing":
        default:
            iconProps = {
                className: getIconClassName("Sync") + " campaign-icon",
                style: {
                    color: "#3498db",
                    animation: "fa-spin 2s linear infinite"
                }
            }
            break
        case "finished_fail":
            iconProps = {
                className: getIconClassName("ErrorBadge") + " campaign-icon",
                style: {
                    color: "#e74c3c"
                }
            }
            break
        case "canceled":
            iconProps = {
                className: getIconClassName("Blocked") + " campaign-icon",
                style: {
                    color: "#e67e22"
                }
            }
            break
        case "finished_success":
            iconProps = {
                className: getIconClassName("CheckMark") + " campaign-icon",
                style: {
                    color: "#2ecc71"
                }
            }
            break
        case "paused":
            iconProps = {
                className: getIconClassName("CirclePause") + " campaign-icon",
                style: {
                    color: "#34495e"
                }
            }
            break
        // case "formalized":
        //     iconProps = {
        //         className: "fas fa-check campaign-icon",
        //         style: { backgroundColor: "#2ecc71", color: "white" }
        //     }
        //     break
        // case "rejected":
        //     iconProps = {
        //         className: "fas fa-thumbs-down campaign-icon",
        //         style: { backgroundColor: "#f39c12", color: "white" }
        //     }
        //     break
        // case "failed":
        //     iconProps = {
        //         className: "fas fa-times campaign-icon",
        //         style: { backgroundColor: "#e74c3c", color: "white" }
        //     }
        //     break
        // case "overwritten":
        //     iconProps = {
        //         className: "fas fa-exchange campaign-icon",
        //         style: { backgroundColor: "#e74c3c", color: "white" }
        //     }
        //     break
    }
    return (
        <i
            className="fas fa-clock campaign-icon"
            style={{ color: "rgba(0,0,0,0.2)" }}
            title={getStatus(original.status).title}
            {...iconProps}
        />
    )
}

export default function Campaigns(props) {
    const columns = [
        {
            Header: "Nome",
            accessor: "name",
            // Cell: props => (
            //     <span className="vertical-center">
            //         <i className={`${getIconClassName('Clock')} table-icon`} />
            //         {props.value}
            //     </span>
            // )
            Cell: props => (
                <div className="vertical-center">
                    {getIcon(props.row._original)}
                    <span>{_.startCase(_.toLower(props.value))}</span>
                </div>
            )
        },
        {
            Header: "Início",
            accessor: "executionDate",
            type: "date",
            width: 150
        },
        {
            Header: "Canal",
            accessor: "workspacePlatformId",
            width: 150,
            Cell: props => {
                return "Whatsapp"
            }
        },
        {
            Header: "Mensagens Enviadas",
            accessor: "totalNumbersExecuted",
            width: 150,
            Cell: props => (
                <span>{`${props.value} / ${props.row._original.totalNumbers} `}</span>
            )
        },
        {
            Header: "Conclusão",
            accessor: "totalNumbersExecuted",
            width: 150,
            Cell: props => {
                return (
                    <span>
                        {Math.floor(
                            (parseInt(props.value, 10) /
                                (parseInt(
                                    props.row._original.totalNumbers,
                                    10
                                ) || 1)) *
                                100
                        )}
                        %
                    </span>
                )
            }
        },
        {
            Header: "",
            accessor: "status",
            width: 230,
            Cell: props => (
                <>
                    {props.value !== "connected" && (
                        <span
                            className="contract-status channels-status"
                            style={{
                                backgroundColor: getStatus(props.value).bg
                            }}
                        >
                            {getStatus(props.value).title}
                        </span>
                    )}
                </>
            )
        }
    ]
    return (
        <div className="channel-list-items">
            <PageTableBase
                storeRef={campaign}
                title="Campanhas"
                newLabel="Novo"
                columns={columns}
                EditComponent={AddCampaign}
                ViewComponent={EditCampaign}
                newComponentModalClassname="modal-wide"
                helperAction={
                    <SettingsHelp
                        title="Campanhas"
                        icon={getIconClassName("Megaphone")}
                    >
                        Envie uma mesma mensagem para muitos usuários de forma
                        automática através de um canal. Por exemplo, você pode
                        enviar uma propaganda ou anuncio para milhares de
                        pessoas via Whatsapp.
                        <br />
                        <br />O envio em massa da campanha pode fazer com que
                        seu número seja bloqueado (Caso seja via WhatsApp) se
                        muitas pessoas denunciarem sua mensagem como spam.
                        Recomendamos, nesse caso, criar um canal de WhatsApp
                        (Com outro número de telefone) específico para fazer
                        campanhas.
                    </SettingsHelp>
                }
                nodata={{
                    title:
                        "Parece que você ainda não adicionou nenhuma campanha...",
                    buttonText: "Criar uma nova campanha"
                }}
                // customTo={rowInfo => {
                //     return getProps(rowInfo.original).to
                // }}
                {...props}
            />
        </div>
    )
}
