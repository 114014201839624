import React from "react"
import PageTableBase from "components/PageTableBase"
// import DefaultAvatar from "components/DefaultAvatar"
import SettingsHelp from "components/SettingsHelp"

import AddChannel from "./AddChannel"
import { permissions } from "lib/constants"
import Tooltip from "components/Tooltip"
import { channel } from "store"
import wppImg from "assets/images/whatsapp.png"
import chatWidgetImg from "assets/images/chat-widget.svg"
import instagramImg from "assets/icons/svg/instagram.svg"
import { getImage } from "lib/helper"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import masks from "lib/masks"
import cx from "classnames"
import { getIconClassName } from "@uifabric/styling"

export const ChannelStatus = ({ status, style }) => {
    return (
        <span
            className="contract-status channels-status"
            style={{ backgroundColor: getStatus(status).bg, ...style }}
        >
            {getStatus(status).title}
        </span>
    )
}

export function getProps(item) {
    if (item.channelId == channelsTypesEnum.FACEBOOK) {
        return {
            description: item.description,
            iconStyle: {
                color: "#fff",
                backgroundColor: "#3C5A99",
                fontSize: 20
            },
            to: "/settings/channels/facebook/" + item.id
        }
    }
    if (item.channelId == channelsTypesEnum.WHATSAPP) {
        return {
            name: item.description,
            description: `${masks.cell(item.phone)}`,
            img: wppImg,
            iconStyle: {
                backgroundColor: "#f1fbf6"
            },
            to: "/settings/channels/whatsapp/" + item.id
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
    if (item.channelId == channelsTypesEnum.WIDGET) {
        return {
            name: item.description,
            description: 'Chat Widget',
            img: chatWidgetImg,
            iconStyle: {
                backgroundColor: "#f1fbf6"
            },
            to: "/settings/channels/chat-widget/" + item.id
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
    if (item.channelId == channelsTypesEnum.INSTAGRAM) {
        return {
            name: "Instagram Direct",
            description: item.description,
            img: instagramImg,
            iconStyle: {
                backgroundColor: "#f1fbf6"
            },
            to: "/settings/channels/instagram/" + item.id
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
    if (item.channelId == channelsTypesEnum.TELEGRAM) {
        return {
            description: `${item.description}`,
            img:
                "https://d3t7jw7q309lw8.cloudfront.net/site/telegram_logo.svg",
            iconStyle: {
                backgroundColor: "#eff5fc"
            },
            to: "/settings/channels/telegram/" + item.id
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
    if (item.channelId == channelsTypesEnum.EMAIL) {
        return {
            description: `${item.description}`,
            icon: "fa fa-envelope",
            // img: "https://d3t7jw7q309lw8.cloudfront.net/site/telegram_logo.svg",
            iconStyle: {
                backgroundColor: "rgb(255, 121, 121)",
                color: "white",
                fontSize: 20
            },
            to: "/settings/channels/email/" + item.id,
            name: item.name
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
    if (item.channelId == channelsTypesEnum.CUSTOM) {
        return {
            description: "Canal customizado",
            icon: "fab fa-cuttlefish",
            // img: "https://d3t7jw7q309lw8.cloudfront.net/site/telegram_logo.svg",
            iconStyle: {
                backgroundColor: "#34495e",
                color: "white",
                fontSize: 20
            },
            to: "/settings/channels/custom/" + item.id,
            name: item.description
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={item} />)
        }
    }
}

function getChannelImage({
    name,
    description,
    right,
    to,
    iconStyle,
    icon,
    img,
    onClick,
    imgStyle
}) {
    if (img) {
        return <img src={img} alt="" className="cell-avatar" style={imgStyle} />
    } else {
        return <i className={cx("channel-icon", icon)} style={iconStyle} />
    }
}

export function getStatus(status) {
    switch (status) {
        case "connected":
            return {
                title: "Conectado",
                bg: "rgba(46,204,113, 0.3)"
            }
        case "disconnected":
            return { title: "Desconectado", bg: "rgba(243,156,18, 0.3)" }
        case "processing_channel":
            return { title: "Criando...", bg: "rgba(52, 152, 219, 0.3)" }
        case "restarting_channel":
            return { title: "Reiniciando...", bg: "rgba(52, 152, 219, 0.3)" }
        case "login_required":
            return { title: "Necessário relogar", bg: "rgba(231, 76, 60, 0.3)" }
        case "challenge_required":
            return { title: "Necessário validar o código", bg: "rgba(231, 76, 60, 0.3)" }
        case "qr_code":
            return { title: "Necessário ler QR-Code", bg: "rgba(231, 76, 60, 0.3)" }
        default:
            return { title: status, bg: "rgba(231, 76, 60, 0.3)" }
    }
}

export default function Channels(props) {
    const columns = [
        {
            Header: "",
            accessor: "channelId",
            width: 45,
            Cell: props => (
                <div>
                    {props.row._original.avatar && props.row._original.channelId != channelsTypesEnum.WIDGET
                        ? getChannelImage({
                              img: props.row._original.avatar,
                              imgStyle: { borderRadius: "50%" }
                          })
                        : getChannelImage({
                              ...getProps(props.row._original),
                              icon: channelsTypes[props.value].icon
                          })}
                </div>
            )
        },
        {
            Header: "Canal",
            accessor: "channelId",
            width: 300,
            Cell: props => {
                return (
                    <div className="vertical-center">
                        <span
                            className="truncate"
                            style={{
                                maxWidth: 150
                            }}
                        >
                            {(getProps(props.row._original).name) || channelsTypes[props.value].name}
                        </span>
                    </div>
                )
            }
        },
        {
            Header: "Descrição",
            accessor: "id",
            maxWidth: 300,
            Cell: props => <div>{getProps(props.row._original).description}</div>
        },
        {
            Header: "",
            accessor: "status",
            width: 230,
            Cell: props => (
                <>
                    {/* {props.value !== "connected" && ( */}
                    <ChannelStatus status={props.value} />
                    {/* )} */}
                </>
            )
        }
    ]
    return (
        <div className="channel-list-items">
            <PageTableBase
                storeRef={channel}
                title="Canais"
                newLabel="Novo"
                columns={columns}
                EditComponent={AddChannel}
                helperAction={
                    <SettingsHelp title="Canais de mensagens" icon={getIconClassName("Inbox")}>
                        Conecte aos aplicativos de mensagens mais usados pelos seus clientes e
                        responda todos em sua caixa de entrada.
                    </SettingsHelp>
                }
                nodata={{
                    title: "Parece que você ainda não adicionou nenhum canal...",
                    buttonText: "Adicionar novo canal"
                }}
                customTo={rowInfo => {
                    return getProps(rowInfo.original).to
                }}
                {...props}
            />
        </div>
    )
}
