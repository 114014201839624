import React, { useState, useMemo } from "react"
import GraphBox from "./GraphBox"
import { useBreadcrumb } from "hooks"
import { dashboard, useStore, operator, report } from "store"
import Loader from "components/Loader"
import SettingsHeader from "components/settings/SettingsHeader"
import FilterHeader from "components/FilterHeader"
import { typeFilters } from "lib/constants"
import Table from "components/Table"
import { getHoursAndMinutes } from "lib/helper"
import DashLine from "components/graphs/DashLine"
import Box from "components/Box"
import ExportDrop from "components/ExportDrop"
import config from "config"
import queryString from "query-string"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import { getIconClassName } from "@uifabric/styling"
import SettingsHelp from "components/SettingsHelp"
import FilterDropdown from "components/FilterDropdown"
import { CommandPage, Panel, PanelType, useCommand, Stack, Button } from "nanui"
import ReportFiltersPanel from "./ReportFiltersPanel"

function ReportStat({ label, title, children, style }) {
    return (
        <div className="report-stat" style={{ marginBottom: 0 }} title={title}>
            <div className="report-stat-title">{label}</div>
            <div className="report-stat-value" style={style}>
                {children}
            </div>
        </div>
    )
}

function ReportConversations(props) {
    useBreadcrumb({ title: "Relatório de Conversas" })
    const reportStore = useStore(report)
    const { infoBarItem } = useCommand()
    const [isFilterOpen, setFilterOpen] = useState(false)

    // const filters = [
    //     [
    //         {
    //             type: typeFilters.MORE,
    //             name: "Filtrar por",
    //             position: "right",
    //             icon: getIconClassName("Filter"),
    //             subfilters: [
    //                 { type: typeFilters.PERIOD },
    //                 { type: typeFilters.SELECT_FREQUENCY },
    //                 { type: typeFilters.SELECT_GROUP }
    //             ]
    //         }
    //     ]
    // ]
    const volume = useMemo(
        () => [
            {
                id: "Novas Conversas",
                color: "#e6e6e6",
                data: reportStore.all.map(x => ({
                    x: x.label,
                    y: x.newConversations
                }))
            },
            {
                id: "Conversas resolvidas",
                color: "#367fee",
                data: reportStore.all.map(x => ({
                    x: x.label,
                    y: x.resolvedConversations
                }))
            }
        ],
        [reportStore.all]
    )

    const columns = useMemo(
        () => [
            {
                Header: "Período",
                accessor: "labelLong"
            },
            {
                Header: <span title="Tempo Médio de Espera">TME</span>,
                accessor: "firstTimeResponse",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: <span title="Tempo Médio de Resposta">TMR</span>,
                accessor: "timeResponse",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: <span title="Tempo Médio de Atendimento">TMA</span>,
                accessor: "resolutionTime",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Novas conversas",
                accessor: "newConversations"
            },
            {
                Header: "Resolvidas",
                accessor: "resolvedConversations"
            }
        ],
        [reportStore.all]
    )

    const productivity = useMemo(
        () => [
            {
                id: "TMA",
                color: "hsl(86, 70%, 50%)",
                data: reportStore.all.map(x => ({
                    x: x.label,
                    y: x.resolutionTime
                }))
            },
            // {
            //     id: "TMR",
            //     color: "#367fee",
            //     data: reportStore.all.map(x => ({
            //         x: x.label,
            //         y: x.timeResponse
            //     }))
            // },
            {
                id: "TME",
                color: "#e6e6e6",
                data: reportStore.all.map(x => ({
                    x: x.label,
                    y: x.firstTimeResponse
                }))
            }
        ],
        [reportStore.all]
    )

    const graphs = useMemo(
        () => [
            {
                name: "Conversas Resolvidas",
                graph: DashLine,
                data: volume
            },
            {
                name: "Tempo Médio",
                graph: DashLine,
                data: productivity,
                props: {
                    tooltipFormat: value => getHoursAndMinutes(value)
                }
            }
        ],
        [reportStore.all]
    )

    function getAverageTime(key) {
        return reportStore.all.length > 0 ? getHoursAndMinutes(getAverage(key)) : "-"
    }

    function getAverage(key) {
        return reportStore.all.length > 0
            ? Math.round(
                  reportStore.all.reduce((acc, x) => x[key] + acc, 0) / reportStore.all.length
              )
            : "-"
    }

    function getSum(key) {
        return reportStore.all.length > 0
            ? Math.round(reportStore.all.reduce((acc, x) => x[key] + acc, 0))
            : "-"
    }

    const commandPageProps = {
        commandBarProps: {
            farItems: [infoBarItem],
            items: [
                {
                    key: "export",
                    name: "Exportar",
                    cacheKey: "exportKey", // changing this key will invalidate this items cache
                    iconProps: {
                        iconName: "ShareiOS"
                    },
                    ariaLabel: "export",
                    subMenuProps: {
                        items: [
                            {
                                key: "emailMessage",
                                name: "Exportar para Email",
                                iconProps: {
                                    iconName: "Mail"
                                },
                                onClick: () => {
                                    reportStore.exportToEmail(
                                        config.api.REPORT + "/export/email",
                                        queryString.parse(props.location.search)
                                    )
                                }
                            }
                        ]
                    }
                },
                {
                    key: "filter",
                    name: "Filtros",
                    iconProps: {
                        iconName: "Filter"
                    },
                    onClick: () => setFilterOpen(true)
                }
            ]
        },
        commandPanels: {
            info: (
                <SettingsHelp
                    title="Relatório de Conversas"
                    icon={getIconClassName("AzureAPIManagement")}
                >
                    TME - Tempo Médio de Espera (para o primeiro atendimento)
                    <br />
                    TMA - Tempo Médio de Atendimento
                    <br />
                    TMR - Tempo Médio de Resposta
                </SettingsHelp>
            )
        }
    }

    return (
        <CommandPage {...commandPageProps}>
            {/* <SettingsHeader
                title="Relatório de Conversas"
                right={
                    <ExportDrop
                        options={[
                            {
                                onClick: () => {
                                    reportStore.exportToEmail(
                                        config.api.REPORT + "/export/email",
                                        queryString.parse(props.location.search)
                                    )
                                },
                                name: (
                                    <span className="menu-item-with-avatar not-assigned">
                                        <i className="fal fa-envelope" />
                                        Exportar para e-mail
                                    </span>
                                )
                            }
                        ]}
                    />
                }
            /> */}
            {/* <ContextHeader
                actions={[
                    {
                        name: "Exportar para e-mail",
                        icon: getIconClassName("Mail"),
                        onClick: () => {
                            reportStore.exportToEmail(
                                config.api.REPORT + "/export/email",
                                queryString.parse(props.location.search)
                            )
                        }
                    },
                    {
                        custom: (
                            <>
                                <FilterHeader
                                    filters={filters}
                                    pathname={props.match.url}
                                    history={props.history}
                                    location={props.location}
                                />
                            </>
                        )
                    }
                ]}
            /> */}

            <div className="settings-layout">
                <PageTitle
                    title={
                        <span style={{ display: "flex", flexWrap: "wrap" }}>
                            <span style={{ marginRight: 15 }}>Relatório de Conversas</span>
                            <FilterDropdown {...props} />
                        </span>
                    }
                    style={{ paddingLeft: 0 }}
                />

                {/* <FilterHeader
                    filters={filters}
                    pathname={props.match.url}
                    history={props.history}
                    location={props.location}
                /> */}
                {/* <br /> */}
                <Loader
                    loader="get/report"
                    style={{ height: "100%", marginTop: 200, display: "none" }}
                >
                    <GraphBox graphs={graphs} />
                    <Box style={{ padding: "20px 0px 25px" }}>
                        <div style={{ display: "flex" }}>
                            <div className="report-col">
                                <ReportStat label="TME" title="Tempo Médio de Espera">
                                    {getAverageTime("firstTimeResponse")}
                                </ReportStat>
                            </div>
                            <div className="report-col">
                                <ReportStat label="TMA" title="Tempo Médio de Atendimento">
                                    {getAverageTime("resolutionTime")}
                                </ReportStat>
                            </div>
                            <div className="report-col">
                                <ReportStat label="Novas">{getSum("newConversations")}</ReportStat>
                            </div>
                            <div className="report-col">
                                <ReportStat label="Resolvidas">
                                    {getSum("resolvedConversations")}
                                </ReportStat>
                            </div>
                        </div>
                    </Box>
                </Loader>
                <Table
                    store={reportStore}
                    columns={columns}
                    query={props.location.search}
                    limit={9999}
                    defaultPageSize={20}
                    // loader={"/" + endpoint}
                    history={props.history}
                    // getTrProps={(state, rowInfo) => ({
                    //     onClick: () => {
                    //         props.history.push("/settings/members/" + rowInfo.original.id)
                    //     }
                    // })}
                    nodata={{
                        title: "Parece que você ainda não possui nenhuma estatística..."
                    }}
                />
            </div>
            <ReportFiltersPanel
                isFilterOpen={isFilterOpen}
                setFilterOpen={setFilterOpen}
                {...props}
            />
        </CommandPage>
    )
}

export default ReportConversations
