import React, { useState, useEffect, useMemo } from "react"
import queryString from "query-string"
import ReactTable from "react-table"
import Loader from "components/Loader"
import Button from "components/forms/Button"
// import NoDataImg from "assets/images/nodata.svg"
import NoDataImg from "assets/images/nodata.svg"
import helper from "lib/helper"
import { useLocalStorage } from "react-use"

function parseColumns(header, history) {
    return header.map(e => {
        e.resizable = false
        e.sortable = false
        if (e.type === "date") {
            e.Cell = props => (props.value ? new Date(props.value).toLocaleDateString() : "")
        }
        if (e.limit) {
            e.Cell = props => helper.limit(props.value, e.limit)
        }
        return e
    })
}

function NoData(nodata, store) {
    return (
        <div className="no-data">
            {nodata && store.totalCount === 0 && (
                <div className="no-data-inner">
                    <img src={nodata.img || NoDataImg} alt="" />
                    <br />
                    <h2 className="title">{nodata.title}</h2>
                    <br />
                    {nodata.to && (
                        <Button to={nodata.to} primary>
                            {nodata.buttonText}
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}

function Table({
    store,
    columns,
    history,
    nodata,
    loader,
    query,
    queryPath,
    queryObject,
    defaultPageSize = 20,
    limit = 200,
    style,
    filterFn,
    fetchable = true,
    ...otherProps
}) {
    const [showTable, setShowTable] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    // const [pageSize, setPageSize] = useLocalStorage("pageSize", defaultPageSize)
    const [pageSize, setPageSize] = useState(defaultPageSize)
    const dataAll = useMemo(() => (filterFn ? store.all.filter(filterFn) : store.all), [
        filterFn,
        store.all
    ])
    let _isMounted = false

    useEffect(() => {
        _isMounted = true
        ;(async function() {
            if (fetchable) {
                // debugger
                setLoading(true)
                await store.clear()
                await store.get({
                    params: {
                        limit: Math.max(limit, pageSize),
                        ...queryString.parse(query),
                        ...queryObject
                    }
                })
            }
            if (_isMounted) {
                setLoading(false)
                setShowTable(true)
                setPage(0)
            }
        })()
        return () => (_isMounted = false)
        // return store.clear
    }, [query, queryPath])

    async function fetchData(state) {
        const rowsCount = (state.page + 1) * state.pageSize
        const count = dataAll.length

        // Se tem mais dados para baixar da api
        if (rowsCount >= count && count < store.matchCount && !isLoading) {
            await store.get({
                data: {
                    limit: Math.max(limit, rowsCount - count),
                    offset: count,
                    ...queryString.parse(query)
                },
                isNextPage: true
            })
        }
    }
    return (
        <Loader loader={loader ? "get" + loader : "get" + store.endpoint} table>
            {dataAll.length > 0 && showTable ? (
                <div style={style}>
                    <ReactTable
                        manual
                        pageSize={pageSize}
                        page={page}
                        onPageSizeChange={size => setPageSize(size)}
                        onPageChange={page => setPage(page)}
                        data={dataAll.slice(pageSize * page, pageSize * page + pageSize)}
                        pages={Math.ceil(store.matchCount / pageSize)}
                        onFetchData={(tableState, instance) =>
                            fetchable && fetchData(tableState, store)
                        }
                        resizable={true}
                        showPagination={store.matchCount > Math.min(defaultPageSize, pageSize)}
                        columns={parseColumns(columns, history)}
                        minRows={0}
                        // minRows={store.matchCount > pageSize ? pageSize : 0}
                        previousText= 'Anterior'
                        nextText= 'Próximo'
                        loadingText= 'Carregando...'
                        pageText= 'Página'
                        ofText= 'de'
                        rowsText= 'linhas'
                        {...otherProps}
                    />
                </div>
            ) : (
                NoData(nodata, store)
            )}
        </Loader>
    )
}

export default Table
