import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import Text from "components/forms/Text"
import MenuDrop from "components/menu/MenuDrop"
import DateRangePicker from "components/forms/DateRangePicker"
import { NavLink, Link } from "react-router-dom"
import queryString from "query-string"
import { typeFilters } from "lib/constants"
import { isQueryMatched } from "lib/helper"
import { useField, useSelectField, useForm, useDatePeriod } from "hooks"
import Button from "components/forms/Button"
import { useLocalStorage } from "react-use"
import FilterDropdown from "components/FilterDropdown"
import { useStore, general, stores } from "store"
import { addQueryParams, spreadQueryParams } from "lib/helper"

export const Search = ({ form, location, style }) => {
    const search = useField("query", {
        defaultValue: queryString.parse(location.search).query,
        form
    })
    return (
        // <Text
        //     placeholder="Buscar"
        //     flat
        //     material
        //     transparent
        //     search
        //     // leftIcon="fal fa-search"
        //     style={{ minWidth: 280, fontSize: 14, ...style }}
        //     autoComplete="off"
        //     suffix={
        //         search.value ? (
        //             <Button
        //                 icon="fal fa-times"
        //                 iconStyle={{ color: "rgba(0,0,0,0.3)" }}
        //                 round
        //                 onClick={() => {
        //                     search.setValue("")
        //                     setTimeout(function() {
        //                         form.onSubmit(new Event("submit"))
        //                     })
        //                 }}
        //             />
        //         ) : null
        //     }
        //     {...search}
        // />
        <Text
            search
            placeholder="Pesquisar"
            autoComplete="off"
            suffix={
                search.value ? (
                    <button
                        className="search-clear-btn"
                        onClick={() => {
                            search.setValue("")
                            setTimeout(function() {
                                form.onSubmit(new Event("submit"))
                            })
                        }}
                    >
                        <i className="fa fa-times-circle" />
                    </button>
                ) : null
            }
            {...search}
        />
    )
}

// export function getActive(location, verifyQuery) {
//     const queryParsed = queryString.parse(location.search)
//     const verifyQueryKeys = Object.keys(verifyQuery.query)
//     // verifyQueryKeys.every((k, i) => {
//     //     return tab.query[k] === queryParsed[k] || ""
//     // })
//     // const queryKeys = Object.keys(queryParsed).filter(f => verifyQueryKeys.includes(f))
//     return verifyQueryKeys.every(k => verifyQuery.query[k] === queryParsed[k] || "")
// }

const DateFilter = ({ form, location }) => {
    const query = queryString.parse(location.search)
    const period = useDatePeriod("period", {
        defaultValue: { from: query.from, to: query.to },
        form
    })
    const [isClosed, setIsClosed] = useState(false)

    function toggleModalVisibility() {
        setIsClosed(true)
        setTimeout(() => {
            setIsClosed(false)
        })
    }

    function resetPeriod() {
        period.setValue({ from: undefined, to: undefined })
    }

    useEffect(() => {
        const isOnConversation = location.pathname && (location.pathname.split('/').length >= 4)
        if (!isOnConversation) resetPeriod()
    }, [location.pathname])

    return (
        <MenuDrop
            trigger={
                <div>
                    <i className="fa fa-calendar date-filter-button" title="Filtrar por data" />
                </div>
            }
            width={300}
            scrollableContentMaxHeight={420}
            close={isClosed}
        >
            <div className="date-filter-content">
                <label>Filtrar por data</label>
                <DateRangePicker hideDescription {...period} />
                <div className="date-filter-button-wrapper">
                    <button
                        type="button"
                        className="btn"
                        onClick={() => {
                            resetPeriod()
                            toggleModalVisibility()
                            setTimeout(() => {
                                form.onSubmit(new Event("submit"))
                            })
                        }}
                    >
                        Limpar
                    </button>
                    <button
                        type="button"
                        className="btn primary"
                        onClick={() => {
                            toggleModalVisibility()
                            form.onSubmit()
                        }}
                    >
                        Aplicar
                    </button>
                </div>
            </div>
        </MenuDrop>
    )
}

const Tabs = props => {
    const tabsComponents = props.tabs.map((tab, i) => (
        // <Button
        //     className="filters-tab"
        //     // onClick={() => props.setTab(tab.query)}
        //     onClick={() => addQueryParams({ newParams: tab.query, ...props })}
        //     key={tab.name}
        //     style={
        //         isQueryMatched(props.location, tab.query)
        //         ? { backgroundColor: "#20a2e0", color: "white" }
        //         : {}
        //     }
        //     >
        //     {tab.name}
        // </Button>
        <Link
            className={
                "filters-tab" + (isQueryMatched(props.location, tab.query) ? " isActive" : "")
            }
            to={{
                pathname: props.location.pathname,
                search: spreadQueryParams({ newParams: tab.query, ...props })
            }}
            key={tab.name}
            onClick={props.onClick}
            replace
        >
            {tab.name}
        </Link>
    ))
    return <>{tabsComponents}</>
}

const buildFilters = ({ filters, ...props }) => {
    return filters.map(f => {
        switch (f.type) {
            case typeFilters.SEARCH:
                return (
                    <div className="search-container" key={typeFilters.SEARCH}>
                        <Search {...props} />
                        <DateFilter {...props} />
                    </div>
                )
            case typeFilters.TABS:
                return <Tabs key={typeFilters.TABS} tabs={f.tabs} {...props} />
            case typeFilters.MORE:
                return (
                    <FilterDropdown
                        key={typeFilters.MORE}
                        filters={f.subfilters}
                        name={f.name}
                        icon={f.icon}
                        {...props}
                    />
                )
            case typeFilters.CUSTOM_DROPDOWN:
                return <f.component key={typeFilters.CUSTOM_DROPDOWN} {...props} />
            default:
                return null
        }
    })
}

// const isNilOrBlank = v => v != null && v !== ""
// const getTabValues = (location, filterTabs) => {
//     if (filterTabs) {
//         const activeTab = filterTabs.tabs.find(tab => isQueryMatched(location, tab.query))
//         if (activeTab) {
//             return activeTab.query
//         }
//     }
//     return null
// }

// let firstTime = false
const FilterHeader = props => {
    const { addLocalFilters } = useStore(general)
    const [localFilters, setLocalFilters] = useLocalStorage("filters", {})

    useEffect(() => {
        const { query, ...queryParams } = queryString.parse(props.location.search)
        if (props.storageKey) {
            addLocalFilters({ [props.storageKey]: queryString.stringify(queryParams) })
        }
        // if (localFilters[props.storageKey] && !props.location.search) {
        //     history.replace({
        //         search: localFilters[props.storageKey]
        //     })
        // }
    }, [props.location.search])

    // const [tab, setTab] = useState(
    //     getTabValues(props.location, props.filters.flat().find(f => f.type === typeFilters.TABS))
    // )

    const form = useForm({
        onSubmit: async newParams => {
            // console.log("Novos parametros", newParams)
            addQueryParams({
                ...props,
                newParams
            })

            // debugger
            // const searchObj = {
            //     ..._.pickBy(formData, isNilOrBlank),
            //     ..._.pickBy(tab, isNilOrBlank)
            // }
            // const search = queryString.stringify(searchObj)
            // if (search.replace("?", "") !== props.location.search.replace("?", "")) {
            //     if (props.storageKey) {
            //         const { query, ...toStore } = searchObj
            //         setLocalFiltersState(localObj => ({
            //             ...localObj,
            //             [props.storageKey]: queryString.stringify(toStore)
            //         }))
            //         setLocalFilters(localObj => ({
            //             ...localObj,
            //             [props.storageKey]: queryString.stringify(toStore)
            //         }))
            //     }
            //     history.replace({ search })
            // }
        }
    })

    // useEffect(() => {
    //     if (firstTime) {
    //         form.onSubmit()
    //     } else {
    //         firstTime = true
    //     }
    // }, [tab, form.customs])

    // useEffect(() => {
    //     form.onSubmit()
    // }, [form.customs])

    return (
        <form className="tab-header" onSubmit={form.onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            {props.filters.map((rowFilters, i) => (
                <div className="row" key={"fr" + i}>
                    {buildFilters({ ...props, form, filters: rowFilters })}
                </div>
            ))}
        </form>
    )
}

export default FilterHeader
