import React, { useState } from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import { useField, useForm } from "hooks"

function EditMenu(props) {
    const actionItem = props.actionItem
    const [options, setOptions] = useState(
        props.actionItem.options || [{ name: "", gotTo: "" }]
    )

    const goToOptions = props.item.paths.map(x => ({
        value: x.guid,
        label: x.name
    }))

    const form = useForm({
        onSubmit: async model =>
            await props.onEditAction({ ...model, options }),
        defaultModel: { ...actionItem, type: "menu" }
    })

    const title = useField("title", {
        defaultValue: actionItem.title,
        form
    })

    const text = useField("text", {
        defaultValue: actionItem.text,
        form
    })

    const textInvalid = useField("textInvalid", {
        defaultValue: actionItem.textInvalid || "Não existe essa opção, tente novamente!",
        form
    })

    function changeName(value, i) {
        setOptions(options => {
            options[i].name = value
            console.log(options)
            return [...options]
        })
    }

    function changeGoTo(option, i) {
        setOptions(options => {
            options[i].goTo = option.value
            return [...options]
        })
    }

    return (
        <FormDialog
            {...props}
            title="Menu"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Text
                label="Nome do menu"
                placeholder="Menu principal"
                required
                {...title}
            />
            <Text label="Texto" {...text} rows={3} />
            <Text
                label="Resposta caso usuário digite opção não existente"
                // required
                {...textInvalid}
                rows={3}
            />

            <label className="label-input">Opções</label>
            {options.map((option, i) => (
                <div key={option + i}>
                    <div className="menu-option">
                        {/* {console.log(option)} */}
                        <Text
                            onChange={e => changeName(e.target.value, i)}
                            value={option.name}
                            flat
                        />
                        <Select
                            options={goToOptions}
                            value={option.goTo}
                            onChange={v => changeGoTo(v, i)}
                            placeholder="Ir para..."
                            flat
                        />
                        <Button
                            icon="fa fa-times-circle"
                            onClick={() =>
                                setOptions(
                                    options.filter((e, optInd) => optInd !== i)
                                )
                            }
                            className={i > 0 ? "" : "invisible"}
                            cancel
                            danger
                        />
                        <Button
                            icon="fa fa-plus-circle"
                            onClick={() =>
                                setOptions([
                                    ...options,
                                    {
                                        name: "",
                                        goTo: ""
                                    }
                                ])
                            }
                            className={
                                i === options.length - 1 ? "" : "invisible"
                            }
                        />
                    </div>
                </div>
            ))}
        </FormDialog>
    )
}
export default EditMenu
