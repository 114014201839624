import React, { useMemo } from "react"
import { useField, useCheckbox, useForm, useFetch, useBreadcrumb } from "hooks"
import { TextField, Checkbox, Stack } from "nanui"
import { campaign, useStore } from "store"
import Label from "components/forms/Label"
import Dropzone from "components/forms/Dropzone"
import ModalFooter from "components/ModalFooter"
import ModalBody from "components/ModalBody"
import Button from "components/forms/Button"
import ModalPageIndicator from "components/ModalPageIndicator"

export default function AddCampaignInstagram({ form, files, setFiles, setIndex, name }) {
    const columnProps = {
        tokens: { childrenGap: 20 }
    }

    return (
        <>
            <ModalBody>
                <ModalPageIndicator length={3} index={0} />
                <Stack {...columnProps}>
                    <TextField label="Nome da campanha" {...name} />
                    <div>
                        <Dropzone
                            files={files}
                            setFiles={setFiles}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv, .xls, .xlsx, .txt, .xml"
                            icon="ExcelDocument"
                            iconStyle={{
                                color: "green",
                                opacity: 0.3
                            }}
                            description="Arraste e solte seu arquivo .xlsx, .csv, .txt ou .xml aqui"
                            label="Importar nomes de usuário"
                            disabledIsDragReject
                            multiple={false}
                        />
                        <p style={{ opacity: 0.5 }}>
                            *A primeira coluna deverá conter os nomes de usuário do instagram dos usuários que receberão a campanha
                        </p>
                    </div>
                </Stack>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    style={{ marginRight: 10, height: "auto" }}
                    onClick={() => setIndex(4)}
                >
                    Voltar
                </Button>
                <Button
                    primary
                    type="button"
                    onClick={() => setIndex(5)}
                    disabled={!files.length || !name.value}
                >
                    Continuar
                </Button>
            </ModalFooter>
        </>
    )
}
