import React from "react"
import { getIconClassName } from "@uifabric/styling"
import ContextHeader from "components/ContextHeader"
// import Table from "components/Table"
import ReactTable from "react-table"

// import HelpBanner from "components/HelpBanner"
import PageTitle from "components/PageTitle"
import { payment } from "store"
import { useFetch } from "hooks"
import { getDateFormatted } from "lib/helper"
import masks from "lib/masks"
import Loader from "components/Loader"

const columns = [
    {
        Header: "Horário",
        accessor: "id",
        Cell: props => (
            <span className="vertical-center">
                <i className={`${getIconClassName("M365InvoicingLogo")} table-icon`} />
                Recibo da fatura {props.value}
            </span>
        )
    },
    {
        Header: "Agentes",
        accessor: "plan",
        width: 150,
        Cell: props => props.value.agents
    },
    {
        Header: "Linhas de Whatsapp",
        accessor: "plan",
        width: 150,
        Cell: props => props.value.numbers
    },
    {
        Header: "Instagrams",
        accessor: "plan",
        width: 150,
        Cell: props => props.value.instagramUnits || "0"
    },
    {
        Header: "Periodicidade",
        accessor: "plan",
        width: 150,
        Cell: props => <span>{props.value.months == 12 ? "Anual" : "Mensal"}</span>
    },
    {
        Header: "Valor",
        accessor: "amount",
        width: 150,
        Cell: props => <span>{masks.currency(props.value)}</span>
    },
    {
        Header: "Expira em",
        accessor: "expiresAt",
        width: 200,
        Cell: props => <span>{getDateFormatted(props.value)}</span>
    },
    {
        Header: "",
        accessor: "",
        width: 100,
        Cell: props => <i className={`${getIconClassName("OpenInNewWindow")} table-icon`} />
    }
]

export default function Invoices(props) {
    const paymentStore = useFetch(payment)

    const data = paymentStore.all.map(plan => plan.transactions.map(x => ({ ...x, plan }))).flat()

    console.log(data)

    return (
        <section className="page-table">
            <ContextHeader
                actions={[
                    {
                        to: "/settings/billing/license",
                        name: "Voltar",
                        icon: getIconClassName("Back")
                    }
                ]}
            />
            <PageTitle title="Recibos" />
            <Loader loader={"get" + paymentStore.endpoint} table>
                {paymentStore.all.length > 0 && (
                    <div className="page-table-table">
                        <ReactTable
                            manual
                            pageSize={999}
                            page={0}
                            data={data}
                            resizable={false}
                            columns={columns}
                            showPagination={false}
                            minRows={0}
                            getTrProps={(state, rowInfo) => ({
                                onClick: () => {
                                    window.open(
                                        window.location.origin +
                                            "/invoices/" +
                                            rowInfo.original.plan.id +
                                            "/" +
                                            rowInfo.original.id
                                    )
                                    // props.history.push(
                                    //     buildUrlQuery(rowInfo.original.id, props)
                                    // )
                                }
                            })}
                        />
                    </div>
                )}
            </Loader>
        </section>
    )
}

// import React, { useState } from "react"
// import Button from "components/forms/Button"
// import Loader from "components/Loader"
// import SettingsHeader from "components/settings/SettingsHeader"

// import TopHeader from "components/TopHeader"
// import { useFetch } from "hooks"
// import { payment } from "store"
// // import { plans } from "./Billing"
// import masks from "lib/masks"

// const Invoice = ({
//     id,
//     order,
//     license,
//     autorenewal,
//     whatsapp,
//     agents,
//     months,
//     createdAt,
//     expiresAt,
//     amount,
//     plan,
//     credit
// }) => (
//     <div className="invoice">
//         <div className="left">
//             <div className="invoice-info">
//                 <span>
//                     Fatura {id} até {new Date(expiresAt).toLocaleDateString()}
//                 </span>
//                 <span>
//                     {/* Para {agents} operadores em {plans.find(x => x.months === months).period} */}
//                 </span>
//             </div>
//             <div className="invoice-value">{masks.currency(amount)}</div>
//         </div>
//         <div className="right">
//             <Button primary to={"/invoices/" + plan.id + "/" + id} target="_blank">
//                 {expiresAt ? "Ver recibo" : "Pagar fatura"}
//             </Button>
//         </div>
//     </div>
// )

// function Invoices(props) {
//     const paymentStore = useFetch(payment)
//     return (
//         <div>
//             <SettingsHeader title="Assinatura" toBack="/settings/billing/license" />
//             <br />
//             <Loader loader={"get/payment"} style={{ height: "100%", marginTop: 0, width: "100%" }}>
//                 <div className="invoices">
//                     {paymentStore.all.map(plan =>
//                         plan.transactions.map(x => <Invoice {...x} plan={plan} key={x.id} />)
//                     )}
//                 </div>
//             </Loader>
//         </div>
//     )
// }

// export default Invoices
