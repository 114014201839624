import React, { useEffect, useState } from "react"
import req from "lib/req"
import config from "config"
import {QRCodeSVG} from 'qrcode.react';
import { whatsapp, channel, useStore } from "store"
import Loader from "components/Loader"
import ModalHeader from "components/ModalHeader"
import ModalBody from "components/ModalBody"
// import ModalFooter from "components/ModalFooter"

function QRCodeModal(props) {
    const [item, setItem] = useState({})
    const channelStore = useStore(channel)
    const whatsappStore = useStore(whatsapp)

    async function getQrcode() {
        const response = await req(config.api.WHATSAPP_QRCODE + "/" + props.item.id)
        const errors = response.error || response.errors
        if (!errors) {
            setItem(response)
        }
    }

    useEffect(() => {
        getQrcode()
    }, [])

    useEffect(() => {
        const channelItem = channelStore.all.find(x => x.id === props.item.id)
        if (channelItem) {
            if (channelItem.status === "connected") {
                // toaster.ok("Canal de whatsapp foi conectado.")
                whatsappStore.saveItem({ ...props.item, ...item })
                props.onBackModal()
            }
        }
    }, [channelStore.all])

    return (
        <div>
            <ModalHeader title="Leia o QRcode no seu WhatsApp" />
            <ModalBody>
                <Loader loading={!item.qrcode} style={{ marginTop: 20, marginBottom: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <QRCodeSVG size={256} value={item.qrcode} />
                    </div>
                </Loader>
            </ModalBody>
        </div>
    )
}

export default QRCodeModal
