import { useState, useEffect, useRef } from "react"

export const useInfiniteScroll = callback => {
    const [isFetching, setIsFetching] = useState(false)
    const [scrollHeight, setScrollHeight] = useState(false)
    const containerRef = useRef()

    useEffect(() => {
        if (!isFetching) return
        callback(() => {})
    }, [isFetching])

    // useEffect(() => {
    //     console.log('add event scroll')
    //     window.addEventListener("scroll", handleScroll, { passive: true })
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll)
    //     }
    // }, [])

    function handleScroll(e) {
        // if (!isFetching) console.log(1)
        // setIsFetching(true)
        // console.log('SCROLL', containerRef.current.scrollTop, containerRef.current.scrollHeight)
        if (containerRef.current.scrollTop < 1) {
            setIsFetching(true)
            setScrollHeight(containerRef.current.scrollHeight)
        }

        // if (
        //     window.innerHeight + document.documentElement.scrollTop !==
        //         document.documentElement.offsetHeight ||
        //     isFetching
        // )
        //     return
        // setIsFetching(true)
    }

    return { isFetching, setIsFetching, handleScroll, scrollHeight, containerRef }
}
