import React, { createRef } from "react"
import { observer } from "mobx-react"
import { NavLink } from "react-router-dom"
import Dropdown from "components/Dropdown"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import Text from "components/forms/Text"
import DateRangePicker from "components/forms/DateRangePicker"

@observer
class AddEventDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.dropdownRef = React.createRef()
    }

    onCloseDropdown = () => {
        this.dropdownRef.current.onCloseMenu()
    }

    render() {
        const { onClick } = this.props
        const trigger = (
            <div className="drop-trigger">
                <Button
                    icon="fa fa-plus-circle"
                    iconStyle={{ color: "#20a2e0", borderRadius: 10 }}
                />
            </div>
        )
        return (
            <div className="" style={{ display: "flex", justifyContent: "center", marginTop: 15, marginBottom: 60 }}>
                <Dropdown trigger={trigger} ref={this.dropdownRef} top>
                    <div className="filter-drop-inner">
                        <label className="label-input">Ações</label>
                        <ul className="cf-dropdown-list">
                            <li>
                                <Button
                                    icon="fal fa-comment"
                                    listItem
                                    to={this.props.pathGuid + "/message/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Mensagem
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-comment-edit"
                                    listItem
                                    to={this.props.pathGuid + "/input/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Entrada de dados
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-tag"
                                    listItem
                                    to={this.props.pathGuid + "/tag/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Categorizar
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-headset"
                                    listItem
                                    to={this.props.pathGuid + "/agent/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Atendimento
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-code-branch"
                                    listItem
                                    to={this.props.pathGuid + "/menu/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Menu
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-directions"
                                    listItem
                                    to={this.props.pathGuid + "/goto/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Ir para
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-plug"
                                    listItem
                                    to={this.props.pathGuid + "/integration/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Integração
                                </Button>
                            </li>
                            <li>
                                <Button
                                    icon="fal fa-comment-check"
                                    listItem
                                    to={this.props.pathGuid + "/resolve/new"}
                                    onClick={() => this.onCloseDropdown()}
                                >
                                    Finalizar conversa
                                </Button>
                            </li>
                        </ul>
                    </div>
                </Dropdown>
            </div>
        )
    }
}

export default AddEventDropdown
