import React, { Component } from "react"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import store from "store"
import AuthBase from "./AuthBase"
import { useField, useForm } from "hooks"
import { session, useStore } from "store"

function Forgot(props) {
    const { sendForgotEmail } = useStore(session)
    const form = useForm({
        onSubmit: async model => {
            await sendForgotEmail({ data: model })

            return () => {
                props.history.push("/user/forgot/sent")
            }
        }
    })

    const email = useField("email", { form })

    return (
        <AuthBase>
            <form className="auth-box" onSubmit={form.onSubmit}>
                <h1 className="headline">Esqueci a senha</h1>
                <Text
                    label="Email"
                    type="email"
                    required
                    placeholder="Email"
                    {...email}
                />
                <div className="login-actions">
                    <Button
                        type="submit"
                        className="login-btn"
                        primary
                        loader="put/session"
                    >
                        Obter instruções
                    </Button>
                </div>
            </form>
        </AuthBase>
    )
}

export default Forgot
