import React from "react"
import Text from "components/forms/Text"
import SettingsHeader from "components/settings/SettingsHeader"
import Loader from "components/Loader"
import Button from "components/forms/Button"
// import Checkbox from "components/forms/Checkbox"
import CheckboxGroup from "components/forms/CheckboxGroup"
import HelpBanner from "components/HelpBanner"
import { useField, useCheckbox, useForm, useFetch, useBreadcrumb } from "hooks"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import { getIconClassName } from "@uifabric/styling"
import { useStore, general } from "store"
import { TextField, Checkbox, Stack } from "nanui"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function EditWebhook({ webhookRef }) {
    const webhookStore = useFetch(webhookRef)
    useBreadcrumb({ title: "Webhook", backTo: "/settings" })
    const { panelHelperOpen, setPanelHelperOpen } = useStore(general)

    const webhookData = webhookStore.all[0] || {
        subscriptions: {}
    }
    const form = useForm({
        onSubmit: async formData => {
            console.log("FORMDATA", formData)
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Agente salvou configurações de webhook"
            })
            webhookStore.save({
                method: "put",
                data: {
                    ...formData,
                    webhook: formData.webhook ? "https://" + formData.webhook : "",
                    // email: formData.email || "",
                    subscriptions: {
                        [newMessages.name]: newMessages.checked,
                        [statusChange.name]: statusChange.checked,
                        [userUpdate.name]: userUpdate.checked
                    }
                },
                toastMessage: "Webhook salvo."
            })
        },
        defaultModel: webhookData
    })
    const webhook = useField("webhook", {
        defaultValue: webhookData.webhook || "",
        form
    })
    const email = useField("email", {
        defaultValue: webhookData.email || "",
        form
    })

    const newMessages = useCheckbox("message:received", {
        defaultValue: webhookData.subscriptions["message:received"] || false
    })
    const statusChange = useCheckbox("session:status:changed", {
        defaultValue: webhookData.subscriptions["session:status:changed"] || false
    })
    const userUpdate = useCheckbox("person:changed", {
        defaultValue: webhookData.subscriptions["person:changed"] || false
    })

    const columnProps = {
        tokens: { childrenGap: 20 },
        styles: { root: { width: 460, marginLeft: 32 } }
    }

    const checkboxStyles = () => {
        return {
            root: {
                marginTop: "10px"
            }
        }
    }

    return (
        <Loader
            loading={webhookData.webhook === undefined}
            loader="get/webhook"
            alwaysLoadingOnMount
        >
            <form onSubmit={form.onSubmit} className="form-mid">
                <PageTitle title="Webhook" />
                <div className="form-dialog">
                    <Stack {...columnProps}>
                        <TextField
                            label="Endpoint"
                            prefix="https://"
                            placeholder="www.exemplo.com/api/webhook/..."
                            ariaLabel="Example text field with https:// prefix"
                            {...webhook}
                        />
                        <CheckboxGroup title="Se inscreva nos eventos">
                            <Checkbox
                                {...newMessages}
                                label="Novas mensagens"
                                styles={checkboxStyles}
                            />
                            <Checkbox
                                {...statusChange}
                                label="Mudança de status (Atribuído, Resolvido, Reaberto)"
                                styles={checkboxStyles}
                            />
                            <Checkbox
                                {...userUpdate}
                                label="Atualização de Usuário (Criação, atualização)"
                                styles={checkboxStyles}
                            />
                        </CheckboxGroup>
                        <TextField
                            label="Em caso de falha mandar email para"
                            name="email"
                            type="email"
                            {...email}
                        />
                        <div className="" style={{ marginTop: 20 }}>
                            <Button primary type="submit" big loader="put/webhook">
                                Salvar
                            </Button>
                        </div>
                    </Stack>
                </div>
            </form>
        </Loader>
    )
}

export default EditWebhook
