import React, { useEffect, useState } from "react"
import { useStore, session, stores, general } from "store"
import { channelsTypes } from "lib/constants"
import toaster from "lib/toaster"
import PluginConfirmDialog from "components/plugin/PluginConfirmDialog"
import Loading from "components/Loading"

function SidePlugin({ plugin = {}, id, customer = {}, ...props }) {
    const { user } = useStore(session)
    const { addModal, removeModal, _isProduction } = useStore(general)
    const [loaded, setLoaded] = useState(false)
    const [pluginLoading, setPluginLoading] = useState(false)

    function onLoad() {
        setLoaded(true)
    }

    function getUser() {
        return {
            id: user.id,
            name: user.name,
            phone: user.phone,
            avatar: user.avatar,
            email: user.email,
            isActive: user.isActive
        }
    }

    useEffect(() => {
        var iframe = document.getElementById("plugin-iframe")
        if (loaded) {
            if (customer.id && customer.customerId && customer.createdAt) {
                const data = {
                    contact: {
                        id: customer.customerId,
                        properties: customer.properties,
                        name: customer.name,
                        avatar: customer.avatar,
                        email: customer.email,
                        phone: customer.phone
                    },
                    conversation: {
                        id: customer.id,
                        identifier: customer.identifier,
                        assignee: { id: customer.operatorId },
                        group: { id: customer.groupId },
                        channel_type:
                            channelsTypes[parseInt(customer.platformId, 10)],
                        link: `https://www.kimochat.com.br${
                            props.location.pathname
                        }`,
                        tags: (customer.tags || []).map(x => ({ id: x }))
                    },
                    user: getUser()
                }
                iframe.contentWindow.postMessage(
                    {
                        event: "conversation",
                        data: _isProduction ? data : { ...data, isDev: true }
                    },
                    "*"
                )
            } else {
                console.log("Chamando NO CONVERSATION")
                iframe.contentWindow.postMessage(
                    { event: "no_conversation", data: { user: getUser() } },
                    "*"
                )
            }
        }
    }, [
        loaded,
        customer.id,
        customer.customerId,
        customer.createdAt,
        customer.properties
    ])

    function onClickDialog(confirmed) {
        removeModal()
        var iframe = document.getElementById("plugin-iframe")
        iframe.contentWindow.postMessage(
            {
                event: "dialog_confirm",
                data: { confirmed }
            },
            // plugin.endpoint
            "*"
        )
    }

    function onMessage(event) {
        const payload = event.data
        // if (event.origin != "h ttp://child.com") { return; }
        switch (payload.event) {
            case "dialog":
                addModal(
                    <PluginConfirmDialog
                        {...payload.data}
                        onClick={onClickDialog}
                    />
                )
                break
            case "toast":
                toaster.callToast(payload.data.type, payload.data.message)
                break
            case "set-property":
                stores.inbox.saveProperty(payload.data.customerId, {
                    label: payload.data.label,
                    value: payload.data.value
                })
                break
            case "loading":
                setPluginLoading(payload.data)
                break
            default:
                break
        }
    }

    useEffect(() => {
        window.addEventListener("message", onMessage, false)
        return () => {
            window.removeEventListener("message", onMessage)
        }
    }, [])

    return (
        <div style={{ height: "calc(100% - 4px)" }}>
            {pluginLoading && (
                <Loading
                    center
                    style={{
                        marginTop: 30,
                        position: "absolute",
                        margin: "30px auto",
                        left: 0,
                        right: 0
                    }}
                    size={28}
                />
            )}
            {plugin.endpoint && (
                <iframe
                    src={plugin.endpoint + `?auth_secret=${plugin.authSecret}`}
                    frameBorder="0"
                    title={plugin.name}
                    height="100%"
                    width="100%"
                    id="plugin-iframe"
                    onLoad={onLoad}
                    style={{ opacity: pluginLoading ? 0 : 1 }}
                />
            )}
        </div>
    )
}

export default SidePlugin
