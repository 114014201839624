import React from "react"
import Caret from "components/Caret"
import { getIconClassName } from "@uifabric/styling"

function SelectTrigger({ children, className, notCaret, style }) {
    return (
        <div className={`select-trigger ${className}`} style={style}>
            <div className="left">{children}</div>
            {!notCaret && (
                <i className={getIconClassName('ChevronDown')} style={{ marginLeft: 11, fontSize: 11 }} />
                // <Caret />
            )}
        </div>
    )
}

export default SelectTrigger
