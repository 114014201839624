import React, { useState, useRef, useMemo, useEffect } from "react"
import ReactQuill, { Quill } from "react-quill"
import AgentAvatar from "components/AgentAvatar"
// import { QuillDeltaToHtmlConverter } from "quill-delta-to-html"
import EmojiPicker from "components/forms/EmojiPicker"
// import { convertHtmlToDelta } from "node-quill-converter"
import cx from "classnames"
import "react-quill/dist/quill.bubble.css"
import Button from "components/forms/Button"
import QuickPhrasesDrop from "components/chat/QuickPhrasesDrop"
import { stores, useStore, general } from "store"
import { messagesTypes } from "lib/constants"
import ChatActionIco from "components/inbox/ChatActionIco"
import { getIconClassName } from "@uifabric/styling"
import { quickPhrase, inbox } from "store"
import RecordAudioModal from "components/inbox/RecordAudioModal"
import { channelsTypesEnum } from "lib/constants"

// import TagDrop from "components/inbox/TagDrop"
// import TagBox from "components/inbox/TagBox"
// import EmojiConvertor from "emoji-js"
// import { Delta } from "quill"
import Blockquote from "quill/formats/blockquote"
var Delta = Quill.import("delta")
// var Bold = Quill.import('formats/bold');
// Bold.tagName = 'file';   // Quill uses <strong> by default
let Inline = Quill.import("blots/inline")
// class FileBlot extends BlockEmbed {}
// FileBlot.blotName = "file"
// FileBlot.tagName = "strong"
// Quill.register("formats/file", FileBlot)

let BlockEmbed = Quill.import("blots/block/embed")
class EmphBlot extends BlockEmbed {
    static create(value) {
        let node = super.create(value)
        node.innerText = value
        return node
    }

    static value(node) {
        return {
            alt: node.getAttribute("alt"),
            url: node.getAttribute("src"),
            html: "asddas",
            value: "assda",
        }
    }
}

EmphBlot.blotName = "file"
EmphBlot.tagName = "div"
EmphBlot.className = "blot-file"
ReactQuill.Quill.register("formats/file", EmphBlot)

const formats = ["formats/file"] // add custom format name + any built-in formats you need
// import { convertHtmlToDelta } from "node-quill-converter"

// const emojiConverter = new EmojiConvertor()
// emojiConverter.text_mode = false
// emojiConverter.allow_native = false
// emojiConverter.replace_mode = "unified"
// emojiConverter.allow_native = true

const placeholder = {
    reply: 'Shift + Enter para adicionar nova linha. "/" para frase rápida.',
    private: "Anotações são particulares e visíveis apenas para você e para seu time.",
}

const modules = {
    toolbar: false,
    keyboard: {
        bindings: {
            custom: {
                key: 13,
                handler: (range, context) => {
                    return false
                },
            },
        },
    },
}

function ChatTextEditor(props) {
    const [text, _setText] = useState(props.text || "")
    const [textPrivate, setTextPrivate] = useState("")
    const [isReply, setIsReply] = useState(true)
    const quillRef = useRef()
    const quickPhraseRef = useRef(null)
    const { addModal } = useStore(general)
    const inboxStore = useStore(inbox)
    const quickPhraseStore = useStore(quickPhrase)

    useEffect(() => {
        if (props.text) {
            _setText(props.text)
        }
    }, [props.text])

    useEffect(() => {
        quillRef.current.focus()
    }, [props.id])

    // useEffect(() => {
    //     if (props.files && props.files.length) {
    //         var a = props.files[0].preview
    //         var b = `<img src='${a}' alt=""/>`
    //         console.log(a, b)
    //         setText(a)
    //     }
    // }, [props.files])

    function setText(t) {
        _setText(t)
        if (props.onChangeText) {
            props.onChangeText(t)
        }
    }

    function handleChange(value, delta, source, editor) {
        if (value !== '<p><br></p>' && props.inboxItem && props.inboxItem.platformId === channelsTypesEnum.WHATSAPP) inboxStore.debouncedSendComposing(props.id)
        if (isReply) {
            setText(editor.getContents())
        } else {
            setTextPrivate(editor.getContents())
        }
    }

    function onPickEmoji(emoji) {
        const setTextChanged = isReply ? setText : setTextPrivate
        const textChanged = isReply ? text : textPrivate

        // const emoji = emojiConverter.replace_colons(":" + emojiObj.name + ":")
        if (textChanged.length) {
            const emojiDelta = new Delta().retain(textChanged.length() - 1).insert(emoji.native)
            setTextChanged((text) => text.compose(emojiDelta))
        } else {
            const newText = new Delta().insert(emoji.native)
            setTextChanged(newText)
        }
    }

    function onChangeReplyTab() {
        quillRef.current.editor.root.dataset.placeholder = !isReply
            ? placeholder.reply
            : placeholder.private
        setIsReply((reply) => !reply)
    }

    // const slashPhrase = false
    const slashPhrase = useMemo(() => {
        try {
            const usedText = isReply ? text : textPrivate
            // const usedTextParsed = usedText.replace(/<[^>]+>/g, "")
            const usedTextParsed = (usedText.ops && usedText.ops[0].insert) || ""
            const slashIndex = usedTextParsed.lastIndexOf("/")
            const tag = usedTextParsed.substring(slashIndex, usedTextParsed.length - 1)
            if (slashIndex > -1 && quickPhraseStore.all.find((x) => ("/" + x.tag).includes(tag))) {
                return tag
            }
        } catch (e) {
            console.log(e)
        }
        return null
    }, [text, textPrivate])

    function onSendText(e) {
        if (e) e.preventDefault()
        if (!slashPhrase && !props.notSend) {
            const item = {
                // content: convertHtmlToDelta(isReply ? text : textPrivate),
                content: JSON.stringify(isReply ? text.ops : textPrivate.ops),
                operatorId: stores.session.user.id,
                createdAt: new Date(),
                type: isReply ? messagesTypes.COMMON : messagesTypes.PRIVATE,
                customerPlatformId: props.id,
                Operator: {
                    id: stores.session.user.id,
                    avatar: stores.session.user.avatar,
                    name: stores.session.user.name,
                },
                replyTo: props.replyMessage
                    ? {
                          id: props.replyMessage.message.id,
                          content: props.replyMessage.message.content,
                          externalId: props.replyMessage.message.externalId,
                      }
                    : undefined,
            }
            inboxStore.saveMessage(item, props.id, inboxStore.messages[props.id].data.length)
            setText("")
            setTextPrivate("")
            props.setReplyMessage(null)
        }
    }

    const className = cx("chat-actions-box", {
        focus: true,
        isPrivate: !isReply,
        disabled: props.disabled,
    })

    function onOpenRecordModal() {
        addModal(<RecordAudioModal id={props.id} />)
    }

    // const blank = "<p><br></p>"
    const isBlank = (t) => {
        return t && t.ops && t.ops[0] && t.ops[0].insert.trim && t.ops[0].insert.trim() === ""
    }

    const sendDisabled = isReply ? !text || isBlank(text) : !textPrivate || isBlank(textPrivate)

    return (
        <div className={className}>
            <div className="chat-actions-box-inner chat-midcol">
                {props.replyMessage && (
                    <div className="reply-message-editor">
                        {props.replyMessage.node}
                        <button
                            className={"close-button"}
                            type="button"
                            onClick={() => {
                                props.setReplyMessage(null)
                            }}
                        >
                            <div className={"icon-trigger " + getIconClassName("ChromeClose")} />
                        </button>
                    </div>
                )}
                <form onSubmit={onSendText} style={{ position: "relative" }}>
                    {/* <div className="actions" style={{ alignItems: "flex-start"}}>
                        <AgentAvatar
                            size={30}
                            agent={stores.session.user}
                            style={{ width: 30, height: 30, marginTop: 6 }}
                        />
                    </div> */}
                    <ReactQuill
                        value={(isReply ? text : textPrivate) || { ops: [] }}
                        onChange={handleChange}
                        theme="bubble"
                        placeholder={isReply ? placeholder.reply : placeholder.private}
                        // onFocus={() => {
                        //     setFocus(true)
                        //     focusGlobal = true
                        // }}
                        // onBlur={() => {
                        //     setFocus(false)
                        //     focusGlobal = false
                        // }}
                        defaultValue={{ ops: [] }}
                        ref={quillRef}
                        modules={modules}
                        onKeyUp={(key) => {
                            if (
                                key.which === 13 &&
                                !key.shiftKey &&
                                (isReply ? !!text : !!textPrivate)
                            ) {
                                onSendText()
                            }
                        }}
                        readOnly={props.disabled}
                        // formats={formats}
                    />
                    <div className="actions-space">
                        <div className="actions">
                            {/* <TagBox customerPlatformId={props.id} /> */}
                            {/* <TagDrop customerPlatformId={props.id} />
                            <ChatActionIco
                                icon="fal fa-scroll-old"
                                description="Frases Rápidas"
                                blur
                                onClick={() => {
                                    setShowQuickPhrases(!showQuickPhrases)
                                }}
                            /> */}
                            {props.inboxItem &&
                                (
                                    props.inboxItem.platformId === channelsTypesEnum.WHATSAPP ||
                                    props.inboxItem.platformId === channelsTypesEnum.TELEGRAM
                                ) && (
                                    <ChatActionIco
                                        icon={getIconClassName("Microphone")}
                                        description="Áudio"
                                        onClick={() => onOpenRecordModal()}
                                        medium
                                        className="chat-only"
                                        // style={{ marginRight: 13, marginTop: 6 }}
                                        // iconStyle={{ fontSize: 22 }}
                                    />
                                )}
                            <ChatActionIco
                                icon={isReply ? getIconClassName("AddNotes") : "fal fa-align-right"}
                                description="Nota pro time"
                                onClick={() => onChangeReplyTab()}
                                medium
                                className="chat-only"
                                // style={{ marginRight: 13, marginTop: 6 }}
                                // iconStyle={{ fontSize: 22 }}
                            />
                            <QuickPhrasesDrop
                                slashPhrase={slashPhrase}
                                quillRef={quillRef}
                                setText={(text) => {
                                    isReply ? setText(text) : setTextPrivate(text)
                                    setTimeout(() => {
                                        quillRef.current.focus()
                                    }, 200)
                                }}
                            />
                            {(props.inboxItem && props.inboxItem.platformId !== channelsTypesEnum.WIDGET) || props.isCampaign 
                                ? (
                                    <ChatActionIco
                                        icon="fal fa-paperclip"
                                        description="Anexar"
                                        onClick={props.openFile}
                                    />
                                )
                                : null
                            }
                            <EmojiPicker onPickEmoji={onPickEmoji} left />
                            <Button
                                className="send chat-only"
                                icon="far fa-arrow-up"
                                primary
                                disabled={sendDisabled}
                                type="submit"
                                style={{ marginLeft: 2 }}
                            />
                        </div>
                    </div>
                </form>

                {/* <div className="separator" /> */}
                <div className="actions">
                    {/* <div className="left">
                    <button
                        className={`action-tab ${isReply ? "active" : ""}`}
                        onClick={() => onChangeReplyTab(true)}
                    >
                        Reply
                    </button>
                    <button
                        className={`action-tab ${isReply ? "" : "active"}`}
                        onClick={() => onChangeReplyTab(false)}
                    >
                        Private Note
                    </button>
                </div> */}

                    <div className="right">
                        {/* <ChatActionIco
                        icon="fal fa-tag"
                        description="Marcador"
                        blur
                        onClick={() => {
                            setShowTags(showTags => !showTags)
                        }}
                    /> */}
                        {/* <div data-tip="Digite texto para enviar">
                            <Button primary className="send" disabled={sendDisabled}>
                                Enviar
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatTextEditor
