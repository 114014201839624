import React from "react"

export default function ModalBody({ children, noPadding, showOverflow }) {
    if (!children) return null
    return (
        <div
            className="modal-body"
            style={{ padding: noPadding ? 0 : '', overflow: showOverflow ? 'visible' : '' }}
        >
            {children}
        </div>
    )
}
