import React from "react"
import { Link } from "react-router-dom"
import Select from "components/forms/Select"
import Box from "components/Box"
import DashLine from "components/graphs/DashLine"
import { getHoursAndMinutes } from "lib/helper"

// const data = [
//     {
//         id: "Tempo de resolução",
//         color: "hsl(86, 70%, 50%)",
//         data: [
//             {
//                 x: "4 Fev",
//                 y: 200
//             },
//             {
//                 x: "5 Fev",
//                 y: 50
//             },
//             {
//                 x: "6 Fev",
//                 y: 150
//             },
//             {
//                 x: "7 Fev",
//                 y: 121
//             },
//             {
//                 x: "8 Fev",
//                 y: 170
//             },
//             {
//                 x: "9 Fev",
//                 y: 170
//             },
//             {
//                 x: "10 Fev",
//                 y: 160
//             }
//         ]
//     },
//     {
//         id: "Tempo de primeira resposta",
//         color: "hsl(86, 70%, 50%)",
//         data: [
//             {
//                 x: "4 Fev",
//                 y: 100
//             },
//             {
//                 x: "5 Fev",
//                 y: 72
//             },
//             {
//                 x: "6 Fev",
//                 y: 41
//             },
//             {
//                 x: "7 Fev",
//                 y: 59
//             },
//             {
//                 x: "8 Fev",
//                 y: 70
//             },
//             {
//                 x: "9 Fev",
//                 y: 26
//             },
//             {
//                 x: "10 Fev",
//                 y: 38
//             }
//         ]
//     }
// ]

const ReportProductivity = ({ height, data }) => (
    <div className="page dash-card graph">
        <Box title="Produtividade">
            <div style={{ height }}>
                <DashLine
                    data={data}
                    axisLeft={{ legend: "" , format: value => {
                        const hour = Math.floor(value / 60)
                        const min = Math.round(value % 60)
                        // return min ? '' : (hour + 'h ')
                        return (hour ? (hour + 'h ') : '') + (min + 'm')
                    } }}
                    legends={{ itemWidth: 180 }}
                    tooltipFormat={obj => {
                        console.log(obj)
                        return getHoursAndMinutes(obj.x)
                    }}
                    // gridYValues={[20, 50, 60]}
                />
            </div>
        </Box>
    </div>
)

export default ReportProductivity
