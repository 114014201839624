import React, { useMemo } from "react"
import { NavLink, Route, Redirect } from "react-router-dom"
import { SortableContainer } from "react-sortable-hoc"
import EventBox from "./EventBox"
import EditPath from "./EditPath"
import EditMessage from "./EditMessage"
import EditInput from "./EditInput"
import EditSupport from "./EditSupport"
import EditTag from "./EditTag"
import EditMenu from "./EditMenu"
import EditGoTo from "./EditGoTo"
import EditResolve from "./EditResolve"
import EditIntegration from "./EditIntegration"
import AddEventDropdown from "./AddEventDropdown"
import Modal from "components/Modal"
const EventBoxSeparator = () => <div className="event-box-separator" />

function getAction(action, id) {
    let title
    let Component
    switch (action) {
        case "message":
            title = "Mensagem"
            Component = EditMessage
            break
        case "input":
            title = "Entrada de Dados"
            Component = EditInput
            break
        case "agent":
            title = "Atendimento"
            Component = EditSupport
            break
        case "menu":
            title = "Menu de opções"
            Component = EditMenu
            break
        case "goto":
            title = "Ir para"
            Component = EditGoTo
            break
        case "integration":
            title = "Integração"
            Component = EditIntegration
            break
        case "path":
            title = "Caminho"
            Component = EditPath
            break
        case "resolve":
            title = "Finalizar conversa"
            Component = EditResolve
            break
        case "tag":
            title = "Categorizar"
            Component = EditTag
            break
        default:
            title = "Mensagem"
            Component = EditMessage
            break
    }
    return { title, Component, action: action.type }
}

function EditFlow({ item, pathGuid, flowPath, ...props }) {
    function onBack() {
        props.history.replace(props.match.url)
    }

    function onEditAction(model) {
        props.store.saveAction(model, pathGuid, item)
        return onBack
    }

    function onDeleteAction(model) {
        props.store.deleteAction(model, pathGuid, item)
        onBack()
    }

    const showAddAction = useMemo(() => {
        let lastActionType
        try {
            lastActionType = (flowPath.actions[flowPath.actions.length - 1] || {}).type
        } catch {
            props.history.replace("/settings/bot")
        }
        return (
            lastActionType !== "menu" &&
            lastActionType !== "agent" &&
            lastActionType !== "goto" &&
            lastActionType !== "path" &&
            lastActionType !== "resolve"
        )
    })

    return (
        <div className="flow">
            {flowPath &&
                flowPath.actions.map((action, i) => (
                    <React.Fragment key={action.guid}>
                        <EventBox action={action} url={props.match.url} item={item} index={i} />
                        {(flowPath.actions.length - 1 != i || showAddAction) && (
                            <EventBoxSeparator />
                        )}
                    </React.Fragment>
                ))}
            {showAddAction && <AddEventDropdown pathGuid={pathGuid} />}
            <Route
                path={props.match.url + "/:action/:guid"}
                render={routeProps => {
                    const action = routeProps.match.params.action
                    const actionGuid = routeProps.match.params.guid
                    let editComponent = getAction(action)
                    const actionItem = item.paths
                        .find(x => x.guid == pathGuid)
                        .actions.find(x => x.guid == actionGuid)

                    return (
                        <Modal onBack={onBack}>
                            <editComponent.Component
                                {...routeProps}
                                item={item}
                                actionItem={actionItem || {}}
                                store={props.store}
                                guid={routeProps.match.params.guid}
                                onEditAction={onEditAction}
                                onDeleteAction={onDeleteAction}
                                onBackModal={onBack}
                            />
                        </Modal>
                    )
                }}
            />
        </div>
    )
}

export default SortableContainer(EditFlow)
