import React, { useMemo, useEffect } from "react"
import { Redirect, Link } from "react-router-dom"
import { getRoutes } from "components/Router"
import Button from "components/forms/Button"
import newMessageSound from "assets/sounds/new_message.ogg"
import { Switch } from "react-router-dom"
import Sidenav from "components/sidenav/Sidenav.js"
import Sidebar from "components/sidebar/Sidebar.js"
import { general, session, inbox, useStore, operator, channel, group } from "store"
import { useFetch } from "hooks"
import Modal from "components/Modal"
import toaster from "lib/toaster"
import Header from "components/header/Header"
import BannerNotification from "components/BannerNotification"
import cx from "classnames"
import { channelsTypesEnum } from "lib/constants"

export default function BasicLayout({ routes, ...props }) {
    useFetch(operator)
    useFetch(group)
    // useFetch(channel)

    const { user, workspace, resendEmail, isExpired } = useStore(session)
    const inboxStore = useStore(inbox)
    const channelStore = useStore(channel)
    const {
        breadcrumb,
        _domain,
        modal,
        modalConfigs,
        removeModal,
        bannerNotification,
        addBannerNotification,
        removeBannerNotification,
        showChannelDisconnectedBanner
    } = useStore(general)

    useEffect(() => {
        // try {
        //     window.FB.CustomerChat.hide()
        //     window.FB.Event.subscribe('customerchat.load', () => {
        //         window.FB.CustomerChat.hide()
        //     })
        // } catch(e) {
        //     console.log(e)
        // }
        channelStore.get().then(x => {
            const disconnectedZap = (x.data || []).find(
                x => x.channelId === channelsTypesEnum.WHATSAPP && x.status === "disconnected"
            )
            if (disconnectedZap) {
                showChannelDisconnectedBanner(disconnectedZap, `O canal de Whatsapp "${disconnectedZap.description}" foi desconectado`)
            }
        })
    }, [])


    if (!user.email && !user.isRecovering) {
        return <Redirect to="/user/login" />
    }

    // useEffect(() => {
    //     inboxStore.getFavorites()
    // }, [workspace.workspaceId])

    const socketUrl = useMemo(() => {
        return `${_domain.url.slice(0, _domain.url.length - 4)}/chat/workspace/${
            workspace.workspaceId
        }?token=${workspace.token}`
    }, [_domain, workspace.workspaceId])

    useEffect(() => {
        inboxStore.initSocket(socketUrl)
        return () =>
            inboxStore.socket && inboxStore.socket.disconnect && inboxStore.socket.disconnect()
    }, [socketUrl, socketUrl])

    async function handleResendEmail() {
        const response = await resendEmail()
        if (response && !response.erros) {
            toaster.ok("O e-mail foi reenviado, por favor cheque sua caixa de entrada")
        } else {
            toaster.error(
                "Ocorreu um erro ao enviar o e-mail, por favor tente novamente mais tarde"
            )
        }
    }

    const isSettings = useMemo(() => props.location.pathname.includes("/settings"), [
        props.location.pathname
    ])

    const isInbox = useMemo(() => props.location.pathname.includes("/inbox"), [
        props.location.pathname
    ])

    const isCampaign = useMemo(() => props.location.pathname.includes("/campaigns"), [
        props.location.pathname
    ])

    const isEmailUnverified = user.verificationLevel <= 1

    return (
        <div className="basic-layout">
            <Header {...props} />
            <div className="basic-layout-content">
                {/* <Sidenav {...props} /> */}
                {!isCampaign && <Sidebar {...props} isSettings={isSettings} isInbox={isInbox} />}
                <section
                    className={cx("layout-main", {
                        "email-unverified": isEmailUnverified || isExpired
                    })}
                >
                    {isEmailUnverified && (
                        <div className="email-confirm">
                            <span>
                                Por favor, cheque seu e-mail e siga as intruções para confirmar sua
                                conta.
                            </span>
                            <button onClick={handleResendEmail}>Reenviar e-mail</button>
                        </div>
                    )}
                    {isExpired && (
                        <div className="email-confirm" style={{ backgroundColor: "#e3b200" }}>
                            <span>
                                Seu plano expirou. Por favor, selecione um plano nas configurações
                                de assinatura.
                            </span>
                            <Link to="/settings/billing/license">Atualizar Plano</Link>
                        </div>
                    )}
                    {/* <div className="layout-header-space">
                            <div className="layout-header">
                                <div className="page-info">
                                    {breadcrumb.backTo && (
                                        <Button
                                            className="go-back-arrow"
                                            icon="far fa-angle-left"
                                            to={breadcrumb.backTo}
                                            round
                                        />
                                    )}
                                    <h3>{breadcrumb.title}</h3>
                                    </div>
                                    </div>
                                </div> */}
                    <Switch>{getRoutes(routes)}</Switch>
                </section>
            </div>
            {modal && (
                <Modal onBack={() => removeModal(null)} {...modalConfigs}>
                    {modal}
                </Modal>
            )}
            {bannerNotification && <BannerNotification {...bannerNotification} />}
            <audio id="pop" src={newMessageSound} />
            {/* {!inboxStore.connected && (
                        <Modal>
                            <div className="dialog-inner">
                                <div style={{ marginBottom: 10, fontSize: 16 }}>
                                    Ops... você foi desconectado. Tentando reconectar...
                                </div>
                                <Button primary>Conectar</Button>
                            </div>
                        </Modal>
                    )} */}
        </div>
    )
}
