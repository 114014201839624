import React, { Component } from "react"
import Switch from "components/forms/Switch"
import HourBlock from "./HourBlock"

class DayBlock extends Component {
    onChangeWorkToday = () => {
        // this.setState(state => ({ active: !state.active }))
        this.props.setDay({
            ...this.props.daySchedule,
            active: !this.props.daySchedule.active
        })
    }

    onChangeHour = (index, value) => {
        const hours = [...this.props.daySchedule.hours]
        hours[index] = value
        this.props.setDay({
            ...this.props.daySchedule,
            hours
        })
    }

    onAddHour = () => {
        const hours = [...this.props.daySchedule.hours]
        hours.push({
            startHourIndex: 0,
            endHourIndex: 1
        })
        this.props.setDay({
            ...this.props.daySchedule,
            hours
        })
    }

    onDeleteHour = index => {
        const hours = [...this.props.daySchedule.hours].filter((h, i) => i !== index)
        this.props.setDay({
            ...this.props.daySchedule,
            hours
        })
    }

    render() {
        const { day, daySchedule } = this.props
        const { active, hours } = daySchedule
        return (
            <div className="business-day-block">
                <div className="day-left">
                    <Switch
                        label={day}
                        checked={active}
                        onChange={checked => this.onChangeWorkToday(checked)}
                        height={18}
                        width={37}
                    />
                </div>
                <div className="day-right">
                    {active ? (
                        hours.map((h, i) => (
                            <HourBlock
                                onChangeHour={this.onChangeHour}
                                onAddHour={this.onAddHour}
                                onDeleteHour={this.onDeleteHour}
                                hour={h}
                                index={i}
                                isLast={i === hours.length - 1}
                                key={i}
                            />
                        ))
                    ) : (
                        <div className="closed">Fechado o dia todo</div>
                    )}
                </div>
            </div>
        )
    }
}

export default DayBlock
