import React, { useEffect } from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm } from "hooks"
import { useStore, quickPhrase } from "store"
import config from "config"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

const visibilityOptions = [
    { value: "public", label: "Todos os agentes" },
    { value: "private", label: "Apenas a mim" }
]

function EditQuickPhrase(props) {
    const item = props.item || {}
    const quickPhraseStore = useStore(quickPhrase)

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: `Agente criou uma frase rápida do tipo ${model.privacy}`
            })
            await quickPhraseStore.save({
                data: model,
                toastMessage: "Frase " + (item.id ? "atualizada." : "criada.")
            })
            return props.onBackModal
        },
        defaultModel: props.item
    })

    const privacy = useSelectField("privacy", {
        defaultValue: item.privacy || "public",
        form,
        options: visibilityOptions
    })

    const phrase = useField("phrase", {
        defaultValue: item.phrase,
        form
    })

    const tag = useField("tag", {
        defaultValue: item.tag,
        mask: ["beginWithSlash"],
        form
    })

    return (
        <FormDialog
            {...props}
            superTitle="Adicionar Frase"
            onSubmit={form.onSubmit}
            item={item}
            onDelete={async () => {
                await quickPhraseStore.remove({
                    data: { id: item.id },
                    toastMessage: "Frase excluída."
                })
                props.onBackModal()
            }}
            endpoint={config.api.QUICK_PHRASES}
        >
            <Text label="Frase" required {...phrase} rows={3} />
            <Text label="Tag de atribuição" placeholder="/hello" required {...tag} />
            <Select label="Visivel para" required {...privacy} />
        </FormDialog>
    )
}

export default EditQuickPhrase
