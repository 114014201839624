import React, { Component } from "react"
import ReactSelect from "react-select"
import cx from "classnames"
import masks from "lib/masks"

// import "@material/react-text-field/dist/text-field.css"

class Select extends Component {
    // state = {
    //     value: ""
    // }

    // componentWillMount() {
    //     if (this.props.value) {
    //         this.setState({ value: this.props.value })
    //     }
    // }

    // componentWillReceiveProps(props) {
    //     if (props.value != this.state.value) {
    //         this.setState({ value: props.value })
    //     }
    // }

    // onChange = item => {
    //     if (this.props.onChange) {
    //         this.props.onChange(item, this.props.name)
    //     }
    // }

    render() {
        const {
            label,
            options,
            name,
            invisible,
            small,
            dash,
            className,
            maxWidth,
            placeholder,
            white,
            gray,
            leftIcon,
            flat,
            mb,
            value,
            emptiable,
            style,
            loader,
            required,
            menuPortalTarget,
            ...props
        } = this.props
        const classname = cx("select-space", className, {
            invisible,
            small,
            dash,
            maxWidth,
            "text-margin-bottom": mb,
            grayL: !white,
            gray,
            flat
        })
        const selectValueOption =
            typeof value === "string" && value.length ? options.find(x => x.value === value) : value
        return (
            <div className="input-space" style={style}>
                {leftIcon && <i className={`input-left-icon ${leftIcon}`} />}
                <div className={classname} style={{ maxWidth }}>
                    {label && <label className="label-input">{label}</label>}
                    <ReactSelect
                        {...props}
                        options={emptiable ? [{ value: "", label: "" }, ...options] : options}
                        value={selectValueOption || ""}
                        // value={options.filter(e => e.value === value)}
                        // onChange={this.onChange}
                        classNamePrefix="react-select"
                        placeholder={placeholder || ""}
                        menuPortalTarget={menuPortalTarget !== undefined ? menuPortalTarget : document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPosition={'absolute'}
                        menuShouldBlockScroll={true}
                        menuPlacement={'auto'}
                    />
                    {!props.disabled && (
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, height: 0 }}
                            value={selectValueOption || ""}
                            required={required}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default Select
