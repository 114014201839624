import React, { Component } from "react"
import cx from "classnames"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"

export default class Menu extends Component {
    constructor() {
        super()

        this.state = {
            showMenu: false
        }

        this.showMenu = this.showMenu.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
    }

    showMenu(event) {
        event.preventDefault()

        if (this.props.onOpen) this.props.onOpen()
        this.setState({ showMenu: true }, () => {
            document.addEventListener("click", this.closeMenu)
        })
    }

    closeMenu(event) {
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            if (this.props.onClose) this.props.onClose()
            this.setState({ showMenu: false }, () => {
                document.removeEventListener("click", this.closeMenu)
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.closeMenu)
    }

    render() {
        const { children, trigger, bottomDropdown } = this.props
        const className = cx("menu-space", { bottomDropdown })
        return (
            <div className={className}>
                <a onClick={this.showMenu} style={{ width: "100%" }}>
                    {trigger}
                </a>
                {/* <ReactCSSTransitionGroup
                    transitionName="menu-fade"
                    transitionEnterTimeout={100}
                    transitionLeaveTimeout={100}
                > */}
                {this.state.showMenu ? (
                    <div
                        className={"menu " + (this.state.showMenu ? "show" : "")}
                        ref={element => {
                            this.dropdownMenu = element
                        }}
                    >
                        <div className="menu-inner">{children}</div>
                    </div>
                ) : null}
                {/* </ReactCSSTransitionGroup> */}
            </div>
        )
    }
}
