import React, { useState } from "react"
import numeral from "numeral"
import { Link, Redirect } from "react-router-dom"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import landingLogo from "assets/images/landing-logo.png"
import landingDark from "assets/images/landing-logo-dark.png"
import Typist from "react-typist"
import "react-typist/dist/Typist.css"
import { general, session, company, useStore } from "store"
import { useField } from "hooks"
import Slider, { Range } from "rc-slider"
import "rc-slider/assets/index.css"
import { access } from "fs"

const manFeatures = [
    {
        minValue: 2000,
        features: [
            "Deixar rodando API e BO no servidor e manter app nas stores",
            "Manter SDKs das plataformas mobile atualizados (se não são descontinuados), o que leva a constantes releases",
            "Build e Resubmissão de apps sempre que necessário (Atualizações de descrição, imagens, etc)",
        ]
    },
    {
        minValue: 4000,
        features: ["Análise de APIs", "Análise de problemas"]
    },
    {
        minValue: 6000,
        features: ["Correções de bugs", "Disponibilidade em horário comercial para suporte", "Análise e correção de possível inconsistência de dados no Banco de Dados"]
    },
    {
        minValue: 8000,
        features: ["Disponibilidade de emergência em qualquer horário"]
    },
    {
        minValue: 10000,
        features: ["Desenvolvimento novas features pequenas (Até 20 SP) se não estiver em sprint"]
    },
    {
        minValue: 22000,
        features: []
    }
]

function Ganhomais(props) {
    const { user } = useStore(session)
    const [sliderValue, setSliderValue] = useState(10000)
    const [firstTimeTyping, setFirstTimeTyping] = useState(true)

    if (user.email) {
        return <Redirect to="/inbox" />
    }

    const marks = manFeatures.reduce((acc, v) => ({ ...acc, [v.minValue]: {} }), {})

    console.log(marks)

    return (
        <div className="ganhomais">
            <div className="midcol">
                <div className="left">
                    <div className="value-block">
                        <span className="title">
                            {sliderValue === 22000 ? "Contrato de desenvolvimento" : "Manutenção"}
                        </span>
                        <span className="value">R$ {numeral(sliderValue).format("R$ 0,0.00")}</span>
                    </div>
                    {manFeatures.map(x =>
                        x.features.map(feat => (
                            <span className="feature">
                                {sliderValue >= x.minValue ? "✅" : "❌"} {feat}
                            </span>
                        ))
                    )}
                    {sliderValue === 22000 ? (
                        <span className="feature">
                            ✅ Desenvolvimento geral 100 SP - 10 dias úteis (2x)
                        </span>
                    ) : (
                        <span className="feature" />
                    )}
                    {/* <span className="feature">✅ Manter rodando API e BO</span>
                    <span className="feature">✅ Análise de problemas</span> */}
                </div>
                {sliderValue < 22000 && (
                    <div className="right">
                        <div className="value-block">
                            <span className="title">Sprint</span>
                            <span className="value">
                                R$ {numeral((22000 - sliderValue) / 2).format("R$ 0,0.00")}
                            </span>
                        </div>
                        <span className="feature">
                        ✅ Desenvolvimento geral 100 SP - 10 dias úteis
                        </span>
                        <span className="feature">
                        ✅ Inclui todos os features de manutenção durante a sprint
                        </span>
                        <span className="feature">
                        ✅ Reuniões de desenvolvimento
                        </span>
                    </div>
                )}

                {/* {manFeatures
                        .filter(x => sliderValue < x.minValue)
                        .map(x => (
                            <span className="feature">✅ {x.name}</span>
                        ))} */}
                {/* <span className="feature">✅ Manter rodando API e BO</span>
                    <span className="feature">✅ Análise de APIs</span>
                    <span className="feature">✅ Análise de problemas</span> */}
                {/* <div className="divisao-valores" /> */}
                {/* <div className="separator"></div> */}
            </div>
            <div className="midcol">
                <Slider
                    defaultValue={10000}
                    // step={1000}
                    step={null}
                    // marks={[
                    //     {
                    //         value: 2000
                    //     },
                    //     {
                    //         value: 4000
                    //     }
                    // ]}
                    marks={marks}
                    min={0}
                    max={22000}
                    onChange={value => {
                        setSliderValue(value)
                    }}
                />
            </div>
            {/* <Range /> */}
        </div>
    )
}

export default Ganhomais
