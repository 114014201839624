import React from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import { facebook } from "store"

function EditFacebook(props) {
    const item = props.item

    return (
        <FormDialog {...props} title="Página do facebook" noSalvable>
            {item.name && <Text label="Nome" required value={item.name} disabled />}
        </FormDialog>
    )
}

export default EditFacebook
