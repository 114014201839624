import React, { useEffect } from "react"
import Modal from "components/Modal"
import { stores, useStore, general } from "components/Modal"

export default function ModalBase(props) {
    const id = props.match.params.id
    const item =
        id !== "new"
            ? props.store.all.find(e => e.id == id) || {
                  isLoading: true
              }
            : {}

    // useEffect(() => {
    //     if (item.isLoading) {
    //         props.store.getOne(id)
    //     }
    //     return () => props.store.clearOne()
    // }, [])

    // const generalStore
    // /* Loading */
    // setTimeout(() => {
    //     stores.general.addLoader(loader)
    //     console.log("999999", stores.general.loaders)
    // }, 1)

    const Component =
        !!props.ViewComponent && id !== "new" ? props.ViewComponent : props.EditComponent

    const component = (
        <Component
            {...props}
            item={item.isLoading ? props.store.one || item : item}
            endpoint={props.store.endpoint}
            onSubmit={props.onSubmit}
            onDelete={props.onDelete}
            store={props.store}
        />
    )
    if (props.noModal) {
        return <div className={props.midcol ? "midcol" : ""}>{component}</div>
    }
    return (
        <Modal
            onBack={props.onBackModal}
            className={id === "new" && props.newComponentModalClassname}
        >
            {component}
        </Modal>
    )
}
