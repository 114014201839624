import React from "react"
import queryString from "query-string"
import { Link } from "react-router-dom"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import Fade from "react-reveal/Fade"
import AuthBase from "../AuthBase"
import { useField, useForm } from "hooks"
import { session, useStore } from "store"

function Register(props) {
    const { completeRegister } = useStore(session)
    const item = queryString.parse(props.location.search)

    const form = useForm({
        onSubmit: async formData => {
            const data = await completeRegister({
                data: {
                    ...formData,
                    token: item.token
                }
            })
            return props.history.push("/user/join/register/success", data)
        }
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })
    // const workspace = useField("workspace", {
    //     defaultValue: item.workspace,
    //     form
    // })
    const email = useField("email", {
        defaultValue: item.email,
        form
    })
    // const phone = useField("cellphone", {
    //     mask: ["cell"],
    //     form
    // })

    const password = useField("password", {
        form
        // validations: [
        //     value =>
        //         value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"
        // ]
    })
    // const confirmPassword = useField("confirmPassword", {
    //     form,
    //     validations: [
    //         (value, formData) => value !== formData.password && "Suas senhas estão diferentes",
    //         value => value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"
    //     ]
    // })

    return (
        <AuthBase>
            <Fade>
                <form className="auth-box" onSubmit={form.onSubmit}>
                    <h1 className="headline">Complete seu cadastro para ativar sua conta...</h1>
                    <Text label="Nome completo" placeholder="Primeiro e último nome" required {...name} />
                    <Text label="Email de trabalho" type="email" placeholder="você@suaempresa.com" required {...email} />
                    {/* <Text
                        label="Telefone"
                        required
                        placeholder="(xx) xxxx-xxxx"
                        name="oi"
                        autofill="off"
                        autoComplete="off"
                        {...phone}
                    /> */}
                    <Text
                        label="Senha"
                        type="password"
                        required
                        autofill="off"
                        autoComplete="new-password"
                        {...password}
                    />
                    {/* <Text
                        label="Confirmar Senha"
                        type="password"
                        autoComplete="new-password"
                        required
                        {...confirmPassword}
                    /> */}

                    <div className="login-actions">
                        <Button type="submit" className="login-btn" primary loader="register">
                            Finalizar cadastro
                        </Button>
                    </div>
                </form>
            </Fade>
        </AuthBase>
    )
}

export default Register
