import React from "react"
import cx from "classnames"

export default function ModalMultiPage({ length, index }) {
    return (
        <div className="modal-page-indicators">
            {Array(length)
                .fill()
                .map((x, i) => (
                    <div
                        className={cx("mp-indicator", { active: i === index })}
                        key={"indicator" + i}
                    ></div>
                ))}
        </div>
    )
}
