import React, { useState } from "react"
import Text from "components/forms/Text"
import SearchText from "components/forms/SearchText"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"
import config from "config"
import req from "lib/req"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function AddPropertyModal(props) {
    const item = props.item || {}
    const [propKeys, setPropKeys] = useState([])
    const [propValues, setPropValues] = useState([])

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: `Agente criou uma propriedade do tipo ${model.label}`
            })
            await props.onSubmit(model)
            return props.onBackModal
        },
        defaultModel: props.item
    })

    const label = useField("label", {
        defaultValue: item.label,
        form
    })
    const value = useField("value", {
        defaultValue: item.value,
        mask: props.valueMask,
        form
    })

    async function searchProperties(query) {
        const state = Object.hasOwn(query, 'value') ? 'value' : 'property'
        const setState = state === 'value' ? setPropValues : setPropKeys

        try {
            const response = await req(config.api.COSTUMERS_PROPERTIES, {
                data: {
                    limit: 20,
                    ...query
                }
            })
            const formattedState = response
                .map((item) => ({
                    value: item[state],
                    label: item[state]
                }))
            setState(formattedState)
        } catch (error) {
            setState([])
        }
    }

    return (
        <FormDialog
            {...props}
            title="Propriedade"
            superTitle={props.fixed && "Alterar " + item.label}
            onSubmit={form.onSubmit}
            item={item}
            onDelete={() => {
                if (props.onDelete) props.onDelete(item.id)
                props.onBackModal()
            }}
            endpoint={config.api.CUSTOMER_DETAILS}
            noExcludable={props.fixed}
            showOverflow
        >
            {props.fixed ? (
                <Text
                    label={item.label}
                    {...value}
                    placeholder={props.valuePlaceholder || "e.g: Maria da Silva"}
                />
            ) : (
                <>
                    <SearchText
                        label="Nome do Campo"
                        required
                        {...label}
                        placeholder="e.g: Nome da mãe"
                        options={propKeys}
                        onOptionClick={(item) => label.setValue(item)}
                        searchFunction={() => searchProperties({ property: label.value })}
                        observableValues={[ label.value ]}
                    />
                    <SearchText
                        label="Valor"
                        required
                        {...value}
                        placeholder={props.valuePlaceholder || "e.g: Maria da Silva"}
                        options={propValues}
                        onOptionClick={(item) => value.setValue(item)}
                        searchFunction={() => searchProperties({ property: label.value, value: value.value })}
                        observableValues={[ value.value, label.value ]}
                        updateWhenFocused
                    />
                </>
            )}
        </FormDialog>
    )
}

export default AddPropertyModal
