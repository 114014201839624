import React, { useEffect } from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm } from "hooks"
import { useStore, session } from "store"
import config from "config"

/**
 * Google Analytics
 */
import { analyticsCategory } from 'lib/constants'
import ReactGA from 'react-ga'

function CreateWorkspace(props) {
    const sessionStore = useStore(session)

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: 'Agente criou um workspace'
            })
            await sessionStore.createWorkspace({ data: model }, props.history)
        }
    })

    const workspace = useField("name", {
        defaultValue: "",
        form
    })

    return (
        <FormDialog
            {...props}
            superTitle="Criar Workspace"
            item={{}}
            onSubmit={form.onSubmit}
            endpoint={config.api.WORKSPACE}
            noExcludable
        >
            <Text label="Nome do workspace" required {...workspace} />
        </FormDialog>
    )
}

export default CreateWorkspace
