import React, { useRef } from "react"
import ReactDOM from "react-dom"
import Button from "components/forms/Button"
import cx from "classnames"
import { useModalClick, useKeyPress } from "hooks"
import { general, useStore } from "store"

function Modal(props) {
    useModalClick(onClickOut, !!props.children)
    useKeyPress(onKeyPress, { condition: true })
    const { removeModal } = useStore(general)
    const className = cx("modal-space", props.className, { open: props.children })
    let overlayRef = useRef()

    function onKeyPress({ key, keyCode }) {
        if (key === "Escape" || keyCode === 27) {
            onClose()
        }
    }

    function onClickOut(e) {
        if (overlayRef && overlayRef.current.contains(e.target)) {
            onClose()
        }
    }

    function onClose() {
        if (props.onBack) {
            props.onBack()
        } else {
            removeModal()
        }
    }

    return ReactDOM.createPortal(
        <div className={className}>
            <div className="modal-overlay">
                <div
                    className="modal-overlay-clickable"
                    id="modal-overlay-clickable"
                    ref={overlayRef}
                />
                <div className={"modal-box" + (props.side ? " side" : "")} style={props.style}>
                    {/* {props.title && (
                        <div className="modal-header">
                            <span className="modal-header-title">{props.title}</span>
                            
                        </div>
                    )} */}
                    {props.onBack && (
                        <Button
                            icon="fal fa-times"
                            round
                            className="modal-closer"
                            onClick={onClose}
                        />
                    )}
                    {props.children}
                </div>
            </div>
        </div>,
        document.getElementById("modal-root")
    )
}

export default Modal
