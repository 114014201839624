import React, { useEffect, useState } from "react"
import cx from "classnames"
import Conversations from "./Conversations"
import Chat from "./Chat"
import ChatSide from "components/chat/side/ChatSide"
import ChatSideNav from "components/chat/side/ChatSideNav"
import PanelDetail from "components/PanelDetail"
import { useBreadcrumb, useFetch } from "hooks"
import { inbox, operator, tag, segment, group, useStore, quickPhrase, session, plugin,bot } from "store"
import queryString from "query-string"
import NoDataChat from "./NoDataChat"

// function ChatHeader

function Inbox(props) {
    const [isTabFocused, setTabFocused] = useState(false)
    useBreadcrumb({ title: "Mensagens" })
    useFetch(tag)
    useFetch(quickPhrase)
    useFetch(plugin)
    useFetch(bot)
    const inboxStore = useStore(inbox)

    function onClosePanel() {
        props.history.push("/inbox/new")
    }

    const id = props.match.params.id
    const segmentId = props.match.params.segment
    const classname = cx("page-layout inbox", {
        open: id
    })

    function onTabFocus() {
        setTabFocused(true)
    }

    function onTabBlur() {
        setTabFocused(false)
    }

    useEffect(() => {
        if (isTabFocused) {
            inboxStore.setUnread(0)
        }
    }, [inboxStore.unread, isTabFocused])

    // Tab Focus / Blur
    useEffect(() => {
        window.addEventListener("focus", onTabFocus)
        window.addEventListener("blur", onTabBlur)
        return () => {
            window.removeEventListener("focus", onTabFocus)
            window.removeEventListener("blur", onTabBlur)
        }
    }, [])

    // Carrega mais conversas
    useEffect(() => {
        inboxStore.clear()
        inboxStore.get({
            params: {
                ...queryString.parse(props.location.search),
                segmentId,
                limit: 20
            }
        })
        inboxStore.setUnread(0)
    }, [props.location.search, props.match.params.segment])

    useEffect(() => {
        inboxStore.setActiveConversationId(id)
    }, [id])

    const unresolved = inboxStore.all.filter(x => !x.isFinished).length

    return (
        <div>
            <div className="page-layout has-subheader" style={{ paddingBottom: 0 }}>
                <div className={classname}>
                    <Conversations
                        {...props}
                        id={id}
                        segmentId={segmentId}
                        inboxStore={inboxStore}
                    />
                    {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    {/* <header className="context-header" /> */}
                    <PanelDetail onClose={onClosePanel}>
                        {id && inboxStore.totalCount !== -1 ? (
                            <Chat
                                {...props}
                                id={id}
                                inboxStore={inboxStore}
                                query={queryString.parse(props.location.search)}
                            />
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                <div className="header-mock-shadow"></div>
                                <NoDataChat inboxStore={inboxStore} unresolved={unresolved} />
                            </div>
                        )}
                        {/* <ChatSide id={id} location={props.location} />
                        <ChatSideNav /> */}
                    </PanelDetail>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Inbox
