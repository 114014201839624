import React from "react"
// import { useLocalStorage } from "react-use"
import Select from "components/forms/Select"
import SettingsHeader from "components/settings/SettingsHeader"
import { useStore, general } from "store"
import Text from "components/forms/Text"
import Radiobox from "components/forms/Radiobox"
import { defaultThemes } from "lib/constants"

function getColors(theme) {
    const themeSplitted = theme.colors.split(",")
    return {
        SIDEBAR_BG: themeSplitted[0],
        MENU_BG_HOVER: themeSplitted[1],
        ACTIVE_ITEM: themeSplitted[2],
        ACTIVE_ITEM_TEXT: themeSplitted[3],
        HOVER_ITEM: themeSplitted[4],
        TEXT_COLOR: themeSplitted[5],
        ACTIVE_PRESENCE: themeSplitted[6],
        MENTION_BADGE: themeSplitted[7],
        name: theme.name
    }
}

function ThemeItem({ theme, themeColors, setThemeColors }) {
    const colors = getColors(theme)
    return (
        <a className="theme-item-preference" onClick={() => setThemeColors(theme.colors)}>
            <Radiobox name="theme" checked={themeColors === theme.colors}>
                {theme.name}
            </Radiobox>
            <div className="theme-preview" style={{ backgroundColor: colors.SIDEBAR_BG }}>
                <div className="tp-line">
                    <div
                        className="tp-shadow"
                        style={{ width: 30, backgroundColor: colors.TEXT_COLOR }}
                    />
                </div>
                <div className="tp-line selected" style={{ backgroundColor: colors.ACTIVE_ITEM }}>
                    <div className="tp-shadow" style={{ backgroundColor: colors.ACTIVE_ITEM_TEXT }} />
                </div>

                <div className="tp-line">
                    <div className="tp-shadow" style={{ backgroundColor: colors.TEXT_COLOR }} />
                    <div className="tp-count" style={{ backgroundColor: colors.MENTION_BADGE }} />
                </div>
            </div>
        </a>
    )
}

function EditPreferences({ webhookRef }) {
    const { themeColors, setThemeColors } = useStore(general)
    return (
        <div>
            <SettingsHeader title="Preferências" />
            <br />
            {/* <label htmlFor="" className="label-input">
                Tema escuro
            </label> */}
            <div className="settings-midcols">
                <h1>Tema</h1>
                <p>Personalize a aparência do seu Workspace. Apenas você verá isso.</p>
                <br />
                <div className="themes-defaults">
                    {defaultThemes.map(x => (
                        <ThemeItem
                            theme={x}
                            themeColors={themeColors}
                            setThemeColors={setThemeColors}
                        />
                    ))}
                </div>

                <div style={{ maxWidth: 600 }}>
                    <Text
                        label="Tema personalizado"
                        value={themeColors}
                        required
                        onChange={e => setThemeColors(e.target.value)}
                    />
                </div>
            </div>
            {/* <Switch
                small
                checked={false}
                onClick={() => {
                }}
            /> */}
        </div>
    )
}

export default EditPreferences
