import React, { useMemo, useEffect } from "react"
import AgentAvatar from "components/AgentAvatar"
import DefaultAvatar from "components/DefaultAvatar"
import { Link } from "react-router-dom"
// import whatsappBlue from "assets/images/whatsappBlue.png"
import { buildUrlQuery } from "lib/helper"
import cx from "classnames"
import { channelsTypesEnum } from "lib/constants"
import { operator, useStore, inbox } from "store"
import ClampLines from "react-clamp-lines"
import { isSameDay } from "lib/dates"
import bonita from "data-bonita"
import whatsappIcon from "assets/icons/svg/whatsapp.svg"
import instagramIcon from "assets/icons/svg/instagram.svg"
import messengerIcon from "assets/icons/svg/messenger.svg"
import telegramIcon from "assets/icons/svg/telegram.svg"
import gmailIcon from "assets/icons/svg/gmail.svg"
import customIcon from "assets/images/customc.png"
import widgetIcon from "assets/images/chat-widget.svg"
import TagBox from "components/chat/TagBox"
import masks from "lib/masks"

function parseLastMessage(message) {
    if (!message) return ""
    const parsedMessage = JSON.parse(message)
    const msg = parsedMessage.reduce((acc, curr) => {
        if (curr.insert === null) {}

        if (typeof curr.insert === "string") {
            acc = acc + " " + curr.insert
        } 

        if (curr.insert && typeof curr.insert === "object") {
            if (curr.insert.image) {
                acc = acc + " Enviou uma imagem 🖼️ "
            }
            else if (curr.insert.audio) {
                acc = acc + " Mensagem de audio 🔊 "
            }
            else if (curr.insert.video || curr.insert.media_video) {
                acc = acc + " Mensagem de  video 📹 "
            }
            else if (curr.insert.document) {
                acc = acc + " Enviou um arquivo 📁"
            }
            
            else {
                acc = acc + " Arquivo anexado 📁"
            }
        }
        return acc
    }, "")
    return msg
}

function getPlatformById(id) {
    switch (id) {
        case channelsTypesEnum.WHATSAPP:
            return <i className="channel-bg" style={{ backgroundImage: `url(${whatsappIcon})` }} />
        case channelsTypesEnum.INSTAGRAM:
            return <i className="channel-bg" style={{ backgroundImage: `url(${instagramIcon})` }} />
        case channelsTypesEnum.FACEBOOK:
            return <i className="channel-bg" style={{ backgroundImage: `url(${messengerIcon})` }} />
        case channelsTypesEnum.TELEGRAM:
            return (
                <i
                    className="channel-bg"
                    style={{
                        backgroundImage: `url(${telegramIcon})`,
                        borderRadius: "50%"
                    }}
                />
            )
        case channelsTypesEnum.EMAIL:
            return <i className="channel-bg" style={{ backgroundImage: `url(${gmailIcon})` }} />
        case channelsTypesEnum.CUSTOM:
            return <i className="channel-bg" style={{ backgroundImage: `url(${customIcon})` }} />
        case channelsTypesEnum.WIDGET:
            return <i className="channel-bg" style={{ backgroundImage: `url(${widgetIcon})` }} />

        default:
            break
    }
}

function ConversationItem({ item, ...props }) {
    const operatorStore = useStore(operator)
    const { favorites } = useStore(inbox)
    const pathname = useMemo(() => `/inbox/${props.match.params.segment}/${item.id}`, [
        item.id,
        props.location
    ])

    const selected = useMemo(() => item.id == props.match.params.id, [item.id, props.location])
    const lastRes = useMemo(() => item.lastMessage && parseLastMessage(item.lastMessage.content))

    const messageDate = useMemo(() => {
        try {
            // debugger'
            const createdDate = (item.lastMessage.createdAt && new Date(item.lastMessage.createdAt)) || new Date(item.lastMessageAt)
            if (!isSameDay(new Date(), createdDate)) {
                return bonita.delta(createdDate)
            }
            const createdAt = Intl.DateTimeFormat("pt-BR", {
                hour: "numeric",
                minute: "numeric"
            }).format(createdDate)

            return createdAt
            // return createdAt.substring(0, createdAt.length - 6)
        } catch (e) {
            return ""
        }
    })

    const operatorAssigned = useMemo(
        () => operatorStore.all.find(x => x.id == item.operatorId) || {},
        [item.operatorId, operatorStore.all]
    )

    const name = useMemo(
        () => item.name || masks.cell(item.phone) || item.email || "Visitante" + item.customerId,
        [item]
    )

    return (
        <Link
            to={{
                search: props.location.search,
                pathname
            }}
            className={cx("message-item", {
                selected,
                hasNewMessages: item.hasNewMessages,
                assigned: operatorAssigned.name
            })}
        >
            <div className="left">
                {item.hasNewMessages && !item.isFinished && !item.starred && (
                    <span className="unread-icon">
                        <i className="fa fa-circle" />
                    </span>
                )}
                {item.starred && !item.isFinished && (
                    <span className="unread-icon">
                        <i
                            className="fa fa-star"
                            style={
                                item.hasNewMessages
                                    ? {
                                          color: "rgb(63, 130, 239)",
                                          fontSize: 12
                                      }
                                    : { color: "#ffbf43", fontSize: 12 }
                            }
                        />
                    </span>
                )}
                {item.isFinished && (
                    <span className="finished-icon">
                        <i className="fa fa-check-circle" style={{ color: "#27ae60" }} />
                    </span>
                )}
            </div>
            <div
                className="message-item-content"
                style={item.isFinished && !selected ? { opacity: 0.6 } : {}}
            >
                <div className="mid">
                    {item.avatar ? (
                        <img
                            src={item.avatar}
                            className="person-avatar-inbox"
                            alt=""
                            onError={e => {
                                e.target.onerror = null
                                e.target.src =
                                    "https://static.licdn.com/scds/common/u/images/themes/katy/ghosts/person/ghost_person_200x200_v1.png"
                            }}
                        />
                    ) : (
                        <DefaultAvatar
                            size={48}
                            fontSize={30}
                            index={item.id}
                            name={name}
                        />
                    )}
                    <div className="channel-indicator-conversation">
                        {getPlatformById(item.platformId)}
                    </div>
                    {operatorAssigned.name && (
                        <div className="user-agent">
                            <AgentAvatar
                                agent={operatorAssigned}
                                size={16}
                                style={{ width: 40, height: 40 }}
                            />
                        </div>
                    )}
                </div>
                <div className="right">
                    <div className="top truncate">
                        <span className="user-name truncate">{name} </span>
                        <span className="last-response-time">{messageDate}</span>
                    </div>
                    <div className="bottom">{lastRes || item.email}</div>
                    <div className="more-bottom">
                        <TagBox inboxItem={item} maxWidth={props.colWidth - 125} presentationOnly />
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ConversationItem
