import React from "react"
import { Link } from "react-router-dom"
import { useLocalStorage } from "react-use"
import Button from "components/forms/Button"

const SettingsHelp = ({ title, children, icon }) => {
    return (
        <div className="settings-help">
            <div className="top">
                <i className={icon} />
                <h2>{title}</h2>
            </div>
            <br/>
            <br/>
            <span className="settings-help-section">Sobre</span>
            <p>{children}</p>
        </div>
    )
}

export default SettingsHelp
