import React, { useEffect, useMemo } from "react"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import Checkbox from "components/forms/Checkbox"
import TopHeader from "components/TopHeader"
import masks from "lib/masks"
import { useField, useCheckbox, useForm, useFetch, useBreadcrumb } from "hooks"
import { useStore, payment } from "store"

function Billing(props) {
    const paymentStore = useStore(payment)
    let order = useMemo(() => paymentStore.all.find(x => x.id == props.match.params.id) || {}, [
        paymentStore.all,
        props.match.params.id
    ])

    useEffect(() => {
        const paymentOrder = paymentStore.all.find(x => x.id == props.match.params.id)
        if (!paymentOrder) {
            paymentStore.get({
                params: {
                    id: props.match.params.id
                }
            })
        }
    }, [])

    const form = useForm({
        onSubmit: async model => {}
    })

    const autorenewal = useCheckbox("autorenewal", {
        defaultValue: order.autorenewal
    })

    return (
        <div className="billing-space">
            <TopHeader
                backTo="/settings/billing/subscription"
                breadcrumb="Voltar as opções de assinatura"
                bordered
                settings
            />
            <h1 style={{ fontSize: 30 }}>Fatura №{order.order} de {masks.currency(order.total - (order.credit ? order.credit : 0))} para {order.agents} operadores</h1>
            {order.autorenewal && (
                <div className="billing-row vertical">
                    <div className="left">
                        <Checkbox {...autorenewal}>Eu concordo com a renovação automática</Checkbox>
                        <span className="description">
                            A assinatura será renovada automaticamente 7 dias antes da expiração.
                            Você pode cancelar a renovação no máximo até 8 dias antes da data de
                            expiração. Se você cancelar, cobranças anteriores não serão reembolsadas
                            mas você poderá continuar a utilizar os recursos Profissionais até a
                            expiração da sua assinatura.
                        </span>
                    </div>
                </div>
            )}
            <div className="payment-methods">
                <h4>Métodos de Pagamento</h4>
                <a className="payment-method">
                    <i className="fa fa-credit-card-blank" style={{ color: "#f39c12" }} />
                    Cartão de crédito
                </a>
                <a className="payment-method" style={{ pointerEvents: "none", opacity: 0.3 }}>
                    <i className="fab fa-paypal" style={{ color: "#3498db" }} />
                    PayPal
                </a>
            </div>
        </div>
    )
}

export default Billing
