import { useState } from "react"
import req from "lib/req"
import _ from "lodash"
import arrayMove from "array-move"
import { request, toast } from "nanui"

function baseStore(baseEndpoint, hoc) {
    return () => {
        const [endpoint] = useState(baseEndpoint)
        const [all, setAll] = useState([])
        const [one, setOne] = useState()
        const [loading, setLoading] = useState({ get: false })
        const [loaders, setLoaders] = useState([])
        const [matchCount, setMatchCount] = useState(0)
        const [totalCount, setTotalCount] = useState(-1)

        /*
         * Get
         */
        async function get(payload = {}) {
            const { isNextPage, ...config } = payload
            setLoading({ ...loading, get: true })
            // const response = await req(endpoint, config)
            try {
                const response = await request.get(endpoint, config)
                const dataArray = response.data.data || []
                if (isNextPage) {
                    setAll(all => [...all, ...dataArray])
                } else {
                    setAll(dataArray)
                    setMatchCount(response.data.matchCount)
                    setTotalCount(response.data.totalCount)
                }
                return response.data
            } catch (error) {
                return error
            } finally {
                setLoading({ ...loading, get: false })
            }
        }

        /*
         * Get One
         */
        async function getOne(id) {
            // const response = await req(endpoint + "/" + id)
            // const errors = response.error || response.errors
            // console.log("errors", errors)
            // if (!errors) {
            //     setOne(response)
            // }
        }

        /*
         * Save
         */
        async function save(payload, { customEndpoint, callback } = {}) {
            const { data, toastMessage, ...configs } = payload
            try {
                const response = await request.save(customEndpoint || endpoint, data, configs)

                if (callback) {
                    callback(response.data)
                } else {
                    saveItem(response.data)
                }

                if (toastMessage) {
                    toast(toastMessage, "success")
                }

                return response.data
            } catch (e) {
                console.error(e)
                throw e
            }
        }

        /*
         * Remove
         */
        async function remove(payload, { fast } = {}) {
            const { data, toastMessage, ...configs } = payload
            try {
                const response = await request.delete(endpoint, data, configs)
                removeFromAll(response.data)
                setMatchCount(matchCount - 1)
                setTotalCount(totalCount - 1)

                if (toastMessage) {
                    toast(toastMessage, "warn", { icon: "delete" })
                }
            } catch (e) {
                console.error(e)
            }
        }

        /*
         * Remove
         */
        async function removeFromAll(item) {
            setAll(all => all.filter(e => e.id !== item.id))
        }

        function clear() {
            setAll([])
        }

        function clearOne() {
            setOne(null)
        }

        function saveItem(
            item,
            { saveOnEnd = true, byIndex, dataArray = all, setDataArray = setAll } = {}
        ) {
            // console.log("SAVE ITEM", dataArray, item)
            const index = byIndex || dataArray.findIndex(x => x.id == item.id)
            if (index > -1) {
                setDataArray(dataArray => {
                    const indexInside = byIndex || dataArray.findIndex(x => x.id == item.id)
                    dataArray[indexInside] = { ...dataArray[index], ...item }
                    return [...dataArray]
                })
            } else {
                const isArray = Array.isArray(item)
                if (saveOnEnd) {
                    setDataArray(dataArray =>
                        isArray ? [...dataArray, ...item] : [...dataArray, item]
                    )
                } else {
                    setDataArray(dataArray =>
                        isArray ? [...item, ...dataArray] : [item, ...dataArray]
                    )
                }
            }
        }

        function sortToFirst(item) {
            setAll(all => {
                const sorted = _.sortBy(all, function(x) {
                    return x.id == item.id ? 0 : 1
                })
                return [...sorted]
            })
        }

        function addLoader(loader) {
            setLoaders([...loaders, loader])
        }
        function removeLoader(loader) {
            setLoaders(loaders.filter(x => x !== loader))
        }

        async function moveItem({ oldIndex, newIndex }) {
            const newAll = arrayMove(all, oldIndex, newIndex)
            setAll(newAll)
            await req(endpoint + "/sort", {
                method: "patch",
                data: newAll.map(x => x.id)
            })
        }
    
        function findById(id) {
            return all.find(x => x.id === id)
        }

        const toReturn = {
            all,
            one,
            matchCount,
            totalCount,
            endpoint,
            loading,
            loaders,

            // Actions
            get,
            getOne,
            save,
            remove,
            clear,
            clearOne,
            setLoading,
            addLoader,
            removeLoader,
            sortToFirst,
            removeFromAll,
            findById,

            // For HOC Actions
            setAll,
            saveItem,
            moveItem,
            setOne
        }

        return hoc ? hoc(toReturn) : toReturn
    }
}

export default baseStore
