import React from "react"
import { Link } from "react-router-dom"
import { useLocalStorage } from "react-use"
import Button from "components/forms/Button"

const HelpBanner = ({ title, children, tutorial, id }) => {
    const [helpers, setHelpers] = useLocalStorage("helpers", [])

    if (helpers.includes(id)) {
        return <></>
    }
    return (
        <div className="help-banner">
            <h1 className="help-banner-title">{title}</h1>
            <p>{children}</p>
            <div className="help-banner-actions">
                <Button primary onClick={() => setHelpers(helpers => [...helpers, id])}>
                    Entendi
                </Button>
                {tutorial && <Link to={tutorial.to} className="color-link">
                    {tutorial.name}
                </Link>}
            </div>
        </div>
    )
}

export default HelpBanner
