import React, { Component } from "react"
import cx from "classnames"
import { NavLink } from "react-router-dom"

const getTitle = title => {
    if (title) {
        return <h3 className="title">{title}</h3>
    }
}

const getSubTitle = subtitle => {
    if (subtitle) {
        return <p className="subtitle">{subtitle}</p>
    }
}

class Box extends Component {
    render() {
        const { children, title, subtitle, colored, grey, tabs, fullheight, nopad, table, style, className } = this.props
        const classname = cx("box", className, {
            colored,
            grey,
            fullheight, 
            nopad,
            table
        })
        const hasTabs = colored && tabs && tabs.length > 1

        return (
            <div className={classname} style={style}>
                <div className="box-header">
                    {getTitle(title)}
                    {getSubTitle(subtitle)}
                    {hasTabs && (
                        <div className="box-header-tabs">
                            {tabs.map(t => (
                                <NavLink className="box-header-tab" to={t.link} exact key={t.label}>
                                    {t.label}
                                </NavLink>
                            ))}
                        </div>
                    )}
                </div>
                <div className="box-space">{children}</div>
            </div>
        )
    }
}

export default Box
