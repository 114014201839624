import React, { useRef, useMemo, useEffect } from "react"
import MenuDrop from "components/menu/MenuDrop"
import SelectTrigger from "components/menu/SelectTrigger"
import { useStore, bot, inbox } from "store"
import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import { getIconClassName } from "@uifabric/styling"

function AssignBotDrop(props) {
    const botStore = useStore(bot)
    const inboxStore = useStore(inbox)
    const selectedBot = useMemo(
        () => botStore.all.find((x) => x.id == props.inboxItem.botId) || {},
        [props.inboxItem.botId, botStore.all]
    )

    const trigger = useMemo(() => {
        return (
            <SelectTrigger
                className="normal-trigger context-header-button"
                style={selectedBot.id ? {} : { borderColor: "#ececec" }}
            >
                {/* <AgentAvatar agent={selectedBot} size={20} /> */}
                {selectedBot.id ? (
                    <span className="name-space">
                        <i className={getIconClassName("ChatBot")} style={{ color: "#2ecc71", marginRight: 8 }} />
                        {/* <i className="fal fa-hashtag" /> */}
                        {/* <i className="fal fa-user-friends" style={{ marginRight: 10 }} /> */}
                        <span className="name truncate">{selectedBot.name}</span>
                    </span>
                ) : (
                    <span className="name-space">
                        <i className={getIconClassName("ChatBot")} style={{ marginRight: 8 }} />
                        <span className="name truncate">Iniciar Bot</span>
                    </span>
                )}
            </SelectTrigger>
        )
    })
    const botOptions = useMemo(
        () => [
            {
                searchable: "Atendimento manual",
                selected: !selectedBot.id,
                onClick: () => assignBot({}),
                name: (
                    <span className="menu-item-with-avatar not-assigned">
                        <i className={getIconClassName("FollowUser")} />
                        Atendimento manual
                    </span>
                ),
            },
            ...botStore.all.map((x) => ({
                searchable: x.name,
                selected: x.id === selectedBot.id,
                onClick: () => assignBot(x),
                name: (
                    <span className="menu-item-with-avatar">
                        <i className={getIconClassName("ChatBot")} style={{ marginRight: 8 }} />
                        {x.name}
                    </span>
                ),
            })),
        ],
        [botStore.all, props.inboxItem.botId, props.inboxItem]
    )

    function assignBot(bot) {
        inboxStore.assignBot(
            {
                data: {
                    customerPlatformId: props.inboxItem.id,
                    botId: bot.id,
                },
            },
            props.inboxItem
        )
    }

    return (
        <div className="select-dropdown-space">
            <MenuDrop
                title="Iniciar bot"
                trigger={trigger}
                options={botOptions}
                // width={props.width - 15}
                searchable
                toLeft
            />
        </div>
    )
}

export default AssignBotDrop
