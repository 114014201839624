import React, { useMemo, useState } from "react"
import EmojiPicker from "components/forms/EmojiPicker"

const i = {
    ICON: 1,
    COLOR: 2,
    EMOJI: 3
}

const colors = [
    "#b3bac5",
    "#61bd4f",
    "#f2d600",
    "#ff9f1a",
    "#eb5a46",
    "#c377e0",
    "#0079bf",
    "#00c2e0",
    "#51e898",
    "#ff78cb",
    "#344563",
]

function ColorEmojiPicker(props) {
    const type = useMemo(() => {
        if (!props.value) return null
        if (props.value.indexOf("fa-") > -1) return i.ICON
        if (props.value.indexOf("#") > -1) return i.COLOR
        return i.EMOJI
    })

    function onPickEmoji(emoji) {
        props.onChange(emoji.colons)
    }

    return (
        <div className="color-emoji-picker">
            <label className="label-input">Selecione uma cor ou emoji</label>
            <div className="tag-colors">
                {colors.map(x => (
                    <a
                        className="tag-color"
                        style={{ backgroundColor: x }}
                        onClick={() => props.onChange(x)}
                        key={x}
                    >
                        {x === props.value && <i className="far fa-check" />}
                    </a>
                ))}
                <div className="color-emoji-separator" />
                <div className="emoji-box">
                    <EmojiPicker
                        bordered
                        onPickEmoji={onPickEmoji}
                        value={type === i.EMOJI ? props.value : null}
                    />
                    {type === i.EMOJI && <i className="fal fa-check" />}
                </div>
            </div>
        </div>
    )
}

export default ColorEmojiPicker
