import React, { useCallback, useState } from "react"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import SearchText from "components/forms/SearchText"
import DefaultAvatar from "components/DefaultAvatar"
import { useField, useSelectField, useForm, useCheckbox } from "hooks"
import { useStore, channel, inbox, general } from "store"
import config from "config"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import wppImg from "assets/images/whatsapp.png"
import masks from "lib/masks"
import ChatTextEditor from "pages/inbox/ChatTextEditor"
import { useDropzone } from "react-dropzone"
import req from "lib/req"
import { request, toast, Checkbox } from "nanui"
import { checkType, assetsToDelta } from "pages/inbox/AttachFileModal"
import ListItem from "components/forms/ListItem"

function NewMessageModal(props) {
    const [text, setText] = useState("")
    const [files, setFiles] = useState([])
    const [assetsLoaded, setAssetsLoaded] = useState(false)
    const [contacts, setContacts] = useState([])
    const [avatar, setAvatar] = useState('')
    const channelStore = useStore(channel)
    const inboxStore = useStore(inbox)
    const { uploadAssets, _domain, removeModal } = useStore(general)

    const form = useForm({
        onSubmit: async model => {
            let assets = []
            let assetsDelta = []
            if (files.length) {
                assets = await uploadAssets(files)
                assetsDelta = assetsToDelta(files, assets, _domain)
            }
            setAssetsLoaded(true)
            const data = {
                ...model,
                phone: masks.number(model.phone),
                avatar,
                // contentAssets: assets.length ? JSON.stringify() : null,
                content: JSON.stringify([...assetsDelta, ...text.ops])
            }
            const response = await request.save(
                config.api.SEND_ACTIVE_MESSAGE,
                data
            )
            console.log("response", response)
            toast("Mensagem enviada.", "success")
            setAssetsLoaded(false)
            inboxStore.saveItem(response.data)
            props.history.push("/inbox/all/" + response.data.id)
            removeModal()

            // const response = await new Promise(function(resolve, reject) {
            //     setTimeout(() => resolve({ id: 3 }), 2000)
            // })
            // return () => props.onBackModal(response)
        },
        onCatch: errors => setAssetsLoaded(false)
        // defaultModel: props.item
    })

    const workspacePlatformId = useSelectField("workspacePlatformId", {
        form,
        options: channelStore.all
            .filter(x => x.channelId == channelsTypesEnum.WHATSAPP)
            .map(x => ({
                value: x.id,
                label: (
                    <ListItem
                        img={wppImg}
                        startText={x.description}
                        endText={masks.cell(x.phone)}
                    />
                )
            }))
    })

    const phone = useField("phone", {
        mask: ["cell"],
        form
    })

    const name = useField("name", {
        form
    })

    const terms = useCheckbox("message:received", {
        defaultValue: false
    })

    const onDrop = useCallback(
        acceptedFiles => {
            console.log(acceptedFiles)
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            )
        },
        [files]
    )

    function setContactData(contact) {
        phone.setValue(masks.cell(contact.phone))
        name.setValue(contact.name)
        setAvatar(contact.avatar)
    }

    async function searchContact(query) {
        try {
            const response = await req(config.api.COSTUMERS, {
                data: {
                    limit: 30,
                    ...query
                }
            })
            const formattedContacts = response
                .filter((contact) => !!contact.phone && contact.phone.length <= 13)
                .map((contact) => ({
                    value: {
                        name: contact.name,
                        phone: contact.phone,
                        avatar: contact.avatar || ''
                    },
                    label: (
                        <ListItem
                            img={contact.avatar || <DefaultAvatar size={20} index={contact.id} name={contact.name} />}
                            startText={contact.name}
                            endText={masks.cell(contact.phone)}
                        />
                    )
                }))
            setContacts(formattedContacts)
        } catch (error) {
            setContacts([])
        }
    }

    const { getInputProps, open } = useDropzone({
        onDrop
    })

    return (
        <FormDialog
            {...props}
            superTitle="Enviar Mensagem"
            onSubmit={form.onSubmit}
            endpoint={config.api.SEND_ACTIVE_MESSAGE}
            saveProps={{
                disabled: !workspacePlatformId.value || !terms.checked || !phone.value
            }}
            saveLoader={assetsLoaded ? undefined : "post/assets"}
            manualRemove
        >
            <Select label="Canal" required {...workspacePlatformId} />
            <SearchText
                label="Nome do contato"
                {...name}
                options={contacts}
                onOptionClick={setContactData}
                searchFunction={() => searchContact({ name: name.value })}
                observableValues={[ name.value ]}
            />
            <SearchText
                label="Celular"
                required
                maxLength={19}
                {...phone}
                placeholder="+xx (xx) x xxxx-xxxx"
                options={contacts}
                onOptionClick={setContactData}
                searchFunction={() => searchContact({ phone: masks.number(phone.value) })}
                observableValues={[ phone.value ]}
            />
            <label className="label-input" style={{ lineHeight: 2 }}>
                Texto
            </label>
            <div className="modal-text-editor">
                <input {...getInputProps()} />
                {!!files.length && (
                    <div className="modal-text-editor-files">
                        {files.map((x, i) =>
                            checkType(x, "image") ? (
                                <img src={x.preview} alt="" key={x.preview} />
                            ) : (
                                <div
                                    className="attach-file-item"
                                    key={x.name + i}
                                >
                                    <i className="fal fa-file" />
                                    <span>{x.name}</span>
                                </div>
                            )
                        )}
                    </div>
                )}
                <ChatTextEditor
                    openFile={open}
                    onChangeText={setText}
                    notSend
                />
            </div>
            <br />
            <Checkbox
                {...terms}
                label={
                    <span>
                        Aceito que a KimoChat não se responsabiliza por banimento
                        do meu número pelo WhatsApp. Conforme os{" "}
                        <a
                            style={{ color: "blue" }}
                            href="https://www.kimochat.com.br/terms"
                        >
                            Termos de Uso.
                        </a>
                    </span>
                }
                // styles={checkboxStyles}
            />
        </FormDialog>
    )
}

export default NewMessageModal
