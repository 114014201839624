import React, { Component } from "react"
import cx from "classnames"
import ReactSwitch from "react-switch"

class Switch extends Component {
    constructor() {
        super()
        this.state = { checked: true }
    }

    onChange = checked => {
        if (this.props.onChange) {
            this.props.onChange(checked)
        } else {
            this.setState({ checked })
        }
    }

    render() {
        const {
            checked,
            onChange,
            label,
            small,
            onClick,
            ...props
        } = this.props
        const isControlled = typeof checked === "boolean"
        const classname = cx("normal-switch", { small })
        return (
            <span className={classname} onClick={onClick}>
                <ReactSwitch
                    onChange={this.onChange}
                    onColor="#2ecc71"
                    checked={isControlled ? checked : this.state.checked}
                    id="normal-switch"
                    {...props}
                />
                {label && <label className="label-input">{label}</label>}
            </span>
        )
    }
}

export default Switch
