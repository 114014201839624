import React from "react"
import { useField, useFormMultiSelect, useForm, useFetch, useColorEmoji } from "hooks"
import { operator, useStore } from "store"
import { DatePicker as FabricDatePicker, DayOfWeek, IDatePickerStrings } from "office-ui-fabric-react"

const _onFormatDate = date => {
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear() % 100)
}

const _onParseDateFromString = value => {
    const date = this.state.value || new Date()
    const values = (value || "").trim().split("/")
    const day =
        values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate()
    const month =
        values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth()
    let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear()
    if (year < 100) {
        year += date.getFullYear() - (date.getFullYear() % 100)
    }
    return new Date(year, month, day)
}

const DayPickerStrings = {
    months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ],

    shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Set",
        "Out",
        "Nov",
        "Dez"
    ],

    days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],

    shortDays: ["D", "S", "T", "Q", "Q", "S", "S"],

    goToToday: "Ir para Hoje",
    prevMonthAriaLabel: "Ir para mês anterior",
    nextMonthAriaLabel: "Ir para próximo mês",
    prevYearAriaLabel: "Ir para ano anterior",
    nextYearAriaLabel: "Ir para próximo ano",
    closeButtonAriaLabel: "Fechar Picker"
}


function DatePicker(props) {
    return (
        <FabricDatePicker
            strings={DayPickerStrings}
            placeholder="Selecione uma data..."
            formatDate={_onFormatDate}
            parseDateFromString={_onParseDateFromString}
            {...props}
        />
    )
}

export default DatePicker
