import React, { Component } from "react"
import cx from "classnames"
import { NavLink } from "react-router-dom"
import Button from "components/forms/Button"
import SyntaxHighlighter from "react-syntax-highlighter"
import { docco, github } from "react-syntax-highlighter/dist/styles/hljs"

class CopyBlock extends Component {
    state = {
        copied: false
    }

    onCopy = () => {
        this.setState({ copied: true })
        setTimeout(() => {
            this.setState({ copied: false })
        }, 2000)
    }

    render() {
        const { copied } = this.state
        const { code, step, children, title } = this.props

        const className = cx("copy-block", {
            code,
            step
        })

        return (
            <div className={className}>
                <div className="to-copy-row">
                    <span className="to-copy-row-title">{title}</span>
                    <Button icon="far fa-copy" small onClick={this.onCopy}>
                        {copied ? "Copiado" : "Copiar"}
                    </Button>
                </div>
                {code ? (
                    <SyntaxHighlighter
                        language="html"
                        style={github}
                        customStyle={{ border: "1px solid #ccc", borderRadius: 4, fontSize: 13 }}
                    >
                        {children}
                    </SyntaxHighlighter>
                ) : (
                    <div className="key-bg">{children}</div>
                )}
            </div>
        )
    }
}

export default CopyBlock
