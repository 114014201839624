import React from "react"
import { getIconClassName } from "@uifabric/styling"
import PageTableBase from "components/PageTableBase"
import SettingsHelp from "components/SettingsHelp"

import EditQuickPhrase from "./EditQuickPhrase"
// import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import { quickPhrase } from "store"

const columns = [
    {
        Header: "Código",
        accessor: "tag",
        maxWidth: 150,
        Cell: props => (
            <span className="vertical-center" style={{ fontWeight: 500 }}>
                {/* <ColorEmojiIcon
                    icon={props.row._original.color}
                    iconStyle={{ marginRight: 10 }}
                    size={18}
                /> */}
                {/* <i className="fal fa-box table-icon" /> */}
                {props.value}
            </span>
        )
    },
    {
        Header: "Frase",
        accessor: "phrase",
        maxWidth: 600
    },
    {
        Header: "Compartilhamento",
        accessor: "privacy",
        width: 200,
        Cell: props =>
            props.value === "private" ? (
                <span className="table-icon-description">
                    <i className={getIconClassName("People")} style={{ visibility: "hidden" }} />
                    Privado
                </span>
            ) : (
                <span className="table-icon-description">
                    <i className={getIconClassName("People")} />
                    Público
                </span>
            )
    }
]

export default function QuickPhrases(props) {
    return (
        <PageTableBase
            storeRef={quickPhrase}
            title="Frases rápidas"
            newLabel="Novo"
            columns={columns}
            EditComponent={EditQuickPhrase}
            helperAction={
                <SettingsHelp title="Frases rápidas" icon={getIconClassName("LightningBolt")}>
                    Frases rápidas são respostas pré-cadastradas para facilitar e agilizar envio de
                    mensagens padrões e comuns para seus clientes.
                </SettingsHelp>
            }
            nodata={{
                title: "Parece que você ainda não adicionou nenhuma frase rápida...",
                buttonText: "Adicionar Frase Rápida"
            }}
            {...props}
        />
    )
}
