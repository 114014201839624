import React, { useState, useEffect, useMemo } from "react"
import InfoBox from "components/inbox/InfoBox"
import Button from "components/forms/Button"
import ContactProperty from "components/chat/side/ContactProperty"
import { getIconClassName } from "@uifabric/styling"

import EditableProperty from "./EditableProperty"
// import ReadOnlyProperty from "../inbox/ReadOnlyProperty"

function PeopleDataProperties(props) {
    const [properties, setProperties] = useState([{}])

    useEffect(() => {
        // if (props.properties.length) console.log("RECEBEU PROPEERTIES", props.properties)
        setProperties([...props.properties, {}])
    }, [props.properties])

    // const isEditing = useMemo(() => properties.find(x => !x.label && !x.value), [properties])

    return (
        <div className="user-custom-data">
            {properties.map((e, i) => (
                <ContactProperty
                    key={e.id || "new"}
                    property={e}
                    onSubmit={(model, config) => props.saveProperty(props.item.id, model, config)}
                    onDelete={async id => {
                        if (id) {
                            await props.removeProperty(props.item.id, { data: { id } })
                        }
                        setProperties(properties.filter((e, index) => i !== index))
                    }}
                />
            ))}
            {/* {!isEditing && (
                    <Button
                        onClick={() => setProperties([...properties, {}])}
                        small
                        icon={getIconClassName("Add")}
                        style={{
                            marginTop: 10
                        }}
                    />
                )} */}
        </div>
    )
}

export default PeopleDataProperties
