import React from "react"

function PageTitle({ title, description, style }) {
    return (
        <div className="page-table-title" style={style}>
            <h1>{title}</h1>
            {description && <p>{description}</p>}
        </div>
    )
}

export default PageTitle
