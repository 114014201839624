import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"
import { session, useStore } from "store"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function ChangePassword(props) {
    const { changePassword } = useStore(session)

    const form = useForm({
        onSubmit: async data => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "O Agente alterou a sua senha de acesso"
            })
            await changePassword({
                data,
                toastMessage: "Senha alterada"
            })
            return () => {
                password.setValue("")
                newPassword.setValue("")
                confirmNewPassword.setValue("")
            }
        }
    })

    const password = useField("password", {
        form,
        validations: [value => value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"]
    })
    const newPassword = useField("newPassword", {
        form,
        validations: [value => value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"]
    })
    const confirmNewPassword = useField("confirmNewPassword", {
        form,
        validations: [
            (value, formData) => value !== formData.newPassword && "Suas senhas estão diferentes",
            value => value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"
        ]
    })

    return (
        <>
            <ContextHeader backAction={{ to: "/settings/account/profile" }} />
            <PageTitle title="Alterar Senha" />
            <FormDialog
                endpoint="/session"
                // superTitle="Mudar Senha"
                onSubmit={form.onSubmit}
                item={{ id: true }}
                footerStyle={{ justifyContent: "flex-start" }}
                noExcludable
                noPadding
            >
                <div style={{ maxWidth: 460, marginLeft: 32 }}>
                    <Text label="Senha Atual" required type="password" {...password} />
                    <Text label="Nova Senha" type="password" required {...newPassword} />
                    <Text
                        label="Confirme nova senha"
                        type="password"
                        required
                        {...confirmNewPassword}
                    />
                </div>
                <br/>
                <br/>
            </FormDialog>
        </>
    )
}

export default ChangePassword
