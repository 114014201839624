import React, { useCallback } from "react"
import ChatTextEditor from "pages/inbox/ChatTextEditor"
import { useDropzone } from "react-dropzone"
import { checkType, assetsToDelta } from "pages/inbox/AttachFileModal"
import Label from "components/forms/Label"

function ModuleChatTextEditor({ files = [], setFiles, setText, text, label, ...props }) {
    const onDrop = useCallback(
        acceptedFiles => {
            console.log(acceptedFiles)
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            )
        },
        [files]
    )

    const { getInputProps, open } = useDropzone({
        onDrop
    })

    return (
        <div>
            <Label disabled={props.disabled}>{label}</Label>
            <div className="modal-text-editor">
                <input {...getInputProps()} />
                {!!files.length && (
                    <div className="modal-text-editor-files">
                        {files.map((x, i) =>
                            checkType(x, "image") ? (
                                <img src={x.preview} alt="" key={x.preview} />
                            ) : (
                                <div className="attach-file-item" key={x.name + i}>
                                    <i className="fal fa-file" />
                                    <span>{x.name}</span>
                                </div>
                            )
                        )}
                    </div>
                )}
                <ChatTextEditor
                    openFile={open}
                    onChangeText={setText}
                    text={text}
                    notSend
                    {...props}
                />
            </div>
        </div>
    )
}

export default ModuleChatTextEditor
