import React from "react"
import { Link } from "react-router-dom"
import { session, useStore } from "store"

function PowerUpCard({ image, name, description, disabled, to = "", code }) {
    const { user, workspace } = useStore(session)

    const isInstalled = (workspace.powerUps || []).includes(code)
    const disabledClassname = disabled ? " disabled" : ""
    return (
        <Link className={"pup-card" + disabledClassname} to={to}>
            <div className="top">
                <img src={image} alt="" />
                <span className="name">{name}</span>
                <span className="description">{description}</span>
            </div>
            {isInstalled && (
                <div className="bottom integration-status">
                    <i className="fa fa-check-circle" />
                    Integrado
                </div>
            )}
        </Link>
    )
}

export default PowerUpCard
