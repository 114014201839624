import React from "react"
import SideItem from "./SideItem"
import { menusEnum } from "./SideConstants"

function RootItems({ setIndex }) {
    const rootItems = [
        {
            name: "Inbox",
            icon: "fal fa-comment",
            to: "/inbox/all",
            toIndex: menusEnum.INBOX
        },
        {
            name: "Dashboard",
            icon: "fal fa-chart-pie-alt",
            to: "/dashboard"
        },
        {
            name: "Relatórios",
            icon: "fal fa-chart-bar",
            to: "/reports"
        },
        {
            name: "Power-Ups",
            icon: "fal fa-rocket",
            to: "/power-ups"
        },
        {
            name: "Configurações",
            icon: "fal fa-cog",
            to: "/settings/account/workspace",
            toIndex: menusEnum.SETTINGS
        }
    ]
    return (
        <div>
            {rootItems.map(x => (
                <SideItem {...x} key={x.name} setIndex={setIndex} />
            ))}
        </div>
    )
}

export default RootItems
