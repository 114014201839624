import React from "react"
import Button from "components/forms/Button"
import { getIconClassName } from "@uifabric/styling"

function ContextHeader({ actions = [], actionsEnd = [], backAction }) {
    const btn = ({ icon, to, name, onClick, loading, ...props }) => (
        <Button
            icon={icon}
            to={to}
            className="context-header-button"
            key={name || icon}
            onClick={onClick}
            isLoading={loading}
            {...props}
        >
            {name && <span>{name}</span>}
        </Button>
    )

    return (
        <header className="context-header">
            <div className="context-header-left">
                {backAction &&
                    btn({
                        to: backAction.to,
                        name: backAction.name || "Voltar",
                        icon: getIconClassName("Back")
                    })}
                {actions.map(x => (x.custom ? <div key={x.key}>{x.custom}</div> : btn(x)))}
            </div>
            <div className="context-header-right">
                {actionsEnd.map(x => (x.custom ? <div key={x.key}>{x.custom}</div> : btn(x)))}
            </div>
        </header>
    )
}
export default ContextHeader
