import React, { useState } from "react"
import Button from "components/forms/Button"
import Modal from "components/Modal"
import QRCodeModal from "./QRCodeModal"
import masks from "lib/masks"
import { SettingsEnum } from "pages/settings/Settings"
// import ManagerBase from "../../../manager/ManagerBase"
import PageTable from "pages/PageTable"
import EditWhatsapp from "./EditWhatsapp"
import PageHeader from "components/PageHeader"
import TopHeader from "components/TopHeader"
import { whatsapp } from "store"

function getStatus(status) {
    switch (status) {
        case "connected":
            return {
                title: "Conectado",
                bg: "rgba(46,204,113, 0.3)"
            }
        case "disconnected":
            return { title: "Desconectado", bg: "rgba(243,156,18, 0.3)" }
        // case "formalized":
        //     return {
        //         title: "Formalizado",
        //         bg: "rgba(46,204,113, 0.3)"
        //     }
        // case "rejected":
        //     return { title: "Rejeitado", bg: "rgba(243,156,18, 0.3)" }
        // case "processed":
        //     return { title: "Aguardando Formalização", bg: "" }
        // case "overwritten":
        //     return { title: "Substituido", bg: "rgba(155,89,182, 0.3)" }
        default:
            return ""
    }
}

const columns = showQRCode => [
    {
        Header: "Descrição",
        accessor: "description",
        width: 300,
        Cell: props => (
            <span className="vertical-center">
                <i
                    className="fab fa-whatsapp"
                    style={{ marginRight: 20, fontSize: 20, color: "#25D366" }}
                />
                {props.value}
            </span>
        )
    },
    {
        Header: "Número",
        accessor: "phone",
        // width: 160,
        Cell: props => masks.cell(props.value)
    },
    {
        Header: "Status",
        accessor: "status",
        width: 200,
        Cell: props => (
            <span
                className="contract-status"
                style={{ backgroundColor: getStatus(props.value).bg }}
            >
                {getStatus(props.value).title}
            </span>
        )
    },
    {
        Header: "Conectar",
        accessor: "id",
        width: 250,
        Cell: props => (
            <Button
                gray
                style={{ width: 130, height: 41 }}
                onClick={e => {
                    e.stopPropagation()
                    showQRCode(props.value)
                }}
            >
                Ler QRCode
            </Button>
        )
    },
]

export default function Whatsapp(props) {
    const [modalNew, setModalNew] = useState()

    function showQRCode(id) {
        setModalNew({ id })
    }

    return (
        <>
            <TopHeader backTo="/power-ups" breadcrumb="Power-Ups" />
            <PageHeader
                title="Whatsapp"
                image="https://image.flaticon.com/icons/png/512/124/124034.png"
                description="Veja e responda mensagens do Whatsapp na sua caixa de entrada."
                afterDescription={
                    <span className="integration-status">
                        <i className="fa fa-check-circle" />
                        Integrado
                    </span>
                }
                action={
                    <Button gray to="/power-ups/whatsapp/new" icon="far fa-plus">
                        Adicionar novo número
                    </Button>
                }
            />
            <div className="page-inner">
                <PageTable
                    storeRef={whatsapp}
                    title="Números adicionados"
                    columns={columns(showQRCode)}
                    EditComponent={EditWhatsapp}
                    nodata={{ title: "Parece que você ainda não adicionou nenhum número..." }}
                    // tableStyle={{ marginTop: -40 }}
                    {...props}
                />
            </div>
            {modalNew && (
                <Modal onBack={() => setModalNew(null)}>
                    <QRCodeModal onBackModal={() => setModalNew(null)} item={modalNew} />
                </Modal>
            )}
        </>
    )
}
