import config from "config"
import fromBase from "stores/baseStore"

function messageStore(store) {
    function saveMessage(item, byIndex) {
        store.save(
            { data: item },
            {
                customEndpoint: config.api.SEND_MESSAGE,
                callback: response => store.saveItem(response, { byIndex })
            }
        )
    }

    return {
        ...store,
        saveMessage
    }
}

export default fromBase(config.api.MESSAGE, messageStore)
