import React from 'react';

function ListItem({
    img,
    startText,
    endText
}) {
    return (
        <div className='list-item-container'>
            {typeof img === 'string'
                ? (<img
                    src={img}
                    alt={startText}
                />)
                : img
            }
            <div className='list-item-content'>
                <span>{startText}</span>
                <span>{endText}</span>
            </div>
        </div>
    )
}

export default ListItem;
