import React from "react"

function PanelDetail(props) {
    function onClose() {
        props.onClose()
    }

    const { children } = props
    return (
        <div className="panel-detail">
            {/* <a className="panel-closer-space" onClick={onClose}>
                <div className="panel-closer">
                    <i className="fa fa-caret-right" />
                </div>
            </a> */}
            {children}
        </div>
    )
}

export default PanelDetail
