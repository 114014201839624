import React, { useState } from "react"
import { useLocalStorage } from "react-use"
import CaretTiny from "components/sidebar/CaretTiny"

function ChatSideItem(props) {
    const [isOpened, setIsOpened] = useLocalStorage(
        `chat-side-item-${props.id}`,
        !props.defaultClosed
    )

    return (
        <div className="chat-side-item" style={props.style}>
            <a className="chat-side-item-title" onClick={() => setIsOpened(opened => !opened)}>
                {/* <CaretTiny style={isOpened ? { transform: "scaleY(-1)" } : {}} /> */}
                <i
                    className="far fa-angle-down"
                    style={isOpened ? {} : { transform: "rotate(-90deg)" }}
                />

                {props.title}
            </a>
            {isOpened && <div className="chat-side-item-inner">{props.children}</div>}
        </div>
    )
}

export default ChatSideItem
