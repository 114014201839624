import React, { useRef, useState, useEffect } from "react"
import { observer } from "mobx-react"
import Dropdown from "components/Dropdown"
import queryString from "query-string"
// import Select from "components/forms/Select"
import Button from "components/forms/Button"
// import Text from "components/forms/Text"
import DateRangePicker from "components/forms/DateRangePicker"
import FilterTag from "components/FilterTag"
import Select from "components/forms/Select"
import { useSelectField, useDatePeriod, useFetch, useFormMultiSelect } from "hooks"
import { group, operator, tag, useStore } from "store"
import bonita from "data-bonita"
import { getIconClassName } from "@uifabric/styling"
import { addQueryParams, spreadQueryParams } from "lib/helper"

// const parseDate = (date) => {
//     if (date) {
//         const dateSplit = date.split('/')
//         return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0])
//     }
// }

const frequencyOptions = [
    { label: "Diário", value: "daily" },
    { label: "Semanal", value: "weekly" },
    { label: "Mensal", value: "monthly" }
]

function FilterDropdown({ filters, name, icon, style, left, form, notLimit, ...props }) {
    // const dropRef = useRef()
    const groupStore = useStore(group)
    const operatorStore = useStore(operator)
    const tagStore = useStore(tag)
    const query = queryString.parse(props.location.search)

    // const period = useDatePeriod("period", {
    //     defaultValue: { from: query.from, to: query.to },
    //     form
    // })

    // const frequency = useSelectField("frequency", {
    //     defaultValue: query.frequency,
    //     form,
    //     options: frequencyOptions
    // })

    // const groups = useSelectField("group", {
    //     defaultValue: query.group,
    //     form,
    //     options: groupStore.all.map(e => ({
    //         value: e.id,
    //         label: e.name
    //     }))
    // })

    // const agents = useSelectField("agent", {
    //     defaultValue: query.members,
    //     loader: "GET/operators",
    //     form,
    //     options: operatorStore.all.map(e => ({
    //         value: e.id,
    //         label: e.name
    //     }))
    // })

    // const tags = useSelectField("tag", {
    //     defaultValue: query.label,
    //     form,
    //     options: tagStore.all.map(e => ({
    //         label: e.name,
    //         value: e.id
    //     }))
    // })

    // const labels = useFormMultiSelect("tags", {
    //     defaultValue: query.members,
    //     form,
    //     options: operatorStore.all.map(e => ({
    //         value: e.id,
    //         label: e.name
    //     }))
    // })

    const queryFrom = query.from ? new Date().toLocaleDateString() : ""
    const queryTo = query.to ? new Date(query.to + " ").toLocaleDateString() : ""
    const queryGroup = (groupStore.all.find(x => x.id == query.group) || {}).name
    const queryFrequency = (frequencyOptions.find(x => x.value === query.frequency) || {}).label
    const queryAgent = (operatorStore.all.find(x => x.id == query.agent) || {}).name
    const queryTag = (tagStore.all.find(x => x.id == query.tag) || {}).name

    // {
    /* const queryLabel = (labels.flatOptions.find(x => x.value === query.label) || {}).label */
    // }
    // const queryTag = (members.options.find(x => x.value === query.agents) || {}).label

    // useEffect(() => {
    //     if (!queryGroup) groups.onChange("")
    //     if (!queryFrequency) frequency.onChange("")
    //     if (!queryAgent) agents.onChange("")
    //     if (!queryTag) tags.onChange("")
    // }, [queryGroup, queryFrequency, queryAgent, queryTag])

    // const trigger = (
    //     <div className="conve-filter-trigger">
    //         {icon && <i className={icon} />}
    //         <span className="name">{name}</span>
    //         <i
    //             className={getIconClassName("ChevronDown")}
    //             style={{ marginLeft: 2, marginTop: 2, fontSize: 12 }}
    //         />
    //     </div>
    // )

    function onRemoveTag(params) {
        addQueryParams({
            ...props,
            newParams: { ...query, ...params }
        })
    }

    return (
        <div className="filter-dropdown-space" style={style}>
            {/* <div className="conve-filter-dropdown small">
                <Dropdown trigger={trigger} ref={dropRef} down notClose left={left}>
                    <div className="filter-drop-inner">
                        <label className="label-input">Período de tempo</label>
                        <DateRangePicker {...period} />
                        <Select label="Frequência" isClearable {...frequency} />
                        <Select label="Grupo" isClearable {...groups} />
                        <Select label="Agente" isClearable {...agents} />
                        <Select label="Etiqueta" isClearable {...tags} />
                        <div className="footer-actions" style={{ marginTop: 20 }}>
                            <Button
                                onClick={() => {
                                    if (!period.value || !period.value.from) {
                                        form.removeCustom("from")
                                    } else {
                                        form.addCustom({
                                            name: period.value.to ? "from" : "to",
                                            value: period.value.from
                                        })
                                    }
                                    if (!period.value || !period.value.to) {
                                        form.removeCustom("to")
                                    } else {
                                        form.addCustom({
                                            name: "to",
                                            value: period.value.to
                                        })
                                    }
                                    setTimeout(function() {
                                        form.onSubmit(new Event("submit"))
                                    })
                                    dropRef.current.onCloseMenu()
                                }}
                                primary
                                small
                            >
                                Aplicar
                            </Button>
                            <Button
                                onClick={() => {
                                    dropRef.current.onCloseMenu()
                                }}
                                small
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Dropdown>
            </div> */}
            <div className="filters-tags">
                {!query.to && !query.from && !notLimit && <FilterTag>Últimos 7 dias</FilterTag>}
                {(query.to || query.from) && (
                    <FilterTag
                        onRemove={e => {
                            onRemoveTag({ to: null, from: null })
                            // e.preventDefault()
                            // period.setValue({
                            //     from: undefined,
                            //     to: undefined
                            // })
                            // form.removeCustom("from")
                            // form.removeCustom("to")
                            // setTimeout(function() {
                            //     form.onSubmit(new Event("submit"))
                            // })
                        }}
                    >
                        {`${bonita.abs(new Date(query.from + " "))}${query.to ? " - " : ""}${
                            query.to ? bonita.abs(new Date(query.to + " ")) : ""
                        }`}
                    </FilterTag>
                )}
                {queryFrequency && (
                    <FilterTag
                        onRemove={e => {
                            onRemoveTag({ frequency: null })
                            // e.preventDefault()
                            // frequency.clear()
                            // setTimeout(function() {
                            //     form.onSubmit(new Event("submit"))
                            // })
                        }}
                    >
                        Frequência {queryFrequency}
                    </FilterTag>
                )}
                {queryGroup && (
                    <FilterTag
                        onRemove={e => {
                            onRemoveTag({ group: null })

                            // e.preventDefault()
                            // groups.clear()
                            // setTimeout(function() {
                            //     form.onSubmit(new Event("submit"))
                            // })
                        }}
                    >
                        Grupo: {queryGroup}
                    </FilterTag>
                )}
                {queryAgent && (
                    <FilterTag
                        onRemove={e => {
                            onRemoveTag({ agent: null })

                            // e.preventDefault()
                            // agents.clear()
                            // setTimeout(function() {
                            //     form.onSubmit(new Event("submit"))
                            // })
                        }}
                    >
                        Agente: {queryAgent}
                    </FilterTag>
                )}
                {queryTag && (
                    <FilterTag
                        onRemove={e => {
                            onRemoveTag({ tag: null })

                            // e.preventDefault()
                            // tags.clear()
                            // setTimeout(function() {
                            //     form.onSubmit(new Event("submit"))
                            // })
                        }}
                    >
                        <i className={getIconClassName("Tag")} style={{ marginRight: 5 }} />
                        Etiqueta: {queryTag}
                    </FilterTag>
                )}
                {/* {queryLabel && (
                    <FilterTag
                        onRemove={e => {
                            e.preventDefault()
                            labels.clear()
                            setTimeout(function() {
                                form.onSubmit(new Event("submit"))
                            })
                        }}
                    >
                        Marcador: {queryLabel}
                    </FilterTag>
                )} */}
            </div>
        </div>
    )
}

export default FilterDropdown
