import React, { useState, useEffect } from "react"
import AuthBase from "./AuthBase"
import Loader from "components/Loader"
import Lottie from "react-lottie"
import { session, useStore } from "store"
import queryString from "query-string"
import okLottie from "assets/animations/success.json"
import Button from "components/forms/Button"

const optionsLottie = {
    loop: false,
    autoplay: true,
    animationData: okLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

function EmailConfirm(props) {
    const [success, setSuccess] = useState(false)
    const { confirmEmail, user } = useStore(session)
    const query = queryString.parse(props.location.search)

    useEffect(() => {
        confirmEmail({ data: { token: query.token } }).then(response => {
            const currentUser = localStorage.getItem("user")
            if (currentUser) {
                user.verificationLevel = response.verificationLevel
                localStorage.setItem("user", JSON.stringify(user))
            }
            setSuccess(true)
        })
    }, [])

    return (
        <AuthBase>
            <Loader loading={!success}>
                <div className="register-success-space">
                    <h1 className="headline">E-mail confirmado!</h1>
                    {/* <i className="fa fa-check" /> */}
                    <Lottie
                        options={optionsLottie}
                        height={220}
                        width={220}
                        speed={1}
                        // isPaused={!showArrow}
                    />
                    <h2 className="headline" style={{ textAlign: "center" }}>
                        E-mail confirmado com sucesso!
                    </h2>
                    <br />
                    <div className="login-actions">
                        <Button
                            className="login-btn"
                            primary
                            loader="login"
                            to="/"
                            style={{ textDecoration: "none" }}
                        >
                            Ok
                        </Button>
                    </div>
                </div>
            </Loader>
            {!success && (
                <>
                    <br />
                    <br />
                    <h3>Confirmando seu e-mail</h3>
                </>
            )}
        </AuthBase>
    )
}

export default EmailConfirm
