import React from "react"
import PageTableBase from "components/PageTableBase"
import SettingsHelp from "components/SettingsHelp"
import EditPlugin from "./EditPlugin"
import { plugin } from "store"
import { getIconClassName } from "@uifabric/styling"

const columns = [
    {
        Header: "Nome do Plugin",
        accessor: "name",
        Cell: props => (
            <span className="vertical-center">
                <i className={`${getIconClassName('Plug')} table-icon`} />
                {props.value}
                {props.row._original.dev ? " (dev)" : ""}
            </span>
        )
    },
    {
        Header: "Endpoint",
        accessor: "endpoint",
        width: 550,
        limit: 85
    }
]

export default function Plugins(props) {
    return (
        <PageTableBase
            storeRef={plugin}
            title="Plugins"
            newLabel="Novo"
            columns={columns}
            EditComponent={EditPlugin}
            helperAction={
                <SettingsHelp title="Seus Plugins" icon={getIconClassName("PlugDisconnected")}>
                    Com plugins você pode criar aplicações customizadas para melhorar seu fluxo de
                    trabalho.<br /><br />Por exemplo, seu desenvolvedor pode construir um plugin para mostrar o
                    contexto do seu cliente do seu banco de dados interno. Os plugins aparecem ao
                    lado do chat.
                </SettingsHelp>
            }
            nodata={{
                title: "Parece que você ainda não adicionou nenhum plugin...",
                buttonText: "Adicionar Plugin"
            }}
            {...props}
        />
    )
}
