import React, { useState, useEffect, useMemo } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import { useStore, general, email } from "store"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import { getStatus } from "pages/settings/channels/Channels"
import SettingsLoader from "components/settings/SettingsLoader"
import shortid from "shortid"
import ContextHeader from "components/ContextHeader"
import { getIconClassName } from "@uifabric/styling"
import PageTitle from "components/PageTitle"
import ContextBg from "components/ContextBg"
/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddEmail(props) {
    const emailStore = useStore(email)
    const { addModal, removeModal } = useStore(general)

    const item = props.match.params.id
        ? emailStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adiciou um canal de email"
                    : "O agente editou um canal de email"
            })
            const response = await emailStore.save({
                data: model,
                toast: "Canal de E-mail salvo."
            })
            return () => {
                if (!item.id && !response.error && !response.errors) {
                    props.history.push("/settings/channels")
                }
            }
        },
        defaultModel: item
    })

    // const emailField = useField("hashIdentifier", {
    //     defaultValue: item.hashIdentifier,
    //     form
    // })

    const description = useField("description", {
        defaultValue: item.description,
        form
    })

    const forwardingId = useMemo(() => shortid.generate() + "kimochat@mail.kimochat.com.br", [])

    const forwarding = useField("identifier", {
        defaultValue: item.identifier || forwardingId,
        form
    })

    return (
        <div className="channel-page">
            <ContextHeader backAction={{ to: "/settings/channels" }} />
            <ContextBg success>
                <h3>Funcionalidade em desenvolvimento!</h3>
                <p> Agradecemos o interesse, em breve teremos novidades!</p>
            </ContextBg>
            <PageTitle
                title={
                    !props.match.params.id ? "Adicionar canal de Email" : "Editar canal de Email"
                }
                description={
                    <>
                        Você pode ler e responder mensagens que seus clientes enviam para o e-mail
                        existente do seu time (e.g: contato@suaempresa.com) diretamente no KimoChat.
                        <br />
                        <br />O processo é simples, apenas habilite "forwarding" nas configurações
                        do seu provedor de e-mail e redirecione para o endereço abaixo.
                    </>
                }
            />
            <SettingsLoader store={emailStore} {...props}>
                <FormDialog
                    {...props}
                    disabledSubmit
                    item={item}
                    endpoint="/integrations/mail"
                    onSubmit={form.onSubmit}
                    onDelete={id =>
                        emailStore
                            .remove({
                                data: { id },
                                toastMessage: "Deletado com sucesso"
                            })
                            .then(() => {
                                props.history.push("/settings/channels")
                            })
                    }
                >
                    {/* <Text
                            label="Seu Email"
                            required
                            placeholder="contato@suaempresa.com"
                            {...emailField}
                        /> */}
                    <div style={{ maxWidth: 560 }}>
                        <Text label="Nome do Canal" required {...description} />
                        <br />
                        {/* {item.id && ( */}
                        <Text
                            label="Configurações de Encaminhamento"
                            required
                            disabled
                            description="Para receber as mensagens que seus clientes enviam para seu e-mail existente, vá para as configurações do seu provedor de email e habilite encaminhamento (forwarding) de todas as mensagens para o endereço especial abaixo:"
                            {...forwarding}
                        />
                        {/* )} */}
                        {/* <Button
                            ios
                            type="button"
                            loader="email"
                            onClick={() => emailStore.sendTest()}
                        >
                            Enviar mensagem de teste
                        </Button>
                        <br /> */}
                        <br />
                    </div>
                </FormDialog>
            </SettingsLoader>
        </div>
    )
}
