import React from "react"
import PageTableBase from "components/PageTableBase"
import SettingsHelp from "components/SettingsHelp"
import EditGroup from "./EditGroup"
import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import { group } from "store"
import { getIconClassName } from "@uifabric/styling"
import { Button, CommandBarButton } from "guilda-ui"

const columns = [
    {
        Header: "",
        accessor: "icon",
        width: 40,
        Cell: props => (
            <ColorEmojiIcon
                icon={props.value}
                iconStyle={{ marginRight: 10 }}
                size={18}
            />
        )
    },
    {
        Header: "Nome do grupo",
        accessor: "name",
        limit: 400
    },
    {
        Header: "Descrição",
        accessor: "description",
        width: 350,
        limit: 45
    },
    {
        Header: "Membros",
        accessor: "members",
        Cell: props => props.value.length
    }
]

export default function Groups(props) {
    return (
        <>
            <PageTableBase
                storeRef={group}
                title="Grupos"
                newLabel="Novo"
                newIcon={getIconClassName("PeopleAdd")}
                columns={columns}
                EditComponent={EditGroup}
                helperAction={
                    <SettingsHelp
                        title="Seus grupos"
                        icon={getIconClassName("People")}
                    >
                        Você pode organizar os membros da sua equipe em grupos
                        específicos, como "vendas", "suporte", "gerentes de
                        produto" e etc.
                        <br />
                        <br />
                        Somente agentes pertencentes a um grupo podem ver as
                        conversas desse grupo.
                    </SettingsHelp>
                }
                nodata={{
                    title:
                        "Parece que você ainda não adicionou nenhum grupo...",
                    buttonText: "Adicionar Grupo"
                }}
                {...props}
            />
        </>
    )
}
