import React, { useEffect, useState } from "react"
import Loading from "./Loading"
import { general, useStore } from "store"

const Loader = ({
    children,
    loading,
    loader,
    table,
    style,
    alwaysLoadingOnMount,
    noMarginTop,
    size = 28,
    all
}) => {
    const { loaders } = useStore(general)
    const [avoidedBlink, setAvoidedBlink] = useState(false)
    useEffect(() => {
        if (alwaysLoadingOnMount) {
            setTimeout(() => {
                setAvoidedBlink(true)
            }, 100)
        }
    }, [])

    const shouldAvoidBlink = alwaysLoadingOnMount ? !avoidedBlink : false
    const isLoading =
        shouldAvoidBlink ||
        loading ||
        (loader ? loaders.includes(loader) : false) ||
        (all ? loaders.length > 0 : false)
    if (isLoading)
        return (
            <Loading
                center
                style={{ ...style, ...(noMarginTop ? { marginTop: 0, padding: 20 } : {}) }}
                size={size}
            />
        )
    if (table) {
        return (
            <>
                <div className="table-loading-wrapper midcol">
                    {isLoading && <Loading center />}
                </div>
                <div className={isLoading ? "invisible-table" : ""}>{children}</div>
            </>
        )
    }
    return <>{children}</>
}

export default Loader
