import React, { useState, useEffect } from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"
import AvatarUploader from "components/AvatarUploader"
import { session, general, useStore } from "store"
import workspaceImg from "assets/images/workspace.svg"
import { getImage } from "lib/helper"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import Radiobox from "components/forms/Radiobox"
import { defaultThemes } from "lib/constants"
import { useBreadcrumb } from "hooks"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function getColors(theme) {
    const themeSplitted = theme.colors.split(",")
    return {
        HEADER_BG: themeSplitted[0],
        // SIDEBAR_BG: themeSplitted[0],
        // MENU_BG_HOVER: themeSplitted[1],
        // ACTIVE_ITEM: themeSplitted[2],
        // ACTIVE_ITEM_TEXT: themeSplitted[3],
        // HOVER_ITEM: themeSplitted[4],
        // TEXT_COLOR: themeSplitted[5],
        // ACTIVE_PRESENCE: themeSplitted[6],
        // MENTION_BADGE: themeSplitted[7],
        name: theme.name
    }
}

function ThemeItem({ theme, themeColors, setThemeColors }) {
    const colors = getColors(theme)
    return (
        <a
            className="theme-item-preference"
            onClick={() => setThemeColors(theme.colors)}
        >
            <Radiobox name="theme" checked={themeColors === theme.colors} onChange={() => {}}>
                {theme.name}
            </Radiobox>
            <div className="theme-preview">
                <div
                    className="tp-header"
                    style={{ backgroundColor: colors.HEADER_BG }}
                />
                <div className="tp-line">
                    <div className="tp-shadow" style={{ width: 30 }} />
                </div>
                <div
                    className="tp-line selected"
                    style={{ backgroundColor: colors.ACTIVE_ITEM }}
                >
                    <div
                        className="tp-shadow"
                        style={{ backgroundColor: "#6474be" }}
                    />
                </div>

                <div className="tp-line">
                    <div
                        className="tp-shadow"
                        style={{ backgroundColor: colors.TEXT_COLOR }}
                    />
                    <div
                        className="tp-count"
                        style={{ backgroundColor: colors.MENTION_BADGE }}
                    />
                </div>
            </div>
        </a>
    )
}

function EditWorkspace(props) {
    useBreadcrumb({ title: "Workspace" })
    const { updateWorkspace, getWorkspace, workspace } = useStore(session)
    const { uploadFile, themeColors, setThemeColors } = useStore(general)

    const [avatar, setAvatar] = useState()
    const item = workspace

    useEffect(() => {
        getWorkspace()
    }, [])

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Agente atualizou os dados de um workspace"
            })
            let avatarModel
            if (avatar) {
                ReactGA.event({
                    category: analyticsCategory.AGENT,
                    action: "Agente atualizou a imagem de um workspace"
                })
                const formData = new FormData()
                formData.append("avatar", avatar)
                const uploadResponse = await uploadFile({
                    data: formData,
                    loader: "put/workspace"
                })
                avatarModel = uploadResponse[0].fileName
            }
            await updateWorkspace({
                data: {
                    ...model,
                    workspaceId: item.workspaceId,
                    avatar: avatarModel
                },
                toastMessage: "Workspace atualizado"
            })
        }
    })

    const name = useField("name", {
        defaultValue: item.workspace,
        form
    })

    const customerIdentifier = useField("customerIdentifier", {
        defaultValue: item.customerIdentifier,
        form
    })

    return (
        <>
            <ContextHeader />
            <PageTitle title="Workspace" />
            <FormDialog
                endpoint="/workspace"
                // superTitle="Editar Workspace"
                onSubmit={form.onSubmit}
                noExcludable
                item={item}
                footerStyle={{
                    justifyContent: "flex-start",
                    marginLeft: 32,
                    paddingLeft: 0
                }}
                saveLoader="put/workspace"
                noPadding
            >
                <div style={{ maxWidth: 460, marginLeft: 32, paddingLeft: 0 }}>
                    <AvatarUploader
                        width={80}
                        height={80}
                        label="Avatar"
                        avatar={getImage(item.avatar)}
                        setAvatar={setAvatar}
                        defaultAvatar={workspaceImg}
                    />
                    <br />
                    <Text label="Nome" required {...name} />
                    <Text label="Propriedade Identificadora" description="Propriedade que relaciona canais diferentes ao mesmo usuário" {...customerIdentifier} />
                    <br />
                    <br />
                    {/* <label className="label-input">Tema</label>
                    <br />
                    <br />
                    <div className="themes-defaults">
                        {defaultThemes.map(x => (
                            <ThemeItem
                                theme={x}
                                themeColors={themeColors}
                                setThemeColors={setThemeColors}
                                key={x.colors}
                            />
                        ))}
                    </div> */}
                </div>
            </FormDialog>
        </>
    )
}

export default EditWorkspace
