import React, { useState } from "react"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import Dropzone from "components/forms/Dropzone"
import { useSelectField, useForm } from "hooks"
import { operationTypes } from "lib/constants"

function EditContract(props) {
    const [files, setFiles] = useState([])

    const form = useForm({
        onSubmit: async model => {
            const formData = new FormData()
            files.forEach((file, i) => formData.append("file" + i, file))
            formData.append("code", model.code)
            const cb = await props.onSubmit(formData, {
                toast: "Importação bem sucedida. Sua importação será processada em breve.",
                customHeaders: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            return cb
        }
    })

    const types = useSelectField("code", {
        form,
        options: operationTypes
    })

    const saveText = files.length ? `Importar Tudo (${files.length})` : "Importar"
    const saveProps = { disabled: !files.length }
    return (
        <FormDialog
            {...props}
            superTitle="Importar Contrato"
            onSubmit={form.onSubmit}
            saveText={saveText}
            saveProps={saveProps}
            noExcludable
        >
            <Select label="Tipo dos contratos" {...types} />
            <Dropzone files={files} setFiles={setFiles} />
        </FormDialog>
    )
}

export default EditContract
