import React, { useMemo, useState } from "react"
import { Emoji } from "emoji-mart"

function ColorEmojiIcon({ icon, iconStyle, color, size = 12 }) {
    if (!icon) return null
    if (typeof icon === "function") return <i>{icon()}</i>
    if (icon.indexOf("fa-") > -1 || icon.indexOf("css-") > -1) return <i className={icon} style={iconStyle} />
    if (icon.indexOf("#") > -1)
        return <i className="fa fa-circle" style={{ ...iconStyle, color: icon, fontSize: 9 }} />
    return (
        <span style={iconStyle} className="icon">
            <Emoji emoji={icon} size={size} native />
        </span>
    )
}

export default ColorEmojiIcon
