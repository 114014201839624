import React from "react"
import BasePlatform from '../BasePlatform'

export default function TelegramLanding(props) {
    return (
        <BasePlatform
            title="KimoChat Telegram"
            description="O KimoChat Telegram é simples, rápido, seguro e sincronizado, trazendo mais praticidade! O Telegram é o segundo mensageiro instantâneo mais utilizado no momento, só no KimoChat você tem mais essa incrível experiência de atender seus clientes em uma única plataforma!"
            icon="https://i.imgur.com/TtrNX2X.png"
            banner="https://i.imgur.com/zvOoXDj.png"
            path="/user/register"
            {...props}
        />
    )
}
