import React, { useState, useEffect, useRef, useMemo } from "react"
import masks from "lib/masks"
import { useStore } from "store"
import { debug } from "util"

/*
 *  Text Input
 */
// export function useFormText(initialValue = "") {
//     const [value, setValue] = useState(initialValue)

//     useEffect(() => {
//         setValue(initialValue)
//     }, [initialValue])

//     function onChange(e) {
//         setValue(e)
//     }

//     return {
//         value,
//         onChange
//     }
// }

/*
 *  Text Input
 */
export function useCheckbox(name, { defaultValue, form }) {
    const [checked, setChecked] = useState(defaultValue)

    useEffect(() => {
        setChecked(defaultValue)
    }, [defaultValue])

    function onChange(e, v) {
        setChecked(!checked)
    }

    const checkbox = {
        checked: !!checked,
        name,
        setChecked,
        onChange
    }
    if (form) {
        form.addCheckbox(checkbox)
    }
    return checkbox
}

// /*
//  *  Text Input
//  */
// export function useSwitch(name, { defaultValue, form }) {
//     const [checked, setChecked] = useState(defaultValue)

//     useEffect(() => {
//         setChecked(defaultValue)
//     }, [defaultValue])

//     function onChange(e, v) {
//         setChecked(!checked)
//     }

//     const checkbox = {
//         checked: !!checked,
//         name,
//         setChecked,
//         onChange
//     }
//     if (form) {
//         form.addCheckbox(checkbox)
//     }
//     return checkbox
// }

/*
 *  Color Emoji
 */
export function useColorEmoji(name, { defaultValue, form }) {
    const [value, setValue] = useState(defaultValue)
    // const [emoji, setEmoji] = useState(defaultValue.emoji)

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    function onChange(v) {
        setValue(v)
    }

    const item = {
        name,
        value,
        setValue,
        onChange
    }

    useEffect(() => {
        if (form) form.addField(item)
    }, [value])

    return item
}

/*
 *  Multi Select
 */
export function useFormMultiSelect(
    name,
    { defaultValue, options, form, loader, loadFromStore, onLoad }
) {
    const [value, setValue] = useState()
    const [rawValue, setRawValue] = useState(defaultValue)

    function onChange(items) {
        setRawValue(items.map(e => ({ id: e.value })))
        setValue(items)
    }

    useEffect(() => {
        setRawValue(defaultValue)
    }, [defaultValue])

    const select = {
        name,
        rawValue,
        value:
            value ||
            (defaultValue
                ? options.filter(e => defaultValue.findIndex(el => e.value == el.id) > -1)
                : []),
        onChange,
        options,
        loader,
        isMulti: true
    }
    if (form) {
        form.addSelect(select)
    }
    return select
}

/*
 *  Select
 */
// export function useFormSelect(initialValue = {}, options) {
//     const [value, setValue] = useState()
//     const [Value, setModelValue] = useState(initialValue)

//     function onChange(item) {
//         setModelValue(item.value)
//         setValue(item)
//     }

//     return {
//         Value,
//         value: value || options.find(e => e.value === initialValue),
//         onChange,
//         options
//     }
// }
export function useSelectField(name, { defaultValue, options, form, loader, ...props }) {
    const [value, setValue] = useState()
    const [rawValue, setRawValue] = useState(defaultValue)
    const flatOptions = useMemo(() => {
        if (!options || !options.length) return []
        return options[0].options
            ? options.reduce((prev, value) => [...prev, ...value.options], [])
            : options
    }, [options])

    function onChange(item) {
        setRawValue(item ? item.value : null)
        setValue(item)
        if (props.onChange) {
            props.onChange(item)
        }
    }

    useEffect(() => {
        if (!value) {
            setRawValue(defaultValue)
            setValue(flatOptions.find(e => e.value === defaultValue))
        }
    }, [defaultValue, flatOptions])

    useEffect(() => {
        setRawValue(defaultValue)
    }, [])

    function clear() {
        setValue(null)
        setRawValue(null)
    }

    const select = {
        name,
        rawValue,
        value,
        onChange,
        setRawValue,
        setValue,
        clear,
        options,
        flatOptions,
        loader
    }
    if (form) {
        form.addSelect(select)
    }
    return select
}

/*
 *  Date Field
 */
export function useDatePeriod(name, { defaultValue, form }) {
    const [value, setValue] = useState()

    function onChange(item) {
        setValue(item)
    }

    useEffect(() => {
        setValue(defaultValue)
    }, [])

    const date = {
        name,
        value,
        setValue,
        onChange
    }
    useEffect(() => {
        if (form) {
            form.addCustom({
                name: "from",
                value: date.value && date.value.from
            })
            form.addCustom({ name: "to", value: date.value && date.value.to })
        }
    }, [date.value])

    return date
}

/*
 *  Array
 */
export function useFormArray(initialValue) {
    const [array, setArray] = useState(initialValue || [])
    const [test, setTest] = useState(0)

    // useEffect(() => {
    //     setArray(initialValue || [])
    // }, [initialValue])

    function onChange(item, index) {
        setTest(test + 1)
        setArray(state => {
            state[index] = item
            return state
        })
    }

    function updateItem(item) {
        setArray(array => {
            array[array.findIndex(x => x.id === item.id)] = item
            return array
        })
    }

    function onAddItem(item) {
        setArray(array => [...array, item])
    }

    function onDeleteItem(index) {
        // console.log("index", index, array.filter((e, i) => i !== index))
        setArray(array => array.filter((e, i) => i !== index))
    }

    return {
        array,
        changeItem: onChange,
        addItem: onAddItem,
        deleteItem: onDeleteItem,
        updateItem
    }
}

/*
 *  Field
 */
export const useField = (
    name,
    { defaultValue = "", validations = [], fieldsToValidateOnChange = [name], mask, form } = {}
) => {
    let [value, setValue] = useState(defaultValue)
    let [errors, setErrors] = useState([])
    let [pristine, setPristine] = useState(true)
    let [validating, setValidating] = useState(false)
    let validateCounter = useRef(0)

    const validate = async () => {
        let validateIteration = ++validateCounter.current
        setValidating(true)
        let formData = form.getFormData()
        let errorMessages = await Promise.all(
            validations.map(validation => validation(formData[name], formData))
        )
        errorMessages = errorMessages.filter(errorMsg => !!errorMsg)
        if (validateIteration === validateCounter.current) {
            // this is the most recent invocation
            setErrors(errorMessages)
            setValidating(false)
        }
        let fieldValid = errorMessages.length === 0
        return fieldValid
    }

    useEffect(() => {
        if (pristine) return
        if (errors.length) {
            form.validateFields(fieldsToValidateOnChange)
        }
    }, [value])

    const applyMasks = v => {
        if (!mask) return v
        if (typeof mask === "function") {
            return mask(v)
        }
        let newValue = v
        if (typeof mask === "object") {
            mask.forEach(m => {
                if (typeof m === "function") {
                    newValue = m(newValue)
                } else {
                    newValue = masks[m](newValue)
                }
            })
        } else {
            newValue = masks[mask]
        }
        return newValue
    }

    useEffect(() => {
        setValue(applyMasks(defaultValue))
    }, [defaultValue])

    let field = {
        name,
        value,
        errors,
        setErrors,
        pristine,
        onChange: e => {
            if (pristine) {
                setPristine(false)
            }
            const value = e.target ? e.target.value : e
            setValue(applyMasks(value))
        },
        setValue,
        validate,
        validating,
        formSubmitted: form && form.submitted
    }
    if (form) form.addField(field)
    return field
}

/*
 *  Form
 */
export const useForm = ({ onSubmit, defaultModel, onCatch }) => {
    let [submitted, setSubmitted] = useState(false)
    let [submitting, setSubmitting] = useState(false)
    // let [customs, setCustoms] = useState([])
    const fields = []
    let selects = []
    let customs = []
    let checkboxes = []

    const validateFields = async fieldNames => {
        let fieldsToValidate
        if (fieldNames instanceof Array) {
            fieldsToValidate = fields.filter(field => fieldNames.includes(field.name))
        } else {
            //if fieldNames not provided, validate all fields
            fieldsToValidate = fields
        }
        let fieldsValid = await Promise.all(
            fieldsToValidate.map(field => !field.validate || field.validate())
        )
        let formValid = fieldsValid.every(isValid => isValid === true)
        return formValid
    }

    const reduceForm = array => {
        return array.reduce((formData, f) => {
            formData[f.name] = f.rawValue || f.value || f.checked || null
            return formData
        }, {})
    }

    const getFormData = () => {
        return {
            ...reduceForm(fields),
            ...reduceForm(selects),
            ...reduceForm(customs),
            ...reduceForm(checkboxes)
        }
    }

    const validateServerErrors = error => {
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors.filter(
                e => e.type === "ValidationError"
            )
            if (validationErrors) {
                validationErrors.forEach(vError => {
                    const field = fields.find(e => e.name === vError.key)
                    if (field) {
                        field.setErrors(vError.descriptions)
                    }
                })
            }
        }
    }

    return {
        onSubmit: async params => {
            if (params && params.preventDefault) {
                params.preventDefault()
            }
            setSubmitting(true)
            setSubmitted(true) // User has attempted to submit form at least once
            let formValid = await validateFields()
            if (!formValid) {
                console.log("Form não válida")
                return false
            }
            setSubmitting(false)
            try {
                const cb = await onSubmit({ ...defaultModel, ...getFormData() }, formValid, params)
                if (cb && typeof cb === "function") {
                    cb()
                }
            } catch (errors) {
                console.log("Erro no form", errors)
                validateServerErrors(errors)
                if (onCatch) onCatch(errors)
            }
        },
        isValid: () => fields.every(f => f.errors.length === 0),
        addField: field => {
            const index = fields.findIndex(x => x.name === field.name)
            if (index > -1) {
                fields[index] = field
            } else {
                fields.push(field)
            }
        },
        addSelect: select => selects.push(select),
        addCheckbox: checkbox => checkboxes.push(checkbox),
        addCustom: custom => {
            // setCustoms(customs => [...customs.filter(e => e.name !== custom.name), custom])
            // if (custom.value) {
            //     console.log('ADICIONANDO', custom)
            // } else {
            //     setCustoms(customs => [...customs.filter(e => e.name !== custom.name)])
            // }
            customs = [...customs.filter(e => e.name !== custom.name), custom]
        },
        removeCustom: name => (customs = customs.filter(e => e.name !== name)),
        // removeCustom: name => setCustoms(customs => customs.filter(e => e.name !== name)),
        customs,
        fields,
        getFormData,
        validateFields,
        submitted,
        submitting
    }
}
