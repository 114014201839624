import React, { Component } from "react"
import cx from "classnames"
import masks from "lib/masks"

class Radiobox extends Component {
    state = {
        value: ""
    }

    componentWillMount() {
        if (this.props.value !== undefined) {
            this.setState({ value: this.props.value })
        }
    }

    componentWillReceiveProps(props) {
        if (props.value != this.state.value) {
            this.setState({ value: props.value })
        }
    }

    onChange = (e) => {
        this.setState({ value: e.target.value })
        this.props.onChange(this.props.name, e.target.value)
    }

    render() {
        const {
            label,
            disabled,
            rows,
            children,
            ...props
        } = this.props
        const className = cx('checkbox-space radio', {
            disabled
        })
        return (
            <div className={className}>
                {/* <label className="label-input">{label}</label> */}
                <div className="checkbox-box">
                    <input id={children} {...props} type="radio"/>
                </div>
                <label for={children} className="checkbox-description">
                    {children}
                </label>
            </div>
        )
    }
}

export default Radiobox
