import React from "react"
import { Line } from "@nivo/line"
import { getHoursAndMinutes } from "lib/helper"

const DashLine = ({ data, axisLeft, legends, tooltip, width, props }) => (
    <Line
        data={data}
        margin={{
            top: 20,
            right: 20,
            bottom: 50,
            left: 20
        }}
        xScale={{
            type: "point"
        }}
        yScale={{
            type: "linear",
            stacked: false,
            min: "auto",
            max: "auto"
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "",
            legendOffset: 36,
            legendPosition: "middle"
        }}
        axisLeft={null}
        // axisLeft={{
        //     orient: "left",
        //     tickSize: 0,
        //     tickPadding: 0,
        //     tickRotation: 0,
        //     legend: "",
        //     legendOffset: 0,
        //     legendPosition: "middle",
        //     ...axisLeft,
        //     enable: false,
        //     "format": () => null,
        // }}
        dotSize={8}
        // dotColor="inherit:darker(0.3)"
        dotBorderWidth={2}
        // dotBorderColor="#000"
        enableDotLabel={false}
        dotLabel="y"
        dotLabelYOffset={-12}
        animate={true}
        // curve="monotoneX"
        motionStiffness={90}
        motionDamping={15}
        pointColor="black"
        // dotColor="white"
        // dotBorderColor="black"
        pointBorderColor="black"
        // enableGridX={false}
        lineWidth={4}
        tooltip={tooltip}
        colorBy={d => d.color}
        {...props}

        // tooltip={obj => {
        //     return getHoursAndMinutes(obj.value)
        // }}
        // layers={[
        //     "grid",
        //     "markers",
        //     "axes",
        //     // "areas",
        //     "crosshair",
        //     "lines",
        //     "points",
        //     "slices",
        //     "dots"
        //     // "mesh",
        //     // "legends"
        // ]}
        // legends={[
        //     {
        //         anchor: "top-left",
        //         direction: "row",
        //         justify: false,
        //         translateX: -40,
        //         translateY: -40,
        //         itemsSpacing: 0,
        //         itemDirection: "left-to-right",
        //         itemWidth: 140,
        //         itemHeight: 20,
        //         itemOpacity: 0.75,
        //         symbolSize: 12,
        //         symbolShape: "circle",
        //         symbolBorderColor: "rgba(0, 0, 0, .5)",
        //         ...legends
        //         // effects: [
        //         //     {
        //         //         on: "hover",
        //         //         style: {
        //         //             itemBackground: "rgba(0, 0, 0, .03)",
        //         //             itemOpacity: 1
        //         //         }
        //         //     }
        //         // ]
        //     }
        // ]}
        theme={{
            // grid: {
            //     stroke: "red",
            //     strokeWidth: 5
            // },
            // axis: {
            //     textColor: "#fff",
            //     fontSize: "14px",
            //     tickColor: "#fff"
            // },
            grid: {
                line: {
                    stroke: "#f0f2f5",
                    strokeWidth: 1
                }
            }
        }}
        width={width}
        height={250}
    />
)

export default DashLine
