import React from "react"
import axios from "axios"
import toaster from "./toaster"
import * as dev from "./dev"
import config from "config"
import { stores } from "store"

function getHeaders(customHeaders) {
    const workspace = stores.session.workspace
    return {
        "Content-Type": "application/json",
        Authorization: workspace ? workspace.token : "",
        ...customHeaders
    }
}

async function renewToken(url, props) {
    const workspace = stores.session.workspace
        console.log("CHAMANDO RENOVACAO DE TOKEN", {
            token: workspace.token,
            renewalToken: workspace.renewalToken
        })

    try {
        const response = await axios({
            headers: getHeaders(),
            method: "post",
            url: props.domain + config.api.RENEW_TOKEN,
            data: {
                token: workspace.token,
                renewalToken: workspace.renewalToken
            }
        })
        console.log("Response da renovacao de token", response)
        stores.session.saveUser(response.data)
        return req(url, props)
    } catch (e) {
        toaster.error("Sua sessão expirou")
        stores.session.logout()
        return {}
    }
}

async function handleError(error, props, url) {
    console.error(error)
    dev.printRequest({ ...props, url, response: error.response })

    /* Server offline */
    if (error.message === "Network Error") {
        const message = "Erro de conexão. Sem contato com servidor"
        toaster.error(message, { toastId: "conectionError" })
        dev.writeSpell("response", { ...props, data: { error: message } })
        return { error: message }
        // return new Error(message)
    }

    /* Unknown Error */
    let errorDescriptions
    try {
        errorDescriptions = error.response.data.errors[0].descriptions
    } catch (e) {
        const message = (
            <div>
                Erro inesperado no servidor (Erro {error.response.status})<br />
                {error.message}{" "}
            </div>
        )
        toaster.error(message)
        dev.writeSpell("response", {
            ...props,
            data: error.response && error.response.data
        })
        return { error: message }
        // return new Error(error.message)
    }

    /* Token expirado */
    // if (error.response.data.errors[0].code === "UNAUTHORIZED") {
    //     console.log("Vou renovar o token")
    //     const response = await renewToken(url, props)
    //     console.log("Resposta da renovação de token", response)
    //     return response
    // }

    /* Standard Error */
    errorDescriptions && errorDescriptions.forEach(e => toaster.error(e))
    dev.writeSpell("response", { ...props, data: error.response.data })
    throw error.response.data
}

/*
 *  Req
 */
export default async function req(
    url,
    {
        method = "get",
        data,
        endpointId,
        customHeaders = {},
        loader = method + url,
        toast,
        toastDanger,
    } = {}
) {
    const props = {
        method,
        data,
        customHeaders,
        loader,
        toast,
        url,
        toastDanger
    }
    const domain = stores.general._domain.url

    /* Loading */
    stores.general.addLoader(loader)

    /* DEVELOPER */
    if (!stores.general._isProduction) {
        dev.writeSpell("sent", props)
    }

    let response
    try {
        if (domain === "mock") {
            response = await dev.getFromMock(method, url)
        } else {
            response = await axios({
                method,
                url: domain + url + (endpointId ? "/" + endpointId : ""),
                data,
                headers: getHeaders(customHeaders),
                params: method === "get" ? { ...data } : {},
            })
            // console.log("response", response)
        }
    } catch (e) {
        console.log(e)
        stores.general.removeLoader(loader)
        return handleError(e, { ...props, domain }, url)
    }

    /* DEVELOPER */
    if (!stores.general._isProduction) {
        dev.writeSpell("response", { ...props, data: response.data })
        dev.printRequest({ ...props, url, response })
    }

    /* Toasters */
    if (toast) {
        toaster.ok(toast)
    }
    if (toastDanger) {
        toaster.warn(toastDanger)
    }

    stores.general.removeLoader(loader)
    return response.data
}
