import React, { useRef, useEffect } from "react"
import jsonFormatter from "json-string-formatter"
import Dropdown from "components/Dropdown"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import Text from "components/forms/Text"
import { useFormText, useSelectField } from "hooks"
import ScriptBox from "components/forms/ScriptBox"
import { general, useStore } from "store"

function trigger() {
    return <Button icon="far fa-book-spells" round />
}

export const getDefaultApiEndpoints = (isProd) => {
    const prod = [{ value: "https://api.kimochat.com.br/api", label: "production" }]
    const devs = [
        { value: "https://api.kimochat.com.br/api", label: "staging" },
        { value: "http://localhost:3000/api", label: "localhost" },
        { value: "mock", label: "mock" },
    ]
    return isProd ? prod : devs
}

function DevRouteDropdown(props) {
    const dropRef = useRef()
    const { _domain, _changeDomain, _reqModel, _isProduction } = useStore(general)
    const defaultDomains = useSelectField("domains", {
        defaultValue: _domain.url,
        options: getDefaultApiEndpoints(_isProduction),
    })

    useEffect(() => {
        if (defaultDomains.value) {
            _changeDomain(defaultDomains.value)
        }
    }, [defaultDomains.rawValue])

    function onClose() {
        dropRef.current.onCloseMenu()
    }

    const sent = _reqModel.sent
    const response = _reqModel.response

    const labelSent = (
        <span className="dev-method">
            <span>{sent.method && sent.method.toUpperCase()}</span>
            <span>{sent.endpoint}</span>
            <span>Body</span>
        </span>
    )

    const labelResponse = (
        <span className="dev-method">
            <span>{response.method && response.method.toUpperCase()}</span>
            <span>{response.endpoint}</span>
            <span>Response</span>
        </span>
    )

    let sentJSON
    let responseJSON

    if (sent.body) {
        try {
            sentJSON = jsonFormatter.format(JSON.stringify(sent.body))
        } catch (e) {
            console.log("Erro ao formatar body")
        }
    }
    if (response.body) {
        try {
            responseJSON = jsonFormatter.format(JSON.stringify(response.body))
        } catch (e) {
            console.log("Erro ao formatar body")
        }
    }

    return (
        <div className="dev-dropdown">
            <Dropdown
                trigger={trigger(onClose)}
                ref={dropRef}
                style={{ width: 450, bottom: 50, top: "auto" }}
                // openStyle={{ left: 'auto' }}
                openStyle={{ left: 0 }}
                notClose
                // down
            >
                <div
                    className="filter-drop-inner"
                    style={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 105px)",
                    }}
                >
                    <label className="label-input" style={{ opacity: 0.6 }}>
                        Inspetor de Request
                    </label>
                    <Select label="Domínios padrões" {...defaultDomains} />
                    <Text
                        label="Domínio"
                        required
                        value={_domain.value || ""}
                        onChange={(e) => _changeDomain({ value: e.target.value })}
                    />
                    <label className="label-input" style={{ opacity: 0.6, marginTop: 10 }}>
                        Última requisição
                    </label>
                    {sent.body && (
                        <ScriptBox label={labelSent} value={sentJSON} onChange={() => {}} />
                    )}
                    {response.body && (
                        <ScriptBox
                            label={labelResponse}
                            value={responseJSON}
                            onChange={() => {}}
                            disabled
                        />
                    )}

                    {/* <Button danger type="button" full>
                        Excluir
                    </Button> */}
                </div>
            </Dropdown>
        </div>
    )
}

export default DevRouteDropdown
