import React, { useEffect, useMemo, useRef, useState } from "react"
import io from "socket.io-client"
import { addResponseMessage } from "react-chat-widget"
import { v4 as uuidv4 } from 'uuid'
import queryString from "query-string"
import { general, useStore } from "store"
import config from "config"
import req from "lib/req"
import WidgetChat from "lib/widget/widget"

function Widget({ location }) {
    const { _domain } = useStore(general)
    const [socket, setSocket] = useState({})
    const [hasSentMessage, setHasSentMessage] = useState(false)
    // const senderId = useRef('c716a4d3-a8b5-48e2-ad28-748cc15b845a')
    const senderId = useRef(uuidv4())
    const params = useMemo(() => queryString.parse(location.search), [location.search])

    async function sendMessage({ content }) {
        await req(config.api.SEND_MESSAGE_BY_CLIENT, {
            method: 'POST',
            data: {
                sender: senderId.current,
                content,
                type: "text",
                identifier: params.workspacePlatformIdentifier
            }
        })
        setHasSentMessage(true)
    }

    function initSocket() {
        const socketUrl = `${_domain.url.slice(0, _domain.url.length - 4)}/chat/widget/${params.workspacePlatformIdentifier}/${senderId.current}`
        const socketVar = io(socketUrl)

        setSocket(socketVar)

        socketVar.on("chat.widgetNewMessage", (payload) => {
            try {
                const messages = JSON.parse(payload.newMessage.content)
                messages.forEach(cont => addResponseMessage(cont.insert))
            } catch (error) {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        if (hasSentMessage) initSocket()
    }, [hasSentMessage])

    useEffect(() => {
        return () => socket.disconnect && socket.disconnect()
    }, [])

    return (
        <WidgetChat 
            sendMessage={sendMessage}
            title="Nome da empresa"
            subtitle="Fale conosco"
            // launcherOpenImg={gymLogo}
            // launcherCloseImg={gymLogo}
        />
    )
}

export default Widget
