import React from "react"
import { withRouter } from "react-router-dom"
import ContextHeader from "components/ContextHeader"
import Table from "components/Table"
import { useBreadcrumb } from "hooks"
import { useStore, general } from "store"
import { buildUrlQuery } from "lib/helper"
import ModalBase from "pages/ModalBase"
import Button from "components/forms/Button"
import { getIconClassName } from "@uifabric/styling"
import PageTitle from "components/PageTitle"
import cx from "classnames"

function PageTableBase({
    storeRef,
    breadcrumb,
    title,
    description,
    columns,
    nodata,
    EditComponent,
    ViewComponent,
    noModal,
    newLabel,
    newIcon,
    helper,
    customTo,
    helperAction,
    actions = [],
    actionsEnd = [],
    noHeader,
    ...props
}) {
    const store = useStore(storeRef)
    const { panelHelperOpen, setPanelHelperOpen } = useStore(general)
    useBreadcrumb({ title: breadcrumb || title })

    const onBackModal = () =>
        props.history.replace(
            props.match.url.substring(0, props.match.url.lastIndexOf("/")) +
                props.location.search
        )

    async function onSubmit(data, configs) {
        store.save({ ...configs, data }).then(onBackModal)
    }

    function onDelete(id) {
        store
            .remove({ data: { id }, toastMessage: "Excluído com sucesso." })
            .then(onBackModal)
    }

    // const newButton = newLabel ? (
    //     <Button
    //         gray
    //         ios
    //         icon="fa fa-plus-circle"
    //         to={buildUrlQuery("new", props)}
    //         className="button-page-table-add"
    //     >
    //         {newLabel}
    //     </Button>
    // ) : null

    const newAction = newLabel
        ? [
              {
                  name: newLabel,
                  icon: newIcon || getIconClassName("Add"),
                  to: buildUrlQuery("new", props)
              }
          ]
        : []

    const helperActionEnd = helperAction
        ? [
              {
                  icon: getIconClassName("Info"),
                  style: panelHelperOpen ? { backgroundColor: "#dadada" } : {},
                  iconStyle: panelHelperOpen
                      ? { color: "#000000", margin: 0 }
                      : { margin: 0 },
                  onClick: () => {
                      setPanelHelperOpen(opened => !opened)
                  }
              }
          ]
        : []

    return (
        <section
            className={cx("page-table", { openSidePanel: panelHelperOpen && !noHeader })}
        >
            {!noHeader && (
                <ContextHeader
                    actions={[...newAction, ...actions]}
                    actionsEnd={[...helperActionEnd, ...actionsEnd]}
                />
            )}
            {/* {helper} */}
            <div className="page-table-content">
                <div className="page-table-content-left">
                    <PageTitle title={title} description={description} />
                    <div className="page-table-table">
                        <Table
                            store={store}
                            columns={columns}
                            query={props.location.search}
                            // loader={"/" + endpoint}
                            history={props.history}
                            getTrProps={(state, rowInfo) => ({
                                onClick: () => {
                                    if (customTo) {
                                        props.history.push(customTo(rowInfo))
                                    } else {
                                        props.history.push(
                                            buildUrlQuery(
                                                rowInfo.original.id,
                                                props
                                            )
                                        )
                                    }
                                }
                            })}
                            nodata={{
                                ...nodata,
                                to: nodata.buttonText
                                    ? buildUrlQuery("new", props)
                                    : undefined
                            }}
                        />
                    </div>
                </div>
                {helperAction && (
                    <section
                        className={cx("context-side-panel", {
                            open: panelHelperOpen
                        })}
                    >
                        {helperAction}
                    </section>
                )}
            </div>
            {props.match.params.id && (
                <ModalBase
                    {...props}
                    store={store}
                    ViewComponent={ViewComponent}
                    EditComponent={EditComponent}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    noModal={noModal}
                    onBackModal={onBackModal}
                />
            )}
        </section>
    )
}
export default withRouter(PageTableBase)
