import React, { useMemo } from "react"
import Grid from "@material-ui/core/Grid"
import Table from "components/Table"
import { Link } from "react-router-dom"
import GraphMembersCard from "./GraphMembersCard"
import { useBreadcrumb } from "hooks"
import { dashboard, dashboardAgents, useStore } from "store"
import { getHoursAndMinutes } from "lib/helper"

function DashboardMembers(props) {
    useBreadcrumb({ title: "Dashboard de agentes", backTo: "/dashboard" })
    // const dashboardStore = useStore(dashboard)
    const dashboardAgentsStore = useStore(dashboardAgents)

    // useEffect(() => {
    //     dashboardStore.getAgentsGraph()
    // }, [])


    const columns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                Cell: props => (
                    <Link
                        className="vertical-center"
                        to={"/settings/members/" + props.row._original.id}
                    >
                        {props.row._original.avatar ? (
                            <img className="cell-avatar" src={props.row._original.avatar} alt="" />
                        ) : (
                            <i className="ico ico-user cell-avatar" />
                        )}
                        <span>{props.value}</span>
                    </Link>
                )
            },
            {
                Header: "Tempo de primeira resposta",
                accessor: "firstTimeResponse",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Tempo de resposta",
                accessor: "timeResponse",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Tempo de atendimento",
                accessor: "resolutionTime",
                Cell: props => getHoursAndMinutes(props.value)
            },
            {
                Header: "Total de conversas",
                accessor: "newConversations"
            },
            {
                Header: "Conversas resolvidas",
                accessor: "resolvedConversations"
            }
        ],
        [dashboardAgentsStore.all]
    )

    const agentsStats = useMemo(
        () =>
            dashboardAgentsStore.all.map(x => ({
                member: x.name,
                "Tempo de primeira resposta": x.firstTimeResponse,
                "Tempo de resposta": x.timeResponse,
                "Tempo de atendimento": x.resolutionTime
            })),
        [dashboardAgentsStore.all]
    )

    return (
        <>
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <GraphMembersCard height={300} data={agentsStats} />
                </Grid>
            </Grid>
            <Table
                store={dashboardAgentsStore}
                columns={columns}
                query={props.location.search}
                // loader={"/" + endpoint}
                history={props.history}
                getTrProps={(state, rowInfo) => ({
                    onClick: () => {
                        props.history.push("/settings/members/" + rowInfo.original.id)
                    }
                })}
                nodata={{
                    title: "Parece que você ainda não possui nenhum agente...",
                    buttonText: "Convidar Novo Agente",
                    to: "/settings/members/new"
                }}
            />
        </>
    )
}

export default DashboardMembers
