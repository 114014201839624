import React from "react"
import BasePlatform from '../BasePlatform'
import screen3 from "assets/images/landing/screen3.png"

export default function BotLanding(props) {
    return (
        <BasePlatform
            title="KimoChat Bot"
            description="Com o KimoChat Bot você pode atender aos chats Instantaneamente! Chega de reclamações de espera de seus clientes, você pode responder mensagens, coletar informações e encaminhar conversas para seus agentes, tudo isso automaticamente!"
            icon="https://i.imgur.com/ScuTwGD.png"
            banner={screen3}
            path="/user/register"
            {...props}
        />
    )
}
