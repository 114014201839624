import React from "react"
import BasePlatform from '../BasePlatform'

export default function WidgetLanding(props) {
    return (
        <BasePlatform
            title="KimoChat Widget"
            description="Com o KimoChat Widget você pode ter um chat customizado em seu site, ou seja, além de atender seus cliente de outras redes sociais você pode atender aqueles clientes que visitam seu site diretamente!"
            icon="https://i.imgur.com/qJn8mKL.png"
            banner="https://i.imgur.com/ol1e29m.png"
            path="/user/register"
            {...props}
        />
    )
}
