import React, { useState, useMemo } from "react"
import { Link, Redirect } from "react-router-dom"

export default function LandingRegisterSection() {
    return (
        <div
            className="landing-section blue"
            style={{
                // paddingTop: 0,
                paddingBottom: 96,
                textAlign: "center"
                // marginTop: 90
            }}
        >
            <div className="midcol">
                <div className="center">
                    <h1
                        style={{
                            fontSize: 27,
                            maxWidth: 720,
                            marginBottom: 0,
                            lineHeight: "45px"
                        }}
                    >
                        Gostou do que viu? Cadastre-se!
                        <br />
                        Todas as suas mensagens em um só lugar!
                    </h1>
                    <h3 style={{ color: "white", fontSize: 15, marginTop: 20 }}>
                        Não é necessário inserir cartão de crédito
                    </h3>
                    <Link to="/user/register" className="landing-button-connect register-btn">
                        Experimente grátis!
                    </Link>
                </div>
            </div>
        </div>
    )
}
