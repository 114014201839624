import React from "react"
// import cx from "classnames"
import ReactSwitch from "react-switch"

function SwitchField(props) {
    const { label, description, setChecked, onClick, ...otherProps } = props

    return (
        <div className="switch-field" onClick={onClick}>
            <div className="sf-left">
                <div className="label-input">{label}</div>
                <div className="sf-description">{description}</div>
            </div>
            <div className="sf-right">
                <ReactSwitch
                    onColor="#2ecc71"
                    id={label}
                    offColor="#e9e9e9"
                    handleDiameter={22}
                    checkedIcon={null}
                    uncheckedIcon={null}
                    height={30}
                    width={48}
                    {...otherProps}
                />
            </div>
        </div>
    )
}

export default SwitchField
