import React, { useState, useMemo } from "react"
import Box from "components/Box"
import DashLine from "components/graphs/DashLine"
import { useSelectField } from "hooks"
import Select from "components/forms/Select"

function GraphBox({ graphs, height, width = 740 }) {
    const [index, setIndex] = useState(0)
    const selectedGraph = useMemo(() => graphs[index], [index])
    const graphsSelect = useSelectField("graphs", {
        defaultValue: 0,
        options: graphs.map((x, i) => ({
            value: i,
            label: x.name
        })),
        onChange: x => setIndex(x.value)
    })
    return (
        <div className="page dash-card graph">
            <Box>
                <div style={{ width: 200, margin: "30px 40px 5px", fontWeight: 500}}>
                    <Select {...graphsSelect} />
                </div>
                <div style={{ display: "flex", width: width, margin: "0 auto" }}>
                    <selectedGraph.graph data={graphs[index].data} width={width} props={graphs[index].props} />
                </div>
            </Box>
        </div>
    )
}

export default GraphBox
