import React from "react"
import BasePlatform from '../BasePlatform'

export default function FacebookLanding(props) {
    return (
        <BasePlatform
            title="KimoChat Facebook Messenger"
            description="O KimoChat Facebook Messenger é composto por um conjunto de recursos destinados a oferecer tudo o que você precisa para criar experiências interativas incríveis no Messenger oferecendo a você mais uma canal de comunicação com seu cliente! Não perca mais tempo experimente já!"
            icon="https://logodownload.org/wp-content/uploads/2017/04/facebook-messenger-logo.png"
            banner="https://i.imgur.com/qXMMCdw.png"
            path="/user/register"
            {...props}
        />
    )
}
