import React, { useState } from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm } from "hooks"
import { useStore, segment, general } from "store"
import config from "config"
import ModalHeader from "components/ModalHeader"
import ModalBody from "components/ModalBody"
import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import cx from "classnames"
import EditSegment from "components/inbox/EditSegment"

function FilterItem({ id, icon, iconStyle, name, selected, onClick, subscribed }) {
    return (
        <a
            className={cx("browse-filter-item", { selected })}
            onClick={() => onClick(id, subscribed)}
        >
            <ColorEmojiIcon icon={icon} iconStyle={iconStyle} />
            <span className="name">{name}</span>
            {selected && <i className="fa fa-check" />}
        </a>
    )
}

function BrowseFilters(props) {
    const segmentStore = useStore(segment)
    const { addModal } = useStore(general)

    const search = useField("search")

    return (
        <div>
            <ModalHeader title="Pesquisar Filtros" />
            <ModalBody>
                <div className="form-row" style={{ marginBottom: 20 }}>
                    <Button primary onClick={() => addModal(<EditSegment />)}>
                        Novo Filtro
                    </Button>
                    <div style={{ width: 240 }}>
                        <Text placeholder="Pesquisar" {...search} flat />
                    </div>
                </div>
                <div className="browse-filters-title">Filtros Particulares</div>
                <div className="browse-filters-section">
                    {segmentStore.all
                        .filter(
                            x =>
                                x.name.toLowerCase().includes(search.value.toLowerCase()) &&
                                x.isPublic === false
                        )
                        .map(x => (
                            <FilterItem
                                {...x}
                                key={x.id}
                                selected={x.subscribed}
                                onClick={segmentStore.toggleSubscription}
                            />
                        ))}
                </div>
                <div className="browse-filters-title" style={{ marginTop: 10 }}>
                    Filtros Públicos
                </div>
                <div className="browse-filters-section">
                    {segmentStore.all
                        .filter(
                            x =>
                                x.name.toLowerCase().includes(search.value.toLowerCase()) &&
                                x.isPublic === true
                        )
                        .map(x => (
                            <FilterItem
                                {...x}
                                key={x.id}
                                selected={x.subscribed}
                                onClick={segmentStore.toggleSubscription}
                            />
                        ))}
                </div>
            </ModalBody>
        </div>
    )
}

export default BrowseFilters
