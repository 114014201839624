import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"
import AvatarUploader from "components/AvatarUploader"
import { session, general, useStore } from "store"
import { getImage } from "lib/helper"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import { useBreadcrumb } from "hooks"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function EditProfile(props) {
    useBreadcrumb({ title: "Perfil" })

    const { user, updateProfile } = useStore(session)
    const { uploadFile, _domain } = useStore(general)
    const [avatar, setAvatar] = useState()
    const item = user

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Agente atualizou seus dados do perfil"
            })
            let avatarModel
            if (avatar) {
                ReactGA.event({
                    category: analyticsCategory.AGENT,
                    action: "Agente atualizou a foto perfil"
                })
                const formData = new FormData()
                formData.append("avatar", avatar)
                const uploadResponse = await uploadFile({
                    data: formData,
                    loader: "put/session"
                })
                avatarModel = { avatar: uploadResponse[0].fileName }
            }
            await updateProfile({
                data: { ...model, ...avatarModel },
                toastMessage: "Perfil atualizado"
            })
        }
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })
    const email = useField("email", {
        defaultValue: item.email,
        form
    })
    const phone = useField("cellphone", {
        defaultValue: item.phone,
        mask: ["cell"],
        form
    })

    return (
        <>
            <ContextHeader />
            <PageTitle title="Perfil" />
            <FormDialog
                endpoint="/session"
                // superTitle="Editar Perfil"
                onSubmit={form.onSubmit}
                noExcludable
                item={item}
                footerStyle={{ justifyContent: "flex-start", marginLeft: 32, paddingLeft: 0 }}
                noPadding
            >
                <div style={{ maxWidth: 460, marginLeft: 32 }}>
                    <AvatarUploader
                        width={80}
                        height={80}
                        label="Avatar"
                        avatar={getImage(item.avatar)}
                        setAvatar={setAvatar}
                    />
                    <br />
                    <Text label="Nome" required {...name} />
                    <Text
                        label="Email"
                        type="email"
                        required
                        disabled
                        // description="Seu email é usado para te enviar notificações. Seus visitantes não verão isso"
                        {...email}
                    />
                    <Text label="Celular" {...phone} />
                    <div style={{ marginBottom: 2 }}>
                        <label className="label-input">Senha</label>
                    </div>
                    <Button
                        to="/settings/account/profile/password"
                        style={{ width: "100%", justifyContent: "center" }}
                    >
                        Alterar Senha
                    </Button>
                    <br />
                    <br />
                    <br />
                </div>
            </FormDialog>
        </>
    )
}

export default EditProfile
