import React, { useCallback, useState } from "react"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm, useCheckbox } from "hooks"
import { useStore, channel, inbox, general, session } from "store"
import config from "config"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import wppImg from "assets/images/whatsapp.png"
import masks from "lib/masks"
import { request, toast, Checkbox } from "nanui"
import { checkType, assetsToDelta } from "pages/inbox/AttachFileModal"
import { ReactMic } from "react-mic"
import { getIconClassName } from "@uifabric/styling"
import { messagesTypes } from "lib/constants"

var audio
function RecordAudioModal(props) {
    const [recording, setRecording] = useState()
    const [record, setRecord] = useState()
    // const [files, setFiles] = useState([])
    const [assetsLoaded, setAssetsLoaded] = useState(false)
    const channelStore = useStore(channel)
    const inboxStore = useStore(inbox)
    const { user } = useStore(session)
    const { uploadAssets, _domain, removeModal } = useStore(general)

    const form = useForm({
        onSubmit: async model => {
            const files = [new File([record.blob], "record.webm")]
            const assets = await uploadAssets(files)
            setAssetsLoaded(true)
            const msgItem = {
                content: JSON.stringify(assetsToDelta(files, assets, _domain, "audio")),
                customerPlatformId: props.id,
                createdAt: new Date(),
                operatorId: user.id,
                Operator: { name: user.name, avatar: user.avatar },
                type: messagesTypes.COMMON
            }
            // const response = await request.save(config.api.SEND_ACTIVE_MESSAGE, data)
            // console.log("response", response)
            setAssetsLoaded(false)
            await inboxStore.saveMessage(
                msgItem,
                props.id,
                inboxStore.messages[props.id].data.length
            )
            toast("Áudio enviado", "success")
            removeModal()
        },
        onCatch: errors => setAssetsLoaded(false)
    })

    const toggleRecording = () => {
        setRecording(rec => !rec)
    }

    function onData(recordedBlob) {
        // console.log("chunk of real-time data is: ", recordedBlob)
    }

    function onStop(recordedBlob) {
        console.log("recordedBlob is: ", recordedBlob)
        setRecord(recordedBlob)
        // const audioUrl = URL.createObjectURL(recordedBlob)
    }

    function deleteRecord() {
        setRecord(null)
        // if (audio && )
        // audio = new Audio(record.blobURL)
        // audio.play()
    }

    return (
        <FormDialog
            {...props}
            superTitle="Gravar Áudio"
            onSubmit={form.onSubmit}
            endpoint={config.api.SEND_ACTIVE_MESSAGE}
            saveProps={{
                disabled: !record
            }}
            saveLoader={assetsLoaded ? undefined : "post/assets"}
            manualRemove
        >
            {!record && (
                <>
                    <ReactMic
                        record={recording}
                        className="sound-wave"
                        onStop={onStop}
                        onData={onData}
                        strokeColor="#000000"
                        // backgroundColor="#FF4081"
                    />
                    <Button
                        onClick={toggleRecording}
                        type="button"
                        style={{ marginRight: 10 }}
                        icon={recording ? getIconClassName("CircleStopSolid") : undefined}
                        iconStyle={{ color: "#e74c3c" }}
                    >
                        {recording ? "Parar" : "Gravar"}
                    </Button>
                </>
            )}
            {record && (
                <>
                    <div>
                        <audio controls>
                            <source src={record.blobURL} type="audio/ogg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    <br />
                    <Button
                        onClick={deleteRecord}
                        type="button"
                        style={{ marginRight: 10 }}
                        icon={getIconClassName("Delete")}
                    >
                        Excluir
                    </Button>
                </>
            )}
        </FormDialog>
    )
}

export default RecordAudioModal
