import React, { useState, useMemo } from "react"
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import { HashLink } from "react-router-hash-link"
import { Link, Redirect } from "react-router-dom"
import cx from "classnames"
import useWindowSize from '@rehooks/window-size';


function LandingFeatureHeader({ children, icon, color, to, title }) {
    return (
        <Link to={to}>
            <i className={icon} style={{ backgroundColor: color }} />
            <div className="info">
                <span className="title">{title}</span>
                <span className="description">{children}</span>
            </div>
        </Link>
    )
}

export default function LandingHeader({ white }) {
    let { innerWidth } = useWindowSize()

    const [isOpened, setOpen] = useState(false)

    let position = useWindowScrollPosition({
        throttle: 100
    })
    let showBorder = useMemo(() => position.y > 0, [position.y])

    function toggleOpen () {
        setOpen(!isOpened)
    }

    return (
        <div className={cx(isOpened ? "landing-header menu-shadow" : "landing-header", { sticky: showBorder || white })}>
            {
                innerWidth < 960 ?
                    <header>
                        <div className="wrapper">
                            <HashLink to="/#top">
                                <div style={{ margin: '0 18px' }} className="landing-logo" />
                            </HashLink>
                            <div onClick={toggleOpen} className="menu-btn">
                                <i className="fa fa-bars" />
                            </div>
                        </div>
                        {
                            isOpened && 
                                <div className="landing-header-navigation">
                                    <div className="landing-drop">
                                        <a>Funcionalidades</a>
                                        <div className="landing-menu-hoverable">
                                            <div className="landing-drop-menu">
                                                <div className="landing-arrow" />
                                                <LandingFeatureHeader
                                                    title="Integração Whatsapp"
                                                    icon="fab fa-whatsapp"
                                                    color="#00bf54"
                                                    to="/whatsapp"
                                                >
                                                    Atenda seus clientes pelo whatsapp
                                            </LandingFeatureHeader>
                                                <LandingFeatureHeader
                                                    title="Chat para sites"
                                                    icon="fa fa-browser"
                                                    color="#7d92eb"
                                                    to="/features"
                                                >
                                                    Converse com os visitantes do seu site com nosso widget
                                            </LandingFeatureHeader>
                                                <LandingFeatureHeader
                                                    title="Campanhas"
                                                    icon="fa fa-bullseye-arrow"
                                                    color="#341f97"
                                                    to="/whatsapp"
                                                >
                                                    Impulsione suas vendas enviando mensagens por email ou
                                                    whatsapp
                                            </LandingFeatureHeader>
                                                <LandingFeatureHeader
                                                    title="Bot Universal"
                                                    icon="fa fa-robot"
                                                    color="#FFDE7E"
                                                    to="/bot"
                                                >
                                                    Poderoso bot personalizavel para automatizar suas conversas
                                            </LandingFeatureHeader>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/pricing">Preços</Link>
                                    <HashLink to="/pricing#faq">Faq</HashLink>
                                    <Link to="/user/login">Login</Link>
                                    <Link className="round" to="/user/register">Cadastrar</Link>
                                </div>
                        }
                    </header>
                    :
                    <div className="midcol">
                        <div className="left">
                            <HashLink to="/#top">
                                <div className="landing-logo" />
                                <h1>KimoChat</h1>
                            </HashLink>
                            <div className="landing-header-navigation">
                                <div className="landing-drop">
                                    <a>Funcionalidades</a>
                                    <div className="landing-menu-hoverable">
                                        <div className="landing-drop-menu">
                                            <div className="landing-arrow" />
                                            <LandingFeatureHeader
                                                title="Integração Whatsapp"
                                                icon="fab fa-whatsapp"
                                                color="#00bf54"
                                                to="/whatsapp"
                                            >
                                                Atenda seus clientes pelo whatsapp
                                    </LandingFeatureHeader>
                                            <LandingFeatureHeader
                                                title="Chat para sites"
                                                icon="fa fa-browser"
                                                color="#7d92eb"
                                                to="/features"
                                            >
                                                Converse com os visitantes do seu site com nosso widget
                                    </LandingFeatureHeader>
                                            <LandingFeatureHeader
                                                title="Campanhas"
                                                icon="fa fa-bullseye-arrow"
                                                color="#341f97"
                                                to="/whatsapp"
                                            >
                                                Impulsione suas vendas enviando mensagens por email ou
                                                whatsapp
                                    </LandingFeatureHeader>
                                            <LandingFeatureHeader
                                                title="Bot Universal"
                                                icon="fa fa-robot"
                                                color="#FFDE7E"
                                                to="/bot"
                                            >
                                                Poderoso bot personalizavel para automatizar suas conversas
                                    </LandingFeatureHeader>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/pricing">Planos</Link>
                                <HashLink to="/pricing#faq">Faq</HashLink>
                            </div>
                        </div>
                        <div className="right">
                            <Link to="/user/login" className="landing-login-btn">
                                Entrar
                    </Link>
                            <Link to="/user/register" className="landing-register-btn">
                                Cadastre-se
                    </Link>
                        </div>
                    </div>
            }
        </div>
    )
}
