import React from "react"
import AgentAvatar from "components/AgentAvatar"
import { NavLink } from "react-router-dom"
import MenuDrop from "components/menu/MenuDrop"
import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import cx from "classnames"
import { stores, useStore, session } from "store"
import { permissionsEnum } from "lib/constants"
import { getIconClassName } from "@uifabric/styling"

const editableOptions = (onEdit, onDelete) => [
    {
        name: "Editar",
        onClick: onEdit
    }
    // {
    //     name: "Excluir",
    //     onClick: onDelete
    // }
]

function SideItem({
    name,
    icon,
    iconSvg,
    iconStyle,
    agent,
    count,
    to,
    toIndex,
    exact,
    setIndex,
    style,
    editable,
    hideable,
    className,
    onEdit,
    onUnsubscribe,
    onDelete,
    operatorId,
    title,
    inbox
}) {
    const { user, workspace } = useStore(session)
    const hasCount = count !== undefined && parseInt(count) > 0
    const hasPermissions =
        inbox === "me" || operatorId == user.id || workspace.accessLevel >= permissionsEnum.MASTER
    return (
        <NavLink
            to={to}
            exact={exact}
            onClick={() => toIndex && setIndex(toIndex)}
            className={cx("sidebar-item", className, { hasCount })}
            style={style}
            title={title}
        >
            {agent ? (
                <AgentAvatar size={16} agent={stores.session.user} />
            ) : (
                <ColorEmojiIcon icon={icon} iconStyle={iconStyle} />
            )}
            <span className="name truncate" title={name}>{name}</span>
            {toIndex && <i className="fal fa-arrow-right" />}
            {hasCount && <span className="unread_count">{count}</span>}
            {editable && (
                <div className="right">
                    <MenuDrop
                        width={100}
                        style={{ marginLeft: "auto" }}
                        trigger={
                            <i className={getIconClassName("More") + " side-more icon-trigger"} />
                        }
                        options={[
                            ...(hideable
                                ? [
                                      {
                                          name: "Esconder",
                                          onClick: onUnsubscribe
                                      }
                                  ]
                                : []),

                            ...(hasPermissions ? editableOptions(onEdit, onDelete) : [])
                        ]}
                    />
                </div>
            )}
        </NavLink>
    )
}

export default SideItem
