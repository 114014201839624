import React, { useState, useMemo, useEffect } from "react"
import fb from "lib/fbWidget"

export default function FBWidget(props) {
    useEffect(() => {
        setTimeout(() => {
            fb(FB => FB.XFBML.parse())
        }, 2000)
    }, [])

    return (
        <div
            className="fb-customerchat"
            attribution="setup_tool"
            page_id="107019700696725"
            theme_color="#0084ff"
            logged_in_greeting="Olá! Como podemos te ajudar?"
            logged_out_greeting="Olá! Como podemos te ajudar?"
        />
    )
}
