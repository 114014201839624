import React, { useMemo } from "react"
import SideItem from "./SideItem"
import GoBackItem from "./GoBackItem"
import { menusEnum } from "./SideConstants"
import { permissionsEnum } from "lib/constants"
import { session, useStore } from "store"
import { getIconClassName } from "@uifabric/styling"

function SettingsItems({ setIndex }) {
    const { workspace } = useStore(session)

    const meSettings = [
        {
            name: "Perfil",
            to: "/settings/account/profile",
            // icon: "fa fa-user-circle"
            icon: getIconClassName("EditContact")
        },
        // {
        //     name: "Mudar Senha",
        //     to: "/settings/account/password",
        //     // icon: "fa fa-key"
        //     icon: getIconClassName("Lock")
        // },
        {
            name: "Assinatura",
            to: "/settings/billing/license",
            // icon: "fa fa-credit-card",
            icon: getIconClassName("PaymentCard"),
            permission: level => level >= permissionsEnum.ADMIN
        }
        // {
        //     name: "Preferências",
        //     to: "/settings/preferences",
        //     icon: "fa fa-key"
        // }
    ]

    const workspaceSettings = [
        {
            name: "Geral",
            to: "/settings/account/workspace",
            // icon: "fa fa-cog",
            icon: getIconClassName("Work"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Canais",
            to: "/settings/channels",
            path: "/settings/channels/:id?",
            // icon: "fa fa-layer-group",
            icon: getIconClassName("Inbox"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Agentes",
            to: "/settings/members",
            path: "/settings/members/:id?",
            // icon: "fa fa-headset",
            icon: getIconClassName("Telemarketer"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Grupos",
            to: "/settings/groups",
            path: "/settings/groups/:id?",
            // icon: "fa fa-user-friends",
            icon: getIconClassName("People"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Horário comercial",
            to: "/settings/business-hours",
            path: "/settings/business-hours/:id?",
            // icon: "fa fa-clock",
            icon: getIconClassName("Clock"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Tags",
            to: "/settings/tags",
            path: "/settings/tags/:id?",
            icon: getIconClassName("tag")
            // icon: "fa fa-tags"
        },
        {
            name: "Frases Rápidas",
            to: "/settings/quick-phrases",
            path: "/settings/quick-phrases/:id?",
            // icon: "fa fa-bolt"
            icon: getIconClassName("LightningBolt")
        },
        {
            name: "Atribuição Automática",
            to: "/settings/assignment-rules",
            path: "/settings/assignment-rules/:id?",
            // icon: "fa fa-share",
            icon: getIconClassName("MailForward"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Bot",
            to: "/settings/bot",
            path: "/settings/bot/:id?",
            // icon: "fa fa-robot",
            icon: getIconClassName("Robot"),
            permission: level => level >= permissionsEnum.MASTER
        }
    ]

    const developerItems = [
        {
            name: "Webhook",
            to: "/settings/webhook",
            path: "/settings/webhook/:id?",
            // icon: "fa fa-cloud",
            icon: getIconClassName("AzureAPIManagement"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "Plugins",
            to: "/settings/plugins",
            path: "/settings/plugins/:id?",
            // icon: "fa fa-plug",
            icon: getIconClassName("PlugDisconnected"),
            permission: level => level >= permissionsEnum.MASTER
        },
        {
            name: "API",
            to: "/settings/api",
            path: "/settings/api/:id?",
            // icon: "fa fa-server",
            icon: getIconClassName("DataManagementSettings"),
            permission: level => level >= permissionsEnum.MASTER
        }
    ]

    function checkPermission(item) {
        return !item.permission || item.permission(workspace.accessLevel)
    }

    const devItems = useMemo(() =>
        developerItems
            .filter(checkPermission)
            .map(x => <SideItem {...x} key={x.name} setIndex={setIndex} />)
    )
    return (
        <div className="settings-side-items">
            {/* <br />
            <GoBackItem setIndex={setIndex} />
            <br />
            <br /> */}
            <div className="sidebar-section-title" style={{ marginTop: 7 }}>
                Conta
            </div>
            {meSettings.filter(checkPermission).map(x => (
                <SideItem {...x} key={x.name} setIndex={setIndex} />
            ))}
            <br />
            <div className="sidebar-section-title">Workspace</div>
            {workspaceSettings.filter(checkPermission).map(x => (
                <SideItem {...x} key={x.name} setIndex={setIndex} />
            ))}
            <br />
            {/* {!!devItems.length && (
                <div className="sidebar-section-title">Desenvolvedor</div>
            )}
            {devItems} */}
        </div>
    )
}

export default SettingsItems
