import React from "react"
import Button from "components/forms/Button"

function TopHeader({ backTo, breadcrumb, bordered, settings, right }) {
    const borderedStyle = bordered ? { borderBottom: "1px solid rgba(193, 208, 220, 0.3)" } : {}
    return (
        <div className={`layout-header-space ${settings ? "settings" : ""}`}>
            <div className="layout-header" style={borderedStyle}>
                <div className="page-info">
                    {backTo && (
                        <Button
                            className="go-back-arrow"
                            icon="far fa-angle-left"
                            to={backTo}
                            round
                        />
                    )}
                    <h3>{breadcrumb}</h3>
                </div>
                <div className="right">{right}</div>
            </div>
        </div>
    )
}

export default TopHeader
