/* eslint-disable no-loop-func */
import React, { useState } from "react"
import { Link } from "react-router-dom"
import io from "socket.io-client"
import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"
import { stores } from "store"
import { messagesTypes } from "lib/constants"
import { permissions, permissionsEnum, channelsTypes, channelsTypesEnum } from "lib/constants"
import { request, toast } from "nanui"
import { cacheMessageAssets } from 'utils/imageCache'

const defaultSocket = { on: () => {} }
function inboxStore(store) {
    const [messages, setMessages] = useState({})
    const [connected, setConnected] = useState(true)
    const [customers, setCustomers] = useState({})
    const [quickPhrases, setQuickPhrases] = useState([])
    // const [favorites, setFavorites] = useState([])
    const [inboxCounts, setInboxCounts] = useState({})
    const [activeConversationId, setActiveConversationId] = useState()
    const [socket, setSocket] = useState(defaultSocket)
    const [unread, setUnread] = useState(0)

    /*
     * Get Messages
     */
    async function getMessages(payload) {
        const { isNextPage, ...configs } = payload
        stores.general.addLoader("get-message" + (payload.data.callId ? "-call" : ""))
        const response = await req(config.api.MESSAGE, configs)
        
        /**
         * To disable cached IndexedDB assets comment or exclude the lines 36, 37, 522, 523
         */
        // const cachedMessages = await cacheMessageAssets(response.data)
        // response.data = cachedMessages

        stores.general.removeLoader("get-message" + (payload.data.callId ? "-call" : ""))
        const errors = response.error || response.errors
        const key = payload.data.callId ? "call-" + payload.data.callId : payload.data.id
        if (!errors) {
            if (isNextPage) {
                setMessages({
                    ...messages,
                    [key]: {
                        data: [...messages[key].data, ...response.data],
                        matchCount: response.matchCount,
                        totalCount: response.totalCount,
                    },
                })
            } else {
                setMessages({
                    ...messages,
                    [key]: {
                        data: response.data || [],
                        matchCount: response.matchCount,
                        totalCount: response.totalCount,
                    },
                })
            }
        }
    }

    function saveMessage(item, channelId, length) {
        const newMessages = {
            ...messages,
            [channelId]: {
                ...messages[channelId],
                data: [item, ...messages[channelId].data],
            },
        }

        setMessages(newMessages)
        store.save(
            { data: item },
            {
                customEndpoint: config.api.MESSAGE,
                callback: (response) => {
                    // newMessages[channelId].data[
                    //     newMessages[channelId].data.length - length - 1
                    // ] = response
                    // newMessages[channelId].matchCount += 1
                    // newMessages[channelId].totalCount += 1
                    // setMessages({ ...newMessages })
                },
            }
        )
    }

    async function removeMessage(message, channelId, platformId) {
        const newMessages = { ...messages }

        console.log("newMessages", newMessages, message)

        const index = newMessages[channelId].data.findIndex(msg => msg.id === message.id)
        newMessages[channelId].data[index].isDeleted = true

        setMessages(newMessages)

        try {
            request.delete(config.api.MESSAGE, { id: message.id, platformId })
        } catch (e) {
            console.error(e)
        }
    }

    /*
     * Get Customer Details
     */
    async function getCustomerDetails(id) {
        stores.general.addLoader("get-customer")
        const response = await req(config.api.CUSTOMER_DETAILS + "/" + id)
        stores.general.removeLoader("get-customer")
        const errors = response.error || response.errors
        if (!errors) {
            setCustomers({
                ...customers,
                [id]: response,
            })
        }
    }

    /*
     * Get Customer Media
     */
    // async function getCustomerMedias(id) {
    //     stores.general.addLoader("get-customer-media")
    //     const response = await req(config.api.CUSTOMER_MEDIAS + "?id=" + id)
    //     stores.general.removeLoader("get-customer-media")
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setCustomers({
    //             ...customers,
    //             [id]: response
    //         })
    //     }
    // }

    // /*
    //  * Get Quick Phrases
    //  */
    // async function getQuickPhrases(payload = {}) {
    //     const { isNextPage, ...configs } = payload
    //     stores.general.addLoader("get-quick-phrases")
    //     const response = await req(config.api.QUICK_PHRASES, configs)
    //     stores.general.removeLoader("get-quick-phrases")
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setQuickPhrases(response.data || [])
    //     }
    // }

    // function saveQuickPhrase(model, cb) {
    //     store.save(
    //         { data: model },
    //         {
    //             customEndpoint: config.api.QUICK_PHRASES,
    //             callback: response => {
    //                 store.saveItem(response, {
    //                     dataArray: quickPhrases,
    //                     setDataArray: setQuickPhrases
    //                 })
    //                 if (cb) cb()
    //             }
    //         }
    //     )
    // }

    // /*
    //  * Remove QuickPhrase
    //  */
    // async function removeQuickPhrase(payload, onBack) {
    //     const response = await req(config.api.QUICK_PHRASES, {
    //         method: "delete",
    //         ...payload
    //     })
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setQuickPhrases(phrases => phrases.filter(e => e.id !== response.id))
    //         onBack()
    //     }
    // }

    /*
     * Save property
     */
    function saveProperty(id, model, configs) {
        store.save(
            { data: model, ...configs },
            {
                customEndpoint: config.api.CUSTOMER_DETAILS + "/" + id + "/properties",
                callback: (response) => {
                    store.saveItem(response, {
                        dataArray: customers[id].properties,
                        setDataArray: (saveFunc) => {
                            setCustomers((customers) => {
                                customers[id].properties = saveFunc(customers[id].properties)
                                return { ...customers }
                            })
                        },
                    })
                },
            }
        )
    }

    /*
     * Remove Property
     */
    async function removeProperty(customerId, payload) {
        const response = await req(config.api.CUSTOMER_DETAILS + "/" + customerId + "/properties", {
            method: "delete",
            ...payload,
        })
        const errors = response.error || response.errors
        if (!errors) {
            setCustomers((customers) => {
                customers[customerId].properties = customers[customerId].properties.filter(
                    (e) => e.id !== response.id
                )
                return { ...customers }
            })
        }
        return false
    }

    /*
     * Save customer properties
     */
    function updateCustomer(id, customerPlatformId, model, configs) {
        store.save(
            { data: { ...model, id }, ...configs },
            {
                customEndpoint: config.api.CUSTOMER,
                callback: ({ id, ...response }) => {
                    store.saveItem({ id: customerPlatformId, ...response })
                },
            }
        )
    }

    /*
     * Resolve Session
     */
    async function toggleResolved(customerPlatformId, endpoint, inboxItem) {
        const updatedLocal = { ...inboxItem, isFinished: !inboxItem.isFinished }
        store.saveItem(updatedLocal)
        stores.general.addLoader("resolving-" + customerPlatformId)
        const response = await req("/inbox/" + endpoint + "/" + customerPlatformId, {
            method: "post",
        })
        stores.general.removeLoader("resolving-" + customerPlatformId)
        // const errors = response.error || response.errors
        // if (!errors) {
        //     store.saveItem(response)
        // }
    }

    /*
     * Assign to agent
     */
    async function assignAgent(payload, inboxItem) {
        let { customerPlatformId, ...assigned } = payload.data
        console.log("inboxItem", inboxItem)
        store.saveItem({ ...inboxItem, ...assigned })

        stores.general.addLoader("transfer")
        const response = await req(config.api.TRANSFER_CALL_AGENT, {
            method: "post",
            ...payload,
        })
        stores.general.removeLoader("transfer")

        const errors = response.error || response.errors
        if (errors) {
            store.saveItem(inboxItem)
        }

        return response
    }

    /*
     * Assign to group
     */
    async function assignGroup(payload, inboxItem) {
        let { customerPlatformId, ...assigned } = payload.data
        store.saveItem({ ...inboxItem, ...assigned })

        stores.general.addLoader("transfer")
        const response = await req(config.api.TRANSFER_CALL_GROUP, {
            method: "post",
            ...payload,
        })
        stores.general.removeLoader("transfer")

        const errors = response.error || response.errors
        if (errors) {
            store.saveItem(inboxItem)
        }

        return response
    }

    /*
     * Assign to bot
     */
    async function assignBot(payload, inboxItem) {
        let { customerPlatformId, ...assigned } = payload.data
        store.saveItem({ ...inboxItem, ...assigned })

        stores.general.addLoader("transfer")
        const response = await req(config.api.INIT_BOT, {
            method: "post",
            ...payload,
        })
        stores.general.removeLoader("transfer")

        const errors = response.error || response.errors
        if (errors) {
            store.saveItem(inboxItem)
        }

        return response
    }

    /*
     * Get Inbox Counters
     */
    async function getCounters(configs = {}) {
        const response = await req(config.api.INBOX_COUNTERS, {
            method: "post",
            ...configs,
        })
        const errors = response.error || response.errors
        if (!errors) {
            setInboxCounts(response || {})
        }
    }

    async function saveTags(tags, inboxItem) {
        store.saveItem({ ...inboxItem, tags })
        await req(config.api.SAVE_TAG, {
            method: "put",
            data: {
                tags,
                id: inboxItem.id,
                callId: inboxItem.callId,
            },
        })
    }

    /*
     * Favoritos ⭐
     */

    async function togglePin(inboxItem) {
        if (inboxItem.starred) {
            removeFavorite(inboxItem)
        } else {
            addFavorite(inboxItem)
        }
    }

    // async function getFavorites() {
    //     const response = await req(config.api.FAVORITES)
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setFavorites(response.data)
    //     }
    // }

    async function addFavorite(inboxItem) {
        store.saveItem({ ...inboxItem, starred: true })
        await req(config.api.FAVORITES + "/" + inboxItem.id, {
            method: "post",
        })
    }

    async function removeFavorite(inboxItem) {
        store.saveItem({ ...inboxItem, starred: false })
        await req(config.api.FAVORITES + "/" + inboxItem.id, {
            method: "delete",
        })
    }

    /*
     * Merge Users
     */
    async function mergeUsers(stayId, leaveId) {
        stores.general.addLoader("merging")
        try {
            console.log("Prestes a chamar request", config.api.MERGE, stayId, leaveId)
            const response = await request.put(config.api.MERGE, {
                stayId,
                leaveId,
            })
            toast("Usuários mesclados", "success")
            console.log(response.data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        } finally {
            stores.general.removeLoader("merging")
        }
    }

    /*
     * Socket 🧲
     */
    function initSocket(socketUrl) {
        clearSocket()
        let socketVar = defaultSocket

        // TODO: Why disabled on localhost?
        // if (socketUrl.search("localhost") === -1 && socketUrl.search("http") !== -1) {
        //     socketVar = io(socketUrl)
        //     console.log("socketUrl", socketUrl, socketVar)
        //     setSocket(socketVar)
        // }

        socketVar = io(socketUrl)
        console.log("socketUrl", socketUrl, socketVar)
        setSocket(socketVar)

        function shouldSaveOnList(payload) {
            const userGroups = stores.group.all.filter((x) =>
                x.members.find((y) => y.id == stores.session.user.id)
            )

            const isMasterOrAbove = stores.session.workspace.accessLevel >= permissionsEnum.MASTER
            const isOperatorOrAbove =
                stores.session.workspace.accessLevel >= permissionsEnum.OPERATOR
            const isRestricted =
                stores.session.workspace.accessLevel <= permissionsEnum.OPERATOR_RESTRICT
            const noGroup = !payload.groupId
            const noAgent = !payload.operatorId
            const isMine = payload.operatorId == stores.session.user.id

            const filterByGroup =
                isMasterOrAbove ||
                (noGroup && !isRestricted) ||
                userGroups.findIndex((x) => x.id == payload.groupId) > -1

            const filterByOperator =
                isOperatorOrAbove || (noAgent && !isRestricted) || (filterByGroup && noAgent)

            return isMine || (filterByGroup && filterByOperator)
        }

        socketVar.on("chat.messageStatus", ({ message }) => {
            try {
                const { key: { id: externalId }, update: { status }, callMessage } = message
                console.log("chat.messageStatus", externalId, status, callMessage)
                stores.inbox.setMessages((messages) => {
                    if (messages && callMessage && callMessage.callId && messages[callMessage.callId]) {
                        const index = messages[callMessage.callId].data.findIndex(
                            (message) => message.externalId && message.externalId.includes(externalId)
                        )
                        if (index > -1) {
                            messages[callMessage.callId].data[index].status = status
                        }
                    }
                    return { ...messages }
                })
            } catch (error) {
                console.error(error)
            }
            
        })

        socketVar.on("channel.status", ({ id, status }) => {
            try {
                const channel = stores.channel.all.find((x) => x.id === id)
                const channelType = channelsTypes[channel.channelId]
                let description = ""
                if (channel) {
                    switch (status) {
                        case "connected":
                            stores.general.removeBannerNotification({})
                            toast(
                                `Canal de ${channelType.name} "${channel.description}" conectado!`,
                                "success"
                            )
                            break
                        case "disconnected":
                            description = `O canal de ${channelType.name} "${channel.description}" foi desconectado.
                            Tentando reconectar...`
                            stores.general.showChannelDisconnectedBanner(channel, description)
                            break
                        case "login_required":
                            description = `Canal desconectado. Logue novamente no canal de Instagram "${channel.description}"`
                            stores.general.showChannelDisconnectedBanner(channel, description)
                            break
                        case "challenge_required":
                            description = `Necessário validar o código no canal de Instagram "${channel.description}"`
                            stores.general.showChannelDisconnectedBanner(channel, description)
                            break
                        case "qr_code":
                            description = `Necessário ler o QRCode no canal de Whatsapp "${channel.description}"`
                            stores.general.showChannelDisconnectedBanner(channel, description)
                            break
                        default:
                            break
                    }
                    stores.channel.saveItem({
                        id,
                        status,
                    })
                }
            } catch (e) {
                console.error(e)
            }
        })

        socketVar.on("chat.newCall", (payload) => {
            console.log("chat.newCall", payload)
            document.getElementById("pop").play()
            // stores.general.setHasNewMessages(true)

            if (shouldSaveOnList(payload)) {
                stores.inbox.saveItem({ tags: [], ...payload, hasNewMessages: true })
                stores.inbox.sortToFirst(payload)
            }
        })
        socketVar.on("chat.callStatus", (payload) => {
            console.log("chat.callStatus", payload)
            // Filtrar por grupo e operator
            if (shouldSaveOnList(payload)) {
                stores.inbox.saveItem({ tags: [], ...payload })
                stores.inbox.sortToFirst(payload)
            } else {
                stores.inbox.setAll((all) => all.filter((x) => x.id != payload.id))
            }
        })
        socketVar.on("chat.newMessage", async (payload) => {
            console.log("chat.newMessage", payload)

            // Atualizar na conversa
            // const item = stores.inbox.all.find(x => x.callId == payload.callId)
            const item = stores.inbox.all.find((x) => x.id == payload.customerPlatformId)
            // const cachedMessages = await cacheMessageAssets([item.lastMessage])
            // item.lastMessage = cachedMessages[0]
            
            if (item) {
                // console.log(payload, payload.operatorId, stores.session.user.id, payload.newMessage.type, messagesTypes.SYSTEM)
                if (
                    payload.newMessage.operatorId != stores.session.user.id &&
                    payload.newMessage.type != messagesTypes.SYSTEM
                ) {
                    document.getElementById("pop").play()
                    setUnread((un) => un + 1)
                    // stores.general.setHasNewMessages(true)
                }
                stores.inbox.saveItem({
                    ...item,
                    lastMessage: payload.newMessage,
                    hasNewMessages: stores.inbox.activeConversationId != item.id,
                })

                // Manda para primeiro lugar
                stores.inbox.sortToFirst(item)

                // Atualizar nas mensagens
                if (stores.inbox.messages[item.id]) {
                    const index = stores.inbox.messages[item.id].data.findIndex(
                        (x) => x.content === payload.newMessage.content && !x.id
                    )
                    if (index > -1) {
                        stores.inbox.setMessages((messages) => {
                            messages[item.id].data[index] = payload.newMessage
                            messages[item.id].matchCount += 1
                            messages[item.id].totalCount += 1
                            return { ...messages }
                        })
                    } else {
                        stores.inbox.setMessages((messages) => {
                            messages[item.id].data = [payload.newMessage, ...messages[item.id].data]
                            messages[item.id].matchCount += 1
                            messages[item.id].totalCount += 1
                            return { ...messages }
                        })
                    }
                }
            }
        })
        socketVar.on("disconnect", () => {
            // toast("Desconectado do servidor.", "error")
            console.log("DESCONECTOU DO SOCKET")
            setConnected(false)
        })
        socketVar.on("connect_error", (e) => {
            // toast("Desconectado do servidor. Tentando reconectar...", "error")
            setConnected(false)
        })
        socketVar.on("connect_timeout", (e) => {
            // toast("Desconectado do servidor. Tentando reconectar...", "error")
            setConnected(false)
        })
        socketVar.on("connect", () => {
            console.log("CONECTOU DO SOCKET")
            setConnected(true)
        })
        socketVar.on("reconnect", () => {
            // toast("Reconectado ao servidor.", "success")
            setConnected(true)
        })
        socketVar.on("WorkspaceActivation", ({ message }) => {
            toast(
                // "Seu ambiente agora está pronto para usar canais de WhatsApp",
                message,
                "success"
            )
        })
    }

    function clearSocket() {
        if (socket.disconnect) {
            console.log("DESCONECTANDO SOCKET", socket)
            socket.disconnect()
            setSocket(defaultSocket)
        }
    }

    async function sendComposing(customerPlatformId) {
        await req(config.api.SEND_COMPOSING, {
            method: "post",
            data: {
                customerPlatformId
            }
        })
    }

    function debounce(func, delay) {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    const debouncedSendComposing = debounce(sendComposing, 1000);

    return {
        ...store,
        messages,
        customers,
        quickPhrases,
        inboxCounts,
        socket,
        initSocket,
        debouncedSendComposing,
        activeConversationId,

        // Actions
        getMessages,
        saveMessage,
        setMessages,
        removeMessage,
        getCustomerDetails,
        // getQuickPhrases,
        // saveQuickPhrase,
        // removeQuickPhrase,
        saveProperty,
        updateCustomer,
        removeProperty,
        assignAgent,
        assignGroup,
        assignBot,
        getCounters,
        toggleResolved,
        saveTags,
        // favorites,
        // setFavorites,
        // getFavorites,
        addFavorite,
        removeFavorite,
        togglePin,
        setActiveConversationId,
        clearSocket,
        unread,
        setUnread,
        mergeUsers,
    }
}

export default fromBase(config.api.INBOX, inboxStore)
