import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"

function segmentStore(store) {
    async function toggleSubscription(id, isToUnsubscribe) {
        store.setAll(all => {
            const index = all.findIndex(x => x.id == id)
            all[index] = { ...all[index], subscribed: !all[index].subscribed }
            return [...all]
        })
        const endpoint = isToUnsubscribe ? "/unsubscribe/" : "/subscribe/"
        await req(config.api.SEGMENTS + endpoint + id, {
            method: "post"
        })
    }

    return {
        ...store,
        toggleSubscription
    }
}

export default fromBase(config.api.SEGMENTS, segmentStore)
