import React from "react"

const Loading = ({ center, style, size = 48 }) => (
    <div className={center ? 'loading-space center' : ''} style={style}>
        <svg
            className="loading2"
            focusable="false"
            height={size}
            size={size}
            viewBox="0 0 48 48"
            width={size}
        >
            <circle cx="24" cy="24" r="21.5" />
        </svg>
    </div>
)

export default Loading
