import React, { useState } from "react"
import PageTableBase from "components/PageTableBase"
import EditBot from "pages/power-ups/bot/EditBot"
import SettingsHelp from "components/SettingsHelp"

// import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
// import Switch from "components/forms/Switch"
import { limit } from "lib/helper"
import { bot, channel, useStore } from "store"
import { channelsTypes } from "lib/constants"
import SwitchField from "components/forms/SwitchField"
import { getIconClassName } from "@uifabric/styling"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

const columns = (onChangeActive, channelStore) => [
    {
        Header: "Cenário",
        accessor: "name",
        width: 300,
        Cell: props => (
            <span className="vertical-center">
                <i
                    className={`${getIconClassName("Robot")} table-icon`}
                    style={{ marginRight: 10, fontSize: 16 }}
                />
                {props.value}
            </span>
        )
    },
    {
        Header: "Prioridade",
        accessor: "priority",
        // width: 350,
        Cell: props => {
            return props.value || ""
        }
    },
    {
        Header: "Canais",
        accessor: "channels",
        // width: 350,
        Cell: props => {
            return props.value.length
                ? limit(
                      props.value
                          .map(
                              channelId =>
                                  (channelStore.all.find(x => x.id == channelId.id) || {})
                                      .description
                          )
                          .join(", "),
                      45
                  )
                : "Todos os canais"
        }
    },
    {
        Header: "Status",
        accessor: "isActive",
        maxWidth: 300,
        Cell: props => (
            <SwitchField
                checked={props.value}
                onClick={e => {
                    e.stopPropagation()
                }}
                onChange={value => onChangeActive(props.row._original, value)}
            />
        )
    }
]

export default function Bot(props) {
    const botStore = useStore(bot)
    const channelStore = useStore(channel)

    // const onDrop = useCallback(
    //     acceptedFiles => {
    //         console.log(acceptedFiles)
    //         setFiles(
    //             acceptedFiles.map(file =>
    //                 Object.assign(file, {
    //                     preview: URL.createObjectURL(file)
    //                 })
    //             )
    //         )
    //     },
    //     [files]
    // )

    // const { getInputProps, open } = useDropzone({
    //     onDrop
    // })

    function onChangeActive(item, isActive) {
        ReactGA.event({
            category: analyticsCategory.AGENT,
            action: `Agente alterou o estado do bot para: ${isActive}`
        })
        botStore.saveItem({ ...item, isActive })
        botStore.save({
            method: "put",
            data: { ...item, isActive }
        })
    }

    function onChangeHandlerFile(event) {
        try {
            var reader = new FileReader()
            reader.onload = e => {
                const botJson = JSON.parse(e.target.result)
                botStore.setImportedItem(botJson)
                props.history.push("/settings/bot/new")
            }
            reader.readAsText(event.target.files[0])
        } catch (e) {
            console.log(e)
        }
    }

    function importBot() {
        var dlAnchorElem = document.getElementById("inputImport")
        dlAnchorElem.click()
    }

    return (
        <>
            <PageTableBase
                storeRef={bot}
                title="Bot"
                newLabel="Novo"
                columns={columns(onChangeActive, channelStore)}
                EditComponent={EditBot}
                actions={[
                    {
                        name: "Importar",
                        icon: getIconClassName("ShareiOS"),
                        onClick: importBot
                    }
                ]}
                helperAction={
                    <SettingsHelp title="Seu Bot" icon={getIconClassName("Robot")}>
                        Deixe seu bot ter o primeiro contato e coletar informações dos seus
                        usuários, ajudando sua equipe a focar em conversas que realmente precisam de
                        sua atenção.
                        <br />
                        <br />
                        Bots podem ser ativados dependendo de condições, como se é um contato novo
                        ou já existente.
                    </SettingsHelp>
                }
                nodata={{
                    title: "Parece que você ainda não adicionou nenhum bot...",
                    buttonText: "Adicionar Bot"
                }}
                {...props}
            />
            <input
                type="file"
                name="file"
                onChange={onChangeHandlerFile}
                style={{ display: "none" }}
                id="inputImport"
                accept=".json,application/json"
            />
        </>
    )
}
