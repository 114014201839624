import React from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm, useCheckbox } from "hooks"
import { permissions, permissionsEnum } from "lib/constants"
import { session, useStore } from "store"
import SwitchField from "components/forms/SwitchField"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function EditMember(props) {
    const item = props.item
    const { user, workspace } = useStore(session)

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: item.id
                    ? "O agente atualizou dados de outro agente"
                    : "O agente convidou outro agente"
            })
            await props.onSubmit(
                { ...item, ...model },
                { toastMessage: item.id ? "Usuário atualizado" : "Usuário convidado" }
            )
        }
    })
    const name = useField("name", {
        defaultValue: item.name,
        form
    })
    const email = useField("email", {
        defaultValue: item.email,
        form
    })
    const accessLevel = useSelectField("accessLevel", {
        defaultValue: item.accessLevel,
        form,
        options: permissions.filter(
            e => e.value <= workspace.accessLevel && e.value < permissionsEnum.OWNER
        )
    })

    const active = useCheckbox("isActive", {
        defaultValue: item.id ? item.isActive : true,
        form
    })

    const isEdit = item.id || item.isLoading
    const disabledPermission = item.id === user.id || item.accessLevel >= workspace.accessLevel
    return (
        <FormDialog
            {...props}
            superTitle={isEdit ? "Editar Pessoa" : "Convidar Pessoa"}
            onSubmit={form.onSubmit}
            noExcludable={disabledPermission}
            saveText={isEdit ? "Salvar" : "Convidar"}
            saveProps={{ disabled: disabledPermission }}
        >
            <Text label="Nome" required disabled={isEdit} {...name} />
            <Text label="Email" required type="email" disabled={isEdit} {...email} />
            <Select label="Permissões" isDisabled={disabledPermission} {...accessLevel} />
            {item.id && (
                <SwitchField
                    label="Ativo"
                    description="Agente inativos não poderão logar"
                    {...active}
                />
            )}
        </FormDialog>
    )
}

export default EditMember
