import { useEffect } from "react"
import { useStore, general, inbox, stores } from "store"
import usePosition from "./usePosition"

function useFetch(storeName, { filters, query, ...config } = {}) {
    const store = useStore(storeName)

    useEffect(() => {
        ;(async function() {
            await store.clear()
            await store.get({ params: query, ...config })
        })()
    }, [filters])

    return store
}

function useBreadcrumb(newBreadcrumb, { revertBreadcrumbOnBack } = {}) {
    const { changeBreadcrumb, breadcrumb } = useStore(general)
    const { unread } = useStore(inbox)
    useEffect(() => {
        changeBreadcrumb(newBreadcrumb)
        document.title = newBreadcrumb.title + " - KimoChat"
        if (revertBreadcrumbOnBack) {
            return () => {
                changeBreadcrumb(breadcrumb)
                document.title = breadcrumb.title + " - KimoChat"
            }
        }
    }, [])

    useEffect(() => {
        if (unread) {
            document.title = `(${unread}) ` + document.title.replace(/\(\d+\) /, '')
        } else {
            document.title = document.title.replace(/\(\d+\) /, '')
        }
    }, [unread])
}

function useModalClick(onClick, hasModal) {
    useEffect(() => {
        if (hasModal) {
            const element = document.getElementById("modal-overlay-clickable")
            element.addEventListener("click", onClick, { passive: false })
            return () => {
                element.removeEventListener("click", onClick)
            }
        }
    }, [hasModal])
}

function useKeyPress(handler, { condition = false } = {}, listeners = []) {
    useEffect(() => {
        window.removeEventListener("keydown", handler)
        if (condition) {
            window.addEventListener("keydown", handler, { passive: true })
            return () => {
                window.removeEventListener("keydown", handler)
            }
        }
    }, [...listeners])
}

function useDevTools(configs) {
    useEffect(() => {
        if (configs.hide) {
            var x = document.getElementsByClassName("dev-space")[0]
            x.setAttribute("style", "display: none")
            return () => x.setAttribute("style", "display: block")
        }
    }, [])
}

export { useBreadcrumb, useFetch, useModalClick, useKeyPress, useDevTools, usePosition }

export * from "./formHook"
export * from "./useInfiniteScroll"
export * from "./useScrollTop"
// export usePosition from "./usePosition"