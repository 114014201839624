import React, { useState } from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useSelectField, useForm, useFetch } from "hooks"
import Radiobox from "components/forms/Radiobox"
import Select from "components/forms/Select"
import { operator, group, useStore } from "store"

function EditSupport(props) {
    const actionItem = props.actionItem
    const [assigneeType, setAssigneeType] = useState("group")
    const operatorStore = useStore(operator)
    const groupStore = useStore(group)
    const isGroup = assigneeType === "group"
    let selectStore = isGroup ? groupStore : operatorStore

    const form = useForm({
        onSubmit: async model => await props.onEditAction({...model, assigneeType}),
        defaultModel: { ...actionItem, type: "agent" }
    })

    const assignee = useSelectField("assignee", {
        defaultValue: actionItem.assignee,
        form,
        options: selectStore.all.map(x => ({ label: x.name, value: x.id }))
    })

    function changeAssigneeType(type) {
        assignee.clear()
        setAssigneeType(type)
    }

    return (
        <FormDialog
            {...props}
            title="Atendimento"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Radiobox
                name="assignment"
                onChange={() => changeAssigneeType("group")}
                checked={isGroup}
            >
                Atribuir para um grupo
            </Radiobox>
            <Radiobox
                name="assignment"
                checked={!isGroup}
                onChange={() => changeAssigneeType("agent")}
            >
                Atribuir para um agente
            </Radiobox>
            <br />
            <Select label={isGroup ? "Grupo" : "Agente"} required {...assignee} />
        </FormDialog>
    )
}
export default EditSupport
