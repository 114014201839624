import React, { useEffect } from "react"
import { apiToken, useStore } from "store"
import SettingsHeader from "components/settings/SettingsHeader"
import Text from "components/forms/Text"
import Loader from "components/Loader"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import { useBreadcrumb } from "hooks"

export default function Api(props) {
    useBreadcrumb({ title: "API" })
    
    const { secrets, getSecrets } = useStore(apiToken)

    useEffect(() => {
        getSecrets()
    }, [])

    return (
        <>
            <ContextHeader />
            <PageTitle title="API" />
            <div className="api-page" style={{ maxWidth: 490, marginLeft: 32 }}>
                <Loader loading={!secrets.api_secret}>
                    <Text
                        label="API Secret"
                        required
                        disabled
                        description="Esse API Secret é usado para assinar seus tokens assim como as requisições enviadas para suas URLs de webhook."
                        value={secrets.api_secret}
                        copyable
                    />
                    <br />
                    <br />
                    <Text
                        label="KimoChat Token"
                        required
                        disabled
                        description="Token para permitir terceiros acesso as suas informações do KimoChat."
                        value={secrets.api_token}
                        copyable
                    />
                </Loader>
            </div>
        </>
    )
}
