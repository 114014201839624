import React from "react"
import BasePlatform from "../BasePlatform"

export default function InstagramLanding(props) {
    return (
        <BasePlatform
            title="KimoChat Instagram"
            description="Para as empresas, o aplicativo pode acabar se tornando uma ferramenta eficaz de marketing digital, com alto potencial de atração de clientes, desde que seja utilizado estrategicamente, com o KimoChat você atende, automatiza e organiza seus chats de forma super simples e prática, aumente seu faturamento promovendo um atendimento excepcional."
            icon="https://i.imgur.com/sfQO3bV.png"
            path="/user/register"
            banner="https://i.imgur.com/H1yI7vs.png"
            separator="insta-box"
            {...props}
        />
    )
}
