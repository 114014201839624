import React, { useState } from "react"
import _ from "lodash"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useCheckbox, useForm, useSelectField, useFetch } from "hooks"
import { useStore, segment, operator, channel, group, assignmentRules } from "store"
import CheckboxGroup from "components/forms/CheckboxGroup"
import Radiobox from "components/forms/Radiobox"
import { channelsTypes } from "lib/constants"
import SwitchField from "components/forms/SwitchField"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

const assignToOptions = {
    GROUP: "group",
    AGENT: "agent"
}

function EditSegment(props) {
    const item = props.item
    if (!item.rules) item.rules = {}
    const operatorStore = useStore(operator)
    const groupStore = useFetch(group)
    const channelStore = useStore(channel)
    const [assignmentType, setAssignmentType] = useState(
        item.rules.operator ? assignToOptions.AGENT : assignToOptions.GROUP
    )

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: "Salvou uma nova regra de atribuição"
            })
            await props.onSubmit(
                {
                    ...model,
                    isActive: true,
                    rules: {
                        group: assignmentType === assignToOptions.GROUP && model.group,
                        operator: assignmentType === assignToOptions.AGENT && model.operator,
                        workspacePlatformId: channels.rawValue
                    }
                },
                { toastMessage: "Regra " + (item.id ? "atualizada." : "criada.") }
            )
        },
        defaultModel: item
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    const rule = useField("rule", {
        defaultValue: 'Qualquer nova conversa',
    })

    const channelsOptions = () => {
        let options = channelStore.all.map((x) => ({
            value: x.id,
            label: `${channelsTypes[x.channelId].name} - ${x.description}`,
        }))
        return [{ value: null, label: 'Em todos os canais' }, ...options]
    }

    const channels = useSelectField("channels", {
        defaultValue: item.rules.workspacePlatformId,
        loader: "GET/channels",
        form,
        options: channelsOptions(),
    })

    const agents = useSelectField("operator", {
        defaultValue: item.rules.operator,
        loader: "GET/operators",
        options: operatorStore.all.map(e => ({
            value: e.id,
            label: e.name
        })),
        form
    })

    const groups = useSelectField("group", {
        defaultValue: item.rules.group,
        loader: "GET/groups",
        options: groupStore.all.map(e => ({
            value: e.id,
            label: e.name
        })),
        form
    })

    return (
        <FormDialog {...props} title="Regra de Atribuição" item={item} onSubmit={form.onSubmit}>
            <Text label="Nome" required {...name} />
            <Text label="Regra" disabled required {...rule} />
            <Select label="Atendimentos recebidos no canal" placeholder="Em todos os canais" {...channels} />
            <CheckboxGroup title="Atribuir para um" style={{ marginBottom: 0 }}>
              <div style={{ marginBottom: 0, paddingTop: 6, display: 'flex', gap: 10 }}>
                <Radiobox
                    name="assignment-type"
                    onChange={() => setAssignmentType(assignToOptions.GROUP)}
                    checked={assignmentType === assignToOptions.GROUP}
                >
                    Grupo
                </Radiobox>
                <Radiobox
                    name="assignment-type"
                    onChange={() => setAssignmentType(assignToOptions.AGENT)}
                    checked={assignmentType === assignToOptions.AGENT}
                >
                    Agente
                </Radiobox>
                </div>
            </CheckboxGroup>
            {assignmentType === assignToOptions.GROUP && <Select label="Grupo" required {...groups} />}
            {assignmentType === assignToOptions.AGENT && <Select label="Agente" required {...agents} />}
        </FormDialog>
    )
}

export default EditSegment
