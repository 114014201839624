import React, { useMemo } from "react"
import Loader from "components/Loader"
import { Redirect } from "react-router-dom"
import { payment, useStore } from "store"
import { useFetch } from "hooks"
import paidStampImg from "assets/images/paidstamp.png"
import { getDateFormatted } from "lib/helper"
import masks from "lib/masks"
import logo from "assets/images/logo.svg"

function InvoicePrint(props) {
    const paymentStore = useFetch(payment)
    let [order, transaction] = useMemo(() => {
        const order = paymentStore.all.find(x => x.id == props.match.params.id) || {
            transactions: []
        }
        console.log(order, props.match.params.id, paymentStore.all)
        return [order, order.transactions.find(x => x.id == props.match.params.transactionId) || {}]
    }, [paymentStore.all, props.match.params.id])

    const isPaid = transaction.status === "paid"

    return (
        <div className="invoice-print-space">
            <Loader
                loading={!transaction.id}
                style={{ height: "100%", marginTop: 0, width: "100%" }}
            >
                <div className="invoice-print-box">
                    <div className="top">
                        <img
                            src={logo}
                            alt=""
                        />
                        <span>{isPaid ? "Recibo" : "Fatura"}</span>
                    </div>
                    {isPaid && (
                        <div className="stamp-row">
                            <img src={paidStampImg} alt="" />
                        </div>
                    )}
                    <div className="invoice-box-inner">
                        <div className="invoice-row">
                            <div>
                                <span>Nº Fatura:</span> {transaction.id}
                            </div>
                            <div>
                                <span>Data:</span>{" "}
                                {new Date(transaction.createdAt).toLocaleString()}
                            </div>
                        </div>
                        <div className="order-details">
                            <table>
                                <tbody>
                                    <tr className="headerRow">
                                        <th className="date">Data</th>
                                        <th className="description">Descrição</th>
                                        <th className="amount">Cobrança (R$)</th>
                                        <th className="amount">Crédito (R$)</th>
                                    </tr>
                                    <tr className="product">
                                        <td className="date">
                                            {new Date(transaction.createdAt).toLocaleString()}
                                        </td>
                                        <td className="description">
                                            Tarifa da Assinatura Pro do KimoChat com {order.agents}{" "}
                                            agente(s), {order.numbers} linhas de whatsapp e {order.instagramUnits || "0"} canais de instagram pelo
                                            período de {getDateFormatted(order.createdAt)} a{" "}
                                            {getDateFormatted(order.expiresAt)}.
                                        </td>
                                        <td className="amount">
                                            {" "}
                                            {masks
                                                .currency(transaction.amount)
                                                .replace("R$", "R$ -")}{" "}
                                        </td>
                                        <td className="amount" />
                                    </tr>
                                    {isPaid && (
                                        <tr className="product">
                                            <td className="date">
                                                {new Date(transaction.createdAt).toLocaleString()}
                                            </td>
                                            <td className="description">
                                                Cobrança efetuada no seu cartão de crédito{" "}
                                                {"XXXX-XXXX-XXXX-" + transaction.card_last_digits}
                                            </td>
                                            <td className="amount" />
                                            <td className="amount">
                                                {masks.currency(transaction.amount)}
                                            </td>
                                        </tr>
                                    )}
                                    <tr className="total">
                                        <td />
                                        <td className="description">TOTAL</td>
                                        <td className="amount">
                                            {" "}
                                            {masks
                                                .currency(transaction.amount)
                                                .replace("R$", "R$ -")}{" "}
                                        </td>
                                        <td className="amount">
                                            {isPaid
                                                ? masks.currency(transaction.amount)
                                                : "$0.00"}
                                        </td>
                                    </tr>
                                    <tr className="total">
                                        <td />
                                        <td className="description">SALDO</td>
                                        <td className="balance">
                                            {masks.currency(order.total - transaction.amount)}
                                        </td>
                                        <td className="balance" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="footer">
                        <p>
                            Se tiver alguma dúvida sobre esta fatura, envie um email para
                            contato@kimochat.com
                        </p>
                        <p style={{ margin: "22px 0 12px" }}>
                            <a href="https://www.kimochat.com.br/terms">Termos de serviço</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://www.kimochat.com.br/privacy">Política de privacidade</a>
                        </p>
                        {/* <div className="address">
                            <p>
                                Koruja Software.
                                <br />
                                <br />
                                AV ENG HUMBERTO MONTE 2929 402 BS PICI
                                <br />
                                <br />
                                Fortaleza, CE, Brazil 60420-670
                            </p>
                        </div> */}
                        <p />
                    </div>
                </div>
            </Loader>
        </div>
    )
}

export default InvoicePrint
