import React from "react"
import CopyBlock from "components/CopyBlock"

function GettingStarted(props) {
    return (
        <>
            <h2 className="box-title">Incorporar o widget no seu site</h2>
            <p>
                Comece a falar com os visitantes do seu site incorporando esse
                código na sua página. Lembre-se, nada irá funcionar até que você
                faça isso.
            </p>
            <br />
            <br />
            <CopyBlock
                title="Passo 1: Cole este código dentro do <HEAD>"
                code
                step
            >
                {`<script src="https://kchat.koruja.com/js/widget.js"></script>`}
            </CopyBlock>
            <br />
            <br />
            <p>
                Copie o seguinte script e o coloque no código fonte HTML do seu
                site antes da tag de final do BODY nas páginas em que você
                queira que o widget apareça. Para instruções de instalação,{" "}
                <a href="#">vá aqui</a>
            </p>
            <br />
            <CopyBlock
                title="Passo 2: Cole este trecho de código antes de </BODY>"
                code
                step
            >
                {`<script>
    window.kcWidget.init({
        token: "73945e09-63b6-40fb-9ef3-480cce0c4c3e",
        host: "https://kchat.koruja.com"
    });
</script>
`}
            </CopyBlock>
            <br />
            <br />
            <CopyBlock title="(Opcional) Identificar o usuário" code>
                {`<script>
  // Make sure kcWidget.init is included before setting these values

  // To set unique user id in your system when it is available
  window.kcWidget.setExternalId("isaac.thiago1991");

  // To set user name
  window.kcWidget.user.setFirstName("Isaac");

  // To set user email
  window.kcWidget.user.setEmail("isaac.thiago@gmail.com");

  // To set user properties
  window.kcWidget.user.setProperties({
    plan: "Estate",                 // meta property 1
    status: "Active"                // meta property 2
  });
</script>
`}
            </CopyBlock>
        </>
    )
}

export default GettingStarted
