import React from "react"
import PageTableBase from "components/PageTableBase"
import SettingsHelp from "components/SettingsHelp"
import HelpBanner from "components/HelpBanner"
import EditTag from "./EditTag"
import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import { tag } from "store"
import { getIconClassName } from "@uifabric/styling"

const columns = [
    {
        Header: "Tags",
        accessor: "name",
        Cell: props => (
            <span className="vertical-center" style={{ textTransform: "capitalize" }}>
                <ColorEmojiIcon
                    icon={getIconClassName('TagSolid')}
                    iconStyle={{ marginRight: 10, color: props.row._original.color }}
                    size={18}
                />
                {/* <i className="fal fa-box table-icon" /> */}
                {props.value}
            </span>
        )
    }
]

export default function Tags(props) {
    return (
        <PageTableBase
            storeRef={tag}
            title="Tags"
            newLabel="Novo"
            columns={columns}
            EditComponent={EditTag}
            helperAction={
                <SettingsHelp title="Suas Tags" icon={getIconClassName("tag")}>
                    Tags são rótulos que podem te ajudar a organizar as suas conversas sobre um
                    mesmo tópico ou status. Elas são visíveis a todos do seu workspace.
                </SettingsHelp>
            }
            nodata={{
                title: "Parece que você ainda não adicionou nenhuma tag...",
                buttonText: "Adicionar Tag"
            }}
            {...props}
        />
    )
}
