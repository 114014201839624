import React, { useMemo } from "react"

import { useStore, general, inbox, tag } from "store"
import MenuDrop from "components/menu/MenuDrop"
import EditTag from "pages/settings/tags/EditTag"

export function TagTrigger({ tag, maxWidth }) {
    return (
        <div
            className="tag-box-item"
            style={{ backgroundColor: tag.color, color: tag.textColor, maxWidth }}
        >
            {tag.name}
        </div>
    )
}

function TagBox({ inboxItem, maxWidth, ...props }) {
    // const inboxStore = useStore(inbox)
    const tagStore = useStore(tag)
    const { addModal } = useStore(general)
    const inboxStore = useStore(inbox)

    const selectedTags = useMemo(
        () => (inboxItem.tags || []).map(tagId => tagStore.all.find(x => x.id === tagId)),
        [inboxItem.tags, tagStore.all]
    )

    if (!props.presentationOnly) {
        console.log("inboxItem.tags", inboxItem.tags)
    }

    return (
        <div className="tag-box">
            {selectedTags.map(
                tag =>
                    !!tag &&
                    (props.presentationOnly ? (
                        <TagTrigger
                            key={tag.id}
                            tag={tag}
                            maxWidth={maxWidth / selectedTags.length - 5}
                        />
                    ) : (
                        <MenuDrop
                            key={tag.id}
                            width={100}
                            // style={{ marginLeft: "auto" }}
                            trigger={<TagTrigger tag={tag} />}
                            options={[
                                {
                                    name: "Editar",
                                    onClick: () => addModal(<EditTag item={tag} />)
                                },
                                {
                                    name: "Deletar",
                                    onClick: () =>
                                        inboxStore.saveTags(
                                            selectedTags.filter(x => x.id != tag.id).map(x => x.id),
                                            inboxItem
                                        )
                                }
                            ]}
                        />
                    ))
            )}
        </div>
    )
}

export default TagBox
