import { toast } from "nanui"

export function error(msg, configs) {
    callToast("error", msg, configs)
}

export function ok(msg, configs) {
    callToast("success", msg, configs)
}

export function info(msg, configs) {
    callToast("info", msg, configs)
}

export function warn(msg, configs) {
    callToast("warn", msg, {
        hideProgressBar: true,
        ...configs
    })
}

function callToast(type, msg, { toastId, ...configs } = {}) {
    if (!toastId) toast(msg, type, { ...configs, toastId })
    // toast[type](msg, {
    //     ...configs,
    //     toastId
    // })
}

export default {
    ok,
    info,
    error,
    warn,
    callToast
}
