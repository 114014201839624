import React, { useRef } from "react"
import Tooltip from "components/Tooltip"
import cx from "classnames"

function ChatActionIco({
    icon,
    iconSvg,
    iconMaterial,
    description,
    onClick,
    blur,
    style,
    iconStyle,
    medium,
    className
}) {
    const btnRef = useRef(null)

    return (
        <Tooltip title={description}>
            <button
                className={cx("drop-trigger", className)}
                ref={btnRef}
                type="button"
                onClick={() => {
                    if (onClick) onClick()
                    if (blur) {
                        btnRef.current.blur()
                    }
                }}
                style={{ ...style }}
            >
                {iconSvg ? (
                    <i
                        className="icon-trigger medium svg"
                        style={{
                            backgroundImage:
                                `url(${iconSvg})`
                        }}
                        alt=""
                    />
                ) : (
                    <i
                        className={cx("icon-trigger", icon, {
                            medium
                        })}
                        style={iconStyle}
                    >
                        {iconMaterial}
                    </i>
                )}
            </button>
        </Tooltip>
    )
}

export default ChatActionIco
