import React, { useEffect, useRef, useState } from "react"
import { ChromePicker } from "react-color"
import { useStore, widget } from "store"
import { useField, useForm } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import SettingsLoader from "components/settings/SettingsLoader"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddWidget(props) {
    const widgetStore = useStore(widget)
    const [showColorPicker, setShowColorPicker] = useState(false)    
    const colorPickerRef = useRef(null)

    const item = props.match.params.id
        ? widgetStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const handleOutsideClick = (e) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
            setShowColorPicker(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick)
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    })

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adicionou um Chat Widget"
                    : "O agente editou um Chat Widget"
            })
            const response = await widgetStore.save({
                data: model,
                toastMessage: "Chat Widget salvo."
            })
            return () => {
                if (!item.id && !response.error && !response.errors) {
                    props.history.push("/settings/channels")
                }
            }
        },
        defaultModel: item
    })

    const name = useField("description", {
        defaultValue: item.description,
        form
    })

    const mainColor = useField("avatar", {
        defaultValue: item.avatar,
        form
    })

    return (
        <div className="channel-page">
            <ContextHeader backAction={{ to: "/settings/channels" }} />
            <PageTitle
                title={!props.match.params.id
                  ? "Criar um Chat Widget"
                  : "Editar o seu Chat Widget"}
                description="Um Chat Widget pode ser integrado ao seu site e através dele os 
                seus clientes podem entrar em contato com o atendimento de forma simples e rápida."
            />
            <SettingsLoader store={widgetStore} {...props}>
                <FormDialog
                    {...props}
                    item={item}
                    endpoint="/integrations/widget"
                    onSubmit={form.onSubmit}
                    onDelete={id =>
                        widgetStore
                            .remove({
                                data: { id },
                                toastMessage: "Deletado com sucesso"
                            })
                            .then(() => {
                                props.history.push("/settings/channels")
                            })
                    }
                >
                    <div
                        className={`form-widget ${!props.match.params.id ? 'form-widget-offset' : ''}`}
                    >
                        <Text label="Nome" required {...name} />
                        <Text
                            label="Cor principal do widget"
                            description="Informe a cor em hexadecimal. Exemplo: #000000"
                            onFocus={() => setShowColorPicker(true)}
                            {...mainColor}
                        />
                        {showColorPicker && (
                            <div className="color-picker-wrapper" ref={colorPickerRef}>
                              <ChromePicker
                                  color={{ hex: mainColor.value }}
                                  onChange={(color) => mainColor.setValue(color.hex)}
                              />
                            </div>
                        )}
                        {!!props.match.params.id && (
                            <Text
                                label="Adicione o seguinte script dentro da tag <head> no HTML do seu site"
                                value={`<script async src="${window.location.origin}/livechat.js?title=${encodeURIComponent(item.description)}&identifier=${item.identifier}&main_color=${item.avatar ? encodeURIComponent(item.avatar) : ""}"></script>`}
                                required
                                disabled
                                copyable
                            />
                        )}
                    </div>
                </FormDialog>
            </SettingsLoader>
        </div>
    )
}
