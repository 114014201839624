import React, { useState } from "react"
import { Link } from "react-router-dom"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import AuthBase from "../AuthBase"
import { useField, useForm } from "hooks"
import Lottie from "react-lottie"
import okLottie from "assets/animations/success.json"

const optionsLottie = {
    loop: false,
    autoplay: true,
    animationData: okLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

function RegisterSuccess(props) {
    const { name, workspaceName } = props.location.state
    return (
        <AuthBase>
            <div className="register-success-space">
                <h1 className="headline">Cadastro concluído!</h1>
                {/* <i className="fa fa-check" /> */}
                <Lottie
                    options={optionsLottie}
                    height={220}
                    width={220}
                    speed={1}
                    // isPaused={!showArrow}
                />
                <h2 className="headline" style={{ textAlign: "center" }}>
                    <strong>{name}</strong>, você agora faz parte do workspace <br />{" "}
                    <strong>{workspaceName}</strong>.
                </h2>
                <br/>

                <div className="login-actions">
                    <Button
                        className="login-btn"
                        primary
                        loader="login"
                        to="/"
                        style={{ textDecoration: "none" }}
                    >
                        Ok
                    </Button>
                </div>
            </div>
        </AuthBase>
    )
}

export default RegisterSuccess
