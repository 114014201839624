import React from "react"
import cx from "classnames"

export default function Label({ children, style, disabled }) {
    return (
        <label className={cx("input-label", { disabled })} style={style}>
            {children}
        </label>
    )
}
