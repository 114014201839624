import { useState } from "react"
import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"
import { stores } from "store"

const model = {
    customerDistribution: [],
    weekConversations: [],
    agents: {},
    inbox: {},
    averageTime: {}
}
function dashboardStore() {
    const [generalStats, setGeneralStats] = useState(model)
    const [agentStats, setAgentStats] = useState(model)

    /*
     * Get Dashboard
     */
    async function get() {
        stores.general.addLoader("dashboard")
        const response = await req(config.api.DASHBOARD, {})
        stores.general.removeLoader("dashboard")
        const errors = response.error || response.errors
        if (!errors) {
            setGeneralStats(response || model)
        }
    }

    return {
        generalStats,
        // Actions
        get,
    }
}

export default dashboardStore
