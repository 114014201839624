import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import Text from "components/forms/Text"
import MenuItem from "./MenuItem"
import MenuSection from "components/menu/MenuSection"
import useEventListener from "@use-it/event-listener"
import { general, useStore } from "store"
import RelativePortal from "react-relative-portal"
import { useKeyPress } from "hooks"
import SimpleBar from "simplebar-react"
import cx from "classnames"
import MenuSeparator from "components/menu/MenuSeparator"
import { normalizeStringWithAccents } from "utils/normalizeStringWithAccents"

function MenuDrop({
    trigger,
    children,
    options,
    title,
    width = 250,
    scrollableContentMaxHeight = 300,
    full,
    searchable,
    style,
    toTop,
    toLeft,
    initialFocusIndex = -1,
    onEsc,
    notCloseOnClick,
    onHoverStyle,
    dropClassName,
    ...props
}) {
    const { modal } = useStore(general)
    const [isOpen, setIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [reRender, setReRender] = useState(0)
    const [focusedIndex, setFocusedIndex] = useState(initialFocusIndex)
    const dropRef = useRef()
    const dropParentRef = useRef()
    const triggerRef = useRef()
    useKeyPress(onKey, { condition: isOpen }, [focusedIndex, searchValue, options, isOpen])

    // let { left, top } = usePosition(dropParentRef)
    let timer = null
    useEventListener("click", e => {
        if (isOpen && dropRef.current && !dropRef.current.contains(e.target)) {
            close()
        }
    })

    useEffect(() => {
        if (isOpen && (modal || props.close)) close()
    }, [modal, props.close])

    useEffect(() => {
        if (!isOpen) setSearchValue("")
    }, [isOpen])

    useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen])

    function toggle(e) {
        if (e) e.preventDefault()
        if (isOpen) close()
        else open()
    }

    function open() {
        asyncToggle(true)
        // setIsOpen(true)
    }

    function close() {
        asyncToggle(false)
        // setIsOpen(false)
    }

    function asyncToggle(value) {
        clearTimeout(timer)
        timer = setTimeout(() => {
            setIsOpen(value)
        }, 0)
    }

    function searchFilter(option) {
        const isEmpty = !Object.values(option).length
        if (isEmpty) return false

        if (!searchValue) return true
        if (!option.searchable || !option.name) return false
        const searchTerm = option.searchable || option.name
        return normalizeStringWithAccents(searchTerm.toLowerCase())
            .indexOf(normalizeStringWithAccents(searchValue.toLowerCase())) > -1
    }

    function onItemClick(itemOnClick) {
        if (!notCloseOnClick) close()
        if (itemOnClick) itemOnClick()
    }

    const getTop = useCallback(() => {
        if (!toTop) return 0
        if (isOpen) {
            if (!dropRef.current) {
                setTimeout(() => {
                    setReRender(re => re + 1)
                })
                return -9999
            }
            return -dropRef.current.clientHeight - dropParentRef.current.clientHeight
        }
    }, [isOpen, reRender, props.reRender])

    const getLeft = useCallback(() => {
        if (!toLeft) return 0
        if (isOpen) {
            return -width + triggerRef.current.clientWidth
        }
    }, [isOpen, reRender])

    const filteredOptions = useMemo(() => {
        setFocusedIndex(initialFocusIndex)
        return options ? options.filter(searchFilter) : []
    }, [searchValue, options])

    useEffect(() => {
        setReRender(re => re + 1)
    }, [props.reRender, filteredOptions])

    function onKey({ key, keyCode }) {
        if (key === "Escape" || keyCode === 27) {
            close()
            if (onEsc) {
                onEsc()
            }
        } else if (key === "Enter" || keyCode === 13) {
            onItemClick(filteredOptions[focusedIndex].onClick)
            close()
        }
        if (key === "ArrowDown" || keyCode === 40) {
            setFocusedIndex(index => {
                if (index >= filteredOptions.length - 1) return 0
                return index + 1
            })
        } else if (key === "ArrowUp" || keyCode === 38) {
            setFocusedIndex(index => {
                if (index === 0) return filteredOptions.length - 1
                return index - 1
            })
        }
    }

    return (
        <div
            className="menu-drop"
            style={full ? { width: "100%", ...style } : style}
            ref={dropParentRef}
        >
            <span
                onClick={toggle}
                type="button"
                className={`drop-trigger ${onHoverStyle}`}
                ref={triggerRef}
            >
                {trigger}
            </span>
            <RelativePortal top={getTop()} left={getLeft()}>
                {isOpen && (
                    <div
                        className={cx("menu-drop-inner", dropClassName)}
                        ref={dropRef}
                        style={{ width }}
                    >
                        {title && (
                            <div className="menu-drop-header">
                                <span className="title">{title}</span>
                                {searchable && (
                                    <Text
                                        placeholder="Pesquisar"
                                        autoComplete="off"
                                        autoFocus
                                        value={searchValue}
                                        onChange={v => setSearchValue(v.target.value)}
                                    />
                                )}
                            </div>
                        )}
                        <div className="menu-drop-content">
                            <SimpleBar style={{ maxHeight: scrollableContentMaxHeight, width: "100%" }}>
                                <>
                                    {options &&
                                        filteredOptions.map((x, i) =>
                                            x.section ? (
                                                <MenuSection
                                                    title={x.section.title}
                                                    key={x.section.title + i}
                                                />
                                            ) : x.separator ? (
                                                <MenuSeparator key={"separator" + i} />
                                            ) : (
                                                <MenuItem
                                                    {...x}
                                                    key={x.name + i}
                                                    onClick={() => onItemClick(x.onClick)}
                                                    focused={i === focusedIndex && !!x.name}
                                                />
                                            )
                                        )}
                                    {children}
                                </>
                            </SimpleBar>
                        </div>
                    </div>
                )}
            </RelativePortal>
        </div>
    )
}

export default MenuDrop
