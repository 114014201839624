import React, { useEffect, useMemo } from "react"
import Grid from "@material-ui/core/Grid"
import DashCard from "./DashCard"
import GraphChannelsCard from "./GraphChannelsCard"
import GraphConversations from "../reports/GraphConversations"
import { useBreadcrumb } from "hooks"
import { dashboard, useStore, operator, inbox } from "store"
import Loader from "components/Loader"
import SettingsHeader from "components/settings/SettingsHeader"
import GraphBox from "../reports/GraphBox"
import DashLine from "components/graphs/DashLine"
import DashPie from "components/graphs/DashPie"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"

function ReportStat({ label, children, style }) {
    return (
        <div className="report-stat">
            <div className="report-stat-title">{label}</div>
            <div className="report-stat-value" style={style}>
                {children}
            </div>
        </div>
    )
}

function getColorByChannel(name) {
    switch (name) {
        case "Widget":
            return "rgb(255, 255, 179)"
        case "Email":
            return "rgb(254, 224, 210)"
        case "Telegram":
            return "rgb(216, 218, 235)"
        case "Facebook":
            return "rgb(107, 174, 214)"
        case "Whatsapp":
            return "#2ecc71"
        default:
            return "#2ecc71"
    }
}

function DashboardGeneral(props) {
    useBreadcrumb({ title: "Dashboard" })
    const dashboardStore = useStore(dashboard)
    const operatorStore = useStore(operator)
    // const inboxStore = useStore(inbox)

    useEffect(() => {
        dashboardStore.get()
    }, [])

    const data = dashboardStore.generalStats

    // const agentsStats = useMemo(
    //     () => [
    //         {
    //             label: "Conectado",
    //             value: data.agents ? data.agents.connected : "-"
    //         },
    //         {
    //             label: "Conectaram hoje",
    //             value: data.agents ? data.agents.connectedToday : "-"
    //         }
    //     ],
    //     [data]
    // )

    // const inboxStats = useMemo(
    //     () => [
    //         {
    //             label: "Em espera",
    //             value: data.inbox ? data.inbox.waiting : "-",
    //             to: "/inbox/new",
    //             color: "#e74c3c"
    //         },
    //         {
    //             label: "Em atendimento",
    //             value: data.inbox ? data.inbox.assigned : "-",
    //             to: "/inbox/assigned",
    //             color: "#2ecc71"
    //         },
    //         {
    //             label: "Resolvidos hoje",
    //             value: data.inbox ? data.inbox.resolvedToday : "-",
    //             to: "/inbox/resolved",
    //             color: "#2ecc71"
    //         }
    //     ],
    //     [data]
    // )

    // const timeStats = useMemo(
    //     () => [
    //         {
    //             label: "De espera",
    //             value: data.averageTime ? data.averageTime.waiting : "-"
    //         },
    //         {
    //             label: "De atendimento",
    //             value: data.averageTime ? data.averageTime.resolution : "-"
    //         }
    //     ],
    //     [data]
    // )

    const customerDistribution = useMemo(
        () =>
            data.customerDistribution.map(x => ({
                id: x.channel,
                label: x.channel,
                value: x.count,
                color: getColorByChannel(x.channel)
            })),
        [data]
    )

    // const weekConversations = useMemo(
    //     () =>
    //         data.weekConversations.map(x => ({
    //             weekday: x.weekday,
    //             Novas: x.new,
    //             Resolvidas: x.resolved
    //         })),
    //     [data]
    // )
    // console.log(data.weekConversations.all)
    const volume = useMemo(
        () => [
            {
                id: "Conversas Novas",
                color: "#e6e6e6",
                data: (data.weekConversations || []).map(x => ({
                    x: x.weekday,
                    y: x.new
                }))
            },
            {
                id: "Conversas Resolvidas",
                color: "#367fee",
                data: (data.weekConversations || []).map(x => ({
                    x: x.weekday,
                    y: x.resolved
                }))
            }
        ],
        [data]
    )

    const graphs = useMemo(
        () => [
            {
                name: "Contatos por canais",
                graph: DashPie,
                data: customerDistribution
            },
            {
                name: "Conversas Resolvidas",
                graph: DashLine,
                data: volume
            },
            
        ],
        [data]
    )

    return (
        <div>
            <ContextHeader />
            <div style={{ width: 800, margin: "0 auto" }}>
                <PageTitle title="Resumo de hoje" style={{ paddingLeft: 0 }} />
                <Loader loader={"dashboard"} style={{ height: "100%", marginTop: 200 }}>
                    <GraphBox graphs={graphs} />

                    {/* <GraphConversations data={volume} /> */}
                    <br />
                    <div style={{ display: "flex" }}>
                        <div className="report-col">
                            <ReportStat label="Agentes Online">{data.agents.connected}</ReportStat>
                            <ReportStat label="Conectaram hoje">
                                {data.agents.connectedToday}
                            </ReportStat>
                            <ReportStat label="Total de Agentes">
                                {operatorStore.totalCount > 0 ? operatorStore.totalCount : "-"}
                            </ReportStat>
                            {/* <ReportStat label="Total de Conversas">
                                {inboxStore.totalCount > 0 ? operatorStore.totalCount : "-"}
                            </ReportStat> */}
                        </div>
                        <div className="report-col bordered">
                            <ReportStat label="Novas conversas">{data.inbox.waiting}</ReportStat>
                            <ReportStat label="Conversas Em Atendimento">
                                {data.inbox.assigned}
                            </ReportStat>
                            <ReportStat
                                label="Conversas Finalizadas Hoje"
                                style={{ color: "#2ecc71" }}
                            >
                                {data.inbox.resolvedToday}
                            </ReportStat>
                        </div>
                        <div className="report-col">
                            <ReportStat label="Tempo Médio de Espera Hoje">
                                {data.averageTime.waiting || "-"}
                            </ReportStat>
                            <ReportStat label="Tempo Médio de Atendimento Hoje">
                                {data.averageTime.resolution || "-"}
                            </ReportStat>
                        </div>
                    </div>
                    {/* <GraphChannelsCard
                                height={200}
                                // minHeight={350}
                                data={customerDistribution}
                            /> */}
                    {/* <Grid container spacing={16}>
                        <Grid item xs={4}>
                            <DashCard title="Agentes" icon="fa fa-user-tie" stats={agentsStats} />
                        </Grid>
                        <Grid item xs={4}>
                            <DashCard
                                title="Caixa de entrada"
                                icon="fa fa-comment-lines"
                                stats={inboxStats}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DashCard
                                title="Tempo médio de hoje"
                                icon="fa fa-clock"
                                stats={timeStats}
                            />
                        </Grid>
                    </Grid>

                    {/* Row 2 */}
                    {/* <Grid container spacing={16}>
                        <Grid item xs={4}>
                            <GraphChannelsCard
                                height={height}
                                minHeight={350}
                                data={customerDistribution}
                            />
                        </Grid> */}
                    {/* <Grid item xs={8}>
                            
                        </Grid> */}
                    {/* </Grid> */}
                </Loader>
            </div>
        </div>
    )
}

export default DashboardGeneral
