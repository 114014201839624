import React, { useState } from "react"
import copy from "copy-to-clipboard"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import Dropzone from "components/forms/Dropzone"
import { useField, useSelectField, useForm } from "hooks"
import { operationTypes } from "lib/constants"
import masks from "lib/masks"
import Button from "components/forms/Button"
import config from "config"
import toaster from "lib/toaster"
import { stores } from "store"
// import pdfExample from "assets/images/pdf.pdf"

async function openContract(item) {
    const user = stores.session.user
    const domain = stores.general._domain.url
    const link =
        domain + config.api.DOWNLOAD_CONTRACT + "/" + item.path + "/?authorization=" + user.token
    window.open(link)
    // var a = document.createElement("A")
    // var filePath = link
    // a.href = filePath
    // a.download = filePath.substr(filePath.lastIndexOf("/") + 1)
    // document.body.appendChild(a)
    // a.click()
    // document.body.removeChild(a)
}

function onCopyHash(hash) {
    copy(hash)
    toaster.ok("Hash Serpro copiado para clipboard")
}

function ViewContract(props) {
    const item = props.item
    console.log("item", item)

    const form = useForm({
        onSubmit: async model => {
            await props.onSubmit(
                { ...item, ...model },
                {
                    toast:
                        "Tipo do contrato n°" +
                        item.loanNumber +
                        " atualizado para " +
                        operationTypes.find(e => e.value === model.code).label
                }
            )
        }
    })

    const code = useSelectField("code", {
        defaultValue: item.code,
        form,
        options: operationTypes
    })

    const saveProps = { disabled: !item.json }
    return (
        <FormDialog
            {...props}
            superTitle={"Contrato N° " + (item.loanNumber || "")}
            onSubmit={form.onSubmit}
            noExcludable={item.status === "formalized"}
            saveProps={saveProps}
        >
            <Select label="Tipo de contrato" {...code} />
            <Text label="Nome" required disabled value={(item.json && item.json.name) || ""} />
            <Text label="CPF" required disabled value={masks.cpf(item.cpf) || ""} />
            <div className="subactions">
                <Button
                    big
                    icon="fal fa-file-contract"
                    loader="get/loan"
                    onClick={() => openContract(item)}
                >
                    Visualizar Contrato
                </Button>
                {item.stamp && (
                    <Button
                        big
                        icon="fal fa-copy"
                        // loader="get/loan"
                        onClick={() => onCopyHash(item.stamp)}
                        style={{ marginLeft: 10 }}
                    >
                        Copiar Hash Serpro
                    </Button>
                )}
            </div>
        </FormDialog>
    )
}

export default ViewContract
