import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useLocalStorage } from "react-use"
import { stores } from "store"
import req from "lib/req"
import config from "config"
// import { getDefaultApiEndpoints } from "components/DevRouteDropdown"
import { defaultThemes } from "lib/constants"
import { channelsTypes, channelsTypesEnum } from "lib/constants"

function generalStore() {
    const _isProduction = process.env.NODE_ENV === "production"
    const [breadcrumb, setBreadcrumb] = useState({ title: "", backTo: "" })
    const [loaders, setLoaders] = useState([])
    const [dialog, setDialog] = useState()
    const [modal, setModal] = useState()
    const [bannerNotification, setBannerNotification] = useState()
    const [modalConfigs, setModalConfigs] = useState({})
    const [_domain, setDomain] = useState({})
    const [_reqModel, setReqModel] = useState({ sent: {}, response: {} })
    const [localFilters, setLocalFilters] = useState(
        JSON.parse(localStorage.getItem("filters")) || {}
    )
    const [sidebarMinimized, setSidebarMinimized] = useLocalStorage("sidebarMinimized", false)
    const [chatSideOpened, setChatSideOpened] = useLocalStorage("chatSideWidthOpened", true)
    const [themeColors, setThemeColors] = useLocalStorage("themeColors", defaultThemes[0].colors)
    const [activeIntegration, setActiveIntegration] = useLocalStorage("activeIntegration", {})
    const [chatPanelOpen, setChatPanelOpen] = useLocalStorage("chatPanelOpen", true)
    const [panelHelperOpen, setPanelHelperOpen] = useLocalStorage("panelHelperOpen", true)

    function changeBreadcrumb(text) {
        setBreadcrumb(text)
    }

    const addLoader = loader => {
        setLoaders(loaders => [...loaders, loader])
    }

    function removeLoader(loader) {
        setLoaders(loaders => loaders.filter(x => x !== loader))
    }

    function addDialog({ confirmText = "Salvar", cancelText = "Cancelar", ...payload }) {
        setDialog({ confirmText, cancelText, ...payload })
    }

    function removeDialog(loader) {
        setDialog(null)
    }

    function addModal(payload, configs = {}) {
        setModal(payload)
        setModalConfigs(configs)
    }

    function removeModal() {
        setModal(null)
        setModalConfigs({})
        if (modal) {
            const cb = modal.cb
            if (cb) cb()
        }
    }

    function addBannerNotification(payload) {
        setBannerNotification(payload)
    }

    function removeBannerNotification() {
        setBannerNotification(null)
    }

    async function uploadFile(payload) {
        const response = await req(config.api.UPLOAD_FILE, {
            method: "post",
            loader: "put/session",
            customHeaders: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            ...payload
        })
        return response
    }

    function getAsset(assetName) {
        return assetName
    }

    function uploadAssets(assets) {
        const formData = new FormData()
        assets.forEach((x, i) => formData.append("file" + i, x))
        return uploadFile({
            data: formData,
            loader: "post/assets"
        })
    }

    function toggleSidebar() {
        // if (window.innerWidth <= 1366 && sidebarMinimized) {
        //     setChatSideOpened(false)
        // }
        setSidebarMinimized(minimized => !minimized)
    }

    function toggleSideChat() {
        if (window.innerWidth <= 1366 && !chatSideOpened) {
            setSidebarMinimized(true)
        }
        setChatSideOpened(opened => !opened)
    }

    function getColors() {
        const theme = themeColors.split(",")
        return {
            SIDEBAR_BG: theme[0],
            MENU_BG_HOVER: theme[1],
            ACTIVE_ITEM: theme[2],
            ACTIVE_ITEM_TEXT: theme[3],
            HOVER_ITEM: theme[4],
            TEXT_COLOR: theme[5],
            ACTIVE_PRESENCE: theme[6],
            MENTION_BADGE: theme[7]
        }
    }

    function showChannelDisconnectedBanner({ id, channelId }, description) {
        const channelType = channelsTypes[channelId]
        addBannerNotification({
            message: (
                <span>
                    {description}
                    <Link
                        to={`/settings/channels/${channelType.name.toLowerCase()}/` + id}
                        onClick={() => removeBannerNotification()}
                    >
                        Ver Canal
                    </Link>
                    <a onClick={() => removeBannerNotification()}>Fechar</a>
                </span>
            ),
            type: "warning"
        })
    }

    /* Developer
    =================================================================== */
    // function _changeDomain(newDomain) {
    //     setDomain(newDomain)
    //     localStorage.setItem("dev", JSON.stringify({ domain: newDomain }))
    // }

    function _changeReqModel(payload) {
        setReqModel({ ..._reqModel, [payload.method]: payload.data })
    }

    // const _recoverDev = state => {
    //     if (_isProduction) {
    //         setDomain(getDefaultApiEndpoints(_isProduction).find(x => x.label === "production"))
    //     } else {
    //         const devString = localStorage.getItem("dev")
    //         const devParsed = JSON.parse(devString)
    //         if (devParsed && devParsed.domain) {
    //             setDomain(devParsed.domain)
    //         } else {
    //             setDomain(getDefaultApiEndpoints(_isProduction)[0])
    //         }
    //     }
    // }
    const recoverDomain = () => {
        if (_isProduction) {
            setDomain(config.envs[1])
        } else {
            setDomain(JSON.parse(localStorage.getItem("nanui-env")) || config.envs[0])
        }
    }

    return {
        breadcrumb,
        changeBreadcrumb,

        // Loader
        loaders,
        addLoader,
        removeLoader,

        // Dialog
        dialog,
        addDialog,
        removeDialog,

        // Modal
        modal,
        addModal,
        removeModal,
        setLoaders,
        modalConfigs,

        // Upload/Download
        uploadFile,
        uploadAssets,
        getAsset,

        chatSideOpened,
        setChatSideOpened,
        sidebarMinimized,
        setSidebarMinimized,
        toggleSidebar,
        toggleSideChat,
        themeColors,
        setThemeColors,
        activeIntegration,
        setActiveIntegration,
        panelHelperOpen,
        setPanelHelperOpen,
        bannerNotification,
        addBannerNotification,
        removeBannerNotification,
        chatPanelOpen,
        setChatPanelOpen,

        showChannelDisconnectedBanner,

        addLocalFilters: newParams => {
            setLocalFilters(localFilters => {
                const query = {
                    ...localFilters,
                    ...newParams
                }
                localStorage.setItem("filters", JSON.stringify(query))
                return query
            })
        },

        localFilters,

        theme: getColors(),

        // Dev
        _domain,
        setDomain,
        recoverDomain,
        _reqModel,
        // _changeDomain,
        _changeReqModel,
        // _recoverDev,
        _isProduction
    }
}

export default generalStore
