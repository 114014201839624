import wppImg from "assets/images/whatsapp.png"
import instagramImg from "assets/icons/svg/instagram.svg"
import customImg from "assets/images/customc.png"

export const typeFilters = {
    SEARCH: "search",
    TABS: "tabs",
    PERIOD: "period",
    MORE: "period",
    SELECT_FREQUENCY: "select-frequency",
    SELECT_GROUP: "select-group",
    CUSTOM_DROPDOWN: "custom-dropdown"
}

export const operationTypes = [
    {
        label: "Novo",
        value: 1
    },
    {
        label: "INSS Refinanciamento",
        value: 2
    },
    {
        label: "Refinanciamento",
        value: 3
    },
    {
        label: "Refinanciamento da portabilidade",
        value: 4
    },
    {
        label: "Portabilidade",
        value: 5
    },
    {
        label: "Unificado",
        value: 6
    },
    {
        label: "Reorganização",
        value: 7
    }
]

export const permissionsEnum = {
    // ADMIN: 10,
    // MASTER: 8,
    // OPERATOR: 4
    OWNER: 10,
    ADMIN: 9,
    MASTER: 6,
    OPERATOR: 3,
    OPERATOR_RESTRICT: 2
}

export const permissions = [
    {
        label: "Admin",
        value: permissionsEnum.ADMIN,
        icon: "CrownSolid"
    },
    {
        label: "Master",
        value: permissionsEnum.MASTER
        // icon: "MasterDatabase"
    },
    {
        label: "Agente",
        value: permissionsEnum.OPERATOR
    },
    {
        label: "Agente Restrito",
        value: permissionsEnum.OPERATOR_RESTRICT
    },
    {
        label: "Proprietário",
        value: permissionsEnum.OWNER,
        icon: "CrownSolid"
    }
]

export const messagesTypes = {
    COMMON: 0,
    SYSTEM: 1,
    PRIVATE: 2,
    BOT: 3,
    EMAIL: 4
}

export const channelsTypes = {
    1: {
        name: "Whatsapp",
        bgLight: "#f1fbf6",
        icon: "fab fa-whatsapp",
        image: wppImg
    },
    2: {
        name: "Telegram",
        image:
            "https://d3t7jw7q309lw8.cloudfront.net/site/telegram_logo.svg"
    },
    3: {
        name: "Facebook",
        icon: "fab fa-facebook",
        bgLight: "#eff5fc",
        color: "#2356b3"
    },
    4: {
        name: "Widget",
        image: '/static/media/chat-widget.dd54c271.svg'
    },
    5: {
        name: "Email",
        icon: "fas fa-envelope",
        bgLight: "#eff5fc",
        color: "#2356b3"
    },
    6: {
        name: "Custom",
        icon: "fab fa-cuttlefish",
        bgLight: "#34495e",
        color: "#fff",
        image: customImg
    },
    7: {
        name: "Instagram",
        bgLight: "#f1fbf6",
        icon: "fab fa-instagram",
        image: instagramImg
    }
}

export const analyticsCategory = {
    VISITOR: "Visitante",
    AGENT: "Agente"
}

export const channelsTypesEnum = {
    WHATSAPP: "1",
    TELEGRAM: "2",
    FACEBOOK: "3",
    WIDGET: "4",
    EMAIL: "5",
    CUSTOM: "6",
    INSTAGRAM: "7",
}

export const messageStatusEnum = {
    SENT: 1,
    RECEIVED_ON_SERVER: 2,
    DELIVERED_TO_DEVICE: 3,
    READED: 4
}

export const messageStatusEnumText = {
    1: "Enviada",
    2: "Recebida",
    3: "Entregue no dispositivo",
    4: "Lida"
}

export const defaultThemes = [
    {
        name: "KimoChat",
        colors: "#6474be"
    },
    {
        name: "Noir",
        colors: "#242f3c"
    }
]
