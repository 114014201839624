import React, { useCallback } from "react"
import { useField, useCheckbox, useForm, useFetch, useSelectField } from "hooks"
import { TextField, Checkbox, Stack } from "nanui"
import Select from "components/forms/Select"
import { channel, inbox, useStore } from "store"
import Dropzone from "components/forms/Dropzone"
import ModalFooter from "components/ModalFooter"
import ModalBody from "components/ModalBody"
import Button from "components/forms/Button"
// import campaignImageWhatsApp from "assets/images/campaignWhatsapp.svg"
import { useDropzone } from "react-dropzone"
import ModuleChatTextEditor from "components/chat/ModuleChatTextEditor"
import { checkType, assetsToDelta } from "pages/inbox/AttachFileModal"
import ModalPageIndicator from "components/ModalPageIndicator"

export default function AddCampaignWhatsappPart2({
    form,
    files,
    setFiles,
    text,
    setText,
    setIndex,
    workspacePlatformId
}) {

    return (
        <>
            <ModalBody>
                <ModalPageIndicator length={3} index={1} />
                <Select label="Canal" required {...workspacePlatformId} />
                <ModuleChatTextEditor
                    label="Mensagem"
                    setText={setText}
                    text={text}
                    files={files}
                    setFiles={setFiles}
                />
                {/* <div className="modal-text-editor">
                    <input {...getInputProps()} />
                    {!!files.length && (
                        <div className="modal-text-editor-files">
                            {files.map((x, i) =>
                                checkType(x, "image") ? (
                                    <img
                                        src={x.preview}
                                        alt=""
                                        key={x.preview}
                                    />
                                ) : (
                                    <div
                                        className="attach-file-item"
                                        key={x.name + i}
                                    >
                                        <i className="fal fa-file" />
                                        <span>{x.name}</span>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    <ChatTextEditor
                        
                    />
                </div> */}
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    style={{ marginRight: 10, height: "auto" }}
                    onClick={() => setIndex(5)}
                >
                    Voltar
                </Button>
                <Button
                    primary
                    type="button"
                    onClick={() => setIndex(6)}
                    disabled={!workspacePlatformId.value}
                >
                    Continuar
                </Button>
            </ModalFooter>
        </>
    )
}
