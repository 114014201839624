import React, { useState, useMemo, useEffect } from "react"
import _ from "lodash"
import MenuDrop from "components/menu/MenuDrop"
import { general, inbox, tag, useStore } from "store"
import EditTag from "pages/settings/tags/EditTag"
import Tooltip from "components/Tooltip"
// import labelSvg from "assets/icons/svg/label.png"
import { getIconClassName } from "@uifabric/styling"
import SelectTrigger from "components/menu/SelectTrigger"

function TagDrop(props) {
    const { inboxItem } = props
    const { addModal } = useStore(general)
    const tagStore = useStore(tag)
    const inboxStore = useStore(inbox)
    const selectedTags = useMemo(() => inboxItem.tags || [], [inboxItem.tags, tagStore.all])
    let newTags = []

    function onToggleTag(tag) {
        if (newTags.findIndex(x => x === tag.id) > -1) {
            // setNewTags(newTags => newTags.filter(x => x !== tag.id))
            newTags = newTags.filter(x => x !== tag.id)
        } else {
            newTags = [...newTags, tag]
        }

        inboxStore.saveTags(_.xor(newTags.map(x => x.id), selectedTags), inboxItem)
    }

    return (
        <div className="quick-phrase-picker-box">
            <MenuDrop
                title="Tags"
                width={350}
                trigger={
                    <SelectTrigger className="normal-trigger context-header-button">
                        <span className="name-space">
                            <i className={getIconClassName("Tag")} style={{ marginRight: 8 }} />
                            <span className="name">Categorizar</span>
                        </span>
                    </SelectTrigger>
                    // <Tooltip title="Etiquetar">
                    //     <div
                    //         // className="icon-trigger fa fa-tag medium"
                    //         className={`icon-trigger medium ${getIconClassName("TagSolid")} context-header-button`}
                    //         style={{ fontSize: 16 }}
                    //     />
                    // </Tooltip>
                }
                isOpen={!!props.slashPhrase}
                searchable
                initialFocusIndex={0}
                notCloseOnClick
                // toLeft
                onEsc={() => props.quillRef.current.focus()}
                options={[
                    { section: { title: "Criar" } },
                    {
                        icon: "fal fa-plus drop-icon-add",
                        onClick: () => addModal(<EditTag item={{}} />),
                        name: "Nova tag",
                        searchable: "@"
                    },
                    { section: { title: "Todas as tags" } },
                    // {...tagStore.all.map((tag, i) => (
                    //     <div className="editable-tag" key={tag.id}>
                    //         <button
                    //             className="left"
                    //             onClick={() => onToggleTag(tag)}
                    //             style={{ backgroundColor: tag.color }}
                    //         >
                    //             {tag.name}
                    //             {selectedTags.findIndex(x => x === tag.id) > -1 && (
                    //                 <i
                    //                     className="fal fa-check"
                    //                     style={{ position: "absolute", right: 14 }}
                    //                 />
                    //             )}
                    //         </button>
                    //     </div>
                    // ))}
                    ...tagStore.all.map((x, i) => ({
                        name: x.name,
                        searchable: x.name,
                        key: x.id,
                        icon: "fa fa-tag drop-icon-tag",
                        iconStyle: { color: x.color },
                        selected: selectedTags.findIndex(id => id == x.id) > -1,
                        onClick: () => onToggleTag(x)
                    }))
                ]}
            >
                {/* <MenuSection title="Criar">
                    <MenuItem
                        icon="fal fa-plus drop-icon-add"
                        onClick={() => addModal(<EditQuickPhrase />)}
                        name="Nova resposta rápida"
                    />
                </MenuSection>
                <MenuSection title="Respostas">
                    {finalQuickPhrases.map((x, i) => (
                        <MenuItem
                            focused={props.slashPhrase && i === focused}
                            key={x.id}
                            onClick={() => {
                                setCloseDrop(true)
                                props.setText(x.phrase)
                            }}
                            name={
                                <span
                                    className="quick-phrase-phrase-space"
                                    style={{ maxWidth: width - 30 }}
                                >
                                    <span className="quick-phrase-code">/{x.tag}</span>
                                    <span className="quick-phrase-phrase">{x.phrase}</span>
                                </span>
                            }
                        />
                    ))}
                </MenuSection> */}
            </MenuDrop>
        </div>
    )
}

export default TagDrop
