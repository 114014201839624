import React from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import ContextHeader from "components/ContextHeader"
import { TagTrigger } from "components/chat/TagBox"

// import EditWhatsapp from "pages/settings/channels/whatsapp/EditWhatsapp"
// import { useStore, channel, general } from "store"

function getChannels(addModal) {
    return [
        {
            name: "Whatsapp",
            icon: "fab fa-whatsapp",
            description: "Veja e responda mensagens do Whatsapp na sua caixa de entrada",
            to: "/settings/channels/add/whatsapp"
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={{}} />)
        },
        {
            name: "Telegram",
            icon: "fab fa-telegram-plane",
            description: "Veja e responda mensagens do Telegram na sua caixa de entrada",
            to: "/settings/channels/add/telegram",
        },
        {
            name: "Chat Widget",
            icon: "far fa-comment-dots",
            description: "Veja e responda mensagens do seu site na sua caixa de entrada",
            to: "/settings/channels/add/chat-widget"
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={{}} />)
        },
        {
            name: "Instagram Direct",
            icon: "fab fa-instagram",
            description: "Veja e responda mensagens do Instagram na sua caixa de entrada",
            to: "/settings/channels/add/instagram",
            tag: {
              name: 'Em breve', 
              color: '#6474be'
            }
            // onClick: () => addModal(<EditWhatsapp endpoint="/integrations/whatsapp" item={{}} />)
        },
        {
            name: "Facebook",
            icon: "fab fa-facebook",
            iconStyle: { fontSize: 26 },
            description: "Veja e responda mensagens do Messenger na sua caixa de entrada",
            to: "/settings/channels/add/facebook",
            tag: {
                name: 'Em breve', 
                color: '#6474be'
            }
        },
        {
            name: "Email",
            icon: "fa fa-envelope",
            iconStyle: { fontSize: 24 },
            description: "Veja e responda seus email direto da sua caixa de entrada do KimoChat",
            to: "/settings/channels/add/email",
            tag: {
                name: 'Em breve', 
                color: '#6474be'
            }
        },
        // {
        //     name: "Chat Widget",
        //     icon: "fa fa-comment-alt-smile",
        //     iconStyle: { fontSize: 24 },
        //     description: "Converse com os visitantes do seu site",
        //     to: "/settings/channels/add",
        //     tag: {
        //         name: 'Em breve', 
        //         color: '#6474be'
        //     }
        // },
        {
            name: "Customizado",
            icon: "fab fa-cuttlefish",
            iconStyle: { fontSize: 24 },
            description: "Um canal personalizado que você controla usando nossa API",
            to: "/settings/channels/add/custom",
            tag: {
                name: 'Em breve', 
                color: '#6474be'
            }
        }
    ]
}

export default function AddChannel(props) {
    // const { addModal } = useStore(general)

    return (
        <div>
            <ContextHeader backAction={{ to: "/settings/channels" }} />
            <FixedHelper title="Adicionar canal">
                Conecte aos aplicativos de mensagens mais usados pelos seus clientes e responda
                todos em sua caixa de entrada.
            </FixedHelper>
            <div className="channels-list">
                {getChannels().map((channel, i) => (
                    <Link className="channel" key={channel.name} to={channel.to}>
                        <i className={channel.icon} style={channel.iconStyle} />
                        <div className="info">
                            <div className="channel-title">
                                <div className="name">{channel.name}</div>
                                {channel.tag && <TagTrigger tag={channel.tag} />}
                            </div>
                            <div className="description">{channel.description}</div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}
