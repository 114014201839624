import React, { useRef, useMemo, useEffect } from "react"
import MenuDrop from "components/menu/MenuDrop"
import SelectTrigger from "components/menu/SelectTrigger"
import { useStore, operator, inbox } from "store"
import AgentAvatar from "components/AgentAvatar"
import { getIconClassName } from "@uifabric/styling"

function AssignAgentDrop(props) {
    const operatorStore = useStore(operator)
    const inboxStore = useStore(inbox)
    const selectedAgent = useMemo(
        () => operatorStore.all.find(x => x.id == props.inboxItem.operatorId) || {},
        [props.inboxItem.operatorId, operatorStore.all]
    )

    const trigger = useMemo(() => {
        return (
            <SelectTrigger
                className="normal-trigger context-header-button"
                // notCaret={!selectedAgent.id}
                style={selectedAgent.id ? {} : { borderColor: "#ececec" }}
            >
                {selectedAgent.id ? (
                    <span className="name-space">
                        <AgentAvatar agent={selectedAgent} size={15} />
                        {/* <i className="fal fa-headset" /> */}
                        <span className="name truncate">{selectedAgent.name}</span>
                    </span>
                ) : (
                    <span className="name-space">
                        <i className={getIconClassName("FollowUser")} style={{ marginRight: 8 }} />
                        <span className="name truncate">Atribuir</span>
                    </span>
                )}
            </SelectTrigger>
        )
    })

    const agentOptions = useMemo(
        () => [
            {
                searchable: "Não atribuído",
                selected: !selectedAgent.id,
                onClick: () => assignAgent({}),
                name: (
                    <span className="menu-item-with-avatar not-assigned">
                        <i className="fal fa-user" style={{ width: 20, marginRight: 8 }} />
                        Não atribuído
                    </span>
                )
            },
            ...operatorStore.all.map(x => ({
                searchable: x.name,
                selected: x.id === selectedAgent.id,
                onClick: () => assignAgent(x),
                name: (
                    <span className="menu-item-with-avatar">
                        <AgentAvatar agent={x} size={20} />
                        <span className="ellipse">{x.name}</span>
                    </span>
                )
            }))
        ],
        [operatorStore.all, props.inboxItem.operatorId, props.inboxItem]
    )

    function assignAgent(agent) {
        if (agent.id != props.inboxItem.operatorId) {
            inboxStore.assignAgent(
                {
                    data: {
                        customerPlatformId: props.inboxItem.id,
                        operatorId: agent.id
                    }
                },
                props.inboxItem
            )
        }
    }

    return (
        <div className="select-dropdown-space" style={props.style}>
            <MenuDrop
                title="Atribuir"
                trigger={trigger}
                options={agentOptions}
                // width={props.width - 15}
                searchable
                toLeft
            />
        </div>
    )
}

export default AssignAgentDrop
