import React, { useRef } from "react"
import Dropdown from "components/Dropdown"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import Text from "components/forms/Text"
import DateRangePicker from "components/forms/DateRangePicker"

function DeleteDropdown(props) {
    const dropRef = useRef()

    function onDelete(e) {
        dropRef.current.onCloseMenu()
        props.onDelete(e)
    }

    return (
        <div className="">
            <Dropdown trigger={props.children} ref={dropRef} top style={{ width: 300 }}>
                <div className="filter-drop-inner">
                    <label className="label-input">{props.name || "Excluir"}?</label>
                    <p className="description">{props.description}</p>
                    <br/>
                    <Button
                        danger
                        type="button"
                        onClick={onDelete}
                        full
                    >
                        {props.name || "Excluir"}
                    </Button>
                </div>
            </Dropdown>
        </div>
    )
}

export default DeleteDropdown
