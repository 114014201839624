import React from "react"
import PageTableBase from "components/PageTableBase"
import SettingsHelp from "components/SettingsHelp"
import DefaultAvatar from "components/DefaultAvatar"
import EditMember from "./EditMember"
import { permissions } from "lib/constants"
import Tooltip from "components/Tooltip"
import { operator } from "store"
import { getImage } from "lib/helper"
import { getIconClassName } from "@uifabric/styling"

const columns = [
    {
        Header: "",
        accessor: "avatar",
        width: 50,
        Cell: props => (
            <div className="vertical-center">
                {props.value ? (
                    <img
                        className="cell-avatar"
                        src={getImage(props.row._original.avatar)}
                        alt=""
                    />
                ) : (
                    <DefaultAvatar index={props.row._original.id} name={props.row._original.name} />
                )}
            </div>
        )
    },
    {
        Header: "Nome",
        accessor: "name",
        maxWidth: 500,
        minWidth: 200,
        Cell: props => {
            return (
                <div className="vertical-center">
                    <span
                        className="truncate"
                        style={{
                            maxWidth: 150,
                            textDecoration: props.row._original.isActive ? "none" : "line-through"
                        }}
                    >
                        {props.value}
                    </span>
                    {props.row._original.verificationLevel == 0 ? (
                        <Tooltip
                            title="Aguardando conclusão do cadastro"
                            key={props.row._original.id}
                        >
                            <i
                                className="fas fa-exclamation-triangle"
                                style={{ marginLeft: 8, color: "#f39c12" }}
                            />
                        </Tooltip>
                    ) : (
                        ""
                    )}
                </div>
            )
        }
    },
    {
        Header: "Permissão",
        accessor: "accessLevel",
        width: 140,
        Cell: props => {
            const permission = permissions.find(e => e.value == props.value) || {}
            return (
                <div
                    style={{
                        border: "1px solid rgba(0,0,0,0.1)",
                        borderRadius: 5,
                        padding: "2px 8px",
                        display: 'flex',
                        alignItems: "center"
                    }}
                >
                    {permission.icon && (
                        <i
                            className={getIconClassName(permission.icon)}
                            style={{ marginRight: 7, color: "#f1c40f" }}
                        />
                    )}
                    {permission.label}
                </div>
            )
        }
    },
    {
        Header: "Email",
        accessor: "email",
        width: 230,
        limit: 30
    }
]

export default function Members(props) {
    return (
        <>
            <PageTableBase
                storeRef={operator}
                title="Agentes"
                newLabel="Convidar"
                newIcon={getIconClassName("AddFriend")}
                columns={columns}
                EditComponent={EditMember}
                helperAction={
                    <SettingsHelp title="Seus agentes" icon={getIconClassName("Telemarketer")}>
                        Seus agentes representam seu negócio, cativando e conversando com seus
                        usuários.
                        <br />
                        Você pode adicionar e gerenciar seus agentes aqui.
                    </SettingsHelp>
                }
                nodata={{
                    title: "Parece que você ainda não adicionou nenhum agente...",
                    buttonText: "Convidar novo Agente"
                }}
                {...props}
            />
        </>
    )
}
