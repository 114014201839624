import React, { useState, useEffect } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import toaster from "lib/toaster"
import { FacebookProvider, Login } from "react-facebook"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import { useStore, general, facebook, channel } from "store"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import { getStatus } from "pages/settings/channels/Channels"
import SettingsLoader from "components/settings/SettingsLoader"
import PagesModal from "../facebook/PagesModal"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import { envKeys } from "config/envs.config"
import { ChannelStatus } from "../Channels"
import ContextBg from "components/ContextBg"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

export default function AddFacebook(props) {
    const facebookStore = useStore(facebook)
    const channelStore = useStore(channel)

    const { addModal, removeModal, _domain } = useStore(general)

    const item = props.match.params.id
        ? facebookStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const channelItem = props.match.params.id
        ? channelStore.all.find(x => x.id == props.match.params.id) || {}
        : {}

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: !props.match.params.id
                    ? "O agente adiciou uma linha de Facebook"
                    : "O agente editou uma linha de Facebook"
            })
            const response = await facebookStore.save({
                data: model,
                toast: "Canal de Facebook salvo."
            })
            return () => {
                if (!item.id && !response.error && !response.errors) {
                    props.history.push("/settings/channels/facebook/" + response.id)
                }
            }
        },
        defaultModel: item
    })

    function handleResponse(data) {
        console.log("HANDLE DATA", data)
        addModal(
            <PagesModal
                item={item}
                accessToken={data.tokenDetail.accessToken}
                onBackModal={response => {
                    removeModal()
                    if (!item.id && !response.error && !response.errors) {
                        props.history.push("/settings/channels")
                    }
                }}
            />
        )
    }

    function handleError(error) {
        toaster.warn(error.message)
        console.log(error)
    }

    return (
        <FacebookProvider
            appId={(envKeys[_domain.key] || {}).facebook_app_id || envKeys.staging.facebook_app_id}
        >
            <div className="channel-page">
                <Login
                    scope="manage_pages,pages_messaging"
                    onCompleted={handleResponse}
                    onError={handleError}
                >
                    {({ loading, handleClick, error, data }) => (
                        <ContextHeader
                            backAction={{ to: "/settings/channels" }}
                            actions={[
                                // {
                                //     name: item.id
                                //         ? "Reconectar ao Facebook"
                                //         : "Conectar ao Facebook",
                                //     icon: "fab fa-facebook-f",
                                //     onClick: handleClick,
                                //     loading
                                // }
                            ]}
                        />
                    )}
                </Login>
                <ContextBg success>
                    <h3>Funcionalidade em desenvolvimento!</h3>
                    <p> Agradecemos o interesse, em breve teremos novidades!</p>
                </ContextBg>
                <PageTitle
                    title={
                        <span>
                            {!props.match.params.id
                                ? "Adicionar canal de Facebook"
                                : "Editar canal de Facebook"}
                            {channelItem.status && (
                                <ChannelStatus
                                    status={channelItem.status}
                                    style={{ marginLeft: 20, top: 72, position: "absolute" }}
                                />
                            )}
                        </span>
                    }
                    description="Você poderá responder as perguntas dos seus clientes imediatamente e também
                continuar a conversa com eles a qualquer momento no futuro"
                />
                <SettingsLoader store={facebookStore} {...props}>
                    <FormDialog
                        {...props}
                        disabledSubmit
                        noSalvable
                        item={item}
                        endpoint="/integrations/facebook"
                        onSubmit={form.onSubmit}
                        onDelete={id =>
                            facebookStore
                                .remove({
                                    data: { id },
                                    toastMessage: "Deletado com sucesso"
                                })
                                .then(() => {
                                    props.history.push("/settings/channels")
                                })
                        }
                    >
                        <div style={{ maxWidth: 460 }}>
                            <Text label="Nome" required value={item.name} disabled />
                        </div>
                    </FormDialog>
                </SettingsLoader>
            </div>
        </FacebookProvider>
    )
}
