import React from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import { useField, useForm } from "hooks"

function EditPath(props) {
    const pathGuid = props.guid
    const pathItem = props.item.paths.find(x => x.guid === pathGuid) || {}

    const form = useForm({
        onSubmit: async model => {
            await props.store.savePath(model, props.item)
            return props.onBackModal
        },
        defaultModel: pathItem
    })

    const name = useField("name", {
        defaultValue: pathItem.name,
        form
    })

    return (
        <FormDialog
            {...props}
            title="Caminho"
            onSubmit={form.onSubmit}
            item={{ ...pathItem, id: pathItem.guid }}
        >
            <Text label="Nome" required {...name} />
        </FormDialog>
    )
}
export default EditPath
