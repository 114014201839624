import React from "react"
import DayPicker, { DateUtils } from "react-day-picker"
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "lib/dates"
import "react-day-picker/lib/style.css"

const parseDate = date => {
    if (!date) return date
    // console.log("Recebo: ", date, "Entrego: ", new Date(date))
    return new Date(date + " ")
}

const stringifyDate = date => {
    if (!date) return date
    const dateObj = new Date(date)
    return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0]
    //.replace(/-/g, '/')
}

const stringifyDatePeriod = period => {
    if (!period) return period
    return { from: stringifyDate(period.from), to: stringifyDate(period.to) }
}

const parseDatePeriod = period => {
    if (!period) return period
    return { from: parseDate(period.from), to: parseDate(period.to) }
}

export default class Example extends React.Component {
    static defaultProps = {
        numberOfMonths: 1
    }
    constructor(props) {
        super(props)
        this.handleDayClick = this.handleDayClick.bind(this)
        this.handleResetClick = this.handleResetClick.bind(this)
        this.state = this.getInitialState()
    }
    getInitialState() {
        return {
            from: undefined,
            to: undefined
        }
    }
    static getDerivedStateFromProps(props) {
      if (props.value) return parseDatePeriod(props.value)
    }
    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state)
        this.setState(range)
        if (this.props.onChange) {
            this.props.onChange(stringifyDatePeriod(range))
        }
    }
    handleResetClick() {
        this.setState(this.getInitialState())
        this.props.onChange(undefined)
    }
    render() {
        const { from, to } = this.state
        const modifiers = { start: from, end: to }
        return (
            <div className="RangeExample">
                {!this.props.hideDescription && (
                    <p className="range-description">
                        {!from && !to && "Por favor selecione o primeiro dia."}
                        {from && !to && "Por favor selecione o último dia."}
                        {from &&
                            to &&
                            `Selecionado de ${from.toLocaleDateString()} até
                    ${to.toLocaleDateString()}`}{" "}
                        {from && to && (
                            <button
                                className="link"
                                onClick={this.handleResetClick}
                            >
                                <i className="fal fa-times" />
                            </button>
                        )}
                    </p>
                )}
                <DayPicker
                    className="Selectable"
                    numberOfMonths={this.props.numberOfMonths}
                    selectedDays={[
                        parseDate(from),
                        { from: parseDate(from), to: parseDate(to) }
                    ]}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    months={MONTHS}
                    weekdaysLong={WEEKDAYS_LONG}
                    weekdaysShort={WEEKDAYS_SHORT}
                />
            </div>
        )
    }
}
