import React, { useState, useEffect } from "react"
import Text from "components/forms/Text"
import Select from "components/forms/Select"
import FormDialog from "components/forms/FormDialog"
import { useField, useSelectField, useForm } from "hooks"
import { permissions } from "lib/constants"
import DayBlock from "./DayBlock"
import { session, useStore } from "store"

/**
 * Google Analytics
 */
import { analyticsCategory } from 'lib/constants'
import ReactGA from 'react-ga'

const defaultScheduleDay = {
    active: true,
    hours: [
        {
            startHourIndex: 0,
            endHourIndex: 1
        }
    ]
}

const defaultSchedule = {
    mon: defaultScheduleDay,
    tue: defaultScheduleDay,
    wed: defaultScheduleDay,
    thu: defaultScheduleDay,
    fri: defaultScheduleDay,
    sat: defaultScheduleDay,
    sun: defaultScheduleDay
}

function EditBusinessHours(props) {
    const item = props.item
    const [schedule, setSchedule] = useState(item.id ? item : defaultSchedule)

    useEffect(() => {
        if (item.id) setSchedule(item)
    }, [item])

    console.log(schedule)

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: item.id ? "Agente atualizou os horários de trabalho" : "Agente criou os horários de trabalho"
            })
            await props.onSubmit(
                { ...model, ...schedule },
                {
                    toastMessage: "Horário " + model.name + " " + (item.id ? "atualizado" : "criado")
                }
            )
        },
        defaultModel: item
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    return (
        <FormDialog {...props} title="Horário" onSubmit={form.onSubmit}>
            <Text label="Nome" required {...name} />
            <label className="label-input">Agendar horários de atendimento</label>
            <div className="day-blocks-list">
                <DayBlock
                    day="Segunda"
                    daySchedule={schedule.mon}
                    setDay={mon => setSchedule({ ...schedule, mon })}
                />
                <DayBlock
                    day="Terça"
                    daySchedule={schedule.tue}
                    setDay={tue => setSchedule({ ...schedule, tue })}
                />
                <DayBlock
                    day="Quarta"
                    daySchedule={schedule.wed}
                    setDay={wed => setSchedule({ ...schedule, wed })}
                />
                <DayBlock
                    day="Quinta"
                    daySchedule={schedule.thu}
                    setDay={thu => setSchedule({ ...schedule, thu })}
                />
                <DayBlock
                    day="Sexta"
                    daySchedule={schedule.fri}
                    setDay={fri => setSchedule({ ...schedule, fri })}
                />
                <DayBlock
                    day="Sábado"
                    daySchedule={schedule.sat}
                    setDay={sat => setSchedule({ ...schedule, sat })}
                />
                <DayBlock
                    day="Domingo"
                    daySchedule={schedule.sun}
                    setDay={sun => setSchedule({ ...schedule, sun })}
                />
            </div>
        </FormDialog>
    )
}

export default EditBusinessHours
