import React, { Component } from "react"

const Caret = ({ style }) => (
    <svg
        fill="currentColor"
        preserveAspectRatio="xMidYMid meet"
        height="16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        style={style}
    >
        <g fill="currentColor">
            <g>
                <path d="M11,7.3l-2.3,2.9c-0.3,0.3-0.8,0.4-1.1,0.1c0,0-0.1-0.1-0.1-0.1L5,7.3C4.8,7,4.8,6.5,5.2,6.2 C5.3,6.1,5.5,6,5.7,6h4.7c0.4,0,0.8,0.4,0.8,0.8C11.1,7,11.1,7.2,11,7.3z" />
            </g>
        </g>
    </svg>
)
// <svg
//     className="caret-big"
//     width="16"
//     height="16"
//     viewBox="0 0 16 16"
// >
//     <path d="M8 7.804L11.607 11 13 9.5 8 5 3 9.5 4.393 11z" />
// </svg>

export default Caret
