import React, { useState } from "react"
import { Link } from "react-router-dom"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import AuthBase from "./AuthBase"
import { useField, useForm } from "hooks"

function ResetSuccess(props) {
    return (
        <AuthBase>
            <div className="register-success-space">
                <h1 className="headline">Senha resetada</h1>
                <i className="fa fa-lock" />
                <h2 className="headline" style={{ textAlign: "center" }}>
                    Você já pode se logar usando sua nova senha!
                </h2>
                <br/>
                <div className="login-actions">
                    <Button
                        className="login-btn"
                        primary
                        loader="login"
                        to="/"
                        style={{ textDecoration: "none" }}
                    >
                        Ok
                    </Button>
                </div>
            </div>
        </AuthBase>
    )
}

export default ResetSuccess
