import React from "react"
import { MONTHS } from "lib/dates"

const DaySeparator = ({ date }) => (
    <div className="date-separator">
        {/* <span className="line left" /> */}
        <span className="date">
            {`${MONTHS[date.getMonth()].substring(0,3)} ${date.getDate()}, ${date.getFullYear()}`}
        </span>
        {/* <span className="line right" /> */}
    </div>
)

export default DaySeparator
