import React from "react"
import queryString from "query-string"
import _ from "lodash"
import config from "config"
import { stores } from "store"
import { MONTHS } from "./dates"
const isNilOrBlank = v => v != null && v !== ""

export function limit(text, maxlength) {
    if (typeof text === "string" && text.length >= maxlength) {
        return <span title={text}>{text.slice(0, maxlength) + "..."}</span>
    }
    return text
}

export function timeout(delay) {
    return new Promise(resolve => {
        setTimeout(resolve, delay)
    })
}

export const buildUrlQuery = (endpoint, props) => {
    return `${props.match.url}/${endpoint}${props.location.search}`
}

export function getHoursAndMinutes(value) {
    const hour = Math.floor(value / 60)
    const min = Math.round(value % 60)
    return (hour ? hour + "h " : "") + (min + "m")
}

export function getDateFormatted(value) {
    const date = new Date(value)
    return `${date.getDate()} de ${MONTHS[date.getMonth()]} de ${date.getFullYear()}`
}

/*
 *  Eg: verifyQuery: { query: { my: true }}
 *  Verifica se todas os parametros de query da url correspondem a condição de ativação
 */
export function isQueryMatched(location, query) {
    const queryParsed = queryString.parse(location.search)
    const verifyQueryKeys = Object.keys(query)
    return verifyQueryKeys.every(k => query[k] === queryParsed[k] || "")
}

// Adiciona parametros de query na URL
export function spreadQueryParams({ location, history, newParams }) {
    const queryParsed = queryString.parse(location.search)
    return queryString.stringify(_.pickBy({ ...queryParsed, ...newParams }, isNilOrBlank))
}

// Adiciona parametros de query na URL
export function addQueryParams({ history, ...props }) {
    // console.log(props, { search: spreadQueryParams(props) })
    history.replace({ search: spreadQueryParams(props) })
}

export function getImage(fileName) {
    if (!fileName) return undefined
    if (fileName.includes("http")) {
        return fileName
    }
    return stores.general._domain.url + config.api.DOWNLOAD_ASSET + "/" + fileName
}

export default {
    limit,
    timeout,
    getImage,
    buildUrlQuery,
    isQueryMatched,
    addQueryParams,
    spreadQueryParams
}
