import React, { useEffect, useMemo } from "react"
import { NavLink, Route, Link, Switch, Prompt } from "react-router-dom"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import EditPath from "./EditPath"
import Text from "components/forms/Text"
import { useBreadcrumb, useForm, useField } from "hooks"
// import WidgetChat from "components/widget/WidgetChat"
import Button from "components/forms/Button"
import EditFlow from "./EditFlow"
import EditBotConfig from "./EditBotConfig"
import Modal from "components/Modal"
import botImg from "assets/images/bot.svg"
import { useStore, general, bot } from "store"
import TopHeader from "components/TopHeader"
import Loader from "components/Loader"
import ChatActionIco from "components/inbox/ChatActionIco"
import { getIconClassName } from "@uifabric/styling"
import ContextHeader from "components/ContextHeader"
import PluginConfirmDialog from "components/plugin/PluginConfirmDialog"
import stringify from "json-stringify-deterministic"
import SideItem from "components/sidebar//SideItem"
import PageTitle from "components/PageTitle"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

const BotPathLink = ({ name, guid, url }) => (
    <div className="bot-paths-space">
        <NavLink to={url + "/path/" + guid} className="bot-menu-path-link" style={{ flex: 1 }}>
            <i className="fal fa-code-branch" style={{ opacity: 0.7 }} />
            {name}
        </NavLink>
        <Link className="path-edit" to={url + "/path/" + guid + "/edit"}>
            <i className="fal fa-edit" style={{ marginBottom: 10, marginLeft: 10 }} />
        </Link>
    </div>
)

const SideItemPath = SortableElement(({ url, path, history, botStore, i }) => (
    <div style={{ zIndex: 5 }}>
        <SideItem
            icon={getIconClassName(i === 0 ? "Flag" : "ContextMenu")}
            editable
            to={url + "/path/" + path.guid}
            name={path.name}
            onEdit={() => history.push(url + "/path/" + path.guid + "/edit")}
            onDelete={() => {
                botStore.deletePath(path.guid)
            }}
            title={i === 0 ? "Ponto de partida" : ("Caminho " + (i + 1))}
        />
    </div>
))

const Paths = SortableContainer(({ item, url, id, history, botStore, ...props }) => (
    <div className="paths">
        {/* <label className="sidebar-section-title">Caminhos</label> */}
        {/* {item.paths && item.paths.map(path => <BotPathLink {...path} key={path.name} url={url} />)} */}
        <Link className="side-add-button" to={url + "/path/new"}>
            <span className="title">Caminhos</span>
            <i className="side-add-icon" />
        </Link>
        {item.paths &&
            item.paths.map((path, i) => (
                <SideItemPath
                    url={url}
                    path={path}
                    history={history}
                    botStore={botStore}
                    index={i}
                    i={i}
                    key={path.guid}
                />
            ))}
        {/* <Button
            gray
            icon="far fa-plus"
            to={url + "/path/new"}
            style={{ marginTop: 15, paddingLeft: 25, width: 130 }}
        >
            Adicionar
        </Button> */}
    </div>
))

function EditBot(props) {
    const botStore = useStore(bot)
    const { addModal, removeModal } = useStore(general)

    useEffect(() => {
        async function fetchBot() {
            if (!botStore.all.length) {
                await botStore.get()
            }
            botStore.resetItem(props.match.params.id)
        }
        fetchBot()
        return () => botStore.resetItem(props.match.params.id)
    }, [])

    const item = botStore.item
    // const item = useMemo(
    //     () => botStore.all.find(x => x.id === props.match.params.id) || botStore.item,
    //     [props.match.params]
    // )

    // if (props.item.isLoading) {
    //     return <Redirect to="/settings/bot" />
    // }

    const isNew = props.match.params.id === "new"

    // Item é o flow
    // const item = props.item.isLoading || !props.item.path ? { paths: [] } : props.item
    // const item = props.item.isLoading || isNew ? botStore.item : props.item

    useBreadcrumb(
        { title: "Editar Fluxo", backTo: "/settings/bot" },
        { revertBreadcrumbOnBack: true }
    )

    const form = useForm({
        onSubmit: async model => {
            ReactGA.event({
                category: analyticsCategory.AGENT,
                action: item.id ? "O agente atualizou o bot" : "O agente criou um bot"
            })
            const savedBot = await botStore.save({
                data: { ...botStore.item, ...model },
                toastMessage: item.id ? "Bot atualizado" : "Bot criado"
            })
            botStore.setItem(savedBot)
            if (props.match.params.id == "new") {
                props.history.replace("/settings/bot/" + savedBot.id)
            }
        },
        defaultModel: { isActive: true, ...item }
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })

    // console.log("----------------------------------------------")
    // console.log(stringify(botStore.all.find(x => x.id === props.match.params.id)))
    // // console.log(stringify({ ...item, ...form.getFormData() }))
    // console.log("----------------------------------------------")
    // console.log(props.match, props.location)

    const excludeAction = [
        {
            name: "Excluir",
            icon: getIconClassName("Delete"),
            onClick: () =>
                addModal(
                    <PluginConfirmDialog
                        title="Deletar Bot"
                        message="Tem certeza que deseja deletar esse bot?"
                        okTitle="Deletar"
                        okBtnProps={{
                            danger: true,
                            loader: "delete" + botStore.endpoint
                        }}
                        onClick={async toDelete => {
                            if (toDelete) {
                                await botStore.remove({
                                    data: { id: item.id },
                                    toastMessage: "Bot excluído."
                                })
                                botStore.resetItem(props.match.params.id)
                                props.history.replace(
                                    props.match.url.substring(0, props.match.url.lastIndexOf("/")) +
                                        props.location.search
                                )
                            }
                            removeModal()
                        }}
                    />
                )
        }
    ]

    const when =
        stringify(botStore.all.find(x => x.id === props.match.params.id)) !==
            stringify({ ...item, ...form.getFormData() }) &&
        !(props.match.params.id !== "new" && !item.id)

    function exportBot() {
        var dataStr =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(
                JSON.stringify({ ...item, id: undefined, isNew: true, workspaceId: undefined })
            )
        var dlAnchorElem = document.getElementById("downloadAnchorElem")
        dlAnchorElem.setAttribute("href", dataStr)
        dlAnchorElem.setAttribute(
            "download",
            "bot_" +
                item.name
                    .toLowerCase()
                    .split(" ")
                    .join("_") +
                ".json"
        )
        dlAnchorElem.click()
    }

    function onSortEnd(flowPath, oldIndex, newIndex) {
        botStore.moveAction(flowPath, oldIndex, newIndex)
    }

    function changeRoutePath(botId) {
      if (props.match.params.id == "new") {
          props.history.replace("/settings/bot/" + botId)
      }
    }

    return (
        <div className="page-layout has-subheader page-bot">
            <Prompt
                when={when}
                // message="Você tem mudanças não salvas, tem certeza que deseja sair?"
                message={params =>
                    params.pathname.includes(`/settings/bot/${item.id}`) ||
                    params.pathname.includes(`/settings/bot/new`)
                        ? true
                        : "Você tem mudanças não salvas, tem certeza que deseja sair?"
                }
            />
            <Loader loading={!isNew && !item.id}>
                <ContextHeader
                    backAction={{ to: "/settings/bot" }}
                    actions={[
                        {
                            name: "Configurações do " + item.name,
                            icon: getIconClassName("Settings"),
                            to: props.match.url + "/configuration"
                        },
                        {
                            name: "Exportar",
                            icon: getIconClassName("ShareiOS"),
                            onClick: exportBot
                        },
                        {
                            name: "Salvar",
                            icon: getIconClassName("Save"),
                            to: props.match.url + "/configuration",
                            // loader: (item.id ? "put" : "post") + botStore.endpoint,
                            // onClick: form.onSubmit
                        },
                        ...(item.id ? excludeAction : [])
                    ]}
                />
                <div className="page-bot-content">
                    <div className="sidebar white bot-sidebar">
                        <Paths
                            item={item}
                            url={props.match.url}
                            id={props.match.params.id}
                            history={props.history}
                            botStore={botStore}
                            onSortStart={() => (document.body.style.cursor = "grabbing")}
                            onSortEnd={({ oldIndex, newIndex }) => {
                                botStore.movePath(item.paths, oldIndex, newIndex)
                                document.body.style.cursor = "default"
                            }}
                            lockAxis={"y"}
                            pressDelay={200}
                            helperClass="path-grabbing"
                            lockToContainerEdges={true}
                        />
                    </div>
                    <div className="bot-mid">
                        {/* <PageTitle title="Bot Bruno" /> */}

                        <Switch>
                            <Route
                                path={props.match.url + "/configuration"}
                                render={routeProps => {
                                    const onBack = () => {
                                        props.history.replace(props.match.url)
                                    }

                                    return (
                                        <Modal onBack={onBack}>
                                            <EditBotConfig
                                                {...routeProps}
                                                item={item}
                                                store={botStore}
                                                guid={routeProps.match.params.guid}
                                                onBackModal={onBack}
                                                changeRoutePath={changeRoutePath}
                                            />
                                        </Modal>
                                    )
                                }}
                            />
                            <Route
                                path={props.match.url + "/path/new"}
                                render={routeProps => {
                                    const onBack = () => {
                                        props.history.replace(props.match.url)
                                    }

                                    return (
                                        <Modal onBack={onBack}>
                                            <EditPath
                                                {...routeProps}
                                                item={item}
                                                store={botStore}
                                                guid={routeProps.match.params.guid}
                                                onBackModal={onBack}
                                            />
                                        </Modal>
                                    )
                                }}
                            />
                            <Route
                                path={props.match.url + "/path/:guid?/edit"}
                                render={routeProps => {
                                    const onBack = () => {
                                        props.history.replace(props.match.url)
                                    }

                                    return (
                                        <Modal onBack={onBack}>
                                            <EditPath
                                                {...routeProps}
                                                item={item}
                                                store={botStore}
                                                guid={routeProps.match.params.guid}
                                                onBackModal={onBack}
                                                onDelete={() => {
                                                    botStore.deletePath(
                                                        routeProps.match.params.guid
                                                    )
                                                    onBack()
                                                }}
                                            />
                                        </Modal>
                                    )
                                }}
                            />
                            <Route
                                path={props.match.url + "/path/:guid?"}
                                render={routeProps => {
                                    const pathGuid = routeProps.match.params.guid
                                    const flowPath = item.paths.find(x => x.guid === pathGuid)

                                    return (
                                        <EditFlow
                                            {...routeProps}
                                            item={item}
                                            store={botStore}
                                            pathGuid={pathGuid}
                                            flowPath={flowPath}
                                            onSortEnd={({ oldIndex, newIndex }) =>
                                                onSortEnd(flowPath, oldIndex, newIndex)
                                            }
                                            lockAxis={"y"}
                                            distance={10}
                                        />
                                    )
                                }}
                            />
                        </Switch>
                    </div>
                </div>
                {/* <div className="midcol">
                    <div className="bot-header">
                        <img src={botImg} alt="" />
                        <form className="bot-setting-right" onSubmit={form.onSubmit}>
                            <Text label="Nome do bot" flat required {...name} />
                        </form>
                    </div>
                </div> */}
                {/* <div
                    className="midcol"
                    style={{
                        display: "flex",
                        // maxWidth: 820,
                        justifyContent: "space-between",
                        marginTop: 12,
                        padding: "0px 50px",
                        marginBottom: 150
                    }}
                >
                    <Paths item={item} url={props.match.url} id={props.match.params.id} />
                    <Switch>
                        <Route
                            path={props.match.url + "/configuration"}
                            render={routeProps => {
                                const onBack = () => {
                                    props.history.replace(props.match.url)
                                }

                                return (
                                    <Modal onBack={onBack}>
                                        <EditBotConfig
                                            {...routeProps}
                                            item={item}
                                            store={botStore}
                                            guid={routeProps.match.params.guid}
                                            onBackModal={onBack}
                                        />
                                    </Modal>
                                )
                            }}
                        />
                        <Route
                            path={props.match.url + "/path/new"}
                            render={routeProps => {
                                const onBack = () => {
                                    props.history.replace(props.match.url)
                                }

                                return (
                                    <Modal onBack={onBack}>
                                        <EditPath
                                            {...routeProps}
                                            item={item}
                                            store={botStore}
                                            guid={routeProps.match.params.guid}
                                            onBackModal={onBack}
                                        />
                                    </Modal>
                                )
                            }}
                        />
                        <Route
                            path={props.match.url + "/path/:guid?/edit"}
                            render={routeProps => {
                                const onBack = () => {
                                    props.history.replace(props.match.url)
                                }

                                return (
                                    <Modal onBack={onBack}>
                                        <EditPath
                                            {...routeProps}
                                            item={item}
                                            store={botStore}
                                            guid={routeProps.match.params.guid}
                                            onBackModal={onBack}
                                            onDelete={() => {
                                                botStore.deletePath(routeProps.match.params.guid)
                                                onBack()
                                            }}
                                        />
                                    </Modal>
                                )
                            }}
                        />
                        <Route
                            path={props.match.url + "/path/:guid?"}
                            render={routeProps => {
                                const pathGuid = routeProps.match.params.guid
                                const flowPath = item.paths.find(x => x.guid === pathGuid)

                                return (
                                    <EditFlow
                                        {...routeProps}
                                        item={item}
                                        store={botStore}
                                        pathGuid={pathGuid}
                                        flowPath={flowPath}
                                    />
                                )
                            }}
                        />
                    </Switch> */}
                {/* </div> */}
            </Loader>
        </div>
    )
}

export default EditBot
