import React, { useState, useMemo, useEffect } from "react"
import { Link, Redirect } from "react-router-dom"
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import CountUp from "react-countup"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import landingLogo from "assets/images/landing-logo.png"
import landingDark from "assets/images/landing-logo-dark.png"
import client1 from "assets/images/landing/client1.png"
import client2 from "assets/images/landing/client2.png"
import clientAfagu from "assets/images/landing/client-afagu.png"
import addChannelImg from "assets/images/landing/addChannel.png"
import screen1 from "assets/images/landing/screen1.png"
import screen2 from "assets/images/landing/screen2.png"
import screen3 from "assets/images/landing/screen3.png"
import screen4 from "assets/images/landing/screen4.png"
import headset from "assets/icons/svg/landing/headset.svg"
import chatWidget from "assets/icons/svg/landing/chat-widget.svg"
import filter from "assets/icons/svg/landing/filter.svg"
import media from "assets/icons/svg/landing/media.svg"
import megaphone from "assets/icons/svg/landing/megaphone.svg"
import people from "assets/icons/svg/landing/people.svg"
import report from "assets/icons/svg/landing/report.svg"
import tag from "assets/icons/svg/landing/tag.svg"
import fastChat from "assets/icons/svg/landing/fast-chat.svg"
import Typist from "react-typist"
import "react-typist/dist/Typist.css"
import { general, session, useStore } from "store"
import { useField, useScrollTop, useBreadcrumb } from "hooks"
import cx from "classnames"
import LandingHeader from "./LandingHeader"
import LandingFooter from "./LandingFooter"
import LandingRegisterSection from "./LandingRegisterSection"
import FBWidget from "components/widget/FBWidget"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

function LandingChannel({ style, children, icon, image, color, to }) {
    return (
        <Link to={to} className="landing-channel-space">
            <div className="landing-channel" style={{ ...style, backgroundColor: color }}>
                <i className={icon} />
            </div>
            <span style={{ color }}>{children}</span>
        </Link>
    )
}

function LandingScreenButton({ children, icon, active, onClick }) {
    return (
        <button onClick={onClick} className={cx("landing-screen-button", { active })}>
            <span>{children}</span>
        </button>
    )
}

function LandingFeatureCard({ children, name, icon }) {
    return (
        <div className="landing-feature-card">
            <img src={icon} alt={name} />
            <span className="name">{name}</span>
            <span className="description">{children}</span>
        </div>
    )
}

const screens = [
    {
        icon: "fa fa-comments",
        name: "Integre todos os canais",
        image: screen1,
    },
    {
        icon: "fa fa-hashtag",
        name: "Adicione os agentes",
        image: screen2,
    },
    {
        icon: "fab fa-whatsapp",
        name: "Configure o Bot",
        image: screen3,
    },
    {
        icon: "fa fa-robot",
        name: "Comece a atender",
        image: screen4,
    },
]

function Landing(props) {
    useScrollTop(props.location)
    const { user } = useStore(session)
    const [typing, setTyping] = useState(true)
    const [screenShotIndex, setScreenShotIndex] = useState(0)
    const [showCount, setShowCount] = useState(false)
    const [firstTimeTyping, setFirstTimeTyping] = useState(true)

    const text = useField("text")

    useEffect(() => {
        document.title = "KimoChat"
    }, [])

    const onTypingDone = () => {
        setFirstTimeTyping(false)
        setTyping(false)
        setTimeout(() => setTyping(true), 100)
    }

    function onSubmitEmail() {
        ReactGA.event({
            category: analyticsCategory.VISITOR,
            action: "Ir a tela de criação de conta",
        })
        props.history.push("/user/register?email=" + text.value)
    }

    if (user.email) {
        return <Redirect to="/inbox" />
    }

    // const arrowDefaultOptions = {
    //     loop: false,
    //     autoplay: true,
    //     speed: 0.1,
    //     animationData: arrowLottie,
    //     rendererSettings: {
    //         speed: 0.1,
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // }

    return (
        <div className="landing-space" id="top">
            <div id="fb-root"></div>

            <FBWidget />
            <LandingHeader />
            <div className="landing-top">
                <div className="midcol">
                    <div className="top">
                        <div className="slogan">
                            <span>
                                Integre os canais de atendimento para oferecer uma experiência perfeita aos clientes
                            </span>
                        </div>
                        <img src={addChannelImg} alt="Adicionar canal" />
                    </div>
                </div>
            </div>
            <br />
            <div className="landing-section bottom-offset">
                <div className="midcol">
                    <p className="mid-text">Teste o KimoChat por 30 dias. De <strong>graça.</strong></p>
                    <form className="register-email-space" onSubmit={onSubmitEmail}>
                        <Text placeholder="Seu e-mail" required flat type="email" {...text} />
                        <Button type="submit">Cadastrar</Button>
                    </form>
                </div>
            </div>
            <div className="bg-secondary">
                <div className="midcol">
                    <div className="flex-wrapper">
                        <strong className="mid-text">
                            Aproxime-se dos seus clientes
                        </strong>
                        <p className="small-text" style={{ maxWidth: '610px' }}>
                            O <strong>atendimento ao cliente</strong> é uma das áreas mais importantes de qualquer negócio.
                            É ele que determina a satisfação dos clientes e, consequentemente, <strong>o sucesso da empresa.</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className="landing-section">
                <div className="midcol">
                    <div className="center">
                        <p className="mid-text">
                            Sua equipe terá a <strong>ferramenta</strong> certa para <strong>responder rapidamente</strong>
                            <span role="img" aria-label="Raio">⚡</span>
                        </p>
                        <br />
                        <p className="small-text" style={{ maxWidth: '840px' }}>
                            Ao integrar todos os canais de atendimento no KimoChat, os agentes podem acessar rapidamente
                            as mensagens dos clientes, aumentando a produtividade e redução do tempo de resolução
                            das solicitações.
                        </p>
                        <br />
                        <br />
                        <div className="landing-screenshot-space">
                            <div className="left">
                                {screens.map((x, i) =>
                                    screenShotIndex === i ? (
                                        <div key={x.image}>
                                            <Fade>
                                                <img src={x.image} alt="" />
                                            </Fade>
                                        </div>
                                    ) : null
                                )}
                            </div>
                            <div className="right">
                                <div className="screens-list">
                                    {screens.map((x, i) => (
                                        <LandingScreenButton
                                            icon={x.icon}
                                            onClick={() => setScreenShotIndex(i)}
                                            active={screenShotIndex === i}
                                            key={x.image}
                                        >
                                            {x.name}
                                        </LandingScreenButton>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="app-screen-main" style={{ opacity: 0 }}>
                <div>
                    <img
                        src="https://www.mozilla.org/media/img/firefox/new/trailhead/browser-window.c0dbff1968d6.svg"
                        alt=""
                    />
                </div>
            </div> */}
            <div
                className="landing-section"
                style={{
                    backgroundColor: "rgb(249, 249, 249)",
                    background: "linear-gradient(#E2F4FB ,#fff)",
                    paddingTop: 70,
                    paddingBottom: 16,
                    textAlign: "center",
                    marginTop: 80,
                }}
            >
                <div className="midcol">
                    <div className="center">
                        <p className="mid-text">
                            Proporcione um <strong>melhor atendimento</strong> com <strong>funcionalidades fáceis de usar</strong>
                        </p>
                        <br />
                        <br />
                        <div className="landing-features-section">
                            <div className="row">
                                <LandingFeatureCard name="Multi Usuários" icon={headset}>
                                    O KimoChat é flexível e pode ser utilizado por qualquer equipe
                                    de atendimento, seja pequeno negócio ou Call Center.
                                </LandingFeatureCard>
                                <LandingFeatureCard name="Grupos" icon={people}>
                                    Divida seus agentes em grupos, de acesso restrito, e destine
                                    as mensagens recebidas para estes.
                                </LandingFeatureCard>
                                <LandingFeatureCard name="Filtros" icon={filter}>
                                    Filtre as mensagens por segmentos específicos e organize
                                    facilmente o seu atendimento.
                                </LandingFeatureCard>
                            </div>
                            <div className="row">
                                <LandingFeatureCard name="Tags" icon={tag}>
                                    Cadastre e organize as mensagens recebidas através das tags,
                                    que são visíveis por todos os agentes.
                                </LandingFeatureCard>
                                <LandingFeatureCard name="Respostas Rápidas" icon={fastChat}>
                                    Cadastre respostas padrões e diminua o tempo de atendimento ao
                                    cliente, aumentando sua satisfação.
                                </LandingFeatureCard>
                                <LandingFeatureCard name="Mídias" icon={media}>
                                    Envie e receba imagens, vídeos, gifs, stickers, arquivos,
                                    entre outros, e potencialize o atendimento ao cliente.
                                </LandingFeatureCard>
                            </div>
                            <div className="row">
                                <LandingFeatureCard name="Chat Widget" icon={chatWidget}>
                                    Caso seu estabelecimento possua site, integre o Chat Widget
                                    para que seus clientes entrem em contato a partir dele.
                                </LandingFeatureCard>
                                <LandingFeatureCard name="Relatórios" icon={report}>
                                    Gere relatórios dos atendimentos realizados, com métricas
                                    baseadas em tempo de espera, atendimento e resolução.
                                </LandingFeatureCard>
                                <LandingFeatureCard name="Campanhas" icon={megaphone}>
                                    Utilize a Campanha para enviar uma mesma mensagem para
                                    vários clientes, de forma automática.
                                </LandingFeatureCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="landing-section companies">
                <div className="midcol">
                    <div className="center">
                        <div className="left">
                            <h1 style={{ fontSize: 30, maxWidth: 700 }}>
                            Trusted by 5,000+<br/>growing teams
                            </h1>
                        </div>
                        <div className="right">
                            <div className="companies">
                                <div className="row">
                                <img className="company" src={client1} alt="carmais" />
                                <img className="company" src={client1} alt="carmais" />
                                <img className="company" src={client2} alt="ganhomais" />
                                </div>
                                <div className="row">
                                <img className="company" src={client1} alt="carmais" />
                                <img className="company" src={client1} alt="carmais" />
                                <img className="company" src={client1} alt="carmais" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/*<div
                className="landing-section"
                style={{
                    // backgroundColor: "rgb(249, 249, 249)",
                    paddingTop: 0,
                    paddingBottom: 96,
                    textAlign: "center",
                    // marginTop: 90
                }}
            >
                <div className="midcol">
                    <div className="center">
                        <h1
                            style={{
                                fontSize: 70,
                                maxWidth: 700,
                                marginBottom: 0,
                            }}
                        >
                            <Zoom duration={1000} onReveal={() => setShowCount(true)}>
                                {showCount && (
                                    <CountUp
                                        end={25354}
                                        start={0}
                                        decimal=","
                                        separator=" "
                                        duration={5}
                                    />
                                )}
                            </Zoom>
                        </h1>
                        <h3>Atendimentos foram realizados usando o KimoChat.</h3>
                        <h3>
                            Seus clientes preferem tirar dúvidas através do WhatsApp?
                            <br /> Faça mais vendas interagindo com seus clientes direto pelo
                            WhatsApp, Instagram ou o Chat no seu site.
                        </h3>
                         <h1
                            style={{
                                fontSize: 30,
                                maxWidth: 650,
                                marginTop: 110,
                                paddingTop: 0,
                            }}
                        >
                            Junte-se as equipes que dão o melhor suporte aos seus clientes e fazem
                            mais vendas
                        </h1>
                        <div className="companies">
                            <img className="company" src={client2} alt="gm" />
                            <img
                                className="company"
                                src={clientAfagu}
                                alt="afagu"
                                style={{ width: 80 }}
                            />
                            <img className="company" src={client1} alt="carmais" />
                        </div> 
                    </div>
                </div>
            </div>*/}
            {/* <LandingRegisterSection /> */}
            <div className="landing-section bottom-offset">
                <div className="midcol">
                    <div className="center">
                        <p className="mid-text">
                            <strong>Chegou a hora de experimentar!</strong>
                        </p>
                        <ul className="features-list">
                            <li>30 dias de teste, de graça</li>
                            <li>Não precisa cadastrar cartão de crédito</li>
                            <li>Todos* os recursos liberados <br /> <span>*Exceto Campanhas</span></li>
                        </ul>
                        <br />
                        <form className="register-email-space" onSubmit={onSubmitEmail}>
                            <Text placeholder="Seu e-mail" required flat type="email" {...text} />
                            <Button type="submit">Cadastrar</Button>
                        </form>
                    </div>
                </div>
            </div>
            <LandingFooter />
        </div>
    )
}

export default Landing
