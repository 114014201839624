import React, { useState, useMemo } from "react"
import req from "lib/req"
import config from "config"
import toaster from "lib/toaster"
import { stores } from "store"
import { toast } from "nanui"

const defaultUser = { isRecovering: true, workspaces: [{}] }
function sessionStore() {
    const [user, setUser] = useState(defaultUser)
    const [workspace, setWorkspace] = useState({})
    const [helpers, setHelpers] = useState([])
    const isExpired = useMemo(
        () =>
            (workspace || {}).subscription &&
            new Date(workspace.subscription.expiresAt) < new Date(),
        [workspace]
    )

    async function login(data) {
        const response = await req(config.api.LOGIN, {
            data,
            method: "post",
            loader: "login"
        })
        return onLogin(response)
    }

    function onLogin(response) {
        if (!response.error) {
            saveWorkspace(response.workspaces[0])
            saveUser(response)
            return response
        }
        return false
    }

    async function saveUser(user) {
        // console.log("USER", user)
        setUser(user)
        localStorage.setItem("user", JSON.stringify(user))
    }

    async function saveWorkspace(workspace) {
        setWorkspace(workspace)
        localStorage.setItem("workspace", JSON.stringify(workspace))
        localStorage.setItem("nanui-token", workspace.token)
        localStorage.setItem("nanui-token-renewal", workspace.renewalToken)
    }

    async function changePassword(payload) {
        await req(config.api.CHANGE_PASSWORD, {
            ...payload,
            method: "put",
            loader: "put/session"
        })
        return false
    }

    async function sendForgotEmail(payload) {
        await req(config.api.FORGOT, {
            ...payload,
            method: "put",
            loader: "put/session"
        })
        return false
    }
    async function resetPassword(payload) {
        const response = await req(config.api.RESET, {
            ...payload,
            method: "put",
            loader: "put/session"
        })
        return false
    }

    // From Join
    async function completeRegister(payload) {
        const data = await req(config.api.COMPLETE_REGISTER, {
            ...payload,
            method: "post",
            loader: "register"
        })
        return data
    }

    // From resendemail
    async function resendEmail() {
        const data = await req(config.api.RESEND_AGENT_EMAIL, {
            method: "get"
        })
        return data
    }
    // From confirm email
    async function confirmEmail(payload) {
        const data = await req(config.api.CONFIRM_AGENT_EMAIL, {
            ...payload,
            method: "post"
        })
        return data
    }

    // New Owner
    async function register(payload) {
        const data = await req(config.api.REGISTER, {
            ...payload,
            method: "post",
            loader: "register"
        })
        return data
    }
    async function updateProfile(payload) {
        const response = await req(config.api.UPDATE_PROFILE, {
            ...payload,
            method: "put",
            loader: "put/session"
        })
        if (response && response.email) {
            setUser(response)
            localStorage.setItem("user", JSON.stringify(response))
            toast(payload.toastMessage, "success")
        } else {
            toast('Erro ao atualizar o Perfil', "error")
        }
    }
    async function updateWorkspace(payload) {
        const response = await req(config.api.WORKSPACE, {
            ...payload,
            method: "put",
            loader: "put/workspace"
        })
        if (response) {
            toast(payload.toastMessage, "success")
            patchWorkspace(response)
        } else {
            toast('Erro ao atualizar o Workspace', "error")
        }
    }

    async function getWorkspace(payload) {
        const response = await req(config.api.WORKSPACE, {
            ...payload,
            method: "get",
            loader: "get/workspace"
        })
        if (response) {
            patchWorkspace({ ...workspace, ...response })
        }
    }

    function patchWorkspace(payload) {
        setUser(user => {
            const oldWorkspaceIndex = user.workspaces.findIndex(
                workspace => workspace.workspaceId == payload.workspaceId
            )
            const updatedWorkspace = {
                ...user.workspaces[oldWorkspaceIndex],
                ...payload
            }
            user.workspaces[oldWorkspaceIndex] = updatedWorkspace
            const patchedUser = {
                ...user
                // workspaces: [updatedWorkspace, ...user.workspaces]
            }
            localStorage.setItem("user", JSON.stringify(patchedUser))
            saveWorkspace(patchedUser.workspaces[oldWorkspaceIndex])
            return patchedUser
        })
    }

    async function getJoinInfo(payload) {
        const data = await req(config.api.JOIN_WORKSPACE, payload)
        return data
    }

    async function joinWorkspace(payload) {
        const data = await req(config.api.JOIN_WORKSPACE, {
            ...payload,
            method: "post"
        })
        return data
    }

    async function changeWorkspace(workspace, history) {
        saveWorkspace(workspace)
        history.push("/")
        toaster.info(
            <span>
                Você está agora no workspace <strong>{workspace.workspace}</strong>
            </span>
        )
    }

    async function createWorkspace(payload, history) {
        const data = await req(config.api.WORKSPACE, {
            ...payload,
            method: "post"
        })
        console.log(data, payload, history)
        if (!data.error || !data.errors) {
            saveWorkspace(data)
            saveUser({ ...user, workspaces: [...user.workspaces, data] })
            changeWorkspace(data, history)
        }
    }

    async function recoverHelpers() {
        const localHelpers = JSON.parse(localStorage.getItem("helpers"))
        if (localHelpers) {
            setHelpers(localHelpers)
        }
    }
    async function recoverSession() {
        recoverHelpers()
        const localUser = JSON.parse(localStorage.getItem("user"))
        const localWorkspace = JSON.parse(localStorage.getItem("workspace"))
        setUser(localUser || { workspaces: [{}] })
        setWorkspace(localWorkspace)
    }
    async function saveSeenHelpers(helper) {
        setHelpers([...helpers, helper])
        localStorage.setItem("helpers", JSON.stringify(helpers))
    }

    async function logout(helper) {
        console.log("ENTROU NO LOGOUT")
        localStorage.removeItem("user")
        localStorage.removeItem("helpers")
        localStorage.removeItem("workspace")
        localStorage.clear()
        setUser({})
        setHelpers([])
        setWorkspace({})
        stores.general.setActiveIntegration({})
    }

    return {
        user,
        workspace,
        helpers,

        // Actions
        login,
        saveUser,
        saveWorkspace,
        resendEmail,
        confirmEmail,
        patchWorkspace,
        logout,
        recoverSession,
        saveSeenHelpers,
        changePassword,
        completeRegister,
        register,
        sendForgotEmail,
        updateProfile,
        updateWorkspace,
        getWorkspace,
        getJoinInfo,
        joinWorkspace,
        changeWorkspace,
        createWorkspace,
        onLogin,
        isExpired,
        resetPassword
    }
}

export default sessionStore
