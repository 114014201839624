import React, { useState } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import Button from "components/forms/Button"
import TopHeader from "components/TopHeader"
import Checkbox from "components/forms/Checkbox"
import chestImg from "assets/images/chest.svg"
import { getDateFormatted } from "lib/helper"
import DeleteDropdown from "components/DeleteDropdown"
import AddOnCard from "components/AddOnCard"
import FixedHelper from "components/settings/FixedHelper"
import { useStore, session, payment } from "store"
import cx from "classnames"
import ContextHeader from "components/ContextHeader"
import PageTitle from "components/PageTitle"
import { getIconClassName } from "@uifabric/styling"
import ReactTable from "react-table"
import masks from "lib/masks"
import { useBreadcrumb } from "hooks"

/**
 * Google Analytics
 */
import { analyticsCategory } from "lib/constants"
import ReactGA from "react-ga"

// const basePrice = 39.9

// const plans = [
//     {
//         type: "free"
//     },
//     {
//         type: "trial"
//     }
// ]

// const PlanBox = ({ period, discount, isActive, onClick }) => (
//     <a
//         className="plan-box"
//         style={isActive ? { border: "2px solid #2ecc71" } : {}}
//         onClick={onClick}
//     >
//         {/* <div className="plan-badge"><span>Basic</span></div> */}
//         <span className="period">{period}</span>
//         {discount && <span className="discount">{discount}% de desconto</span>}
//     </a>
// )

function License(props) {
    useBreadcrumb({ title: "Assinatura" })
    const { workspace, isExpired } = useStore(session)
    const { cancelSubscription } = useStore(payment)
    const sub = workspace.subscription

    function onCancelSubscription() {
        cancelSubscription()
    }

    const isCanceled = sub.status === "canceled"

    const columns = [
        {
            Header: "Planos Ativos",
            accessor: "name",
            width: 200,
            Cell: props => (
                <span className="vertical-center">
                    <i className={`${getIconClassName("FavoriteStarFill")} table-icon`} />
                    {props.value}
                </span>
            )
        },
        {
            Header: "Agentes",
            accessor: "agents",
            width: 100
        },
        {
            Header: "Linhas de Whatsapp",
            accessor: "numbers",
            width: 150
        },
        {
            Header: "Instagram",
            accessor: "instagramUnits",
            width: 100
        },
        {
            Header: "Periodicidade",
            accessor: "months",
            width: 170,
            Cell: props => <span>{props.value == 12 ? "Anual" : "Mensal"}</span>
        },
        {
            Header: "Valor",
            accessor: "total",
            width: 100,
            Cell: props => <span>{masks.currency(props.value)}</span>
        },
        {
            Header: "Expira em",
            accessor: "expiresAt",
            width: 200,
            Cell: props => <span>{getDateFormatted(props.value)}</span>
        },
        // {
        //     Header: "",
        //     accessor: "",
        //     width: 200,
        //     Cell: props => (
        //         <DeleteDropdown
        //             onDelete={e => {
        //                 onCancelSubscription()
        //                 e.stopPropagation()
        //             }}
        //             description="Você cancelará seu plano atual e poderá assinar outro plano. Tem certeza?"
        //             name="Cancelar"
        //         >
        //             <Button
        //                 type="button"
        //                 loader="payment"
        //                 // ios
        //                 danger
        //                 // onClick={(e) => e.stopPropagation()}
        //                 // style={{ borderColor: "#e74c3c", color: "#e74c3c"}}
        //             >
        //                 Cancelar Assinatura
        //             </Button>
        //         </DeleteDropdown>
        //     )
        // }
    ]

    return (
        <div className="license-space">
            <ContextHeader
                actions={[
                    {
                        name: "Histórico de faturas",
                        icon: getIconClassName("M365InvoicingLogo"),
                        to: "/settings/billing/invoices"
                    }
                ]}
            />
            <br />
            {/* {sub.license === "trial" && (
                <div>
                    <h1>Este é o teste de 14 dias da Versão Profissional</h1>
                    <div className="billing-row">
                        <div className="left" style={{ width: 510 }}>
                            <span className="description">
                                Você pode delegar até 5 agentes. A versão demo terminará em 1 de
                                Julho de 2019 e irá expirar em 12 dias.
                            </span>
                            <br />
                            <br />
                            <p>
                                A Versão Profissional do KimoChat ajuda seu negócio a ser mais efetivo.
                                Os convites proativos do chat no seu site irão gerar muitos novos
                                leads. Seus agentes serão mais eficientes com respostas rápidas,
                                dicas de resposta, transferência de chats e conferências, tradutor
                                automático e muitos outros recursos.
                            </p>
                        </div>
                        <div className="right">
                            <img
                                src={chestImg}
                                alt=""
                                style={{ width: 140, marginLeft: 70, marginTop: -40 }}
                            />
                        </div>
                    </div>
                    <Button primary big to="/settings/billing/subscription">
                        Tornar-se PRO agora
                    </Button>
                </div>
            )} */}
            {/* {(sub.license !== "pro" || isCanceled || isExpired) && (
                <div style={{ display: "flex", maxWidth: 1100 }}>
                    <div style={{ maxWidth: 700 }}>
                        {isCanceled && (
                            <div
                                className="subscription-active-badge canceled"
                                style={{ marginLeft: 32 }}
                            >
                                Sua licença foi cancelada. Crie um novo plano.
                            </div>
                        )}
                        <PageTitle
                            title="Aumente sua produtividade com as funcionalidades da versão Profissional!"
                            description="A Versão Profissional do KimoChat ajuda seu negócio a ser mais efetivo. Os
                            convites proativos do chat no seu site irão gerar muitos novos leads.
                            Seus agentes serão mais eficientes com respostas rápidas, tags,
                            transferência de chats, grupos, bot automatizado e todos os recursos."
                        />
                        <Button
                            primary
                            to="/settings/billing/subscription"
                            style={{
                                display: "block",
                                width: 300,
                                marginLeft: 32,
                                padding: "13px 23px",
                                fontSize: 16
                            }}
                            textStyle={{ justifyContent: "center" }}
                            onClick={() => {
                                ReactGA.event({
                                    category: analyticsCategory.AGENT,
                                    action: "O Agente clicou em Tornar-se PRO agora"
                                })
                            }}
                        >
                            Atualizar para KimoChat Pro
                        </Button>
                    </div>
                    <img src={chestImg} alt="" style={{ width: 140, marginLeft: 70, marginTop: -40 }} />
                    <img
                        src={
                            "https://cdn.dribbble.com/users/1162077/screenshots/3141890/treasure.png"
                        }
                        alt=""
                        style={{
                            width: "auto",
                            height: 220,
                            margin: "30px auto",
                            display: "block"
                        }}
                    />
                </div>
            )} */}
            {!isCanceled && !isExpired && (
                <div>
                    {/* <div className="subscription-active-badge" style={{ margin: "0 32px" }}>
                        Sua assinatura está ativa!
                    </div> */}
                    <PageTitle
                        title="Sua assinatura"
                        // description={`Expira em ${getDateFormatted(sub.expiresAt)}`}
                    />
                    <div style={{ margin: "0 32px" }} className="table-overflow-visible">
                        <ReactTable
                            manual
                            pageSize={1}
                            page={0}
                            data={[{ name: `Plano ${sub.license[0].toUpperCase() + sub.license.slice(1)}`, ...sub }]}
                            resizable={false}
                            columns={columns}
                            showPagination={false}
                            getTrProps={(state, rowInfo) => ({
                                onClick: () => {
                                    props.history.push("/settings/billing/subscription")
                                }
                            })}
                        />
                        {isCanceled && (
                            <div
                                className={cx("subscription-active-badge", {
                                    canceled: isCanceled
                                })}
                            >
                                Sua assinatura está cancelada!
                            </div>
                        )}
                    </div>
                    {/* <AddOnCard
                        title="Plano Profissional"
                        img={chestImg}
                        price={sub.total}
                        description={`Expira em ${getDateFormatted(sub.expiresAt)}`}
                        per={sub.months == 12 ? "por ano" : "por mês"}
                        features={[
                            `Plano ${sub.months == 12 ? "Anual" : "Mensal"}`,
                            `${sub.agents} agentes`,
                            `${sub.numbers} linhas de whatsapp`
                        ]}
                    >
                        {!isCanceled && (
                            <div style={{ width: 300, marginTop: 20 }}>
                                <DeleteDropdown
                                    onDelete={() => onCancelSubscription()}
                                    description="Você cancelará seu plano atual e poderá assinar outro plano. Tem certeza?"
                                    name="Cancelar"
                                >
                                    <Button
                                        type="button"
                                        loader="payment"
                                        ios
                                        // style={{ borderColor: "#e74c3c", color: "#e74c3c"}}
                                    >
                                        Cancelar Assinatura
                                    </Button>
                                </DeleteDropdown>
                            </div>
                        )}
                    </AddOnCard> */}
                    <br />
                    {isCanceled && (
                        <Button big to="/settings/billing/subscription">
                            Assinar novo plano
                        </Button>
                    )}
                </div>
            )}
            {/* <div className="billing-separator" /> */}
        </div>
    )
}

export default License
