import React from "react"
import { useField, useCheckbox, useForm, useFetch, useBreadcrumb } from "hooks"
import { TextField, Checkbox, Stack } from "nanui"
import { channel, useStore } from "store"
import Label from "components/forms/Label"
import Dropzone from "components/forms/Dropzone"
import ModalFooter from "components/ModalFooter"
import ModalBody from "components/ModalBody"
import Button from "components/forms/Button"
import { channelsTypes, channelsTypesEnum } from "lib/constants"
import campaignImageWhatsApp from "assets/images/campaignWhatsapp.svg"
import campaignInstagram from "assets/images/campaignInstagram.svg"
import ContextBg from "components/ContextBg"

function CampaignType({ img, name, description, onClick, disabled }) {
    return (
        <a
            className="campaign-type"
            style={disabled ? { opacity: 0.3, pointerEvents: "none" } : {}}
            onClick={onClick}
        >
            <img src={img} alt="" />
            <span className="name">{name}</span>
            <span className="description">{description}</span>
            {/* <div className="choose-campaign-button">Escolher</div> */}
        </a>
    )
}

export default function ChooseCampaign({ setIndex, setCampaignType }) {
    const channelStore = useStore(channel)

    const columnProps = {
        tokens: { childrenGap: 10 },
        styles: { root: { marginLeft: 15, marginRight: 30 } }
    }

    return (
        <ModalBody>
            <Label>Escolha a plataforma da campanha</Label>
            <div style={{ display: "flex" }}>
                <CampaignType
                    img={campaignImageWhatsApp}
                    name="WhatsApp"
                    description="Mande mensagens direto para o número dos seus contatos"
                    onClick={() => { 
                        setIndex(1)
                        setCampaignType(channelsTypesEnum.WHATSAPP)
                    }}
                    // disabled
                    disabled={!channelStore.all.find(
                        x => x.channelId == channelsTypesEnum.WHATSAPP
                    )}
                />
                {/* <CampaignType
                    img={campaignInstagram}
                    name="Instagram"
                    description="Mande mensagens direto para o Instagram Direct dos seus contatos"
                    onClick={() => {
                        setIndex(4)
                        setCampaignType(channelsTypesEnum.INSTAGRAM)
                    }}
                    // disabled
                    disabled={!channelStore.all.find(
                        x => x.channelId == channelsTypesEnum.INSTAGRAM
                    )}
                /> */}
            </div>
        </ModalBody>
    )
}
