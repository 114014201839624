import React, { useRef, useMemo, useEffect } from "react"
import MenuDrop from "components/menu/MenuDrop"
import SelectTrigger from "components/menu/SelectTrigger"
import { useStore, group, inbox } from "store"
import ColorEmojiIcon from "components/forms/ColorEmojiIcon"
import { getIconClassName } from "@uifabric/styling"

function AssignGroupDrop(props) {
    const groupStore = useStore(group)
    const inboxStore = useStore(inbox)
    const selectedGroup = useMemo(
        () => groupStore.all.find(x => x.id == props.inboxItem.groupId) || {},
        [props.inboxItem.groupId, groupStore.all]
    )

    const trigger = useMemo(() => {
        return (
            <SelectTrigger
                className="normal-trigger context-header-button"
                style={selectedGroup.id ? {} : { borderColor: "#ececec" }}
            >
                {/* <AgentAvatar agent={selectedGroup} size={20} /> */}
                {selectedGroup.id ? (
                    <span className="name-space">
                        <ColorEmojiIcon
                            icon={selectedGroup.icon}
                            iconStyle={{ marginRight: 10 }}
                            size={14}
                        />
                        {/* <i className="fal fa-hashtag" /> */}
                        {/* <i className="fal fa-user-friends" style={{ marginRight: 10 }} /> */}
                        <span className="name truncate">{selectedGroup.name}</span>
                    </span>
                ) : (
                    <span className="name-space">
                        <i
                            className={getIconClassName("FabricMovetoFolder")}
                            style={{ marginRight: 8 }}
                        />
                        <span className="name truncate">Mover para grupo</span>
                    </span>
                )}
            </SelectTrigger>
        )
    })
    const groupOptions = useMemo(
        () => [
            {
                searchable: "Sem Grupo",
                selected: !selectedGroup.id,
                onClick: () => assignGroup({}),
                name: (
                    <span className="menu-item-with-avatar not-assigned">
                        <i className="fal fa-users" />
                        Sem Grupo
                    </span>
                )
            },
            ...groupStore.all.map(x => ({
                searchable: x.name,
                selected: x.id === selectedGroup.id,
                onClick: () => assignGroup(x),
                name: (
                    <span className="menu-item-with-avatar">
                        {/* <AgentAvatar agent={x} size={20} /> */}
                        {/* <i className="fal fa-user-friends" style={{ marginRight: 10 }} /> */}
                        <ColorEmojiIcon icon={x.icon} iconStyle={{ marginRight: 10 }} size={18} />
                        {x.name}
                    </span>
                )
            }))
        ],
        [groupStore.all, props.inboxItem.groupId, props.inboxItem]
    )

    function assignGroup(group) {
        inboxStore.assignGroup(
            {
                data: {
                    customerPlatformId: props.inboxItem.id,
                    groupId: group.id
                }
            },
            props.inboxItem
        )
    }

    return (
        <div className="select-dropdown-space">
            <MenuDrop
                title="Mover para"
                trigger={trigger}
                options={groupOptions}
                // width={props.width - 15}
                searchable
                toLeft
            />
        </div>
    )
}

export default AssignGroupDrop
