import React, { useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    withRouter
} from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import "simplebar/dist/simplebar.min.css"
import "./styles/App.scss"
import config from "config"
// import routes from "./config/router.config.js"
// import api from "./config/api.config.js"
import { getRootRoutes } from "components/Router"
import DevRouteDropdown from "components/DevRouteDropdown"
import { general, session, inbox, useStore } from "store"
import { initializeIcons } from "@uifabric/icons"
import { initRequest, RequestInspector, initToast } from "nanui"
import mock from "lib/mock"
import { Helmet } from "react-helmet"
// import AudioRecorder from "lib/min/AudioRecorder.min.js"

/**
 * Google Analytics
 */
import analytics from "lib/analytics/index"


async function waitForWidget() {
    return new Promise(resolve => {
        if (document.getElementById('widget')) {
            return resolve(document.getElementById('widget'));
        }

        const observer = new MutationObserver(mutations => {
            if (document.getElementById('widget')) {
                observer.disconnect();
                resolve(document.getElementById('widget'));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

function App(props) {
    const {
        modal,
        // _recoverDev,
        _domain,
        setDomain,
        recoverDomain,
        _isProduction,
        addLoader,
        removeLoader,
        setLoaders,
        showChannelDisconnectedBanner
    } = useStore(general)
    const { user, recoverSession, saveUser, saveWorkspace } = useStore(session)

    useEffect(() => {
        waitForWidget().then((element) => {
            if (window.location.pathname !== '/') {
                element.style.display = 'none'
            } else {
                element.style.display = 'block'
            }
        })
    }, [window.location.pathname])

    useEffect(() => {
        waitForWidget().then((element) => {
            if (window.location.pathname !== '/') {
                if (element) {
                    element.remove();
                }
            }
        })

        const axiosInstance = initRequest({
            defaultEnv: _isProduction ? config.envs[1] : config.envs[0],
            mock,
            renewTokenEndpoint: config.api.RENEW_TOKEN,
            isProduction: _isProduction,
            onRefreshedToken: tokenRefreshResponseData => {
                let actualWorkspace = JSON.parse(
                    localStorage.getItem("workspace")
                )
                let workspace = tokenRefreshResponseData.workspaces.find(
                    x => x.workspaceId == actualWorkspace.workspaceId
                )
                saveUser(tokenRefreshResponseData)
                saveWorkspace(workspace)
                return [workspace.token, workspace.renewalToken]
            }
        })
        axiosInstance.interceptors.request.use(request => {
            addLoader(request.method + request.url)
            return request
        })
        axiosInstance.interceptors.response.use(
            response => {
                removeLoader(
                    response.config.method +
                        response.config.url.substring(
                            response.config.baseURL.length
                        )
                )
                return response
            },
            error => {
                const errorConfig = error.config
                if (errorConfig) {
                    removeLoader(
                        errorConfig.method +
                            errorConfig.url.substring(
                                errorConfig.baseURL.length
                            )
                    )
                }
                if (error.response && error.response.status === 401) {
                    props.history.push("/user/logout")
                }
                return Promise.reject(error)
            }
        )
        initToast()
        analytics()
        recoverDomain()
        recoverSession()
        initializeIcons()
    }, [])

    return (
        <div className="App">
            {/* <Router> */}
            <div>
                {/* <Dialog state={dialog} /> */}
                {/* <Modal cb={modal.cb}>{modal.content}</Modal> */}
                {/* <ToastContainer
                            hideProgressBar
                            toastClassName="toast"
                            position="top-right"
                        /> */}
                {_domain.key && <Switch>{getRootRoutes(config.router)}</Switch>}
                {/* {!user.email && <DevRouteDropdown />} */}
            </div>
            {/* </Router> */}
            {!_isProduction && (
                <RequestInspector
                    envs={config.envs}
                    left
                    onChange={env => setDomain(env)}
                />
            )}
            <div id="modal-root" />
            <a id="downloadAnchorElem" style={{ display: "none" }} />
        </div>
    )
}

export default withRouter(App)
