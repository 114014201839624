import React, { useState, useEffect } from "react"
import { useField, useCheckbox, useForm, useFetch, useBreadcrumb } from "hooks"
import { TextField, Checkbox, Stack } from "nanui"
import { campaign, useStore } from "store"
import Label from "components/forms/Label"
import Collapse from "components/Collapse"
import ModalFooter from "components/ModalFooter"
import ModalBody from "components/ModalBody"
import Button from "components/forms/Button"
// import { SpinButton } from "office-ui-fabric-react/lib/SpinButton"
// import { Position } from "office-ui-fabric-react/lib/utilities/positioning"
import ModalPageIndicator from "components/ModalPageIndicator"
// import { getIconClassName } from "@uifabric/styling"

// import { DatePicker, DayOfWeek, IDatePickerStrings } from "office-ui-fabric-react"
import DatePicker from "components/forms/DatePicker"

const today = new Date(Date.now())

export default function AddCampaignWhatsappPart3({
    setIndex,
    chunks,
    timeBetweenChunks,
    initialTimeBetweenMessages,
    finalTimeBetweenMessages,
    executionDate,
    setExecutionDate,
    loader
}) {
    const columnProps = {
        tokens: { childrenGap: 20 }
    }

    useEffect(() => {
        if (
            parseInt(initialTimeBetweenMessages.value, 10) >
            parseInt(finalTimeBetweenMessages.value, 10)
        ) {
            finalTimeBetweenMessages.setValue(initialTimeBetweenMessages.value)
        }
    }, [initialTimeBetweenMessages.value])

    const [acceptedTerms, setAcceptedTerms] = useState(false)
    return (
        <>
            <ModalBody>
                <ModalPageIndicator length={3} index={2} />
                <Stack {...columnProps}>
                    <DatePicker
                        label="Data de início"
                        minDate={today}
                        onSelectDate={date => setExecutionDate(date)}
                        value={executionDate}
                    />
                    <div>
                        <Label>Avançado</Label>
                        <Collapse title="Configurações de Tempo">
                            <Stack {...columnProps}>
                                <TextField
                                    label="Tamanho do Lote"
                                    type="number"
                                    min="1"
                                    {...chunks}
                                />
                                <TextField
                                    label="Tempo de espera entre lotes (seg)"
                                    type="number"
                                    min="0"
                                    {...timeBetweenChunks}
                                />
                                <TextField
                                    label="Tempo de espera mínimo entre mensagens (seg)"
                                    type="number"
                                    min="0"
                                    {...initialTimeBetweenMessages}
                                />
                                <TextField
                                    // defaultValue="5"
                                    label="Tempo de espera máximo entre mensagens (seg)"
                                    type="number"
                                    min={initialTimeBetweenMessages.value}
                                    {...finalTimeBetweenMessages}
                                />
                            </Stack>
                        </Collapse>
                    </div>
                    <p style={{ opacity: 0.5, marginTop: 10, fontSize: 13 }}>
                        *Diminuir os tempos nas configurações avançadas pode
                        fazer sua campanha rodar mais rápido, mas aumenta a
                        probabilidade de seu número ser banido pelo WhatsApp.
                    </p>
                    <br />
                    <br />
                    <Checkbox
                        onChange={() => setAcceptedTerms(c => !c)}
                        label={
                            <span>
                                Aceito que a KimoChat não se responsabiliza por
                                banimento do meu número pelo WhatsApp. Conforme
                                os{" "}
                                <a
                                    style={{ color: "blue" }}
                                    href="https://www.kimochat.com.br/terms"
                                >
                                    Termos de Uso.
                                </a>
                            </span>
                        }

                        // styles={checkboxStyles}
                    />
                </Stack>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    style={{ marginRight: 10, height: "auto" }}
                    onClick={() => setIndex(6)}
                >
                    Voltar
                </Button>
                <Button
                    primary
                    type="submit"
                    loader={loader}
                    // onClick={() => setIndex(4)}
                    disabled={!acceptedTerms}
                >
                    Criar Campanha
                </Button>
            </ModalFooter>
        </>
    )
}
