import React, { useRef } from "react"
import Dropdown from "components/Dropdown"
import Select from "components/forms/Select"
import Button from "components/forms/Button"
import queryString from "query-string"
import { useStore, inbox } from "store"

function InboxFilterDrop(props) {
    const { segments, inboxCounts } = useStore(inbox)
    const dropRef = useRef()
    const queries = queryString.parse(props.location.search)
    const selectedSegment = segments.find(x =>
        Object.keys(queries).every(key => queries[key] === x.segment[key])
    )

    const onCloseDropdown = () => {
        dropRef.current.onCloseMenu()
    }

    const trigger = (
        <div className="drop-trigger">
            <button className="inbox-user-title" style={{ display: "flex", alignItems: "center" }}>
                {selectedSegment ? selectedSegment.name : segments[segments.length - 1].name}
                <i className="fal fa-angle-down" style={{ marginLeft: 8, fontSize: 20 }} />
            </button>
        </div>
    )

    return (
        <div className="inbox-filter-drop">
            <Dropdown trigger={trigger} ref={dropRef} top>
                <div className="filter-drop-inner">
                    {/* <label className="label-input">Ações</label> */}
                    <ul className="cf-dropdown-list">
                        {segments.map(x => (
                            <li key={x.icon}>
                                <Button
                                    // icon={x.icon}
                                    listItem
                                    onClick={() => {
                                        props.history.push({
                                            search: queryString.stringify(x.segment)
                                        })
                                        onCloseDropdown()
                                    }}
                                    className="button-with-count"
                                >
                                    <span>{x.name}</span>
                                    <span>{inboxCounts[x.index]}</span>
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            </Dropdown>
        </div>
    )
}

export default InboxFilterDrop
