import React, { Component } from "react"
import cx from "classnames"
import masks from "lib/masks"
import Button from "./Button"
import copy from "copy-to-clipboard"
import toaster from "lib/toaster"
import HelpIcon from "components/HelpIcon"

function Text(props) {
    const {
        mask,
        label,
        flat,
        style,
        small,
        disabled,
        rows,
        invisible,
        prefix,
        white,
        gray,
        leftIcon,
        errors = [],
        formSubmitted,
        setErrors,
        pristine,
        validating,
        validate,
        material,
        rounded,
        transparent,
        search,
        suffix,
        setValue,
        description,
        autoComplete = "off",
        copyable,
        tooltipText,
        ...moreProps
    } = props
    let showErrors = (!pristine || formSubmitted) && !!errors.length
    const className = cx("text-space", {
        // hasValue: props.type == "date" && this.state.value,
        flat,
        small,
        disabled,
        invisible,
        white,
        grayL: !white,
        gray,
        leftIcon,
        prefix,
        error: showErrors,
        material,
        rounded,
        search,
        transparent,
        copyable
    })

    function copyText() {
        copy(moreProps.value)
        toaster.info("Copiado para clipboard")
    }

    return (
        <div className="input-space">
            <div className={className} style={style}>
                <div style={{ display: 'flex' }}>
                    {label && (
                        <label className="label-input">
                            {label}{" "}
                            {!moreProps.required && <span className="optional-label">(Opcional)</span>}
                        </label>
                    )}
                    {!!tooltipText && (
                        <HelpIcon
                            style={{ marginBottom: '4px' }}
                            maxWidth={200}
                            description={tooltipText}
                        />
                    )}
                </div>
                {description && <span className="input-description">{description}</span>}
                {rows ? (
                    <textarea
                        {...moreProps}
                        rows={rows}
                        height={200}
                        disabled={disabled}
                        className="kinput textarea"
                    />
                ) : (
                    <div className="kinput">
                        {leftIcon && <i className={`input-left-icon ${leftIcon}`} />}
                        {prefix && <div className="prefix">{prefix}</div>}
                        <input {...moreProps} disabled={disabled} autoComplete={autoComplete} />
                        {suffix && <div className="suffix">{suffix}</div>}
                        {copyable && (
                            <div className="copyable-btn">
                                <Button onClick={copyText}>Copiar</Button>
                            </div>
                        )}
                    </div>
                )}
                {showErrors &&
                    errors.map(errorMsg => (
                        <div key={errorMsg} className="text-error">
                            {errorMsg}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default Text
