import React, { useState } from "react"
import FormDialog from "components/forms/FormDialog"
import { useForm } from "hooks"
import { general, session, useStore } from "store"
import config from "config"
import { messagesTypes } from "lib/constants"

export function checkType(file, type) {
    return file.type.includes(type + "/")
}

export function buildUrlInModel(model, _domain, fileName, type) {
    model.push({
        insert: { [type]: fileName }
    })
}

export function assetsToDelta(files, assets, _domain, forceType) {
    let model = []
    assets.forEach((x, i) => {
        const type = ["image", "video", "audio"].find(x => checkType(files[i], x))
        const finalType = type === "video" ? "media_video" : type
        buildUrlInModel(model, _domain, x.fileName, forceType || finalType || "file")
    })
    return model
}

function AttachFileModal(props) {
    const { user } = useStore(session)
    const { uploadAssets } = useStore(general)
    const { _domain } = useStore(general)

    const form = useForm({
        onSubmit: async () => {
            const assets = await uploadAssets(props.files)
            const msgItem = {
                content: JSON.stringify(assetsToDelta(props.files, assets, _domain)),
                customerPlatformId: props.id,
                createdAt: new Date(),
                operatorId: user.id,
                Operator: { name: user.name, avatar: user.avatar },
                type: messagesTypes.COMMON,
                isMedia: true
            }
            await props.inboxStore.saveMessage(
                msgItem,
                props.id,
                props.inboxStore.messages[props.id].data.length
            )
            return props.onBack()
        }
    })

    return (
        <FormDialog
            {...props}
            superTitle="Enviar arquivo"
            onSubmit={form.onSubmit}
            saveText="Enviar"
            saveLoader={"post/assets"}
            noExcludable
        >
            <div className="attach-file-wrap">
                {props.files.map((x, i) =>
                    checkType(x, "image") ? (
                        <img src={x.preview} alt="" key={x.preview} />
                    ) : (
                        <div className="attach-file-item" key={x.name + i}>
                            <i className="fal fa-file" />
                            <span>{x.name}</span>
                        </div>
                    )
                )}
            </div>
        </FormDialog>
    )
}

export default AttachFileModal
