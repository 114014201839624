import React, { useState, useEffect } from "react"
import SettingsHeader from "components/settings/SettingsHeader"
import { Link } from "react-router-dom"
import FixedHelper from "components/settings/FixedHelper"
import EditWhatsapp from "pages/settings/channels/whatsapp/EditWhatsapp"
import { useStore, channel, whatsapp } from "store"
import { useField, useFormMultiSelect, useForm, useFetch } from "hooks"
import FormDialog from "components/forms/FormDialog"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import { getStatus } from "pages/settings/channels/Channels"
import Loader from "components/Loader"

export default function SettingsLoader({ store, children, ...props }) {
    const item = props.match.params.id
        ? store.all.find(x => x.id == props.match.params.id) || {}
        : {}

    useEffect(() => {
        if (props.match.params.id && !item.id) {
            store.get()
        }
    }, [])

    return <Loader loading={props.match.params.id && !item.id}>{children}</Loader>
}
