import React, { useMemo, useEffect, useState, useRef, useCallback } from "react"
import { Route } from "react-router-dom"
import queryString from "query-string"
import Table from "components/Table"
import AssignmentFilterDrop from "components/inbox/AssignmentFilterDrop"
import InboxFilterDrop from "components/inbox/InboxFilterDrop"
// import SimpleBar from "simplebar-react"
import Scrollbar from "react-scrollbars-custom"
import { useStore, session, general, segment, group } from "store"
import noConversation from "assets/images/noConversation.svg"
import Loader from "components/Loader"
import { useLocalStorage } from "react-use"
import FilterHeader from "components/FilterHeader"
import ConversationItem from "components/inbox/ConversationItem"
import { typeFilters } from "lib/constants"
import Loading from "components/Loading"
import { Resizable } from "re-resizable"
import whatsappBlue from "assets/images/whatsappBlue.png"
import { stores } from "store"
import Button from "components/forms/Button"
import cx from "classnames"
import { css, select as $ } from "glamor"
import { permissionsEnum } from "lib/constants"

function Conversations(props) {
    const { user, workspace } = useStore(session)
    const { loaders, theme } = useStore(general)
    const segmentsStore = useStore(segment)
    const groupsStore = useStore(group)
    // const { inboxCounts } = useStore(inbox)
    const [width, setWidth] = useLocalStorage("inboxBarWidth", 350)
    const storageKey = "inbox"
    // const queryParsed = {}
    const [isFetching, setIsFetching] = useState(false)
    const resizeRef = useRef()

    const selectedSegment = useMemo(
        () => segmentsStore.all.find((x) => x.id == props.segmentId) || {},
        [segmentsStore.all, props.segmentId]
    )

    const canLoadMore = props.inboxStore.all.length < props.inboxStore.matchCount

    // function handleScroll({ scrollTop, clientHeight, scrollHeight } = {}) {
    //     console.log(scrollTop, clientHeight, scrollHeight, isFetching)
    //     if (scrollTop + clientHeight + 1 >= scrollHeight && !isFetching) {
    //         const count = props.inboxStore.all.length
    //         const matchCount = props.inboxStore.matchCount
    //         if (count < matchCount) {
    //             setIsFetching(true)
    //             props.inboxStore
    //                 .get({
    //                     params: {
    //                         limit: 50,
    //                         offset: count
    //                     },
    //                     isNextPage: true
    //                 })
    //                 .then(() => {
    //                     setIsFetching(false)
    //                 })
    //         }
    //     }
    // }

    function onLoadMore() {
        const count = props.inboxStore.all.length
        const matchCount = props.inboxStore.matchCount
        if (count < matchCount) {
            setIsFetching(true)
            props.inboxStore
                .get({
                    params: {
                        limit: 50,
                        offset: count,
                    },
                    isNextPage: true,
                })
                .then(() => {
                    setIsFetching(false)
                })
        }
    }

    const filterBySegment = useCallback(
        (conversation) => {
            const segment = segmentsStore.all.find((x) => x.id == props.segmentId) || {
                filters: {},
            }
            const conversationGroup = groupsStore.all.find((x) => x.id == conversation.groupId)

            // Mine
            if (segment.filters.my && conversation.operatorId !== stores.session.user.id) {
                return false
            }
            // Atribuido
            if (segment.filters.assigned && !conversation.operatorId) {
                return false
            }
            // Não Atribuido
            if (segment.filters.assigned === false && conversation.operatorId) {
                return false
            }
            // Abertos
            if (segment.filters.open && conversation.isFinished) {
                return false
            }
            // Fechados
            if (segment.filters.open === false && !conversation.isFinished) {
                return false
            }
            // Favoritos
            if (segment.filters.starred && !conversation.starred) {
                return false
            }
            // Meus
            // if (segment.filters.my && conversation.operatorId != stores.session.user.id) {
            //     return false
            // }

            // console.log(conversation, segment.filters.channels, segment.filters.channels.every(x => x.id != conversation.platformId))
            // Canais específicos
            if (
                segment.filters.channels &&
                segment.filters.channels.length &&
                segment.filters.channels.every((x) => x.id != conversation.workspacePlatformId)
            ) {
                return false
            }
            // Groups específicos
            if (
                segment.filters.groups &&
                segment.filters.groups.length &&
                segment.filters.groups.every((x) => x.id != conversation.groupId)
            ) {
                return false
            }
            // Agents específicos
            if (
                segment.filters.agents &&
                segment.filters.agents.length &&
                segment.filters.agents.every((x) => x.id != conversation.operatorId)
            ) {
                return false
            }
            // Tags específicos
            if (
                segment.filters.tags &&
                segment.filters.tags.length &&
                segment.filters.tags.every(
                    (x) => conversation.tags.findIndex((y) => y == x.id) == -1
                )
            ) {
                return false
            }

            if (conversation.id == "54") {
                console.log("STATES", user.accessLevel, permissionsEnum.MASTER, conversationGroup, user.id)
            }

            // Não é do grupo da conversa
            if (
                workspace.accessLevel <= permissionsEnum.MASTER &&
                conversationGroup &&
                !conversationGroup.members.find((x) => x.id == user.id)
            ) {
                return false
            }

            return true
        },
        [segmentsStore.all, props.inboxStore.all]
    )

    // useEffect(() => {
    //     if (!isFetching && scrollRef.current && scrollRef.current.scrollTo) {
    //         if (scrollRef.current && scrollHeight)
    //             scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight - scrollHeight)
    //         // setTimeout(() => {
    //         // }, 1000)
    //     }
    // }, [isFetching])

    // function getCompactHeaders(id) {
    //     const columns = [
    //         {
    //             Header: "",
    //             accessor: "name",
    //             Cell: props => (
    //                 <div
    //                     className={`compact-user-inbox ${
    //                         id === props.row._original.id ? "active" : ""
    //                     } ${props.row._original.hasNewMessages ? "hasNewMessages" : ""}`}
    //                 >
    //                     <div
    //                         className="vertical-center"
    //                         // to={"/people/" + props.row._original.id}
    //                     >
    //                         <div className="inbox-customer-avatar">
    //                             {props.row._original.avatar ? (
    //                                 <img
    //                                     className="cell-avatar"
    //                                     src={props.row._original.avatar}
    //                                     alt=""
    //                                 />
    //                             ) : (
    //                                 <i className="ico ico-user cell-avatar" />
    //                             )}
    //                             <img
    //                                 src={whatsappBlue}
    //                                 className="conversation-channel-indicator"
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div className="people-name-email">
    //                             <span className="people-name">
    //                                 {props.value}
    //                                 <span className="newMessageBadge">1+</span>
    //                             </span>

    //                             <span style={{ opacity: 0.7, marginTop: 2 }}>
    //                                 {/* {(props.row._original.lastMessage &&
    //                                     parseLastMessage(
    //                                         props.row._original.lastMessage.content
    //                                     )) ||
    //                                     props.row._original.email} */}
    //                             </span>
    //                         </div>
    //                         <div className="details">
    //                             <span className="time">23h</span>
    //                             <i
    //                                 className={
    //                                     props.row._original.isFinished
    //                                         ? "fal fa-check"
    //                                         : "fal fa-long-arrow-right"
    //                                 }
    //                                 style={
    //                                     props.row._original.isFinished
    //                                         ? { backgroundColor: "#34bb1f", color: "#fff" }
    //                                         : {}
    //                                 }
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             )
    //         }
    //     ]
    //     return columns
    // }

    const filters = [
        [{ type: typeFilters.SEARCH }],
        // [
        //     {
        //         type: typeFilters.TABS,
        //         onClick: () => stores.inbox.clear(),
        //         tabs: [
        //             {
        //                 name: "Todas",
        //                 query: {
        //                     open: undefined
        //                 }
        //             },
        //             {
        //                 name: "Resolvidas",
        //                 query: {
        //                     open: "false"
        //                 }
        //             },
        //             {
        //                 name: (
        //                     <span style={{ position: "relative", display: "flex" }}>
        //                         Não resolvidas{" "}
        //                         {!!inboxCounts[0] && inboxCounts[0] > 0 && (
        //                             <span className="tab-badge">{inboxCounts[0]}</span>
        //                         )}
        //                     </span>
        //                 ),
        //                 query: {
        //                     open: "true"
        //                     // new: undefined
        //                 }
        //             }
        //         ]
        //     }
        // ]
    ]

    // const { id, segmentId } = props
    // const renderTable = ({ style, filterFn } = {}) => (
    //     <Table
    //         store={props.inboxStore}
    //         // columns={id ? getCompactHeaders(id) : getHeaders()}
    //         columns={getCompactHeaders(id)}
    //         history={props.history}
    //         query={props.location.search}
    //         queryObject={segmentFilter}
    //         queryPath={segmentId}
    //         // filterFn={filterFn}
    //         getTrProps={(state, rowInfo) => {
    //             return {
    //                 onClick: () => {
    //                     // const to =
    //                     //     id === rowInfo.original.id ? `/inbox/` : `/inbox/${rowInfo.original.id}`
    //                     props.history.push(`/inbox/${segmentId}/${rowInfo.original.id}`)
    //                     props.inboxStore.saveItem({ ...rowInfo.original, hasNewMessages: false })
    //                 },
    //                 style: {
    //                     // backgroundColor: id === rowInfo.original.id ? "#f3f4f5" : null,
    //                     backgroundColor: id == rowInfo.original.id ? "#f3f4f5" : null,
    //                     borderRadius: 3
    //                 }
    //             }
    //         }}
    //         style={id ? { marginTop: -5, ...style } : { marginTop: -25, ...style }}
    //         headerStyle={{ display: "none" }}
    //         // fetchable={!(localFilters && localFilters[storageKey] && !props.location.search)}
    //     />
    // )
    const conversationsStyles = css(
        $("& .inbox-conversation-item.selected", {
            backgroundColor: `${theme.ACTIVE_ITEM} !important`,
            borderColor: `${theme.ACTIVE_ITEM} !important`,
            color: `${theme.ACTIVE_ITEM_TEXT} !important`,
        }),
        $(
            "& .inbox-conversation-item.selected .user-agent, .inbox-conversation-item.selected .icon-channel, .inbox-conversation-item.selected .last-response-time",
            {
                color: `${theme.ACTIVE_ITEM_TEXT} !important`,
            }
        )
    )

    const isLoadingInboxFixeds = useMemo(
        () => loaders.includes("get/inbox/favorites") || loaders.includes("get/label"),
        [loaders]
    )
    const isLoadingInbox = useMemo(
        () => loaders.includes("get/inbox") || isLoadingInboxFixeds[loaders]
    )
    // const loadingMarginTop = isFetching ? 10 : isLoadingInbox ? 70 : 10
    const getSegmentName = useCallback(() => {
        switch (props.segmentId) {
            case "all":
                return "Inbox"
            case "starred":
                return "Favoritos"
            case "me":
                return "Meus"
            case "archived":
                return "Arquivado"
            default:
                return selectedSegment.name
        }
    }, [props.segmentId, selectedSegment])

    return (
        <Resizable
            ref={resizeRef}
            size={{ width, height: "calc(100vh - 40px)" }}
            onResizeStop={(e, direction, ref, d) => {
                setWidth((w) => w + d.width)
            }}
            maxWidth={700}
            minWidth={280}
            enable={{ right: true }}
            handleWrapperClass="resize-line"
            className="resizer"
        >
            <div
                className={"conversations-wrapper" + (isLoadingInbox ? " loading" : "")}
                {...conversationsStyles}
            >
                <div className="conversations-wrapper-content">
                    <div className="conversations-header-context" />
                    <div className="conversations-header">
                        <h3 className="conversation-title">{getSegmentName()}</h3>
                        <FilterHeader
                            filters={filters}
                            pathname={props.match.url}
                            history={props.history}
                            location={props.location}
                            storageKey={storageKey}
                        />
                    </div>
                    <div className="conversation-separator" />
                    <Scrollbar
                        style={{ height: "100%", width: "100%" }}
                        // onScroll={handleScroll}
                        noScrollX={true}
                    >
                        {!isLoadingInboxFixeds &&
                            props.inboxStore.all
                                .filter(filterBySegment)
                                .map((x) => (
                                    <ConversationItem
                                        {...props}
                                        item={x}
                                        key={x.id}
                                        colWidth={width}
                                    />
                                ))}
                        <div
                            className={cx("conversation-loading", {
                                loadingCenter: !isFetching && isLoadingInbox,
                            })}
                        >
                            {(isFetching || isLoadingInbox) && (
                                <Loading center style={{ marginTop: 10, height: 30 }} size={28} />
                            )}
                        </div>
                        {!isFetching && !isLoadingInbox && !props.inboxStore.all.length && (
                            <div className="no-conversations">
                                {/* <img
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MCIgaGVpZ2h0PSI4MCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDgwIDgwIj48cGF0aCBmaWxsPSIjRThFQUVEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4yMDEgMzcuNzVIMjcuNzFsLjU3IDEuNzQ0YzEuNzQxIDUuMzE5IDYuNjU1IDguOTgxIDEyLjI2IDguOTgxIDUuNjA0IDAgMTAuNTE3LTMuNjYyIDEyLjI1Ny04Ljk4MWwuNTctMS43NDRoMTQuNTA5bC01LjI2Mi0xNC42MzRjLTEuMDg0LTMuMDE1LTMuOTA1LTUuMDItNy4wNjMtNS4wMkgyNS41MjZjLTMuMTU4IDAtNS45OCAyLjAwNS03LjA2MyA1LjAyTDEzLjIgMzcuNzV6bTU5Ljg3NSAwdjE2LjI4MmMwIDcuMDM1LTUuNjExIDEyLjczOS0xMi41MzMgMTIuNzM5aC00MC4wMUMxMy42MTEgNjYuNzcgOCA2MS4wNjcgOCA1NC4wM1YzNy4zNzNsNS43NTQtMTYuMDA2QzE1LjU2MSAxNi4zNDIgMjAuMjYzIDEzIDI1LjUyNiAxM0g1NS41NWM1LjI2MyAwIDkuOTY2IDMuMzQyIDExLjc3MiA4LjM2Nmw1Ljc1NCAxNi4wMDZ2LjM3OHoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="
                                    alt=""
                                /> */}
                                Sem conversas
                            </div>
                        )}
                        <div className={cx("conversation-load-more", { isFetching })}>
                            {canLoadMore && !isFetching && (
                                <Button
                                    onClick={onLoadMore}
                                    // isLoading={loading}
                                >
                                    Carregar mais conversas
                                </Button>
                            )}
                        </div>
                    </Scrollbar>
                </div>
            </div>
        </Resizable>
    )
}

export default Conversations
