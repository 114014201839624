import { useState } from "react"
import config from "config"
import fromBase from "stores/baseStore"
import shortid from "shortid"
import { stores } from "store"
import arrayMove from "array-move"

// import req from "lib/req"
// import { defaults } from "components/DevRouteDropdown"

const defaultItem = { paths: [], name: "Bot", isNew: true }
function botStore(store) {
    const [item, setItem] = useState(JSON.parse(JSON.stringify(defaultItem)))
    const [importedItem, setImportedItem] = useState()

    function savePath(path, item) {
        if (path.guid) {
            const pathIndex = item.paths.findIndex(x => x.guid === path.guid)
            item.paths[pathIndex] = path
        } else {
            item.paths.push({ ...path, guid: shortid.generate(), actions: [] })
        }

        setItem(item)
        // store.saveItem(item)
    }

    function saveConfigs(configs) {
        // const bot = store.all.find(x => x.id === configs.id)
        setItem(item => ({ ...item, ...configs }))
        // if (!bot) {
        // } else {
        //     store.saveItem({ ...bot, ...configs })
        // }
    }

    function saveAction(action, pathGuid, item) {
        const pathIndex = item.paths.findIndex(x => x.guid === pathGuid)
        if (action.guid) {
            const actionIndex = item.paths[pathIndex].actions.findIndex(x => x.guid === action.guid)
            item.paths[pathIndex].actions[actionIndex] = action
        } else {
            item.paths[pathIndex].actions.push({
                ...action,
                guid: shortid.generate()
            })
        }

        // store.saveItem(item)
    }

    function deleteAction(action, pathGuid, item) {
        const pathIndex = item.paths.findIndex(x => x.guid === pathGuid)
        // delete item.paths[pathIndex].actions[actionIndex]
        item.paths[pathIndex].actions = item.paths[pathIndex].actions.filter(
            x => x.guid != action.guid
        )

        // store.saveItem(item)
    }

    function deletePath(pathGuid) {
        setItem(item => {
            return { ...item, paths: item.paths.filter(x => x.guid !== pathGuid) }
        })
    }

    function resetItem(id) {
        if (id !== "new") {
            setItem(JSON.parse(JSON.stringify(stores.bot.all.find(x => x.id === id) || {})))
        } else {
            setItem(JSON.parse(JSON.stringify(importedItem || defaultItem)))
            setImportedItem(null)
        }
    }

    function moveAction(path, oldIndex, newIndex) {
        const newActions = arrayMove(path.actions, oldIndex, newIndex)
        setItem(item => {
            const pathIndex = item.paths.findIndex(x => x.guid === path.guid)
            item.paths[pathIndex].actions = newActions
            return { ...item }
        })
    }

    function movePath(paths, oldIndex, newIndex) {
        const newPaths = arrayMove(paths, oldIndex, newIndex)
        setItem(item => {
            item.paths = newPaths
            return { ...item }
        })
    }

    return {
        ...store,
        item,
        importedItem,
        setImportedItem,
        deletePath,
        setItem,
        savePath,
        saveAction,
        saveConfigs,
        resetItem,
        deleteAction,
        moveAction,
        movePath
    }
}

export default fromBase(config.api.BOT, botStore)
