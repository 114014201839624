import React, { useEffect } from "react"
// import FocusLock from "react-focus-lock"
import Button from "components/forms/Button"
import DeleteDropdown from "components/DeleteDropdown"
import { useStore, general } from "store"
import ModalHeader from "components/ModalHeader"
import ModalBody from "components/ModalBody"
import ModalFooter from "components/ModalFooter"

export default function FormDialog({
    className,
    children,
    saveText,
    loader,
    endpoint,
    item = {},
    store,
    title,
    superTitle,
    inputIcons,
    onSubmit,
    saveProps,
    submitting,
    noExcludable,
    disabledFocus,
    disabledSubmit,
    onDelete,
    noSalvable,
    footerStyle,
    saveLoader,
    noPadding,
    manualRemove,
    match,
    onClickCancelButton,
    cancelText,
    showOverflow
}) {
    const { removeModal } = useStore(general)
    return (
        <form
            onSubmit={async e => {
                await onSubmit(e)
                if (!manualRemove) {
                    removeModal()
                }
            }}
            className="form-dialog"
        >
            {(superTitle || title) && (
                <ModalHeader
                    title={superTitle ? superTitle : (item.id ? "Editar " : "Cadastrar ") + title}
                />
            )}
            <ModalBody noPadding={noPadding} showOverflow={showOverflow}>{children}</ModalBody>
            <ModalFooter style={footerStyle}>
                {item.id && !noExcludable && (
                    <DeleteDropdown
                        onDelete={() => onDelete(item.id)}
                        description="Você deleterá permanentemente. Não é possível desfazer. Tem certeza?"
                    >
                        <Button
                            gray
                            type="button"
                            loader={"delete" + endpoint}
                            style={{ marginRight: noSalvable ? 0 : 10, height: "auto" }}
                            // big
                        >
                            Excluir
                        </Button>
                    </DeleteDropdown>
                )}
                {!!onClickCancelButton && (
                    <Button
                        gray
                        type="button"
                        onClick={onClickCancelButton}
                        style={{ marginRight: 10, height: "auto" }}
                    >
                        {cancelText || "Cancelar"}
                    </Button>
                )}                
                {!noSalvable && (
                    <Button
                        disabled={disabledSubmit}
                        primary
                        type="submit"
                        loader={saveLoader || (item.id ? "put" : "post") + endpoint}
                        style={inputIcons ? { marginLeft: 34 } : {}}
                        // big
                        {...saveProps}
                    >
                        {saveText || "Salvar"}
                    </Button>
                )}
            </ModalFooter>
        </form>
        // <FocusLock disabled={disabledFocus}>
        // </FocusLock>
    )
}
