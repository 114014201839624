import React from "react"

function FixedHelper({ title, children, icon, iconStyle, separator }) {
    return (
        <>
            <div className="fixed-helper">
                <h1 className="title">
                    {icon && <i className={icon} style={iconStyle} />}
                    {title}
                </h1>
                {children}
            </div>
            {separator && <div className="fixed-separator" />}
        </>
    )
}

export default FixedHelper
