import React, { useEffect, useMemo, useState } from "react"
import EditableProperty from "./EditableProperty"
import ReadOnlyProperty from "./ReadOnlyProperty"
import ContactProperty from "components/chat/side/ContactProperty"
import userImg from "assets/images/user.svg"
import Loader from "components/Loader"
import { stores, inbox, useStore } from "store"
import masks from "lib/masks"

const PeopleBasic = ({ item, updateCustomer, customerId, zoomInAvatar, setZoomInAvatar }) => (
    <div className="user-card-basic">
        <Loader loading={!item.id}>
            <img
                className="user-image-big"
                src={item.avatar || userImg}
                style={{
                    ...(zoomInAvatar ? { height: "auto", cursor: "zoom-out" } : {}),
                    objectFit: item.avatar ? "cover" : "contain"
                }}
                onClick={() => setZoomInAvatar(zoom => !zoom)}
                alt=""
            />
        </Loader>

        <span className="user-card-name" style={{ flex: 1 }}>
            <ContactProperty
                property={{ label: "Nome", value: (item.name || "").toLowerCase() }}
                onSubmit={(model, config) =>
                    updateCustomer(customerId, item.id, { name: model.value }, config)
                }
                fixed
            />
        </span>
        {/* <span className="user-card-name">{item.name}</span> */}
    </div>
)

const PeopleDetails = ({ item, updateCustomer, customerId }) => (
    <div className="user-card-details">
        <ContactProperty
            property={{ label: "Email", value: item.email }}
            onSubmit={(model, config) =>
                updateCustomer(customerId, item.id, { email: model.value }, config)
            }
            fixed
            valuePlaceholder="Digite o email"
        />
        <ContactProperty
            property={{ label: "Celular", value: masks.cell(item.phone) }}
            onSubmit={(model, config) =>
                updateCustomer(customerId, item.id, { phone: masks.number(model.value) }, config)
            }
            fixed
            valueMask={["cell"]}
            valuePlaceholder="+xx (xx) x xxxx-xxxx"
        />
        {/* <ReadOnlyProperty property="Identificador" value={item.id} /> */}
    </div>
)

// const UseDetails = ({ customerDetails }) => (
//     <div className="people-data-section">
//         <div className="people-data-section-title">Detalhes de uso</div>
//         <ReadOnlyProperty
//             property="Visualizado"
//             value={new Date(customerDetails.lastViewed).toLocaleString()}
//         />
//         <ReadOnlyProperty
//             property="Criado"
//             value={new Date(customerDetails.createdAt).toLocaleString()}
//         />
//         <ReadOnlyProperty property="Sessões" value={customerDetails.sessionsCount} />
//         {/* <ReadOnlyProperty property="Criado" value="Nov 25, 2018 8:52 PM" /> */}
//     </div>
// )

function DetailsOverview(props) {
    const inboxStore = useStore(inbox)
    const [zoomInAvatar, setZoomInAvatar] = useState(false)

    return (
        <div className="people-data-section">
            <PeopleBasic
                item={props.itemWithDetails}
                updateCustomer={inboxStore.updateCustomer}
                customerId={props.customerDetails.id}
                setZoomInAvatar={setZoomInAvatar}
                zoomInAvatar={zoomInAvatar}
            />
            <PeopleDetails
                item={props.itemWithDetails}
                updateCustomer={inboxStore.updateCustomer}
                customerId={props.customerDetails.id}
            />
        </div>
    )
}

export default DetailsOverview
