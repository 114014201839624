import React from "react"
import ModalSide from "components/ModalSide"
import Button from "components/forms/Button"
import ModalBody from "components/ModalBody"
import ModalFooter from "components/ModalFooter"

export default function ModalMultiPage({ pages, index = 0 }) {
    return (
        <div className="multi-page-modal">
            <ModalSide {...pages[index].sideProps} />
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}
            >
                {pages[index].component}
            </div>
        </div>
    )
}
