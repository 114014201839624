export const MONTHS = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
]

export const WEEKDAYS_LONG = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado"
]
export const WEEKDAYS_SHORT = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]

export function isSameDay(date, prevDate) {
    if (
        prevDate.getMonth() === date.getMonth() &&
        prevDate.getDate() === date.getDate() &&
        prevDate.getFullYear() === date.getFullYear()
    ) {
        return true
    }
    return false
}

export function getTime(date) {
    return date.toLocaleTimeString("pt-BR", {
        hour: "numeric",
        minute: "numeric",
        hour12: false
    })
}
