import React, { useEffect, useState } from "react"
import req from "lib/req"
import axios from "axios"
import config from "config"
import FormDialog from "components/forms/FormDialog"
// import toaster from "lib/toaster"
import { facebook, useStore } from "store"
import { useForm } from "hooks"
import Loader from "components/Loader"
import Radiobox from "components/forms/Radiobox"

function PagesModal(props) {
    const [pages, setPages] = useState()
    // let chosenPage
    // const [chosenPage, setChosenPage] = useState({})
    const facebookStore = useStore(facebook)

    const form = useForm({
        onSubmit: async (model, formValid, chosenPage) => {
            if (chosenPage) {
                await facebookStore.save({
                    data: {
                        avatar: chosenPage.picture.data.url,
                        pageId: chosenPage.id,
                        name: chosenPage.name,
                        accessToken: chosenPage.access_token
                    }
                })
                props.onBackModal()
            }
        }
    })

    async function getPages() {
        const response = await axios({
            method: "get",
            url: config.api.FACEBOOK_GRAPH_API + props.item.accessToken
        })
        const errors = response.error || response.errors
        if (!errors) {
            setPages(response.data)
        }
    }

    useEffect(() => {
        getPages()
    }, [])

    return (
        <FormDialog
            {...props}
            superTitle="Escolha uma página para integrar"
            onSubmit={() => {}}
            noSalvable
            noExcludable
        >
            <Loader
                loading={!pages}
                style={{ marginTop: 20, marginBottom: 20 }}
            >
                {pages &&
                    pages.data.map(x => (
                        <button className="facebook-page" key={x.id} type="button" onClick={() => {
                            // setChosenPage(x)
                            form.onSubmit(x)
                        }}>
                            <div className="left">
                                <img src={x.picture.data.url} alt="" />
                                <span className="page-name">{x.name}</span>
                            </div>
                            {/* <Radiobox
                                name="assignment"
                                checked={chosenPage.id === x.id}
                                onChange={() => setChosenPage(x)}
                            /> */}
                        </button>
                    ))}
            </Loader>
        </FormDialog>
    )
}

export default PagesModal
