import React, { useState, useMemo } from "react"
import { useBreadcrumb } from "hooks"
import { useStore, reportTags } from "store"
import Table from "components/Table"
import config from "config"
import queryString from "query-string"
import PageTitle from "components/PageTitle"
import { getIconClassName } from "@uifabric/styling"
import SettingsHelp from "components/SettingsHelp"
import FilterDropdown from "components/FilterDropdown"
import { CommandPage, useCommand } from "nanui"
import ReportFiltersPanel from "./ReportFiltersPanel"

function ReportTags(props) {
    useBreadcrumb({ title: "Relatório de Tags" })
    const reportStore = useStore(reportTags)
    const { infoBarItem } = useCommand()
    const [isFilterOpen, setFilterOpen] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "Nome da Tag",
                accessor: "name",
            },
            {
                Header: "Quantidade de vezes utilizada",
                accessor: "count",
            }
        ],
        [reportStore.all]
    )

    const commandPageProps = {
        commandBarProps: {
            farItems: [infoBarItem],
            items: [
                {
                    key: "export",
                    name: "Exportar",
                    cacheKey: "exportKey", // changing this key will invalidate this items cache
                    iconProps: {
                        iconName: "ShareiOS"
                    },
                    ariaLabel: "export",
                    subMenuProps: {
                        items: [
                            {
                                key: "emailMessage",
                                name: "Exportar para Email",
                                iconProps: {
                                    iconName: "Mail"
                                },
                                onClick: () => {
                                    reportStore.exportToEmail(
                                        config.api.REPORT_TAGS_EXPORT_EMAIL,
                                        queryString.parse(props.location.search)
                                    )
                                }
                            }
                        ]
                    }
                },
                // {
                //     key: "filter",
                //     name: "Filtros",
                //     iconProps: {
                //         iconName: "Filter"
                //     },
                //     onClick: () => setFilterOpen(true)
                // }
            ]
        },
        commandPanels: {
            info: (
                <SettingsHelp
                    title="Relatório de Tags"
                    icon={getIconClassName("AzureAPIManagement")}
                >
                    Aqui você tem acesso ao relatório das tags utilizadas nos atendimentos.
                </SettingsHelp>
            )
        }
    }

    return (
        <CommandPage {...commandPageProps}>
            <div className="settings-layout">
                <PageTitle
                    title={
                        <span style={{ display: "flex", flexWrap: "wrap" }}>
                            <span style={{ marginRight: 15 }}>Relatório de Tags</span>
                            {/* <FilterDropdown {...props} /> */}
                        </span>
                    }
                    style={{ paddingLeft: 0 }}
                />

                <Table
                    store={reportStore}
                    columns={columns}
                    query={props.location.search}
                    limit={9999}
                    defaultPageSize={20}
                    history={props.history}
                    nodata={{
                        title: "Parece que você ainda não possui nenhuma estatística..."
                    }}
                />
            </div>
            <ReportFiltersPanel
                isFilterOpen={isFilterOpen}
                setFilterOpen={setFilterOpen}
                {...props}
            />
        </CommandPage>
    )
}

export default ReportTags
