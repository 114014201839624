import React, { useState } from "react"
import { Link } from "react-router-dom"
import queryString from "query-string"
import Text from "components/forms/Text"
import Button from "components/forms/Button"
import Fade from "react-reveal/Fade"
import Checkbox from "components/forms/Checkbox"
import AuthBase from "./AuthBase"
import { useField, useCheckbox, useForm } from "hooks"
import { session, useStore } from "store"
// import ReCAPTCHA from "react-google-recaptcha"
// import {
//     GoogleReCaptchaProvider,
//     GoogleReCaptcha
// } from "react-google-recaptcha-v3"

function Register(props) {
    const { register } = useStore(session)
    // const [captchaToken, setCaptchaToken] = useState()
    const item = queryString.parse(props.location.search)

    const form = useForm({
        onSubmit: async formData => {
                register({
                    data: { ...formData }
                }).then(response => {
                    if (!response.error && !response.errors) {
                        return props.history.push(
                            "/user/register/creating",
                            response
                        )
                    }
                })
            }
    })

    const name = useField("name", {
        defaultValue: item.name,
        form
    })
    const workspace = useField("workspace", {
        defaultValue: item.workspace,
        form
    })
    const email = useField("email", {
        defaultValue: item.email,
        form
    })

    // const phone = useField("cellphone", {
    //     mask: ["cell"],
    //     form
    // })

    const password = useField("password", {
        form
        // validations: [
        //     value =>
        //         value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"
        // ]
    })
    const acceptTerms = useCheckbox("acceptTerms", {
        defaultValue: false
    })
    // const confirmPassword = useField("confirmPassword", {
    //     form,
    //     validations: [
    //         (value, formData) => value !== formData.password && "Suas senhas estão diferentes",
    //         value => value.length < 5 && "Sua senha deve ter no mínimo 6 caracteres"
    //     ]
    // })

    return (
        <AuthBase>
            <Fade>
                <form
                    className="auth-box register-box"
                    onSubmit={form.onSubmit}
                >
                    <h1 className="headline">Cadastre-se</h1>
                    <Text
                        label="Nome completo"
                        placeholder="Primeiro e último nome"
                        required
                        {...name}
                    />
                    <Text
                        label="Nome da empresa"
                        placeholder="Nome da sua empresa ou app"
                        required
                        {...workspace}
                    />
                    <Text
                        label="Email de trabalho"
                        type="email"
                        placeholder="você@suaempresa.com"
                        required
                        {...email}
                    />
                    {/* <Text
                        label="Telefone"
                        required
                        placeholder="(xx) xxxx-xxxx"
                        name="oi"
                        autofill="off"
                        autoComplete="off"
                        {...phone}
                    /> */}
                    <Text
                        label="Senha"
                        type="password"
                        required
                        autofill="off"
                        autoComplete="new-password"
                        {...password}
                    />
                    {/* <Text
                        label="Confirmar Senha"
                        type="password"
                        autoComplete="new-password"
                        required
                        {...confirmPassword}
                    /> */}

                    {/* <ReCAPTCHA
                        sitekey="6LdQPbMUAAAAAPPm6oPZmJPo6PgRt549MMlZnvw7"
                        stoken="6LdQPbMUAAAAAPPm6oPZmJPo6PgRt549MMlZnvw7"
                        onChange={token => {
                            setCaptchaToken(token)
                        }}
                    /> */}
                    {/* <GoogleReCaptchaProvider reCaptchaKey="6LdjQbMUAAAAACI_YeYdsKkLUFL2D_nGCjevOZUV">
                        <GoogleReCaptcha
                            onVerify={token => setCaptchaToken(token)}
                        />
                    </GoogleReCaptchaProvider> */}
                    <div style={{ marginTop: 7 }}>
                        <Checkbox {...acceptTerms}>
                            Li e aceito os{" "}
                            <Link to="/terms" style={{ color: "#2275d7" }}>
                                Termos e Condições
                            </Link>
                        </Checkbox>
                    </div>
                    <br />
                    <div className="login-actions">
                        <Button
                            type="submit"
                            className="login-btn"
                            primary
                            loader="register"
                            disabled={!acceptTerms.checked}
                        >
                            Cadastrar
                        </Button>
                    </div>
                </form>
            </Fade>
        </AuthBase>
    )
}

export default Register
