import React, { useState } from "react"
import Text from "components/forms/Text"
import FormDialog from "components/forms/FormDialog"
import ScriptJsonBox from "components/forms/ScriptJsonBox"
import { useField, useForm } from "hooks"
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

// const scriptExample = `\
// /*
// * Exporte uma função que recebe como parâmetro
// * as propriedades do usuário e retorna um JSON
// * com novas propriedades para usar no BOT
// */

// export default async function getProps(userProps) {
//   const response =
//         await fetch('https://example.com/status/'
//         		+ userProps.name)
//   return response.json() // { status: 'Em andamento' }
// }
// `
// const scriptExample = `\
// /*
// * Escreva um JSON conforme o exemplo abaixo
// * para chamar rotas da sua api.
// * Para mais detalhes, veja a documentação.
// */

// {
//     urL: 'https://example.com/status/',
//     method: 'post',
//     headers: [{
//         Authorization: 'xxxxxx'
//     }],
//     body: {
//         name: '{{name}}',
//         phone: '{{phone}}'
//     }
// }
// `
const scriptExample = {
    url: "https://example.com/status/",
    method: "post",
    headers: [
        {
            Authorization: "xxxxxx"
        }
    ],
    body: {
        name: "{{name}}",
        phone: "{{phone}}"
    }
}

// const scriptExample = JSON.stringify({
//     urL: "https://example.com/status/",
//     method: "post",
//     headers: [
//         {
//             Authorization: "xxxxxx"
//         }
//     ],
//     body: {
//         name: "{{name}}",
//         phone: "{{phone}}"
//     }
// })

function EditIntegration(props) {
    const actionItem = props.actionItem

    const form = useForm({
        onSubmit: async model => await props.onEditAction(model),
        defaultModel: { ...actionItem, type: "integration" }
    })

    const name = useField("name", {
        defaultValue: actionItem.name,
        form
    })

    const text = useField("text", {
        defaultValue: actionItem.text,
        form
    })

    const script = useField("script", {
        defaultValue: actionItem.script || scriptExample,
        form
    })

    return (
        <FormDialog
            {...props}
            title="Integração"
            onSubmit={form.onSubmit}
            item={{ ...actionItem, id: actionItem.guid }}
            onDelete={() => props.onDeleteAction(actionItem)}
        >
            <Text label="Nome da integração" required {...name} />
            <Text
                label="Texto"
                rows={3}
                placeholder="O status do seu pedido é {{status}}"
                {...text}
            />
            <ScriptJsonBox label="JSON" placeholder={actionItem.script || scriptExample} onChange={script.onChange} />
        </FormDialog>
    )
}
export default EditIntegration
